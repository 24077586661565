import React, { useState, useEffect } from 'react';
import { faTrash, faPencilAlt ,faHome,faFileExport,faFileImport} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import DropdownButton from '../../components/common/dropdown/DropdownButton';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import Datepicker from '../../components/common/datepicker/datepicker';
import CheckboxComponent from '../../components/common/CheckboxComponent/CheckboxComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { exportOrdersAction } from "../../redux/actions/exportapiActions";
import { getBulkOrders, getBulkOrderItems, deleteOrder, deleteBulkOrderItems,deleteBulkOrders } from '../../redux/actions/orderActions';
import './orderAssign.scss';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
const OrderManagement = () => {
  const token = useSelector((state) => state.adminLogin.token) || [];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    try {
      dispatch(getBulkOrders(token));
      dispatch(getBulkOrderItems(token));
    } catch (error) {
      // console.error("Error loading bulk orders or items", error);
    }
  }, [dispatch]);

  const orders = useSelector((state) => state.orders.orders) || [];
  const orderItems = useSelector((state) => state.orders.orderItems) || [];
  const users = useSelector((state) => state.usermanage.users) || [];
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null)
  const [selectedPayment, setSelectedPayment] = useState('All');
  const [selectedStatus, setSelectedStatus] = useState('All');
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [filteredOrders, setfilteredOrders] = useState(orders);
  const [isExporting, setIsExporting] = useState(false);
  const paymentOptions = ['All', 'paid', 'pending', 'failed'];
  const statusOptions = ['All', 'pending', 'completed', 'cancelled'];

  const deliveryRoleUsers = users.filter(user => user.role === 'delivery');
  const userOptions = [{ label: 'All', value: null }, ...deliveryRoleUsers.map(user => ({ label: user.name, value: user.user_id }))];
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const handlePaymentSelect = (option) => setSelectedPayment(option);
  const handleStatusSelect = (option) => setSelectedStatus(option);
  const handleUserSelect = (option) => setSelectedUserId(option.value);

    
  const handleCheckboxChange = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds((prev) => prev.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds((prev) => [...prev, id]);
    }
  };
  
  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedIds([]);
    } else {
      setSelectedIds(filteredOrders.map((item) => item.order_id));
    }
    setSelectAll(!selectAll);
  };
      useEffect(() => {
        if (selectedIds.length === filteredOrders.length) {
          setSelectAll(true);
        } else {
          setSelectAll(false);
        }
      }, [selectedIds, filteredOrders.length]);


  const handleEditClick = (id) => {
    navigate(`/admin/order-status-management`, { state: id });
  };

  

  const handleExportAction = async () => {
    setIsExporting(true);
    try {
      const startDateParam = startDate || "2024-01-01";
      const endDateParam = endDate || "2024-12-31";

      await dispatch(exportOrdersAction(token, startDateParam, endDateParam));
      toast.success("Export completed! The file has been downloaded.");
    } catch (error) {
      // console.error("Export failed:", error);
      toast.error("Failed to export orders. Please try again.");
    } finally {
      setIsExporting(false);
    }

    //console.log('Import button clicked');
  };


// Export button action: Navigate to the order-export-table page
const handleImportAction = () => {
  navigate('/admin/order-export-table'); // Navigate to the specified route
};

  const handleDeleteClick = async (id) => {
    if (window.confirm('Are you sure you want to delete this order?')) {
      const associatedOrderItemIds = orderItems
        .filter(item => item.order_id === id)
        .map(item => item.order_item_id);
      //console.log(id,"--id")
      await dispatch(deleteOrder(id, token));
      if (associatedOrderItemIds.length > 0) {
        await dispatch(deleteBulkOrderItems(associatedOrderItemIds, token));
      }
    }
  };

  const handleBulkDelete = async () => {
    if (window.confirm('Are you sure you want to delete the selected orders?')) {
      const selectedOrderItems = orderItems.filter(item => selectedIds.includes(item.order_id));
      const associatedOrderItemIds = selectedOrderItems.map(item => item.order_item_id);
      try {
        dispatch(deleteBulkOrders(selectedIds, token));

        if (associatedOrderItemIds.length > 0) {
          await dispatch(deleteBulkOrderItems(associatedOrderItemIds, token));
        }

        setSelectedIds([]);
        setSelectAll(false);
        toast.success('Selected orders and their items deleted successfully.');
        dispatch(getBulkOrders(token));
        dispatch(getBulkOrderItems(token));
      } catch (error) {
        toast.error('Failed to delete selected orders.');
      }
    }
  };

  const handleshowall = () => {
    setfilteredOrders(orders); // Reset to show all subscriptions
    setStartDate(null); // Clear start date
    setEndDate(null); // Clear end date
    toast.success('Showing all Orders.');
  };


  const handleSearch = () => {
    if (!startDate && !endDate) {
      toast.error('Please select a start date or end date.'); // Show error if neither is selected
      setfilteredOrders(orders); // Reset to show all orders
      return;
    }
  
    const filtered = orders.filter((order) => {
      const orderDate = new Date(order.order_date); // Parse order_date as a valid Date object
  
      // Validate and compare dates
      const matchesStartDate = !startDate || orderDate >= new Date(startDate); // If startDate is selected, check >=
      const matchesEndDate = !endDate || orderDate <= new Date(endDate); // If endDate is selected, check <=
      const matchesPayment = selectedPayment === 'All' || order.payment_status === selectedPayment; // Payment filter
      const matchesStatus = selectedStatus === 'All' || order.status === selectedStatus; // Status filter
      const matchesUser = selectedUserId === null || order.user_id === selectedUserId; // User filter
  
      return matchesStartDate && matchesEndDate && matchesPayment && matchesStatus && matchesUser;
    });
  
    setfilteredOrders(filtered); // Update the filtered orders state
  };
  
  

  useEffect(() => {
    setfilteredOrders(orders);
  }, [orders]);

  const getDeliveryPersonName = (user_id) => {
    const user = users.find(user => user.user_id === user_id);
    return user ? user.username : 'Unassigned';
  };

  return (
    <div className="order-management-page">
       <div className="breadcrumb-container">
        <Link to="/admin/dashboard" className="breadcrumb-text">
        <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <span className="breadcrumb-text">Order List</span>
      </div>
      
        <div className="request-purchase-list-container"></div>
      <div className="purchase-management-container">
        <div className="purchase-list-content">
  <div className="header-row">
    <h5 className="text-heading1"> Order List</h5>
   
    </div>
    </div>
        <hr />

        <div className="filter-section">
          <div className="filter-item">
            <label>Start Date</label>
            <Datepicker
            className={"date-input"}
              dateValue={startDate}
              onDateChange={setStartDate}
              placeholder="Select Start Date"
            />
          </div>
          <div className="filter-item">
            <label>End Date</label>
            <Datepicker
             className={"date-input"}
              dateValue={endDate}
              onDateChange={setEndDate}
              placeholder="Select End Date"
            />
          </div>
      
          <div className="filter-item">
            <label>Payment</label>
            <DropdownButton 
              className={"date-input"}
              options={paymentOptions}
              label={selectedPayment}
              onSelect={handlePaymentSelect}
            />
          </div>

          <div className="action-buttons">
          <ButtonComponent text="Search"   className="search-button"  onClick={handleSearch} />
          <ButtonComponent
            text={
              <>
                <FontAwesomeIcon icon={faTrash} className="bulk-delete-icon" /> Bulk Delete
              </>
            }
            className="bulk-delete-button"
            onClick={handleBulkDelete}
             disabled={selectedIds.length === 0}
            />
           
            <ButtonComponent text="Show All" className="show-all-button"    onClick={() => handleshowall(orders)} />
           
          </div>
        </div>
        <div className="button-container">       
      
            <ButtonComponent
                    text={
                      <>
                        <FontAwesomeIcon icon={faFileImport} className="import-icon" />
                        Import
                      </>
                    }
                    className="import-button"
                    onClick={handleImportAction}
                  />
                   
                  <ButtonComponent
                    text={
                      <>
                        <FontAwesomeIcon icon={faFileExport} className="export-icon" />
                        {isExporting ? "Exporting..." : "Export"}
                      </>
                    }
                    className="export-button"
                    onClick={handleExportAction}
                    disabled={isExporting}
                  />
            
                    </div>
                    {/* Display error message below the button */}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
        <hr className="hr-line" />

        <div className="table-container">
  <table className="custom-table">
    <thead>
      <tr>
        <th><CheckboxComponent checked={selectAll} onChange={handleSelectAllChange} /></th>
        <th>Order ID</th>
        <th>User Name (User ID)</th>
        <th>Order Status</th>
        <th>Payment Status</th>
        <th>Delivery Person</th>
        <th>Order Date</th>
        <th>Consignment Number</th>
        <th>Courier Name</th>
        <th>Edit</th>
        <th>Delete</th>
      </tr>
    </thead>
    <tbody>
      {filteredOrders.map((item, index) => (
        <tr key={item.order_id}>
          <td>
            <CheckboxComponent
              checked={selectedIds.includes(item.order_id)}
              onChange={() => handleCheckboxChange(item.order_id)}
            />
          </td>
          <td> {item.order_id.length > 9 ? `${item.order_id.slice(0, 9)}...` : item.order_id}</td>
          <td>
            {getDeliveryPersonName(item.user_id)}
           
          </td>
          <td>{item.order_status}</td>
          <td>{item.payment_status}</td>
          <td>{item.delivery_person || ''}</td>
          <td>{new Date(item.order_date).toLocaleString()}</td>
          <td>{item.consignment_number || ' '}</td>
          <td>{item.courier_name || ''}</td>
          <td>
            <button className="icon-button" onClick={() => handleEditClick(item.order_id)}>
              <FontAwesomeIcon icon={faPencilAlt} />
            </button>
          </td>
          <td>
            <button className="icon-button" onClick={() => handleDeleteClick(item.order_id)}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>


        </div>
      </div>
    </div>
  );
};

export default OrderManagement;