import React from 'react';
import { Dropdown } from 'react-bootstrap';
import './DropdownButton.scss';

const DropdownButton = ({ label, options, onSelect }) => {
  const handleSelect = (option) => {
    if (onSelect && typeof onSelect === 'function') {
      onSelect(option);
    } else {
      // console.warn('onSelect is not a function or not provided');
    }
  };

  return (
    <Dropdown>
      <Dropdown.Toggle className="btn-dropdown" id="dropdown-basic">
        {label}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {options.map((option, index) => (
          <Dropdown.Item
            key={index}
            onClick={() => handleSelect(option)}
            className="left-aligned-option"
          >
            {option}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownButton;
