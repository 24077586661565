import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import lottie from 'lottie-web';
import './OrderSuccessPage.scss';
import animationData from '../../assets/lotties/order-progress.json';
import DetailedStatusModal from '../../components/sections/DetailedStatusModal/DetailedStatusModal';
import ReturnDetailsFormModal from '../../components/sections/return-details-form-modal/ReturnDetailsFormModal';
import CardItem from '../../components/common/card/card-items';
import BillDetailsCard from '../../components/billorderdetails/billorderdetails';
import { getBulkOrderItems, updateOrder } from '../../redux/actions/orderActions'; 
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import toast from 'react-hot-toast';
import { updateProductsWorkflow } from '../../redux/actions/productActions';


const OrderSuccessPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const animationContainer = useRef(null);
  const animationInstance = useRef(null);

  const token = useSelector((state) => state.auth.token);
  const orders = useSelector((state) => state.orders.orders || []);
  const orderItems = useSelector((state) => state.orders.orderItems || []);
  const basicProducts = useSelector((state) => state.products.basicProducts || []); // Fetch basic products
  const productUrls = useSelector((state) => state.image.urls.products || []); 
  const order_id = location.state || null;
  const [cancellingStatus,setCancellingStatus]=useState(false)
  const [canCancel, setCanCancel] = useState(false);
  // Fetch order items
  useEffect(() => {
    if (token) {
      dispatch(getBulkOrderItems(token));
    }
  }, [dispatch, token]);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, []);
  const handleProductClick = (productId, productName) => {
   
    navigate(`/singleproducts/${encodeURIComponent(productName)}`, { state: productId });
    setTimeout(() => {
      window.scrollTo(0, 0); // Ensure it scrolls to the top after navigation
    }, 100);
  };

  // Find current order and items
  const currentOrder = orders.find((order) => order.order_id === order_id);
  const currentOrderItems = orderItems.filter((item) => item.order_id === order_id);
  const addresses = useSelector((state) => state.address.addresses || []); 
  const [currentStage, setCurrentStage] = useState(0);
  //console.log(currentOrderItems,order_id,'current order items')
  const statusToFrameMap = {
    pending: 15,
    processing: 25,
    shipped: 50,
    delivered: 100,
    failed:100
  };
  const currentAddress = addresses.find(
    (address) => address.address_id === currentOrder?.billing_address_id
  );

useEffect(() => {
  if (currentOrder?.order_status && animationInstance.current) {
    const totalFrames = animationInstance.current.totalFrames;
    const isFailed = currentOrder.order_status === 'failed'; // Check if status is failed
    const frame = isFailed
      ? totalFrames // Full progress for failed status
      : (statusToFrameMap[currentOrder.order_status] / 100) * totalFrames;

    animationInstance.current.goToAndStop(frame, true); // Adjust animation frame
    setCurrentStage(statusToFrameMap[currentOrder.order_status]); // Update progress percentage

    //console.log(`Order Status: ${currentOrder.order_status}, Frame: ${frame}`);
  }
}, [currentOrder, statusToFrameMap]);
  useEffect(() => {
    if (animationContainer.current) {
      animationInstance.current = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animationData,
      });
  
      //console.log('Animation initialized');
    }
  
    return () => {
      if (animationInstance.current) {
        animationInstance.current.destroy();
      }
    };
  }, []);


  useEffect(() => {
    if (currentOrder) {
      const orderPlacedTime = new Date(currentOrder.order_date);
      const now = new Date();
      const diffInMinutes = Math.floor((now - orderPlacedTime) / 60000);
      setCanCancel(diffInMinutes > 5);
    }
  }, [currentOrder]);

  
  const updateProductStockLevels = async (cartItems) => {
    //console.log('cancelling items',cartItems)
    const stockLevelDecrements = cartItems
      .map((item) => {
        const product = basicProducts.find((p) => p.product_id === item.product_id);
        if (product) {
          return {
            product_id: product.product_id,
            stock_level: +product.stock_level + +item.quantity,
          };
        }
        return null;
      })
      .filter((product) => product !== null);
      //console.log('cancelling items',cartItems,stockLevelDecrements)
    if (stockLevelDecrements.length > 0) {
      try {
        await dispatch(updateProductsWorkflow(stockLevelDecrements, '', token));
        //console.log('Stock levels updated successfully.');
      } catch (error) {
        console.error('Error updating stock levels:', error);
      }
    }
  };

  // Handle Cancel Order

  // Map order items with product details
  const mappedOrderItems = currentOrderItems.map((item) => {
    const productDetails = basicProducts.find(
      (product) => product.product_id === item.product_id
    );

    return {
      ...item,
      product_name: productDetails?.product_name || 'Unknown Product',
      price: productDetails?.price || 0,
      mrp: productDetails?.mrp || 0,
      discount: productDetails?.discount || '0%',
      imageUrl: productDetails?.image_url,
    };
  });
  const handleCancelOrder = async () => {
    if (currentOrder) {
      setCancellingStatus(true);
      await dispatch(updateOrder({ order_id: currentOrder.order_id, order_status: 'cancelled' }, token));
      await updateProductStockLevels(mappedOrderItems);
      toast.success("Your order has been cancelled successfully");
      setCancellingStatus(false);
     navigate('/orders')
    }
  };

  return (
    <div className="order success-page">
      <div className="left-section">
        {currentOrder ? (
          <>
            <div className="order number">Order #{currentOrder.order_id}</div>
            <div
  className={`order status ${
    currentOrder?.order_status === 'failed' ? 'failed' : 'success'
  }`}
>
  <span className="order status-indicator">{currentOrder.order_status}</span>
</div>

            <div className="order delivery-info">
              <p className="order delivery-date">
                Delivery on: {new Date(currentOrder.delivery_date).toLocaleDateString()}
              </p>
            </div>
            {currentOrder?.order_status !== 'failed' && (
        <div
          ref={animationContainer}
          className={`progress-bar-container ${
            currentOrder?.order_status === 'failed' ? 'failed' : 'success'
          }`}
          style={{
            width: '100%',
            height: '150px', // Height for progress bar visibility
            marginBottom: '20px',
          }}
        ></div>
      )}



            <div className="order ordered-items">
              <h4 className="order ordered-items-header">
                {mappedOrderItems.length} Ordered Item(s)
              </h4>
              {mappedOrderItems.map((item, index) => (
                <CardItem
                manage={true}
                  key={index}
                  imageUrl={productUrls[item.product_id]}
                  title={item.product_name}
                  price={item.price}
                  quantity={item.quantity}
                  mrp={item.mrp}
                  discount={item.discount}
                  hideClose={true}
                  hidePause={true}
                  onClick={() => handleProductClick(item.product_id, item.product_name)}

                />
              ))}
            </div>
            <div className="order details-box">
                <div className="order bordered-box">
                  <p className="order address-details">
                    <strong>Address</strong>
                  </p>
                  {currentAddress ? (
                    <p>
                      <span className="order address-label">
                        {currentAddress.address_type || 'N/A'}
                      </span>{' '}
                      {currentAddress.address}, {currentAddress.city}, {currentAddress.state} -{' '}
                      {currentAddress.pincode}
                    </p>
                  ) : (
                    <p>Address Not Available</p>
                  )}
                </div>
                {currentOrder.order_status === 'cancel' && (
                  <div className="order cancellation-bordered-box">
                    <p className="order order-cancel-details">
                      <strong>Order Cancelled On:</strong>{' '}
                      {new Date(currentOrder.cancellation_date).toLocaleDateString()}
                    </p>
                    <p>Cancellation reason: {currentOrder.cancellation_reason || 'Unknown'}</p>
                  </div>
                )}
              </div>
              {currentOrder.order_status !== 'cancel' && currentOrder.order_status !== 'failed' &&  currentOrder.order_status !== 'completed'&&  (
  <div className="order cancel-order">
    {currentOrder.order_status !== 'cancelled' && !canCancel && (
  <ButtonComponent
    text={cancellingStatus ? 'Cancelling...' : 'Cancel Order'}
    className="order cancel-button"
    onClick={handleCancelOrder}
    disabled={cancellingStatus || canCancel}
  />
    )}
 
</div>




)}

          </>
        ) : (
          <div>Loading Order Details...</div>
        )}
      </div>

      <div className="right-section">
        <BillDetailsCard orderId={order_id} />
      </div>

      <DetailedStatusModal show={false} onHide={() => {}} />
      <ReturnDetailsFormModal show={false} onHide={() => {}} />
    </div>
  );
};

export default OrderSuccessPage;
