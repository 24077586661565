import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputComponent from "../../components/common/InputComponent/InputComponent";
import ButtonComponent from "../../components/common/buttons/ButtonComponent";
import "./inventory-threshold.scss";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckboxComponent from "../../components/common/CheckboxComponent/CheckboxComponent";
import SearchableDropdown from "../../components/common/SearchableDropdown/SearchableDropdown";
import {
  addInventoryThreshold,
  modifyInventoryThreshold,
  
} from "../../redux/actions/inventoryThresholdActions";

const InventoryThreshold = () => {
  const dispatch = useDispatch();

  // State for the form fields
  // const [thresholdId, setThresholdId] = useState("");
  const [productId, setProductId] = useState("");
  const [appliesToAll, setAppliesToAll] = useState(false);
  const [minThreshold, setMinThreshold] = useState("");
  const [maxThreshold, setMaxThreshold] = useState("");
  const [autoThreshold, setAutoThreshold] = useState("");
  const [currentStock,setcurrentStock]=useState('');
  const [originalData, setOriginalData] = useState({});
  const [isModified, setIsModified] = useState(false);

  const [errors, setErrors] = useState({}); // State to store validation errors
  const location = useLocation();
  const navigate = useNavigate();
  const thresholdId = location.state || null;
  const token = useSelector((state) => state.auth.token);
  const productList = useSelector((state) => state.products.basicProducts || []);
  const inventoryThresholds = useSelector((state) => state.inventoryThreshold.inventoryThresholds || []);
  const product_details = useSelector((state) => state.products.basicProducts) || [];
 
  const [loading, setLoading] = useState(false);
  // Populate product options for the dropdown
  const productOptions = productList.map((product) => ({
    label: product.product_name,
    id: product.product_id,
  }));

  // Form validation function
  const validateForm = () => {
    const validationErrors = {};
    // if (!thresholdId) validationErrors.thresholdId = "Threshold ID is required";
    if (!productId) validationErrors.productId = "Product ID is required";
    if (minThreshold === "") validationErrors.minThreshold = "Min Threshold is required";
    if (maxThreshold === "") validationErrors.maxThreshold = "Max Threshold is required";
    if (autoThreshold === "") validationErrors.autoThreshold = "Auto Threshold is required";

    return validationErrors;
  };
  const handleProductSelection = (selectedId) => {
    setProductId(selectedId);
  
    // Find the selected product details
    const selectedProduct = productList.find((product) => product.product_id === selectedId);
  
    // Update currentStock based on the selected product's stock
    if (selectedProduct) {
      setcurrentStock(selectedProduct.stock_level || "0"); // Default to "0" if stock is undefined
    }
  };
 


  useEffect(() => {
    if (thresholdId) {
      const thresholdData = inventoryThresholds.find(
        (threshold) => threshold.threshold_id === thresholdId
      );
      if (thresholdData) {
        // Set individual states
        setProductId(thresholdData.product_id || "");
        setAppliesToAll(thresholdData.applies_to_all || false);
        setMinThreshold(thresholdData.min_threshold || "");
        setMaxThreshold(thresholdData.max_threshold || "");
        setAutoThreshold(thresholdData.auto_threshold || "");
        setcurrentStock(thresholdData.current_stock || "");
  
        // Set original data
        setOriginalData({
          product_id: thresholdData.product_id || "",
          applies_to_all: thresholdData.applies_to_all || false,
          min_threshold: thresholdData.min_threshold || "",
          max_threshold: thresholdData.max_threshold || "",
          auto_threshold: thresholdData.auto_threshold || "",
          current_stock: thresholdData.current_stock || "",
        });
      }
    }
  }, [thresholdId, inventoryThresholds]);
  
  useEffect(() => {
    const currentData = {
      product_id: productId,
      applies_to_all: appliesToAll,
      min_threshold: minThreshold,
      max_threshold: maxThreshold,
      auto_threshold: autoThreshold,
      current_stock: currentStock,
    };
  
    setIsModified(JSON.stringify(currentData) !== JSON.stringify(originalData));
  }, [productId, appliesToAll, minThreshold, maxThreshold, autoThreshold, currentStock, originalData]);
  
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    navigate('/admin/inventory-displaypage');
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const thresholdData = {
      threshold_id: thresholdId,
      product_id: productId,
      applies_to_all: appliesToAll,
      min_threshold: parseFloat(minThreshold),
      max_threshold: parseFloat(maxThreshold),
      auto_threshold: autoThreshold ? parseFloat(autoThreshold) : null,
      current_stock:currentStock
    };

    try {
      //console.log(thresholdData);
      if (thresholdId) {
        await dispatch(modifyInventoryThreshold(thresholdId, thresholdData, token));
      } else {
        await dispatch(addInventoryThreshold(thresholdData, token));
      }
    } catch (error) {
      // console.error("Error during threshold operation:", error.message);
    }
  };

  return (
    <div className="inventory-page-section">
      {/* Breadcrumb Navigation */}
      <div className="breadcrumb-container">
        <Link to="/admin/dashboard" className="breadcrumb-text">
          <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <Link to="/admin/inventory-displaypage" className="breadcrumb-text">Inventory List</Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <span className="breadcrumb-text">Add Inventory</span>
      </div>

      <div className="inventory-container"></div>
      <div className="inventory-page">
        <h5 className="text-heading1">Inventory Threshold</h5>

        <form onSubmit={handleSubmit}>
          {/* Row 1 */}
          <div className="form-row">
            
            <div className="form-group">
              <label>Product</label>
              <SearchableDropdown
            placeholder="Search for a product"
            options={productOptions}
            value={productOptions.find((option) => option.id === productId)?.label || ""}
            onSelect={handleProductSelection}
          />

              {errors.productId && <span className="error-text">{errors.productId}</span>}
            </div>
          
            <div className="form-group">
              <label>Min Threshold</label>
              <InputComponent
                type="number"
                placeholder="Enter Min Threshold"
                value={minThreshold}
                onChange={(e) => setMinThreshold(e.target.value)}
              />
              {errors.minThreshold && <span className="error-text">{errors.minThreshold}</span>}
            </div>
            </div>

          {/* Row 2 */}
          <div className="form-row">
            <div className="form-group">
              <label>Max Threshold</label>
              <InputComponent
                type="number"
                placeholder="Enter Max Threshold"
                value={maxThreshold}
                onChange={(e) => setMaxThreshold(e.target.value)}
              />
              {errors.maxThreshold && <span className="error-text">{errors.maxThreshold}</span>}
            </div>
          
            <div className="form-group">
              <label>Auto Threshold</label>
              <InputComponent
                type="number"
                placeholder="Enter Auto Threshold"
                value={autoThreshold}
                onChange={(e) => setAutoThreshold(e.target.value)}
              />
              {errors.autoThreshold && <span className="error-text">{errors.autoThreshold}</span>}
            </div>
            </div>

          {/* Row 3 */}
          <div className="form-row">
            <div className="form-group">
              <label>Current Stock</label>
              <InputComponent
                      type="number"
                      placeholder="Enter current stock"
                      value={currentStock}
                      readOnly // Make this field non-editable
                    />
              {errors.autoThreshold && <span className="error-text">{errors.autoThreshold}</span>}
            </div>
             
                          
            <div className="form-group">
                  <div className="applies-to-all">
                    <input
                      type="checkbox"
                      id="appliesToAll"
                      checked={appliesToAll}
                      onChange={(e) => setAppliesToAll(e.target.checked)}
                    />
                    <label htmlFor="appliesToAll" className="checkbox-label">
                      Applies to All
                    </label>
                  </div>
                </div>


              </div>

       

          {/* Submit Button */}
          <div className="form-row save-button-container">
            <ButtonComponent
             text={loading ? 'Saving...' : 'Save'} 
             
             className={`submit-button ${!isModified ? 'disabled' : ''}`}
              onClick={handleSubmit}
              disabled={!isModified}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default InventoryThreshold;
