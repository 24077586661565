import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ButtonComponent from '../../components/common/buttons/ButtonComponent'; 
import { FaTrash, FaPlus } from 'react-icons/fa';
import './bulk-location.scss'; 
import DropdownButton from '../../components/common/dropdown/DropdownButton';
import { bulkCreateLocations, bulkUpdateLocations } from "../../redux/actions/locationAction";
import toast from "react-hot-toast";
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import InputComponent from '../../components/common/InputComponent/InputComponent';

const BulkAllocateLocation = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [locationData, setLocationData] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const statusOptions = ["Yes", "No"];
  const [entityOptions, setEntityOptions] = useState([]);
  
  const locations = useSelector(state => state.location.locations || []); 
  const token = useSelector(state => state.adminLogin.token);
  const initialLocations = location.state || [];

  const servicable_fun =(required)=>{
    if (required === "Yes" || required ==="yes" || required ==="YES" ){
      return true;
    }
    else if(required === "no" || required ==="No" || required ==="NO" ){
      return false;
    }
  }
  useEffect(() => {
    const processLocations = () => {
      if (initialLocations.length === 0) {
        if (locationData.length === 0) {
          setLocationData([{ location_id: '', location_name: '', base_charge: '', additional_charge_per_km: '', serviceable: false }]);
        }
      } else {
        if (typeof initialLocations[0] === 'object') {
          const fetchedLocations = initialLocations.map(loc => ({
            location_id: loc.location_id,
            location_name: loc.location_name,
            base_charge: loc.base_charge,
            additional_charge_per_km: loc.additional_charge_per_km,
            serviceable:servicable_fun(loc.serviceable) || false,
          }));
          setLocationData(JSON.parse(JSON.stringify(fetchedLocations))); // Ensure deep copy
        } else if (typeof initialLocations[0] === 'string') {
          const fetchedLocations = initialLocations.map(id => {
            const location = locations.find(l => l.location_id === id);
            return location ? { ...location } : { location_id: id, location_name: '', base_charge: '', additional_charge_per_km: '', serviceable: false };
          });
          setLocationData(fetchedLocations);
        }
      }
    };
  
    processLocations();
  }, [initialLocations, locations]);
  
  const handleSaveClick = async () => {
    const hasEmptyFields = locationData.some(location => !location.location_name || !location.base_charge );
    if (hasEmptyFields) {
      toast.error("Please fill in all mandatory fields (Location Name, Base Charge, and Status).");
      return;
    }

    setIsSaving(true);
    let responseStatus;

    if (Array.isArray(initialLocations) && initialLocations.length > 0) {
      if (typeof initialLocations[0] === 'object') {
        responseStatus = await dispatch(bulkCreateLocations(locationData, token));
      } else if (typeof initialLocations[0] === 'string') {
        responseStatus = await dispatch(bulkUpdateLocations(locationData, token));
      }
    }

    setIsSaving(false);
    
    if (responseStatus) {
      navigate('/admin/location');
    } else {
      // console.error("Failed to save locations");
    }
  };

  const handleInputChange = (index, field, value) => {
    setLocationData(prevLocations => 
      prevLocations.map((loc, locIndex) => 
        locIndex === index ? { ...loc, [field]: value } : loc
      )
    );
  };

  const handleAddRow = () => {
    setLocationData(prevLocations => [
      ...prevLocations,
      { location_id: '', location_name: '', base_charge: '', additional_charge_per_km: '', serviceable: '' }
    ]);
  };

  const handleDelete = (index) => {
    const newLocations = [...locationData];
    newLocations.splice(index, 1);
    setLocationData(newLocations);
  };

  return (
    <div className="bulk-location-main-page">
    <div className="breadcrumb-container">
      
       <Link to="/admin/dashboard" className="breadcrumb-text">
       <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
       </Link>
       <span className="breadcrumb-separator">{'>'}</span>
       <Link to="/admin/location" className="breadcrumb-text">
       <span className="breadcrumb-text">Location Service</span>
       </Link>
    
       <span className="breadcrumb-separator">{'>'}</span>
       <span className="breadcrumb-text">Bulk Location</span>
     </div>
     <div className="bulk-location-container"></div>
 <div className="bulk-location">
    <div className="location-table">
    <div className="header-row">
    <h5 className="text-heading1">Bulk Location</h5>
      <div className="add-new-button">
        {typeof initialLocations[0] === 'object' &&(
             <ButtonComponent 
             text={<><FaPlus style={{ marginRight: '8px' }} />Add New</>} 
             onClick={handleAddRow} 
             className="add-button" 
           />
        )}
       
        <ButtonComponent 
          text={isSaving ? "Saving..." : "Save"} 
          onClick={handleSaveClick} 
          className="save-button" 
          disabled={isSaving} 
        />
      </div>
      </div>
      </div>
      <div className="table-container">
        <table className="custom-table">
          <thead>
            <tr>
              <th>Location Name</th>
              <th>Base Charge</th>
              <th>Additional Charge per KM</th>
              <th>Status</th>
              <th className="small-column">Delete</th>
            </tr>
          </thead>
          <tbody>
            {locationData.map((location, index) => (
              <tr key={index}>
                <td>
                  <InputComponent
                    placeholder="Location Name"
                    value={location.location_name}
                    onChange={(e) => handleInputChange(index, 'location_name', e.target.value)}
                  />
                </td>
                <td>
                  <InputComponent
                    placeholder="Base Charge"
                    type="number"
                    value={location.base_charge}
                    onChange={(e) => handleInputChange(index, 'base_charge', e.target.value)}
                  />
                </td>
                <td>
                  <InputComponent
                    placeholder="Additional Charge per KM"
                    type="number"
                    value={location.additional_charge_per_km}
                    onChange={(e) => handleInputChange(index, 'additional_charge_per_km', e.target.value)}
                  />
                </td>
                <td>
            <DropdownButton
                label={servicable_fun(location.serviceable) ? 'Yes' : 'No'}
                options={statusOptions}
                onSelect={(selectedOption) => handleInputChange(index, 'serviceable', selectedOption === 'Yes')}
            />
            </td>
                <td className="actions1">
                  <FaTrash className="action-icon1" onClick={() => handleDelete(index)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
      
  );
};

export default BulkAllocateLocation;
