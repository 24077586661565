import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import InputComponent from '../../components/common/InputComponent/InputComponent'; 
import ButtonComponent from '../../components/common/buttons/ButtonComponent'; 
import { FaTrash, FaPlus } from 'react-icons/fa';
import "./bulk-discount.scss"; // Assuming you will create a specific stylesheet for offer table
import DropdownButton from '../../components/common/dropdown/DropdownButton';
import { bulkUpdateOffers, bulkCreateOffers } from "../../redux/actions/discountAction"; // Adjust this import based on your action structure
import toast from "react-hot-toast";
import {  faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const OfferTable = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const offers = useSelector(state => state.discount.offerList || []); // Adjust according to your Redux state structure

  const token = useSelector(state => state.adminLogin.token);
  
  const initialOffers = location.state || [];
  const [offerData, setOfferData] = useState([]);
  const [duplicateErrors, setDuplicateErrors] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const statusOptions = ["active", "inactive", "expired"];

  const normalizeDate = (dateString) => {
    if (!dateString) return '';
    const dateParts = dateString.match(/(\d{2})[-\/](\d{2})[-\/](\d{4})/);
    if (dateParts) {
      return `${dateParts[3]}-${dateParts[2]}-${dateParts[1]}`; // Convert to YYYY-MM-DD
    }
    const date = new Date(dateString);
    return !isNaN(date) ? date.toISOString().split('T')[0] : ''; // Fallback to default date parsing
  };
  useEffect(() => {
    const processOffers = () => {
      if (!initialOffers || initialOffers.length === 0) {
        // Initialize with default values if no offers are present
        setOfferData([{
          offer_type: '',        // Default value for offer_type
          discount_value: '',    // Default value for discount_value
          conditions: '',        // Default value for conditions
          start_date: '',        // Default value for start_date
          expiry_date: '',       // Default value for expiry_date
          status: '',            // Default value for status
        }]);
      } else {
        if (typeof initialOffers[0] === 'object') {
          // Set existing offers if they are provided as objects
          setOfferData(initialOffers);
        } else if (typeof initialOffers[0] === 'string' || typeof initialOffers[0] === 'number') {
          // Map IDs to detailed offer information if they are provided as IDs
          const fetchedOffers = initialOffers.map(id => {
            const offer = offers.find(offer => offer.offer_id === id);
            return offer ? { 
              ...offer, 
              offer_id: id 
            } : {
              offer_id: id,
              offer_type: '',         // Default value for offer_type
              discount_value: '',     // Default value for discount_value
              conditions: '',         // Default value for conditions
              start_date: '',         // Default value for start_date
              expiry_date: '',        // Default value for expiry_date
              status: '',             // Default value for status
            };
          });
          setOfferData(fetchedOffers);
        }
      }
    };
  
    processOffers();
  }, [initialOffers, offers]); // Use dependencies for initialOffers and offerList
  
  const handleInputChange = (index, field, value) => {
    const updatedOffers = [...offerData];
  
    // Convert to integer if numeric field
    if (['discount_value'].includes(field)) {
      updatedOffers[index][field] = parseInt(value) || 0;
    } else {
      updatedOffers[index][field] = value;
    }
  
    setOfferData(updatedOffers);
  
    // Reset error if offer_type is modified
    if (field === 'offer_type') {
      const updatedErrors = [...duplicateErrors];
      updatedErrors[index] = false;
      setDuplicateErrors(updatedErrors);
    }
  };

  const handleAddRow = () => {
    setOfferData(prevOffers => [
      ...prevOffers,
      { offer_type: '', discount_value: '', conditions: '', start_date: '', expiry_date: '', status: '' }
    ]);
    setDuplicateErrors(prevErrors => [...prevErrors, false]); // Add new error state
  };

  const handleDelete = (index) => {
    const newOffers = [...offerData];
    newOffers.splice(index, 1);
    setOfferData(newOffers);

    const newErrors = [...duplicateErrors];
    newErrors.splice(index, 1); // Remove corresponding error state
    setDuplicateErrors(newErrors);
  };

  // Check for duplicate offer types in the existing offers
  const checkForDuplicateOfferTypes = () => {
    const existingOfferTypes = offers.map(offer => offer.conditions.trim().toLowerCase());
    const updatedErrors = offerData.map(offer => {
      return !offer.offer_id && existingOfferTypes.includes(offer.conditions.trim().toLowerCase()) && offer.conditions.trim() !== '';
    });
    setDuplicateErrors(updatedErrors);

    return updatedErrors.some(error => error); // Returns true if any duplicate found
  };

  const handleSaveClick = async () => {
    //console.log("saveeeee-----",initialOffers,typeof initialOffers[0])
    const hasEmptyFields = offerData.some(offer => 
      !offer.offer_type.trim() || !offer.status
    );

    if (hasEmptyFields) {
      toast.error("Please fill in all mandatory fields (Offer Type and Status).");
      return;
    }
    //console.log("saveeeee-----11",initialOffers,typeof initialOffers[0])
    if (checkForDuplicateOfferTypes()) {
      return;
    }
    //console.log("saveeeee-----2222",initialOffers,typeof initialOffers[0])

    setIsSaving(true);
    let responseStatus = false;
    //console.log("saveeeee-----33333",initialOffers,typeof initialOffers[0])
    if (typeof initialOffers[0] === 'object') {
      //console.log("saveeeee-----finallllll",initialOffers,typeof initialOffers[0])
      responseStatus = await dispatch(bulkCreateOffers(offerData, token));
    } else {
      responseStatus = await dispatch(bulkUpdateOffers(offerData, token));
    }

    setIsSaving(false);

    if (responseStatus) {
      toast.success("updated successfully");
      navigate('/admin');
    } else {
      // console.error("Failed to save offers");
    }
  };

  return (
    <div className="bulk-discount-main-page">
    

    <div className="breadcrumb-container">
     
        <Link to="/admin/dashboard" className="breadcrumb-text">
        <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <Link to="/admin" className="breadcrumb-text">
        <span className="breadcrumb-text">Discount list</span>
        </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <span className="breadcrumb-text">Bulk discount</span>
   </div>
   <div className="bulk-discount-container"></div>
   <div className="bulk-discount">
    <div className="offer-table">
    <div className="header-row">
    <h5 className="text-heading1">Bulk Discount</h5>
      <div className="add-new-button">
        {typeof initialOffers[0] === 'object' && (
          <ButtonComponent 
            text={<><FaPlus style={{ marginRight: '8px' }} />Add New</>} 
            onClick={handleAddRow} 
            className="add-button" 
          />
        )}
        <ButtonComponent 
          text={isSaving ? "Saving..." : "Save"} 
          onClick={handleSaveClick} 
          className="save-button" 
          disabled={isSaving} 
        />
      </div>
      </div>
      </div>
      <div className="table-container">
        <table className="custom-table">
          <thead>
            <tr>
              <th>Offer Type</th>
              <th>Discount Value</th>
              <th>Conditions</th>
              <th>Start Date</th>
              <th>Expiry Date</th>
              <th>Status</th>
              <th className="small-column">Delete</th>
            </tr>
          </thead>
          <tbody>
            {offerData.map((offer, index) => (
              <tr key={index}>
                <td>
                <DropdownButton
                label={offer.offer_type || "Select Offer Type"} // Display selected type or placeholder
                options={["fixed", "percentage"]} // Dropdown options
                onSelect={(selectedOption) => handleInputChange(index, 'offer_type', selectedOption)} // Update the offer_type field
              />

          
                </td>
                <td>
                  <InputComponent
                    className="table-input"
                    value={offer.discount_value}
                    type="number"
                    onChange={(e) => handleInputChange(index, 'discount_value', e.target.value)}
                  />
                </td>
                <td>
                  <InputComponent
                    className="table-input"
                    value={offer.conditions}
                    onChange={(e) => handleInputChange(index, 'conditions', e.target.value)}
                  />
                </td>
                <td>
                  <InputComponent
                    className="table-input"
                    type="date"
                    value={normalizeDate(offer.start_date)}
                    onChange={(e) => handleInputChange(index, 'start_date', e.target.value)}
                  />
                </td>
                <td>
                  <InputComponent
                    className="table-input"
                    type="date"
                    value={normalizeDate(offer.expiry_date)}
                    onChange={(e) => handleInputChange(index, 'expiry_date', e.target.value)}
                  />
                </td>
                <td>
                  <DropdownButton
                    options={statusOptions}
                    label={offer.status || 'Select Status'}
                    onSelect={(value) => handleInputChange(index, 'status', value)}
                  />
                </td>
                <td className="actions1">
                  <FaTrash className="action-icon1" onClick={() => handleDelete(index)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
  );
};

export default OfferTable;
