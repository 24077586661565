import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputComponent from '../../components/common/InputComponent/InputComponent';
import {  faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import {
    createBusinessInfo,
    updateBusinessInfo,
    fetchBusinessInfoById,
} from '../../redux/actions/businessAction';
import './business-information.scss';

const BusinessInformation = () => {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token); // Assuming auth token is in Redux state
    const businessInfo = useSelector((state) => state.businessInfo.selectedBusinessInfo); // Assuming `businessInfo` is in Redux state
    const user_id = useSelector((state) => state.auth.user);
    //console.log(user_id)
    // State management for form inputs
    const [pharmacyName, setPharmacyName] = useState('');
    const [licenseInfo, setLicenseInfo] = useState('');
    const [businessRegistration, setBusinessRegistration] = useState('');
    const [businessAddress, setBusinessAddress] = useState('');
    const [gstNumber, setGstNumber] = useState('');
    const [proofDocument, setProofDocument] = useState('');
    const [contactInfo, setContactInfo] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [shopId, setShopId] = useState('');
    const [internalId, setInternalId] = useState(''); // NEW: State for Internal ID
    const [businessId, setBusinessId] = useState(null);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [isReadOnly, setIsReadOnly] = useState(false);
    // Reference for original state
    const [originalState, setOriginalState] = useState({});

    // Fetch business information on component mount
    useEffect(() => {
        const businessId = user_id; // Replace with dynamic ID if needed
        dispatch(fetchBusinessInfoById(businessId, token));
        
    }, [dispatch, token]);

    // Populate fields when businessInfo is fetched
    useEffect(() => {
        //console.log(businessInfo,'infoooooooooooooo---------------')
        if (businessInfo) {
            const initialState = {
                pharmacyName: businessInfo[0].business_name || '',
                licenseInfo: businessInfo[0].license_information || '',
                businessRegistration: businessInfo[0].business_registration || '',
                businessAddress: businessInfo[0].business_address || '',
                gstNumber: businessInfo[0].gst || '',
                proofDocument: businessInfo[0].proof || '',
                contactInfo: businessInfo[0].contact_info || '',
                emailAddress: businessInfo[0].email || '',
                phoneNumber: businessInfo[0].phone || '',
                shopId: businessInfo[0].shop_id || '',
                internalId: businessInfo[0].internal_id || '', // NEW: Populate Internal ID
            };


            setPharmacyName(initialState.pharmacyName);
            setLicenseInfo(initialState.licenseInfo);
            setBusinessRegistration(initialState.businessRegistration);
            setBusinessAddress(initialState.businessAddress);
            setGstNumber(initialState.gstNumber);
            setProofDocument(initialState.proofDocument);
            setContactInfo(initialState.contactInfo);
            setEmailAddress(initialState.emailAddress);
            setPhoneNumber(initialState.phoneNumber);
            setShopId(initialState.shopId);
            setInternalId(initialState.internalId); // NEW: Set Internal ID
            setBusinessId(businessInfo.business_id || null);
            setOriginalState(initialState); // Save original state
            setIsSaveDisabled(true); // Disable Save button initially
           setIsReadOnly(true);
        
        } else {
            // No data found, enable Save button
            setIsSaveDisabled(false);

        }
    }, [businessInfo]);

    // Check if current state matches original state
    const isStateUnchanged = () => {
        return (
            pharmacyName === originalState.pharmacyName &&
            licenseInfo === originalState.licenseInfo &&
            businessRegistration === originalState.businessRegistration &&
            businessAddress === originalState.businessAddress &&
            gstNumber === originalState.gstNumber &&
            proofDocument === originalState.proofDocument &&
            contactInfo === originalState.contactInfo &&
            emailAddress === originalState.emailAddress &&
            phoneNumber === originalState.phoneNumber &&
            shopId === originalState.shopId &&
            internalId === originalState.internalId // NEW: Compare Internal ID
        );
    };

    // Handle input changes
    const handleInputChange = (setter, value) => {
        setter(value);
        // Enable Save button only if state changes
    };

    // Handle Save Button Click
    const handleSave = () => {
        const businessData = {
            business_id: businessId, // Only for update
            shop_id: shopId, // Editable shop_id
            client_id: user_id, // Example client ID, replace dynamically
            business_name: pharmacyName,
            license_information: licenseInfo,
            business_registration: businessRegistration,
            business_address: businessAddress,
            gst: gstNumber,
            proof: proofDocument,
            contact_info: contactInfo,
            email: emailAddress,
            phone: phoneNumber,
            internal_id: internalId, // NEW: Include Internal ID
        };

        // Create new business information
        dispatch(createBusinessInfo(businessData, token));

        setOriginalState({
            pharmacyName,
            licenseInfo,
            businessRegistration,
            businessAddress,
            gstNumber,
            proofDocument,
            contactInfo,
            emailAddress,
            phoneNumber,
            shopId,
            internalId, // NEW: Save Internal ID
        }); // Update original state after saving
        setIsSaveDisabled(true); // Disable Save button after saving
    };

    return (
        <div className='business-info-section'>
       <div className="breadcrumb-container">
                        
                        <Link to="/admin/dashboard" className="breadcrumb-text">
                        <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
                        </Link>
                        <span className="breadcrumb-separator">{'>'}</span>
                        <span className="breadcrumb-text">Business Information</span>
                    </div>
                    <div className="location-container"></div>
                        <div className="business-info-container">
                        <h5 className="text-heading1"> Business Information</h5>
                <hr />
                <form>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Pharmacy Name</label>
                            <InputComponent
                                value={pharmacyName}
                                onChange={(e) => handleInputChange(setPharmacyName, e.target.value)}
                                readOnly={isReadOnly}
                            />
                        </div>
                        <div className="form-group">
                            <label>License Information</label>
                            <InputComponent
                                value={licenseInfo}
                                onChange={(e) => handleInputChange(setLicenseInfo, e.target.value)}
                                readOnly={isReadOnly}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>Business Registration</label>
                            <InputComponent
                                value={businessRegistration}
                                onChange={(e) => handleInputChange(setBusinessRegistration, e.target.value)}
                                readOnly={isReadOnly}
                            />
                        </div>
                        <div className="form-group">
                            <label>Business Address</label>
                            <InputComponent
                                value={businessAddress}
                                onChange={(e) => handleInputChange(setBusinessAddress, e.target.value)}
                                readOnly={isReadOnly}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>GST Number</label>
                            <InputComponent
                                value={gstNumber}
                                onChange={(e) => handleInputChange(setGstNumber, e.target.value)}
                                readOnly={isReadOnly}
                            />
                        </div>
                       
                        <div className="form-group">
                            <label>Internal ID</label> {/* NEW: Internal ID Field */}
                            <InputComponent
                                value={internalId}
                                onChange={(e) => handleInputChange(setInternalId, e.target.value)}
                                readOnly={isReadOnly}
                            />
                        
                    </div>
                     
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>Contact Information</label>
                            <InputComponent
                                value={contactInfo}
                                onChange={(e) => handleInputChange(setContactInfo, e.target.value)}
                                readOnly={isReadOnly}
                            />
                        </div>
                        <div className="form-group">
                            <label>Email Address</label>
                            <InputComponent
                                value={emailAddress}
                                onChange={(e) => handleInputChange(setEmailAddress, e.target.value)}
                                readOnly={isReadOnly}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>Phone Number</label>
                            <InputComponent
                                value={phoneNumber}
                                onChange={(e) => handleInputChange(setPhoneNumber, e.target.value)}
                                readOnly={isReadOnly}
                            />
                        </div>
                        <div className="form-group">
                            <label>Shop ID</label>
                            <InputComponent
                                value={shopId}
                                onChange={(e) => handleInputChange(setShopId, e.target.value)}
                                readOnly={isReadOnly}
                            />
                        </div>
                    </div>



                    <div className="form-row save-button-container">
                        <ButtonComponent
                            text="Save"
                            className="save-button"
                            onClick={handleSave}
                            disabled={isSaveDisabled} // Disable button unless necessary
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default BusinessInformation;
