import { fetchUsers, deleteUser,deleteBulkUsers } from '../../redux/actions/adminuserActions';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faSearch, faPencilAlt, faHome, faTrash } from '@fortawesome/free-solid-svg-icons';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import './deliverylistPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import AddItemsAdminModal from '../../components/sections/AddItems-adminModal/AddItems-adminModal';
import CheckboxComponent from '../../components/common/CheckboxComponent/CheckboxComponent';
import toast from 'react-hot-toast';
const DeliveryDisplayPage = () => {
  const dispatch = useDispatch();
  const users = useSelector(state => state.usermanage.users) || [];
  
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);  // Set the number of items per page
  const [showModal, setShowModal] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  
  const token = useSelector(state=>state.adminLogin.token);
  
  useEffect(() => {
    dispatch(fetchUsers(token));
  }, [dispatch, token]);

  useEffect(() => {
    // Apply filtering based on the single search term across multiple fields (name, email, number)
    const filterResults = () => {
      const filtered = users.filter(user => {
        // Use optional chaining (?.) and default empty string ('') to handle null or undefined values
        const matchesName = (user.username?.toLowerCase() || '').includes(searchTerm.toLowerCase());
        const matchesEmail = (user.email?.toLowerCase() || '').includes(searchTerm.toLowerCase());
        const matchesNumber = (user.mobile_number?.includes(searchTerm) || false);
        const isDeliveryRole = user.role === 'delivery';

        return isDeliveryRole && (matchesName || matchesEmail || matchesNumber);
      });
      setFilteredUsers(filtered);
    };
    
    filterResults();
    
  }, [searchTerm, users]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDelete = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
       
        const response = dispatch(deleteUser(userId,token));
        if(response){
          toast.success("Delivery person deleted successfully");
        } 
      } catch (error) {
        // console.error("Failed to delete user");
      }
    }
  };

  const navigate = useNavigate();

  const handleActionSelect = (action) => {
    if (selectedIds.length === 0) {
        alert("Please select at least one user to proceed.");
        return;
    }

    if (action === "edit") {
        //console.log("Editing categories:", selectedIds);
        navigate('/admin/bulk-delivery', { state: selectedIds } )
        setSelectedIds([]);
        // Implement edit functionality here
    } else if (action === "delete") {
        if (window.confirm(`Are you sure you want to delete the selected delivery persons?`)) {
           
            const response = dispatch(deleteBulkUsers(selectedIds,token));
            if (response){
              toast.success("Delivery person deleted successfully");
            }
           
            setSelectedIds([]);
        }
    }
};
const [tableData, setTableData] = useState([]);
const handleSelectAll = () => {
  if (selectAll) {
      // If currently all checked, uncheck all
      setSelectedIds([]);
  } else {
      // If currently not all checked, check all
      const allIds = filteredUsers.map(item => item.user_id);
      setSelectedIds(allIds);
  }
  setSelectAll(!selectAll); // Toggle selectAll state
};
useEffect(() => {
  if (selectedIds.length === tableData.length) {
      setSelectAll(true);
  } else {
      setSelectAll(false);
  }
}, [selectedIds, tableData.length]);


  const handleCheckboxChange = (categoryId) => {
    // Update selectedIds based on the checkbox state
    if (selectedIds.includes(categoryId)) {
        // If already checked, remove from selectedIds
        setSelectedIds(prev => prev.filter(id => id !== categoryId));
    } else {
        // If not checked, add to selectedIds
        setSelectedIds(prev => [...prev, categoryId]);
    }
};

useEffect(() => {
  setTableData(filteredUsers.map(user => ({
      ...user,
      checked: false, // Initialize checked property
  })));
}, [filteredUsers]); // Update tableData when categories change


  const handleEditClick = (id) => {
    navigate('/delivery/add-delivery-person', { state: id });
  };

  const navigationOptions = {
    gridPath: '/delivery/add-delivery-person', // Modify as needed
    sheetPath: '/admin/uploadpage',   // Modify as needed
    from:'delivery'
  };

  // Pagination logic
  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const nextPage = () => {
    if (currentPage < Math.ceil(filteredUsers.length / itemsPerPage)) {
      setCurrentPage(prev => prev + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prev => prev - 1);
    }
  };

  return (
    <div className="delivery-page-layout">
       <div className="breadcrumb-container">
       <Link to="/admin/dashboard" className="breadcrumb-text">
       <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        </Link>
        
        <span className="breadcrumb-separator">{'>'}</span>
        <Link to="/admin/user-management" className="breadcrumb-text">
          Delivery List
        </Link>
      </div>
       <div className="delivery-list-container"></div>
      <div className="admin-delivery-container">
        <div className="right-section">
        <h5 className='text-heading1'>Delivery List</h5>
          <div className="toolbar">
            
            <div className="search-wrapper">
              <FontAwesomeIcon icon={faSearch} className="icon" />
              <input
                type="text"
                className="search-bar"
                placeholder="Search by name, email, or number"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
         

          <div className="item-controls">
            <ButtonComponent text="+ Add Delivery" variant="dark" className="add-items-button" onClick={() => setShowModal(true)} />
            <div className="actions-toolbar">
        <Dropdown>
          <Dropdown.Toggle  id="actions-dropdown" className="dropdown-toggle7">
            Actions
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleActionSelect("edit")}>Edit</Dropdown.Item>
            <Dropdown.Item onClick={() => handleActionSelect("delete")}>Delete</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
          </div>
          </div>
          {/* Conditionally show the table or "No delivery persons found" */}
          {filteredUsers.length > 0 ? (
            <>
              <div className="table-container">
                <table className="custom-table">
                  <thead>
                    <tr>
                    <th>
                    <CheckboxComponent
                      checked={selectAll}
                      onChange={handleSelectAll}
                      />
                      </th>
                      <th>ID</th>
                      <th>Username</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Actions</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentUsers.map((user, index) => (
                      <tr key={index}>
                        <td>
                        <CheckboxComponent
                          checked={selectedIds.includes(user.user_id)}
                          onChange={() => handleCheckboxChange(user.user_id)}
                        />
                        </td>
                        <td>{index+1}</td>
                        <td>{user.username}</td>
                        <td>{user.email}</td>
                        <td>{user.mobile_number}</td>
                        <td className="actions-column">
                          <button className="icon-button" onClick={() => handleEditClick(user.user_id)}>
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </button>
                        </td>
                        <td className="actions-column">
                          <button className="icon-button" onClick={() => handleDelete(user.user_id)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

             
            </>
          ) : (
            <div className="no-results-message">
              <p>No delivery persons found</p>
            </div>
          )}
          <AddItemsAdminModal show={showModal} handleClose={() => setShowModal(false)} navigationOptions={navigationOptions} />
        </div>
      </div>
    </div>
  );
};

export default DeliveryDisplayPage;



