import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    getStock,
    getBulkStockItems,
    createStock,
    updateStock,
    createBulkStockItems,
    updateBulkStockItems,
    deleteStockItem
} from '../../redux/actions/stockpurchaseActions';
import { generatePresignedUrlAPI, uploadDocumentAPI } from '../../api/services/filestorageService';
import SearchableDropdown from '../../components/common/SearchableDropdown/SearchableDropdown';
import Sidebar from '../../components/common/HalfSideBar/halfsidebar';

import RadioButtonComponent from '../../components/common/RadioButtonComponent/RadioButtonComponent';
import CheckboxComponent from '../../components/common/CheckboxComponent/CheckboxComponent';
import InputComponent from '../../components/common/InputComponent/InputComponent';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import './AddPurchase.scss';
import { toast } from 'react-hot-toast';
import { FaTrash } from 'react-icons/fa';
import {  faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { updateProductsWorkflow } from '../../redux/actions/productActions';

const AddStock = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const stockId = location.state || null; // Retrieve stock_id from the location state

    const [supplierName, setSupplierName] = useState('');
    const [invoiceFile, setInvoiceFile] = useState(null);

    // Redux State
    const stocks = useSelector((state) => state.stock.stocks || []);
    const stockItems = useSelector((state) => state.stock.stockItems || []);
    const token = useSelector((state) => state.adminLogin.token);
    const suppliers = useSelector((state) => state.supplier.suppliers);
    const products = useSelector((state) => state.products.basicProducts || []);
    const [saving,setSaving] = useState(false);
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [paymentType, setPaymentType] = useState('unpaid');
    const [selectedDate, setSelectedDate] = useState('');
    const [materials, setMaterials] = useState([]);
    const [invoiceFileUrl, setInvoiceFileUrl] = useState('');
    // const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const parseDateForInput = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; // Extract YYYY-MM-DD
    };
    const [form, setForm] = useState({
        po_number: '',
        supplier_id: '',
        invoice_date: '',
        payment_type: '',
        invoice_number: '', // New field for invoice number
        gst: '' // New field for GST
    });
    const [addType,setAddType] = useState('')
    const updateNames = (id) => {
        const entityName = products.find(product => product.product_id === id)?.product_name || '';
        return entityName;
    };
    const handleCloseSidebar = () => {
        setIsSidebarVisible(false);
        setAddType('');
      };
       const generatePresignedUrl = async (fileUrl) => {
        try {
            const response = await generatePresignedUrlAPI(fileUrl, token);
            if (response?.presigned_url) {
                setInvoiceFileUrl(response.presigned_url);
            } else {
                toast.error('Failed to fetch invoice file URL.');
            }
        } catch (error) {
            // console.error('Error generating presigned URL:', error);
            toast.error('An error occurred while fetching the invoice file URL.');
        }
    };
    useEffect(() => {
        if (stockId) {
            const stockData = stocks.find((s) => s.stock_id === stockId);
            if (stockData) {
                setForm({
                    po_number: stockData.po_number,
                    supplier_id: stockData.supplier_id,
                    invoice_date: stockData.invoice_date,
                    payment_type: stockData.payment_status,
                    invoice_number: stockData.invoice_number || '',
                    gst: stockData.gst || ''
                });
                setPaymentType(stockData.payment_status);
                setSelectedDate(parseDateForInput(stockData.invoice_date));
                setSupplierName(suppliers.find((s) => s.supplier_id === stockData.supplier_id)?.name || '');
    
                if (stockData.invoice_file_url) {
                    generatePresignedUrl(stockData.invoice_file_url);
                }
            }
    
            const items = stockItems.filter((item) => item.stock_id === stockId);
            setMaterials(items.map((item) => ({ ...item, previous_quantity: item.quantity || 0 }))); // Track previous quantity
        }
    }, [stockId, stocks, stockItems, suppliers]);
    
    const handleInputChange = (index, field, value) => {
        setMaterials(prevMaterials =>
            prevMaterials.map((material, i) =>
                i === index ? { ...material, [field]: value } : material
            )
        );
    };
    const uploadInvoiceFile = async () => {
        if (invoiceFile) {
            try {
                const response = await uploadDocumentAPI(invoiceFile, 'invoice/', token);
                if (response?.file_url) {
                    return response.file_url;
                } else {
                    toast.error('Invoice file upload failed.');
                }
            } catch (error) {
                // console.error('Error uploading invoice file:', error);
                toast.error('An error occurred while uploading the invoice file.');
            }
        }
        return '';
    };
    const handleAddNew = () => {
        const newRow = { name: '', quantity: 0, unit: 0, price: 0, amount: 0, description: '' };
        setMaterials(prevMaterials => [...prevMaterials, newRow]);
    };

    const updateProductStockLevels = async (materials) => {
        const stockAdjustments = materials
            .map((material) => {
                const product = products.find((p) => p.product_id === material.product_id);
                if (product) {
                    const quantityDifference = material.quantity - (material.previous_quantity || 0); // Calculate the difference
                    return {
                        product_id: product.product_id,
                        stock_level: +product.stock_level + quantityDifference, // Adjust stock level
                    };
                }
                return null;
            })
            .filter((adjustment) => adjustment !== null);
    
        if (stockAdjustments.length > 0) {
            try {
                await dispatch(updateProductsWorkflow(stockAdjustments, '', token));
                // console.log('Stock levels updated successfully.');
            } catch (error) {
                // console.error('Error updating stock levels:', error);
            }
        }
    };
    

    const handleDelete = async (index, itemId) => {
        setMaterials(prevMaterials => prevMaterials.filter((_, i) => i !== index));
        if (itemId) {
            await dispatch(deleteStockItem(itemId, token));
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setInvoiceFile(file);
            setInvoiceFileUrl(URL.createObjectURL(file));
        }
    };


    const handleSave = async () => {
        setSaving(true);
        if (!form.po_number || !form.supplier_id || !selectedDate || !form.invoice_number || !form.gst || materials.length === 0) {
            toast.error('Please fill all required fields, including Invoice Number and GST, and add at least one material.');
            setSaving(false);
            return;
        }

        if (materials.some((material) => !material.product_id || !material.quantity || !material.unit)) {
            toast.error('All fields in each material are required.');
            setSaving(false);
            return;
        }

        let uploadedFileUrl = invoiceFileUrl;
        if (invoiceFile) {
            uploadedFileUrl = await uploadInvoiceFile();
        }

        const stockData = {
            po_number: form.po_number,
            supplier_id: form.supplier_id,
            invoice_date: selectedDate,
            payment_status: paymentType,
            invoice_number: form.invoice_number,
            gst: form.gst,
            invoice_file_url: uploadedFileUrl
        };

        let savedStockId;
        if (stockId) {
            await dispatch(updateStock({ ...stockData, stock_id: stockId }, token));
            savedStockId = stockId;

            const itemsToUpdate = materials.filter((item) => item.stock_item_id);
            const itemsToCreate = materials.filter((item) => !item.stock_item_id);
            await updateProductStockLevels(materials);

            if (itemsToUpdate.length > 0) {
                const updatedItems = itemsToUpdate.map((item) => ({ ...item, stock_id: savedStockId }));
                await dispatch(updateBulkStockItems(updatedItems, token));
            }

            if (itemsToCreate.length > 0) {
                const newItems = itemsToCreate.map((item) => ({ ...item, stock_id: savedStockId }));
                await dispatch(createBulkStockItems(newItems, token));
            }
        } else {
            const createResponse = await dispatch(createStock(stockData, token));
            savedStockId = createResponse.stock_id;

            const itemsWithStockId = materials.map((item) => ({ ...item, stock_id: savedStockId }));
            await dispatch(createBulkStockItems(itemsWithStockId, token));

            await updateProductStockLevels(itemsWithStockId);
        }
        setSaving(false);
        toast.success('Stock saved successfully.');
        navigate('/admin/purchase-list');
    };
    const handleSupplier = (selectedId) => {
        const foundSupplier = suppliers.find(s => s.supplier_id === selectedId);
        //console.log(foundSupplier, '--founded supplier');
        if (foundSupplier) {
            setSupplierName(foundSupplier.name);
            const updatedFormData = { ...form, supplier_id: selectedId };
            setForm(updatedFormData);
        } else {
            setAddType('supplier');
            setIsSidebarVisible(true);
        }
    };
    

    const handleEntitySelect = (index, selectedId) => {
        const available = products.find(p => p.product_id === selectedId);
        //console.log(available,'--avaailabe')
        if (available) {
            handleInputChange(index, 'product_id', selectedId);
        }
        else{
            setAddType('basic');
            setIsSidebarVisible(true);
        }
    };

    return (
        <div className="add-stock">
                <div className="breadcrumb-container">
                    
                    <Link to="/admin/dashboard" className="breadcrumb-text">
                    <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
                    </Link>
                    <span className="breadcrumb-separator">{'>'}</span>
                    <Link to="/admin" className="breadcrumb-text">
                    <span className="breadcrumb-text">Product list</span>
                    </Link>
                    <span className="breadcrumb-separator">{'>'}</span>
                    <Link to="/admin/purchase-list" className="breadcrumb-text">
                    <span className="breadcrumb-text">Stock Purchase list</span>
                    </Link>
                    <span className="breadcrumb-separator">{'>'}</span>
                    <span className="breadcrumb-text">Add Stock Purchase List</span>
                </div>
                <div className="stock-purchase-container"></div>
                <div className="add-stock-page-container">    
            <div className="add-stock-page">
                <div className="add-stock-header-container">
                    <h6 className="add-stock-header">Stock Details</h6>
                    <hr />
                    <div className="add-stock-form">
                        <div className="form-row">
                            <div className="form-group">
                                <label>Stock No.</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={form.po_number}
                                    onChange={(e) => setForm({ ...form, po_number: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>Invoice Number</label>
                                <InputComponent
                                    type="text"
                                    value={form.invoice_number}
                                    onChange={(e) => setForm({ ...form, invoice_number: e.target.value })}
                                    placeholder="Enter Invoice Number"
                                />
                            </div>
                            <div className="form-group">
                                <label>GST (%)</label>
                                <InputComponent
                                    type="number"
                                    value={form.gst}
                                    onChange={(e) => setForm({ ...form, gst: e.target.value })}
                                    placeholder="Enter GST"
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>Supplier</label>
                                <SearchableDropdown
                                    options={suppliers.map(sup => ({ label: sup.name, id: sup.supplier_id }))}
                                    placeholder="Search for suppliers"
                                    value={supplierName}
                                    onSelect={handleSupplier}
                                />
                            </div>
                            <div className="form-group">
                                <label>Date</label>
                                <InputComponent
                                    type="date"
                                    value={selectedDate}
                                    onChange={(e) => setSelectedDate(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <div className='Payment-container'>
                                <label>Payment Type</label>
                                <RadioButtonComponent
                                    name="paymentType"
                                    value="Unpaid"
                                    checked={paymentType === 'unpaid'}
                                    onChange={() => setPaymentType('unpaid')}
                                />
                                <label>Unpaid</label>
                                <RadioButtonComponent
                                    name="paymentType"
                                    value="Paid"
                                    checked={paymentType === 'paid'}
                                    onChange={() => setPaymentType('paid')}
                                />
                                <label>Paid</label>
                            </div>
                            </div>
                            <div class="form-group">
  <label>Invoice File</label>
  {invoiceFileUrl || invoiceFile ? (
    <div className="file-display">
      <span className="file-name">
        {invoiceFile
          ? invoiceFile.name
          : invoiceFileUrl.split('/').pop().split('?')[0]} {/* Extract file name from URL */}
      </span>
      <span
        className="remove-file"
        onClick={() => {
          setInvoiceFile(null);
          setInvoiceFileUrl('');
          setForm((prev) => ({ ...prev, invoice_file_url: '' }));
        }}
      >
        ✖
      </span>
    </div>
  ) : (
    <input type="file" accept=".jpg,.jpeg,.png,.pdf" onChange={handleFileChange} />
  )}
</div>


                        </div>
                    </div>
                </div>
                </div>
                <div className="material-details">
                    <div className="header">
                    <h6 className="add-request-header1">Material Details</h6>
                        <ButtonComponent text="Add New" className="add-new-button" onClick={handleAddNew} />
                    </div>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th><CheckboxComponent /></th>
                                <th>Name *</th>
                                <th>Quantity *</th>
                                <th>Unit *</th>
                                <th>Price</th>
                                <th>Amount</th>
                                <th>Description</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {materials.map((material, index) => (
                                <tr key={index}>
                                    <td><CheckboxComponent /></td>
                                    <td>
                                        <SearchableDropdown
                                            options={products.map(prod => ({ label: prod.product_name, id: prod.product_id }))}
                                            placeholder="Search for product"
                                            value={updateNames(material.product_id)}
                                            onSelect={(selectedOption) => handleEntitySelect(index, selectedOption)}
                                        />
                                    </td>
                                    <td><InputComponent value={material.quantity} type="number" onChange={(e) => handleInputChange(index, 'quantity', e.target.value)} /></td>
                                    <td><InputComponent value={material.unit} type="number" onChange={(e) => handleInputChange(index, 'unit', e.target.value)} /></td>
                                    <td><InputComponent value={material.price} type="number" onChange={(e) => handleInputChange(index, 'price', e.target.value)} /></td>
                                    <td><InputComponent value={material.amount} type="number" onChange={(e) => handleInputChange(index, 'amount', e.target.value)} /></td>
                                    <td><InputComponent value={material.description} onChange={(e) => handleInputChange(index, 'description', e.target.value)} /></td>
                                    <td>
                                        <FaTrash className="action-icon" onClick={() => handleDelete(index, material.stock_item_id)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div class="button-container">
                    <ButtonComponent text={saving?"Saving...":"Save"} disabled={saving}className={"save-button"} onClick={handleSave} />
                    {isSidebarVisible && <Sidebar type={addType} urls={'yes'} onClose={handleCloseSidebar} />}
                </div>
                </div>
            </div>
        </div>
    );
};

export default AddStock;
