import { coupon_endpoints } from '../endPoints';
import { toast } from 'react-hot-toast';
import apiClient from '../apiClient';

export const fetchAllocatedCouponsFromAPI = async (token) => {
    const response = await apiClient.get(coupon_endpoints.GET_BULK_ALLOCATED_COUPON,
        {
          headers: {
            Authorization:  token,
    
          }
        },    
      ); 
      //console.log(response,'response of allocated couponssss');
        if (!response.data) {
          throw new Error('Failed to fetch alloted coupons');
        }
        return await response.data.allocations;
      };


// Create a new allocated coupon
export const createNewAllocatedCouponAPI = async (newAllocationData,token) => {
  //console.log(newAllocationData);
      const response = await apiClient.post(
        coupon_endpoints.GET_ALLOCATED_COUPON, 
        {
         coupon_id:newAllocationData.coupon_id,
         entity_type:newAllocationData.entity_type,
         entity_id:newAllocationData.entity_id,
         status:newAllocationData.status
         
          
        },
        {
          headers: {
           
            Authorization:token,

          },
        }
      );
  
      //console.log("added new coupon")
    
  
    return await 
    response.data; // Return the created product data from the API
  };
// Update an allocated coupon
export const updateAllocatedCouponInAPI = async (allocationId, updatedAllocationData, token) => {
  //console.log(updatedAllocationData,'allocation---------------')
  try {
    const response = await apiClient.put(
      coupon_endpoints.GET_ALLOCATED_COUPON, 
      {
        coupon_allocation_id: allocationId,
        coupon_id: updatedAllocationData.coupon_id,
        entity_type: updatedAllocationData.entity_type,
        entity_id: updatedAllocationData.entity_id,
        status: updatedAllocationData.status
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    //console.log(response.data); // Log the response data
    return response.data;
  } catch (error) {
    // console.error('Error response:', error.response?.data); // Log API error details
    throw new Error('Failed to update the allocated coupon');
  }
};


// Delete an allocated coupon
export const deleteAllocatedCouponAPI = async (allocationId,token) => {
    const response = await apiClient.delete(
        coupon_endpoints.GET_ALLOCATED_COUPON,  // The endpoint for category deletion
        {
          headers: {
            'Content-Type': 'application/json', // Set the correct Content-Type
            Authorization:  token,
  
          },
         params: {
          coupon_allocation_id: allocationId // Send categoryId in the request body
          }
        }
      );
  
    
      // Check for a successful response
      if (!response.data) {
        throw new Error(`Failed to delete coupon with ID: ${allocationId}`);
      }
    
      return await response.data; // Optionally return the deleted product data
    };
    export const bulkCreateAllocatedCouponsAPI = async (newAllocationsData,token) => {
      const response = await apiClient.post(
        coupon_endpoints.GET_BULK_ALLOCATED_COUPON, // Assuming there's a bulk endpoint for creating
        newAllocationsData, // Send an array of coupon allocations
        {
          headers: {
            Authorization: token,
          },
        }
      );
      
      if (!response.data) {
        throw new Error('Failed to create bulk allocated coupons');
      }
      
      return await response.data; // Return created coupons data
    };
    
    // Bulk Update Allocated Coupons
    export const bulkUpdateAllocatedCouponsAPI = async (allocationsData,token) => {
      const response = await apiClient.put(
        coupon_endpoints.GET_BULK_ALLOCATED_COUPON, // Assuming there's a bulk endpoint for updating
        allocationsData, // Send an array of coupon allocations to update
        {
          headers: {
            Authorization: token,
          },
        }
      );
    
      if (!response.data) {
        throw new Error('Failed to update bulk allocated coupons');
      }
    
      return await response.data; // Return updated coupons data
    };
    
    // Bulk Delete Allocated Coupons
    export const bulkDeleteAllocatedCouponsAPI = async (allocationIds,token) => {
      const response = await apiClient.delete(
        coupon_endpoints.GET_BULK_ALLOCATED_COUPON, // Assuming there's a bulk endpoint for deleting
        {
          headers: {
           
            Authorization: token,
          },
          data: allocationIds // Send an array of coupon allocation IDs

        }
      );
    
      if (!response.data) {
        throw new Error('Failed to delete bulk allocated coupons');
      }
    
      return await response.data; // Optionally return the deleted coupons data
    };