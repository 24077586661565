import apiClient from "../apiClient";
import { expiry_alert_endpoints } from "../endPoints"; // Ensure endpoints are defined correctly

// Fetch all expiry alerts
export const fetchAllExpiryAlerts = async (token) => {
  try {
    const response = await apiClient.get(expiry_alert_endpoints.GET_EXPIRY_ALERT, {
      headers: { Authorization: token },
    });
    return response.data;
  } catch (error) {
    // console.error("Error fetching all expiry alerts:", error.response?.data || error.message);
    throw error;
  }
};

// Fetch a single expiry alert by ID
export const fetchExpiryAlertById = async (alertId, token) => {
  try {
    const response = await apiClient.get(`${expiry_alert_endpoints.GET_EXPIRY_ALERT}/${alertId}`, {
      headers: { Authorization: token },
    });
    return response.data;
  } catch (error) {
    // console.error(`Error fetching expiry alert with ID ${alertId}:`, error.response?.data || error.message);
    throw error;
  }
};

// Create a new expiry alert
export const createExpiryAlert = async (alertData, token) => {
  try {
    const response = await apiClient.post(expiry_alert_endpoints.CREATE_EXPIRY_ALERT, alertData, {
      headers: { Authorization: token },
    });
    return response.data;
  } catch (error) {
    // console.error("Error creating expiry alert:", error.response?.data || error.message);
    throw error;
  }
};

// Update an expiry alert
export const updateExpiryAlertById = async (alertId, alertData, token) => {
  try {
    const response = await apiClient.put(
      expiry_alert_endpoints.UPDATE_EXPIRY_ALERT, // Endpoint URL
      alertData, // Payload
      {
        params: { alert_id: alertId }, // Query parameters
        headers: { Authorization: token }, // Authorization header
      }
    );
    return response.data; // Return the response data
  } catch (error) {
    // console.error("Error updating expiry alert:", error.response?.data || error.message);
    throw error; // Rethrow error for handling in the calling code
  }
};


// Delete an expiry alert
// Delete expiry alert by ID
export const deleteExpiryAlertById = async (alertId, token) => {
  try {
    const response = await apiClient.delete(
      expiry_alert_endpoints.DELETE_EXPIRY_ALERT, // API endpoint
      {
        params: { alert_id: alertId }, // Query parameter
        headers: { Authorization: token }, // Authorization token
      }
    );
    return response.data; // Return the response data
  } catch (error) {
    // console.error(`Error deleting expiry alert with ID ${alertId}:`, error.response?.data || error.message);
    throw error; // Rethrow for higher-level error handling
  }
};


// Fetch bulk expiry alerts
export const fetchBulkExpiryAlerts = async (token) => {
 
  try {
    const response = await apiClient.get(expiry_alert_endpoints.GET_BULK_EXPIRY_ALERT, {
      headers: { Authorization: token },
    });
    //console.log("rrrrrrrrr",response.data)
    return response.data.alerts;
  } catch (error) {
   
    // console.error("Error fetching bulk expiry alerts:", error.response?.data || error.message);
    throw error;
  }
};

// Bulk create expiry alerts
export const createBulkExpiryAlerts = async (alertData, token) => {
  try {
    const response = await apiClient.post(expiry_alert_endpoints.CREATE_BULK_EXPIRY_ALERT, alertData, {
      headers: { Authorization: token },
    });
    return response.data;
  } catch (error) {
    // console.error("Error bulk creating expiry alerts:", error.response?.data || error.message);
    throw error;
  }
};

// Bulk update expiry alerts
export const updateBulkExpiryAlerts = async (alertData, token) => {
  try {
    const response = await apiClient.put(expiry_alert_endpoints.UPDATE_BULK_EXPIRY_ALERT, alertData, {
      headers: { Authorization: token },
    });
    return response.data;
  } catch (error) {
    // console.error("Error bulk updating expiry alerts:", error.response?.data || error.message);
    throw error;
  }
};

// Bulk delete expiry alerts
export const deleteBulkExpiryAlerts = async (alertIds, token) => {
  try {
    //console.log(alertIds,'--alert')
    const response = await apiClient.delete(
      expiry_alert_endpoints.DELETE_BULK_EXPIRY_ALERT, // API endpoint
      {
        headers: { Authorization: token }, // Authorization token
        data:alertIds, // Payload containing alert IDs
      }
    );
    return response.data; // Return the response data
  } catch (error) {
    // console.error("Error bulk deleting expiry alerts:", error.response?.data || error.message);
    throw error; // Rethrow for higher-level error handling
  }
};

