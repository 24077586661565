// pages/Homepage.jsx
import {React,useEffect} from 'react';
import SlickCarouselComponent from '../../components/SlickCarouselComponent/SlickCarouselComponent';
import ShoppingCard from '../../components/ShoppingCard/ShoppingCard';
import ProductItemCards from '../../components/ProductItemCards/ProductItemCards';
import { fetchBanners } from '../../redux/actions/bannerAction';
import { useDispatch,useSelector } from 'react-redux';
import { fetchCartItems,updateCartItem,deleteCartItem,addItemToCart } from '../../redux/actions/cartItemAction';
import { fetchCart,clearCart } from '../../redux/actions/cartActions';
import { fetchCategory } from '../../redux/actions/categoryAction';
import { fetchBrand } from '../../redux/actions/brandAction';
const Homepage = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token) || '';
 
  const cart_id = useSelector((state) => state.cart.cart_id) || '';
  const user_id = useSelector((state) => state.auth.user_id) || '';
  //console.log(token,user_id,'----USERID')
  useEffect(() => {
    // dispatch(fetchCart(token,user_id));
    // dispatch(fetchCartItems(token,cart_id));
    dispatch(fetchBanners(token));
  }, [dispatch]);
  localStorage.removeItem('cart');

  return (

    <div>
     
      <SlickCarouselComponent />
      <ShoppingCard />
      <ProductItemCards />
    </div>
  );
};

export default Homepage;
