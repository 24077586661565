import { notification_endpoints } from '../endPoints';
import apiClient from '../apiClient';

// Fetch all notifications
export const fetchNotifications = async (token) => {
  try {
    const response = await apiClient.get(notification_endpoints.GET_NOTIFICATIONS, {
      headers: { Authorization: token },
    });
    return response.data;
  } catch (error) {
    // console.error("Error in fetchNotifications:", error.response?.data || error.message);
    throw error;
  }
};

export const createNotification = async (notificationData, token) => {
  try {
    const response = await apiClient.post(
      notification_endpoints.CREATE_NOTIFICATION,
      notificationData,
      { headers: { Authorization: token } }
    );
    return response.data;
  } catch (error) {
    // console.error("Error in createNotification:", error.response?.data || error.message);
    throw error; // Ensure the error is thrown for higher-level handling
  }
};

// Update a notification
export const updateNotification = async (notificationId, notificationData, token) => {
  try {
    const response = await apiClient.put(
      notification_endpoints.UPDATE_NOTIFICATION,
      notificationData,
      {
        params: { notification_id: notificationId },
        headers: { Authorization: token },
      }
    );
    return response.data;
  } catch (error) {
    // console.error("Error updating notification:", error.response?.data || error.message);
    if (error.response?.status === 404) {
      // console.error("One or more product_ids are invalid:", notificationData.product_id);
    }
    throw error;
  }
};



// Delete a notification
export const deleteNotification = async (notificationId, token) => {
  try {
    const response = await apiClient.delete(
      notification_endpoints.DELETE_NOTIFICATION,
      {
        params: { notification_id: notificationId },
        headers: { Authorization: token },
      }
    );
    return response.data;
  } catch (error) {
    // console.error("Error in deleteNotification:", error.response?.data || error.message);
    throw error;
  }
};

// Bulk Create Notifications
export const bulkCreateNotifications = async (bulkData, token) => {
  try {
    const response = await apiClient.post(
      notification_endpoints.CREATE_BULK_NOTIFICATIONS,
      bulkData,
      { headers: { Authorization: token } }
    );
    return response.data;
  } catch (error) {
    // console.error("Error in bulkCreateNotifications:", error.response?.data || error.message);
    throw error;
  }
};

// Bulk Fetch Notifications
export const bulkFetchNotifications = async (token) => {
  try {
    const response = await apiClient.get(
      notification_endpoints.GET_BULK_NOTIFICATIONS,
      { headers: { Authorization: token } }
    );
    return response.data.notifications;
  } catch (error) {
    // console.error("Error in bulkFetchNotifications:", error.response?.data || error.message);
    throw error;
  }
};

// Bulk Update Notifications
export const bulkUpdateNotifications = async (notificationData, token) => {
  try {
    //console.log("Bulk Notification Data Update:", notificationData);

    const response = await apiClient.put(
      notification_endpoints.UPDATE_BULK_NOTIFICATIONS,
      notificationData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    //console.log("API Response:", response.data);
    return response.data;
  } catch (error) {
    // console.error("Error in bulkUpdateNotifications:", error.response?.data || error.message);
    throw error;
  }
};

// Bulk Delete Notifications
export const bulkDeleteNotifications = async (notificationIds, token) => {
  try {
    const response = await apiClient.delete(
      notification_endpoints.DELETE_BULK_NOTIFICATIONS,
      {
        data: notificationIds,
        headers: { Authorization: token },
      }
    );
    return response.data;
  } catch (error) {
    // console.error("Error in bulkDeleteNotifications:", error.response?.data || error.message);
    throw error;
  }
};
