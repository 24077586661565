import { 
  fetchCouponsFromAPI, 
  updateCouponInAPI, 
  deleteCouponAPI, 
  createNewCouponAPI, 
  updateCouponsBulkAPI, 
  deleteCouponsBulkAPI, 
  createCouponsBulkAPI 
} from '../../api/services/couponService'; // Assuming you have this API function
import { toast } from 'react-hot-toast';

export const FETCH_COUPONS_REQUEST = 'FETCH_COUPONS_REQUEST';
export const FETCH_COUPONS_SUCCESS = 'FETCH_COUPONS_SUCCESS';
export const FETCH_COUPONS_FAILURE = 'FETCH_COUPONS_FAILURE';
export const CLEAR_COUPONS = 'CLEAR_COUPONS';

export const DELETE_COUPON_REQUEST = 'DELETE_COUPON_REQUEST';
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS';
export const DELETE_COUPON_FAILURE = 'DELETE_COUPON_FAILURE';

export const UPDATE_COUPON_REQUEST = 'UPDATE_COUPON_REQUEST';
export const UPDATE_COUPON_SUCCESS = 'UPDATE_COUPON_SUCCESS';
export const UPDATE_COUPON_FAILURE = 'UPDATE_COUPON_FAILURE';

export const CREATE_COUPON_REQUEST = 'CREATE_COUPON_REQUEST';
export const CREATE_COUPON_SUCCESS = 'CREATE_COUPON_SUCCESS';
export const CREATE_COUPON_FAILURE = 'CREATE_COUPON_FAILURE';

export const BULK_DELETE_COUPONS_REQUEST = 'BULK_DELETE_COUPONS_REQUEST';
export const BULK_DELETE_COUPONS_SUCCESS = 'BULK_DELETE_COUPONS_SUCCESS';
export const BULK_DELETE_COUPONS_FAILURE = 'BULK_DELETE_COUPONS_FAILURE';

export const BULK_UPDATE_COUPONS_REQUEST = 'BULK_UPDATE_COUPONS_REQUEST';
export const BULK_UPDATE_COUPONS_SUCCESS = 'BULK_UPDATE_COUPONS_SUCCESS';
export const BULK_UPDATE_COUPONS_FAILURE = 'BULK_UPDATE_COUPONS_FAILURE';

export const BULK_CREATE_COUPONS_REQUEST = 'BULK_CREATE_COUPONS_REQUEST';
export const BULK_CREATE_COUPONS_SUCCESS = 'BULK_CREATE_COUPONS_SUCCESS';
export const BULK_CREATE_COUPONS_FAILURE = 'BULK_CREATE_COUPONS_FAILURE';


// Fetch coupons with a caching mechanism
export const fetchCoupons = (token) => async (dispatch, getState) => {
  const { coupons = [] } = getState().coupon.couponList || [];
  const timestamp = getState().coupon.timestamp;
  const FIVE_MINUTES = 5 * 60 * 1000;

  if (coupons.length > 0 && timestamp) {
    const now = Date.now();
    if (now - timestamp < FIVE_MINUTES) {
      return; // Data is still valid, no need to fetch
    } else {
      dispatch({ type: CLEAR_COUPONS });
    }
  }

  dispatch({ type: FETCH_COUPONS_REQUEST });
  try {
    const fetchedCoupons = await fetchCouponsFromAPI(token); 
    dispatch({
      type: FETCH_COUPONS_SUCCESS,
      payload: { coupons: fetchedCoupons, timestamp: Date.now() },
    });
  } catch (error) {
    dispatch({ type: FETCH_COUPONS_FAILURE, payload: error.message });
    
  }
};

// Action to clear coupons manually
export const clearCoupons = () => ({
  type: CLEAR_COUPONS,
});

// Action to delete a coupon
export const deleteCoupon = (couponId, token) => async (dispatch) => {
  dispatch({ type: DELETE_COUPON_REQUEST });

  try {
    await deleteCouponAPI(couponId, token); 
    dispatch({
      type: DELETE_COUPON_SUCCESS,
      payload: couponId,
    });
    toast.success('Coupon deleted successfully!');
  } catch (error) {
    dispatch({ type: DELETE_COUPON_FAILURE, payload: error.message });
    toast.error(`Failed to delete coupon: ${error.message}`);
  }
};

// Action to update a coupon
export const updateCoupon = (couponId, updatedData, token) => async (dispatch) => {
  dispatch({ type: UPDATE_COUPON_REQUEST });

  try {
    const updatedCoupon = await updateCouponInAPI(couponId, updatedData, token); 
    dispatch({
      type: UPDATE_COUPON_SUCCESS,
      payload:updatedData,
    });
    toast.success('Coupon updated successfully!');
    return { success: true };
  } catch (error) {
    dispatch({ type: UPDATE_COUPON_FAILURE, payload: error.message });
    toast.error(`Failed to update coupon: ${error.message}`);
    return { success: true };
  }
};

// Action to create a coupon
export const createCoupon = (newCouponData, token) => async (dispatch) => {
  //console.log(newCouponData,"newcoupon data")
  dispatch({ type: CREATE_COUPON_REQUEST });

  try {
    const createdCoupon = await createNewCouponAPI(newCouponData, token);
    dispatch({
      type: CREATE_COUPON_SUCCESS,
      payload: newCouponData,
    });
    toast.success('Coupon created successfully!');
    return { success: true };
  } catch (error) {
    dispatch({ type: CREATE_COUPON_FAILURE, payload: error.message });
    toast.error(`Failed to create coupon: ${error.message}`);
    return { success: false };
  }
};

// Bulk delete coupons
export const bulkDeleteCoupons = (couponIds, token) => async (dispatch) => {
  dispatch({ type: BULK_DELETE_COUPONS_REQUEST });

  try {
    await deleteCouponsBulkAPI(couponIds, token);
    dispatch({
      type: BULK_DELETE_COUPONS_SUCCESS,
      payload: couponIds,
    });
    toast.success('Coupons deleted successfully!');
  } catch (error) {
    dispatch({ type: BULK_DELETE_COUPONS_FAILURE, payload: error.message });
    toast.error(`Failed to delete coupons: ${error.message}`);
  }
};

// Bulk update coupons
export const bulkUpdateCoupons = (couponsData, token) => async (dispatch) => {
  dispatch({ type: BULK_UPDATE_COUPONS_REQUEST });

  try {
    const updatedCoupons = await updateCouponsBulkAPI(couponsData, token);
    dispatch({
      type: BULK_UPDATE_COUPONS_SUCCESS,
      payload:couponsData,
    });
    toast.success('Coupons updated successfully!');
    return { success: true };
  } catch (error) {
    dispatch({ type: BULK_UPDATE_COUPONS_FAILURE, payload: error.message });
    toast.error(`Failed to update coupons: ${error.message}`);
    return { success: false };
  }
};

// Bulk create coupons
export const bulkCreateCoupons = (newCouponsData, token) => async (dispatch) => {
  dispatch({ type: BULK_CREATE_COUPONS_REQUEST });

  try {
    const createdCoupons = await createCouponsBulkAPI(newCouponsData, token);
    dispatch({
      type: BULK_CREATE_COUPONS_SUCCESS,
      payload: newCouponsData,
    });
    toast.success('Coupons created successfully!');
    return { success: true };
  } catch (error) {
    dispatch({ type: BULK_CREATE_COUPONS_FAILURE, payload: error.message });
    toast.error(`Failed to create coupons: ${error.message}`);
    return { success: false };
  }
};
