import React, { useState, useEffect } from 'react';
import chemicalIcon from '../../assets/images/chemical.svg';
import ImageProductCarousel from '../../components/common/ImageProductCarousel/ImageProductCarousel';
import './SingleProductDetails.scss';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import Accordion from 'react-bootstrap/Accordion';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import shareIcon from '../../assets/images/shareIcon.svg';
import gel from '../../assets/images/gel.avif';
import QuantityInput from '../../components/common/QuantityInput/QuantityInput';
import {  useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns';
// import SubscriptionOffcanvas from "../../components/common/subscription-offcanvas/subscription-offcanvas";
import { toast } from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faCreditCard, faBoxOpen,faUndoAlt } from '@fortawesome/free-solid-svg-icons';

import {
  addToCart,
  increaseCartItem,
  decreaseCartItem,
  deleteCartItem,
} from '../../redux/actions/Addtocart';

const SingleProductDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Ensures the page starts at the top
  }, []);
  

  const [showFullText, setShowFullText] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch(); // Initialize dispatch
  const token = useSelector((state) => state.auth.token); // Retrieve token from Redux state
  const productId = location.state || null;
  const navigate = useNavigate();
  const product_details = useSelector((state) => state.products.basicProducts) || [];
  const custom_product_details = useSelector((state) => state.products.customProducts) || [];
  const single_product_details = product_details.find((item) => item.product_id === productId);
  const single_custom_product_details = custom_product_details.find((item) => item.product_id === productId);
  const display_other_products = product_details.filter((item) => item.product_id !== productId);
  const authData = useSelector((state) => state.auth.user) || '';
  const cartItems = useSelector((state) => state.cart.cartItems) || [];
  const [deliveryDate, setDeliveryDate] = useState('');
  const productUrls =useSelector((state) => state.image.urls.products) || [];
  // Check if the product is in the cart and get its quantity
  const cartItem = cartItems.find((item) => item.product_id === productId);
  const quantity = cartItem ? cartItem.quantity : 0;
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  // Determine if QuantityInput should be shown
  const showQuantityInput = Boolean(cartItem);

  const handleAddToCart = () => {
    if(authData){
      const cartItem = {
        productId: productId,
        quantity: single_product_details.minimum_order_quantity,
        ...single_product_details,
      };
      dispatch(addToCart(cartItem, token));
    }
   else{
    const cartItem = {
      productId: productId,
      quantity: single_product_details.minimum_order_quantity,
      ...single_product_details,
    };
    dispatch(addToCart(cartItem, ''));
   }
  };

  const handleIncreaseQuantity = () => {
    //console.log("Addingg",cartItem,single_product_details)
    if(authData){
      //console.log("Addingg1",cartItem,single_product_details)
      if (cartItem.quantity < Number(single_product_details.max_order_quantity) && cartItem.quantity < Number(single_product_details.stock_level)) {
        dispatch(increaseCartItem({ ...cartItem, quantity: cartItem.quantity + 1 }, token));
      } 
       else{
            if(cartItem.quantity >= Number(single_product_details.max_order_quantity)){
              toast.error(`A user can order only up to the specified maximum limit of ${single_product_details.max_order_quantity} items for this product.`);
            }
          }
      
    }
    else{
      if (cartItem.quantity < Number(single_product_details.max_order_quantity) && cartItem.quantity < Number(single_product_details.stock_level)) {
        dispatch(increaseCartItem(single_product_details,'' ));
      } else {
        toast.error(`Cannot add more! Max: ${single_product_details.max_order_quantity}`);
      }
    }
   
  };

  const handleDecreaseQuantity = () => {
    if(authData){
      if (cartItem.quantity > Number(single_product_details.minimum_order_quantity)) {
        dispatch(decreaseCartItem({ ...cartItem, quantity: cartItem.quantity - 1 }, token));
      } else {
        removeFromCart();
      }
    }
  else{
    if (cartItem.quantity > Number(single_product_details.minimum_order_quantity)) {
      dispatch(decreaseCartItem(single_product_details, ''));
    } else {
      removeFromCart();
    }
  }
   
  };

  const handleSubscription = () => {
    setShowOffcanvas(true); // Show the offcanvas when button is clicked
  };

  const handleCloseOffcanvas = () => {
    setShowOffcanvas(false); // Close the offcanvas
  };

  const removeFromCart = () => {
    if(authData){
      dispatch(deleteCartItem(cartItem.cart_item_id, token));
    
    }
    else{
      dispatch(deleteCartItem(productId, ''));
    
    }
  };

  const tabs = {
    ingredients: {
      title: 'Active Ingredients',
      content: single_custom_product_details?.active_ingredients || 'No active ingredients available.',
    },
    sideEffects: {
      title: 'Side Effects',
      content: single_custom_product_details?.side_effects || 'No side effects available.',
    },
    intendedUse: {
      title: 'Intended Use',
      content: single_custom_product_details?.intended_use || 'No intended use available.',
    },
    contraindications: {
      title: 'Contraindications',
      content: single_custom_product_details?.contraindications || 'No contraindications available.',
    },
    careInstructions: {
      title: 'Care Instructions',
      content: single_custom_product_details?.care_instructions || 'No care instructions available.',
    },
  };
  const [activeTab, setActiveTab] = useState('ingredients');

  useEffect(() => {
    const deliveryDays = Number(single_product_details?.delivery_timeframe || 0);
    const today = new Date();
    const calculatedDate = new Date(today);
    calculatedDate.setDate(today.getDate() + deliveryDays);
    const formattedDate = format(calculatedDate, 'MMM dd, yyyy');
    setDeliveryDate(formattedDate);
  }, [single_product_details]);

  return (
    <div className="single-product-details">
      <div className="breadcrumb">
        {single_product_details?.product_name || 'Product Details'}
      </div>
  
      <div className="product-content">
        <div className="left-section">
          <div className="productimage-container">
            <div className="image-carousel">
              <ImageProductCarousel id={single_product_details.product_id} />
            </div>
          </div>
  
          <div className="product-info">
            <div className="product-container">
              <div className="product-header">
                <h3>{single_product_details?.product_name}</h3>
            
              </div>
              <p>{single_product_details?.description}</p>
              <div className="price-info">
                <p className="mrp">
                  MRP <span className="line-through">{single_product_details?.mrp}</span>
                </p>
                <p className="price">
                ₹{single_product_details?.price}{' '}
                  <span className="price-per-unit">{single_product_details?.price_per_unit}</span>
                </p>
                <p className="discount">
                  <span className="discount-badge">
                    {single_product_details?.discount}% off applied
                  </span>
                </p>
                <p className="inclusive-tax">*MRP inclusive of all taxes</p>
              </div>
  
              <div className="product-actions">
                {showQuantityInput ? (
                  <QuantityInput
                    quantity={quantity}
                    onIncrease={handleIncreaseQuantity}
                    onDecrease={handleDecreaseQuantity}
                    onRemove={removeFromCart}
                    min_order={single_product_details.minimum_order_quantity}
                  />
                ) : (
                  <button onClick={handleAddToCart} className="add-to-cart">
                    Add
                  </button>
                )}
            
            {/* <ButtonComponent
                className="subscription-button"
                text="Subscription"
                onClick={handleSubscription}
              /> */}
               

                </div>
            </div>
  
            <div className="delivery-pincode-container">
              <p className="delivery-info">
                Get it by <span className="delivery-date">{deliveryDate}</span>
              </p>
            </div>
  
            <div className="features-container">
        <div className="feature">
          <FontAwesomeIcon icon={faShieldAlt} className="feature-icon" />
          <p>100% genuine medicines</p>
        </div>
        <div className="divider"></div>
        <div className="feature">
          <FontAwesomeIcon icon={faCreditCard} className="feature-icon" />
          <p>Safe & secure payments</p>
        </div>
        <div className="divider"></div>
        <div className="feature">
          <FontAwesomeIcon icon={faUndoAlt} className="feature-icon" />
          <p>No Returns</p>
        </div>
      </div>
            <div className="tabs">
            {Object.keys(tabs).map((key) => (
              <button
                key={key}
                className={`tab-button ${activeTab === key ? 'active' : ''}`}
                onClick={() => setActiveTab(key)}
              >
                {tabs[key].title}
              </button>
            ))}
          </div>

          <div className="tab-content">
            <h5>{tabs[activeTab].title}</h5>
            <p>{tabs[activeTab].content}</p>
          </div>
     
  
          </div>
        </div>
      </div>
       {/* Subscription Offcanvas */}
       {/* <SubscriptionOffcanvas
        show={showOffcanvas}
        handleClose={handleCloseOffcanvas}
        productDetails={single_product_details}
        
      /> */}
    </div>
  );
  
};

export default SingleProductDetails;
