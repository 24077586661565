import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import {
   bulkGetSubscriptions,
  removeSubscription,
  bulkDeleteSubscriptions
} from '../../redux/actions/subscriptionAction';
import Datepicker from '../../components/common/datepicker/datepicker';
import CheckboxComponent from '../../components/common/CheckboxComponent/CheckboxComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash, faHome } from '@fortawesome/free-solid-svg-icons';
import './admin-subscription-displaypage.scss';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
const SubscriptionDisplayPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const token = useSelector((state) => state.adminLogin.token);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null)
  const subscriptions = useSelector((state) => state.subscription?.subscriptionList || []);

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
 const [tableData, setTableData] = useState([]);
  const products = useSelector((state) => state.products.basicProducts || []);
  const [filteredSubscriptions, setfilteredSubscriptions] = useState(subscriptions);

 useEffect(() => {
  const fetchConfigs = () => {
    if (token) {
      //console.log("Fetching subscription configurations with token:", token);
      dispatch(bulkGetSubscriptions(token));
      //console.log("Dispatched bulkGetSubscriptions action");
    } else {
      //console.log("No token available, skipping fetchConfigs");
    }
  };

  //console.log("Initial fetchConfigs call");
  fetchConfigs();

  const intervalId = setInterval(() => {
    //console.log("Interval triggered for fetchConfigs");
    fetchConfigs();
  }, 180000); // Fetch every 3 minutes

  return () => {
    //console.log("Clearing interval for fetchConfigs");
    clearInterval(intervalId);
  };
}, [dispatch, token]);


const getProductName = (productId) => {
  const product = products.find((prod) => prod.product_id === productId);
  return product ? product.product_name : "Unknown Product";
};

  const handleCheckboxChange = (selectedId) => {
    if (selectedIds.includes(selectedId)) {
      setSelectedIds((prev) => prev.filter(id => id !== selectedId));
    } else {
      setSelectedIds((prev) => [...prev, selectedId]);
    }
  };
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedIds([]);
    } else {
      const allIds = filteredSubscriptions.map((sub) => sub.subscription_id);
      setSelectedIds(allIds);
    }
    setSelectAll(!selectAll);
  };
  useEffect(() => {
    setfilteredSubscriptions(subscriptions);
  }, [subscriptions]);
  useEffect(() => {
    if (selectedIds.length === filteredSubscriptions.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedIds, filteredSubscriptions.length]);

  useEffect(() => {
    setTableData(subscriptions.map(banner => ({
      ...banner,
      checked: false,
    })));
  }, [subscriptions]);

  // Handle Search
  const handleSearch = () => {
    if (!startDate && !endDate) {
      toast.warn('Please select a start date or end date.');
      setfilteredSubscriptions(subscriptions); // Reset to show all subscriptions
      return;
    }

    const filtered = subscriptions.filter((sub) => {
      const subscriptionStartDate = new Date(sub.start_date);
      const subscriptionEndDate = new Date(sub.end_date);

      const matchesStartDate = !startDate || subscriptionStartDate >= new Date(startDate);
      const matchesEndDate = !endDate || subscriptionEndDate <= new Date(endDate);

      return matchesStartDate && matchesEndDate;
    });

    setfilteredSubscriptions(filtered); // Update filtered subscriptions
  };



  const handlebulkDelete = async () => {
    if (window.confirm('Are you sure you want to delete the selected subscriptions?')) {
      try {
        // Dispatch bulk delete for selected subscription IDs
        await dispatch(bulkDeleteSubscriptions(selectedIds, token));

        // Reset selected IDs and select all state
        setSelectedIds([]);
        setSelectAll(false);

        // Show success toast and refresh subscriptions
        toast.success('Selected subscriptions deleted successfully.');
        dispatch(  bulkGetSubscriptions(token)); // Refresh the subscription list
      } catch (error) {
        // Handle any errors and show an error toast
        // console.error('Error during bulk delete:', error);
        toast.error('Failed to delete selected subscriptions.');
      }
    }
  };
  const handleshowall = () => {
    setfilteredSubscriptions(subscriptions); // Reset to show all subscriptions
    setStartDate(null); // Clear start date
    setEndDate(null); // Clear end date
    toast.success('Showing all subscriptions.');
  };

  useEffect(() => {
    //console.log('Subscriptions:', subscriptions);
    //console.log('Filtered Subscriptions:', filteredSubscriptions);
  }, [subscriptions, filteredSubscriptions]);


  const handleDelete = async (id) => {
    if (!id) {
      // console.error('Invalid ID:', id);
      return;
    }

    if (window.confirm('Are you sure you want to delete this subscription?')) {
      try {
        await dispatch(removeSubscription(id, token));
        //console.log('Deleted subscription ID:', id);
        toast.success('Subscription deleted successfully.');
      } catch (error) {
        // console.error('Error deleting subscription:', error);
        toast.error('Error deleting subscription.');
      }
    }
  };


  const handleEditClick = (id) => {
    navigate('/admin/add-subscription', { state: id });
  };

  return (
    <div className="subscription-list-page">
      <div className="breadcrumb-container">
        <Link to="/admin/dashboard" className="breadcrumb-text">
          <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <span className="breadcrumb-text">
          Subscription List
        </span>
      </div>
      <div className="subscription-list-container"></div>
      <div className='subscription-list-class'>
      <div className="subscription-list-content">
      <div className="header-row">
          <h5 className="text-heading1">Subscription List</h5>
          </div>
    </div>
        <hr />
        <div className="filter-section">
        <div className="filter-item">
          <label>Start Date</label>
          <Datepicker
           className={"date-input"}
            dateValue={startDate}
            onDateChange={setStartDate}
            placeholder="Select Start Date"
          />
        </div>
        <div className="filter-item">
          <label>End Date</label>
          <Datepicker
           className={"date-input"}
            dateValue={endDate}
            onDateChange={setEndDate}
            placeholder="Select End Date"
          />
        </div>



          <div className="action-buttons">
          <ButtonComponent text="Search"   className="search-button"  onClick={handleSearch} />
          <ButtonComponent
            text={
              <>
                <FontAwesomeIcon icon={faTrash} className="bulk-delete-icon" /> Bulk Delete
              </>
            }
            className="bulk-delete-button"
            onClick={handlebulkDelete}
             disabled={selectedIds.length === 0}
            />

           <ButtonComponent
            text="Show All"
            className="show-all-button"
            onClick={() => handleshowall(subscriptions)}
          />

          </div>
        </div>

        <div className="table-container">
          {filteredSubscriptions.length === 0 ? (
            <div className="no-data-message">No subscriptions found.</div>
          ) : (
            <table className="custom-table">
                <thead>
                  <tr>
                    <th>
                      <CheckboxComponent checked={selectAll} onChange={handleSelectAll} />
                    </th>
                    <th>ID</th>
                    <th>User ID</th>
                    <th>Product Name</th>
                    <th>Status</th>
                    <th>Frequency</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Quantity</th>
                    <th>Pause Reason</th>
                    <th>Resume Date</th>
                    <th>Cancel Reason</th>
                    <th>Cancel Request Date</th>
                    <th>Cancel Approved Date</th>
                    <th>Pause Date</th>
                    <th>Daily Time</th>
                    <th>Weekly Day</th>
                    <th>Monthly Date</th>
                    <th>Yearly Date</th>
                    <th>Transaction ID</th>
                    <th>Reference ID</th>
                    <th>Next Delivery Date</th>
                    <th>Payment Status</th>
                    <th>Payment Method</th>
                    <th>Shipping Address ID</th>
                    <th>Billing Address ID</th>
                    <th>Total Amount</th>
                    <th>Tax Amount</th>
                    <th>Discount Amount</th>
                    <th>Shipping Fee</th>
                    <th>Coupon ID</th>
                    <th>Discount ID</th>
                    <th>Created At</th>
                  
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                {filteredSubscriptions.map((sub, index) => (
                    <tr key={sub.subscription_id}>
                      <td>
                        <CheckboxComponent
                          checked={selectedIds.includes(sub.subscription_id)}
                          onChange={() => handleCheckboxChange(sub.subscription_id)}
                        />
                      </td>
                      <td>{index + 1}</td>
                      <td>{sub.user_id}</td>
                      <td>{getProductName(sub.product_id)}</td>
                      <td>{sub.product_id}</td>
                      <td>{sub.status}</td>
                      <td>{sub.frequency}</td>
                      <td>{sub.start_date}</td>
                      <td>{sub.end_date}</td>
                      <td>{sub.quantity}</td>
                      <td>{sub.pause_reason}</td>
                      <td>{sub.resume_date}</td>
                      <td>{sub.cancel_reason}</td>
                      <td>{sub.cancel_request_date}</td>
                      <td>{sub.cancel_approved_date}</td>
                      <td>{sub.pause_date}</td>
                      <td>{sub.daily_time}</td>
                      <td>{sub.weekly_day}</td>
                      <td>{sub.monthly_date}</td>
                      <td>{sub.yearly_date}</td>
                      <td>{sub.transaction_id}</td>
                      <td>{sub.reference_id}</td>
                      <td>{sub.next_delivery_date}</td>
                      <td>{sub.payment_status}</td>
                      <td>{sub.payment_method}</td>
                      <td>{sub.shipping_address_id}</td>
                      <td>{sub.billing_address_id}</td>
                      <td>{sub.total_amount}</td>
                      <td>{sub.tax_amount}</td>
                      <td>{sub.discount_amount}</td>
                      <td>{sub.shipping_fee}</td>
                      <td>{sub.coupon_id}</td>
                      <td>{sub.discount_id}</td>
                    
                      <td className="actions-column">
                        <button
                          className="icon-button"
                          onClick={() => handleEditClick(sub.subscription_id)}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </button>
                      </td>
                      <td className="actions-column">
                        <button
                          className="icon-button"
                          onClick={() => handleDelete(sub.subscription_id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>

  );
};

export default SubscriptionDisplayPage;