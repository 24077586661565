import React from "react";
import "./termsConditions.scss";

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <div className="header">
        <h1>Terms & Conditions</h1>
      </div>
      <div className="template-box">
        <div className="content-box">
          <h2>Terms of Use</h2>
          <p className="effective-date">Last Updated: Feburary 28, 2025</p>
          <p>Welcome to pmphrama.in! By accessing or using our website and mobile application, you agree to comply with the following Terms and Conditions. If you do not agree with any part of these terms, please discontinue using our services.</p>
          <h3>Scope</h3>
          <p>These terms apply to all users of the pmpharma website and mobile app.</p>
          <h3>General Terms</h3>
          <p>The content of this website is for informational purposes only and subject to change.</p>
          <h3>Medical Disclaimer</h3>
          <p>Our products and services do not constitute medical advice. Always consult a healthcare professional before purchasing medicines.</p>
          <h3>Prescription Medications</h3>
          <p>Certain medications require a valid prescription, and we reserve the right to verify such prescriptions before processing orders.</p>
          <h3>Product Availability</h3>
          <p>All orders are subject to stock availability and regulatory restrictions.</p>
          <h3>Payments</h3>
          <p>Payments must be made through approved gateways, and we are not responsible for transaction failures.</p>
          <h3>Shipping & Returns</h3>
          <p>Orders will be shipped based on the provided delivery address. Returns are only accepted for defective or incorrect products.</p>
          <h3>Intellectual Property</h3>
          <p>All content on this website, including text, images, and design, is owned by us. Unauthorized use is prohibited.</p>
          <h3>Governing Law</h3>
          <p>All disputes arising from the use of this website shall be subject to the laws of [India/TamilNadu].</p>
          <p>For any queries, contact:</p>
          <ul className="contact-list">
            <li><strong>Email:</strong> pmpharma.in@gmail.com</li>
            <li><strong>Phone:</strong> 8825745035</li>
          </ul>
        </div>
        <div className="contact-box">
          <h2>Need Help?</h2>
          <p>Contact our support team for any clarifications regarding these terms.</p>
          <p className="contact-number">8825745035</p>
          <p className="contact-email">pmpharma.in@gmail.com</p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
