// src/services/authService.js

import { auth_endpoints } from '../endPoints';
import { toast } from 'react-hot-toast';
import apiClient from '../apiClient';


export const generateToken = async () => {
  // try {
    const response = await apiClient.post(auth_endpoints.GENERATE_TOKEN, {
      client_key: "jhasbd81e1dasdasd23jh21jh1",
      client_secret: "asdasasdasddasdasdasd212w123",
    });

    if (response && response.data.access_token) {
      //console.log(response.data);
      // Dispatch the setToken action to update the Redux store
      // toast.success('Token generated successfully');
      return response.data.access_token;
  
};
}

// Request OTP
// export const requestOTP = async (token, mobileNumber) => {
//   //console.log(token,mobileNumber);
//   const response = await apiClient.post(
//       auth_endpoints.REQUEST_OTP,
//       { mobileNumber },  // Send mobileNumber in the body
//       {
//         headers: {
//           Authorization: token, // Ensure token is valid and has the correct prefix
//         }
//       }
//   );
//   return response.data;
// };



// Request OTP
export const requestOTP = async (token, mobileNumber) => {
  try {
    //console.log('Token:', token, 'Mobile Number:', mobileNumber);  // Debugging

    const response = await apiClient.post(
      auth_endpoints.REQUEST_OTP,
      {
        mobile_number: mobileNumber, // Send the mobile number in the body
      },
      {
        headers: {
          Authorization: token, // Ensure Bearer token is included in the Authorization header
        },
      }
    );
    //console.log(response.data)
    return response.data;  // Return the response data

  } catch (error) {
    // console.error('Error requesting OTP:', error);  // Handle error and log details
    throw error;  // Re-throw error to handle it in calling code
  }
};


export const mobileLogin = async (mobile_number) => {
  // try {
    //console.log("Mobile Number:", mobile_number);
    
    const response = await apiClient.post(auth_endpoints.LOGIN, {
      mobile_number: mobile_number,  // Sending mobile number in the request body
      role:"user",           // Including role if required by the API
    });
    
    
    return response.data; 
  

  
};


export const mobileRegister = async (mobile_number, otp) => {
  // try {
    //console.log("Mobile Number:", mobile_number, "OTP:", otp);
    
    const response = await apiClient.post(auth_endpoints.VERIFY_OTP, {
      mobile_number: mobile_number, 
      verified:true,
      role:"user",           // Including role if required by the API
    });
    //console.log("Response:", response.data.access_token);
    
    return response.data; // You might want to return the response data to use it elsewhere.

};



// // Request OTP (existing)
// export const requestOtp = async (mobileNumber) => {
//   try {
//     const response = await fetchProductsFromAPI(auth_endpoints.REQUEST_OTP, {
//       mobile_number: mobileNumber,
//     });
    
//     toast.success('OTP requested successfully.');
//     return response;
//   } catch (error) {
//     if (error.response) {
//       toast.error(`Error requesting OTP: ${error.response.data.message || error.message}`);
//     } else {
//       toast.error('Error requesting OTP: No response from server');
//     }
//     throw error;
//   }
// };

// // Verify OTP (existing)
// export const verifyOtp = async (mobileNumber, otp) => {
//   try {
//     const response = await fetchProductsFromAPI(auth_endpoints.VERIFY_OTP, {
//       mobile_number: mobileNumber,
//       otp: otp,
//     });

//     toast.success('OTP verified successfully.');
//     return response;
//   } catch (error) {
//     if (error.response) {
//       toast.error(`Error verifying OTP: ${error.response.data.message || error.message}`);
//     } else {
//       toast.error('Error verifying OTP: No response from server');
//     }
//     throw error;
//   }
// };

// // Login API call (new)
// export const login = async (email, password) => {
//   try {
//     const response = await fetchProductsFromAPI(auth_endpoints.LOGIN, {
//       client_key: email,
//       client_secret: password,
//     });

//     toast.success('Login successful.');
//     return response;
//   } catch (error) {
//     if (error.response) {
//       toast.error(`Login error: ${error.response.data.message || error.message}`);
//     } else {
//       toast.error('Login error: No response from server');
//     }
//     throw error;
//   }
// };

// // Forgot Password API call (new)
// export const forgotPassword = async (email) => {
//   try {
//     const response = await fetchProductsFromAPI(auth_endpoints.FORGOT_PASSWORD, { email });

//     toast.success('Password reset email sent.');
//     return response;
//   } catch (error) {
//     if (error.response) {
//       toast.error(`Forgot password error: ${error.response.data.message || error.message}`);
//     } else {
//       toast.error('Forgot password error: No response from server');
//     }
//     throw error;
//   }
// };
