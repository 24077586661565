import React, { useState,useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './MyOrderDetails.scss';
import BillDetailsCard from '../../components/billorderdetails/billorderdetails';import orderCancelledIcon from '../../assets/images/order-cancelled.png';
import CardItem from '../../components/common/card/card-items';
import EmailModal from '../../components/sections/EmailModal/EmailModal'; // Import the EmailModal component
import Noproduct from '../../assets/images/Noproduct.webp'; 
import { useNavigate } from 'react-router-dom';

const MyOrderDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, []);

  // State to manage modal visibility
  const [isEmailModalOpen, setEmailModalOpen] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const order_id = location.state || null; // Retrieve `order_id` from the location state

  // Redux state selectors
  const orders = useSelector((state) => state.orders.orders || []);
  const orderItems = useSelector((state) => state.orders.orderItems || []);
  const addresses = useSelector((state) => state.address.addresses || []); // Assuming `addresses` are stored in Redux
  const basicProducts = useSelector((state) => state.products.basicProducts || []); // Fetch basic products
  const productUrls=useSelector((state)=>state.image.urls.products);
  // Find the current order and associated items
  const currentOrder = orders.find((order) => order.order_id === order_id);
  const currentOrderItems = orderItems.filter((item) => item.order_id === order_id);

  // Find the address using `address_id` from the current order
  const currentAddress = addresses.find(
    (address) => address.address_id === currentOrder?.billing_address_id
  );
 
  // Map order items with product details from `basicProducts`
  const mappedOrderItems = currentOrderItems.map((item) => {
    const productDetails = basicProducts.find(
      (product) => product.product_id === item.product_id
    );

    return {
      ...item,
      product_name: productDetails?.product_name || 'Unknown Product',
      price: productDetails?.price || 0,
      mrp: productDetails?.mrp || 0,
      discount: productDetails?.discount || '0%',
      imageUrl: productDetails?.image_url || 'default-image.jpg',
    };
  });

  // Function to handle opening the modal
  const openEmailModal = () => {
    setEmailModalOpen(true);
  };

  // Function to handle closing the modal
  const closeEmailModal = () => {
    setEmailModalOpen(false);
  };
  const handleProductClick = (productId, productName) => {
   
    navigate(`/singleproducts/${encodeURIComponent(productName)}`, { state: productId });
    setTimeout(() => {
      window.scrollTo(0, 0); // Ensure it scrolls to the top after navigation
    }, 100);
  };

  return (
    <div className="order details-page2">
      {/* Email Modal Popup */}
      <EmailModal isOpen={isEmailModalOpen} onClose={closeEmailModal} />

      {/* Left Section - Order Details */}
      <div className="order left-section2">
        {currentOrder ? (
          <>
            <div className="order cancelled-details2">
              <div className="order cancelled-header2">
                <img
                  src={orderCancelledIcon}
                  alt={currentOrder.order_status || 'Order Status'}
                  className="order cancelled-icon2"
                />
                <div className="order cancelled-status2">
                  {currentOrder.order_status || 'Status Unknown'}
                </div>
              </div>

              <div className="order info2">
                <p className="order patient-details2">
                  <strong>Order ID:</strong> {currentOrder.order_id}
                </p>
                <p className="order patient-details2">
                  <strong>Order Placed:</strong>{' '}
                  {new Date(currentOrder.order_date).toLocaleDateString()}
                </p>
              </div>
              <hr className="order line2" />

              {/* Ordered Items Section */}
              <div className="order ordered-items2">
                <h6 className="order ordered-items-header2">
                  Ordered Items ({mappedOrderItems.length})
                </h6>
                {mappedOrderItems.map((item, index) => (
                  <CardItem
                    key={index}
                    imageUrl={productUrls[item.product_id]}
                    title={item.product_name}
                    manage={true}
                    price={item.price}
                    mrp={item.mrp}
                    discount={item.discount}
                    quantity={item.quantity || 0}
                    hidePause={true}
                    hideClose={true}
                    onClick={() => handleProductClick(item.product_id, item.product_name)}
                    />
                ))}
              </div>

              {/* Address Section */}
              <div className="order details-box2">
                <div className="order bordered-box2">
                  <p className="order address-details2">
                    <strong>Address</strong>
                  </p>
                  {currentAddress ? (
                    <p>
                      <span className="order address-label2">
                        {currentAddress.address_type || 'N/A'}
                      </span>{' '}
                      {currentAddress.address}, {currentAddress.city}, {currentAddress.state} -{' '}
                      {currentAddress.pincode}
                    </p>
                  ) : (
                    <p>Address Not Available</p>
                  )}
                </div>
                {currentOrder.order_status === 'cancel' && (
                  <div className="order cancellation-bordered-box2">
                    <p className="order order-cancel-details2">
                      <strong>Order Cancelled On:</strong>{' '}
                      {new Date(currentOrder.cancellation_date).toLocaleDateString()}
                    </p>
                    <p>Cancellation reason: {currentOrder.cancellation_reason || 'Unknown'}</p>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <div>Loading Order Details...</div>
        )}
      </div>

      {/* Right Section - Bill Details */}
      <div className="order right-section2">
      <BillDetailsCard orderId={order_id} />
      </div>
    </div>
  );
};

export default MyOrderDetails;
