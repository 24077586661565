import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import InputComponent from '../../components/common/InputComponent/InputComponent'; 
import ButtonComponent from '../../components/common/buttons/ButtonComponent'; 
import { FaTrash, FaPlus } from 'react-icons/fa';
import { faTimes, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import "./bulkuser.scss";
import { updateBulkUsers, createBulkUsers,fetchUsers } from "../../redux/actions/adminuserActions";
import toast from "react-hot-toast";
import DropdownButton from '../../components/common/dropdown/DropdownButton';
const BulkUsers = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const users = useSelector(state => state.usermanage.users) || [];
  const token = useSelector(state => state.adminLogin.token);
  const initialUsers = location.state || [];
  const [userData, setUserData] = useState([]);
  const [duplicateErrors, setDuplicateErrors] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const genderOptions = ['Male', 'Female', 'Other']; 
  const normalizeDate = (dateString) => {
    if (!dateString) return '';
    const dateParts = dateString.match(/(\d{2})[-\/](\d{2})[-\/](\d{4})/);
    if (dateParts) {
      return `${dateParts[3]}-${dateParts[2]}-${dateParts[1]}`; // Convert to YYYY-MM-DD
    }
    const date = new Date(dateString);
    return !isNaN(date) ? date.toISOString().split('T')[0] : ''; // Fallback to default date parsing
  };
  
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  
  useEffect(() => {
    const processUsers = () => {
      if (!initialUsers || initialUsers.length === 0) {
        setUserData([{ username: '', email: '', mobile_number: '', dob: '', gender: 'Male' }]);
      } else if (typeof initialUsers[0] === 'object') {
        const processedUsers = initialUsers.map(user => ({
          username: user.username || '',
          email: user.email || '',
          mobile_number: user.mobile_number || '',
          dob: normalizeDate(user.dob || ''), // Normalize DOB
          gender: capitalizeFirstLetter(user.gender || 'Male'), // Capitalize gender
          role: user.role || 'user'
        }));
        setUserData(processedUsers);
      } else {
        const processedUsers = initialUsers.map(id => {
          const user = users.find(user => user.user_id === id);
          return user
            ? {
                ...user,
                user_id: id,
                username: user.username || '',
                email: user.email || '',
                mobile_number: user.mobile_number || '',
                dob: normalizeDate(user.dob || ''), // Normalize DOB
                gender: capitalizeFirstLetter(user.gender || 'Male'), // Capitalize gender
                role: 'user'
              }
            : {
                user_id: id,
                username: '',
                email: '',
                mobile_number: '',
                dob: '',
                gender: 'Male',
                role: 'user'
              };
        });
        setUserData(processedUsers);
      }
    };
  
    processUsers();
  }, [initialUsers, users]);
  
  
  // Handle input changes for each field
  const handleInputChange = (index, field, value) => {
    setUserData(prevUserData => {
      const updatedUsers = [...prevUserData];
      updatedUsers[index][field] = value;
      return updatedUsers;
    });

    if (field === 'user_id') {
      const updatedErrors = [...duplicateErrors];
      updatedErrors[index] = false; // Reset duplicate errors for user_id
      setDuplicateErrors(updatedErrors);
    }
  };

  // Add a new row for user input
  const handleAddRow = () => {
    setUserData(prevUsers => [
      ...prevUsers,
      { user_id: '', mobile_number: '', role: 'user' }
    ]);
    setDuplicateErrors(prevErrors => [...prevErrors, false]);
  };


  // Handle deletion of a row
  const handleDelete = (index) => {
    const newUsers = [...userData];
    newUsers.splice(index, 1);
    setUserData(newUsers);
    const newErrors = [...duplicateErrors];
    newErrors.splice(index, 1);
    setDuplicateErrors(newErrors);
  };
  const parseDateForInput = (dateString) => {
    if (!dateString) return ''; // Return an empty string if the date is invalid or empty
    const date = new Date(dateString);
    return !isNaN(date) ? date.toISOString().split('T')[0] : ''; // Validate the date before formatting
  };
  

  // Check for duplicate user IDs
  const checkForDuplicateUserIds = () => {
    const existingIds = users.map(user => user.mobile_number.trim().toLowerCase());
    const updatedErrors = userData.map(user => {
      return user.mobile_number && existingIds.includes(user.mobile_number.trim().toLowerCase());
    });
    setDuplicateErrors(updatedErrors);
    return updatedErrors.some(error => error);
  };

  // Handle save button click
  const handleSaveClick = async () => {
    //console.log(userData,'userData')
    // Conditionally format the user data based on initialUsers
    const updatedUserData = userData.map(user => ({
      username: String(user.username || '').trim(),
      email: String(user.email || '').trim(),
      mobile_number: String(user.mobile_number || '').trim(),
      dob: user.dob || null,
      gender: user.gender,
      user_id:user.user_id||'',
      role:"user",
    }));
    //console.log(updatedUserData,'update user data')
    // Check for empty fields (user_id and mobile_number)
    const hasEmptyFields = updatedUserData.some(user => {
        
            return !user.mobile_number && !user.email || !user.email;  // Both user_id and mobile_number are mandatory if user_id is included
        
        
    });
    //console.log(updatedUserData,'update user data 1')
    if (hasEmptyFields) {
        toast.error("Please fill in all mandatory fields (User ID and Mobile Number).");
        return;
    }

     
    //console.log(updatedUserData,'update user data 2')
    setIsSaving(true);
    let responseStatus = false;

    try {
        if (typeof initialUsers[0] === 'object') {
            // Create new users if initialUsers[0] is an object
            responseStatus = await dispatch(createBulkUsers(updatedUserData, token));
        } else {
          //console.log(updatedUserData,'update user data 3')
            // Update users if initialUsers[0] is not an object
            responseStatus = await dispatch(updateBulkUsers(updatedUserData, token));
        }
    } catch (error) {
        // console.error("Error occurred while saving users:", error);
    }

    setIsSaving(false);

    if (responseStatus) {
        navigate('/admin/user-management');
    } else {
        toast.error("Failed to save users.");
        // console.error("Failed to save users");
    }
};

  return (
    <div className="bulk-user-main-page">
    

    <div className="breadcrumb-container">
         
         <Link to="/admin/dashboard" className="breadcrumb-text">
         <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
         </Link>
         <span className="breadcrumb-separator">{'>'}</span>
         <Link to="/admin/user-management" className="breadcrumb-text">
         <span className="breadcrumb-text">User List</span>
         </Link>
         <span className="breadcrumb-separator">{'>'}</span>
         <span className="breadcrumb-text">Bulk User</span>
    </div>
       <div className="bulk-user-container"></div>
       <div className="bulk-user">
       <div className="user-table">
       <div className="header-row">
       <h5 className="text-heading1">Bulk user</h5>
      <div className="add-new-button">
        {typeof initialUsers[0] === 'object' && (
          <ButtonComponent 
            text={<><FaPlus style={{ marginRight: '8px' }} />Add New</>} 
            onClick={handleAddRow} 
            className="add-button" 
          />
        )}
        <ButtonComponent 
          text={isSaving ? "Saving..." : "Save"} 
          onClick={handleSaveClick} 
          className="save-button" 
          disabled={isSaving} 
        />
      </div>
      </div>
      </div>
      <div className="table-container">
        <table className="custom-table">
          <thead>
          <tr>
              <th>Username</th>
              <th>Email</th>
              <th>Mobile Number</th>
              <th>DOB</th>
              <th>Gender</th>
              <th className="small-column">Delete</th>
            </tr>
          </thead>
          <tbody>
          {userData.map((user, index) => (
              <tr key={index}>
                <td>
                  <InputComponent
                    className="table-input"
                    value={user.username}
                    onChange={(e) => handleInputChange(index, 'username', e.target.value)}
                  />
                </td>
                <td>
                  <InputComponent
                    className="table-input"
                    value={user.email}
                    onChange={(e) => handleInputChange(index, 'email', e.target.value)}
                  />
                </td>
                <td>
                  <InputComponent
                    className="table-input"
                    value={user.mobile_number}
                    onChange={(e) => handleInputChange(index, 'mobile_number', e.target.value)}
                  />
                </td>
                <td>
                  <InputComponent
                    className="table-input"
                    type="date"
                    value={parseDateForInput(user.dob)}
                    onChange={(e) => handleInputChange(index, 'dob', e.target.value)}
                  />
                </td>
                <td>
                  <DropdownButton
                    options={genderOptions}
                    label={user.gender || 'Select Gender'}
                    onSelect={(selectedOption) => handleInputChange(index, 'gender', selectedOption)}
                  />
                </td>
                <td className="actions1">
                  <FaTrash className="action-icon1" onClick={() => handleDelete(index)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
  );
};
export default BulkUsers;