import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import CardItem from '../../components/common/card/card-items';
import './product.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
  addToCart,
  decreaseCartItem,
  increaseCartItem,
  deleteCartItem,
  getBulkCartItems,
} from '../../redux/actions/Addtocart';
import { fetchProducts } from '../../redux/actions/productActions';
import toast from 'react-hot-toast';

const ProductItemCards = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Use useLocation to get query
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token) || '';
  const products = useSelector((state) => state.products.basicProducts) || [];
  const cartItems = useSelector((state) => state.cart.cartItems) || [];
  const authData = useSelector((state) => state.auth.user) || '';
  const cart_id = useSelector((state) => state.cart.cart_id) || '';
  const productUrls = useSelector((state)=>state.image.urls.products)||{};
  const [loading, setLoading] = useState(true);

 
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
      const newSearchQuery = location.state?.searchQuery || '';
      if (newSearchQuery !== searchQuery) {
          setSearchQuery(newSearchQuery);
          // Add logic here to fetch or filter products
      }
  }, [location.state?.searchQuery]);

  const handleProductClick = (productId, productName) => {
    navigate(`/singleproducts/${encodeURIComponent(productName)}`, { state: productId });
  };

  useEffect(() => { 
    const fetchData = async () => {
      await dispatch(fetchProducts(token)); // Fetch products
      if (authData){
        await dispatch(getBulkCartItems(cart_id, token)); // Fetch cart items
      }
      else{
        await dispatch(getBulkCartItems(cart_id, '')); // Fetch cart items
      }
      
      setLoading(false);
    };

    fetchData(); // Initial fetch

    const intervalId = setInterval(fetchData, 180000); // Refresh every 3 minutes
    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [dispatch, token, cart_id]);

  const getProductQuantity = (productId) => {
    const item = cartItems.find((item) => item.product_id === productId);
    return item ? item.quantity : 0;
  };

  const handleAddToCart = (product,event) => {
    if (event) {
      event.stopPropagation(); // Stop event bubbling if event exists
    }
    //console.log("Adding to cart:", product);
    if (authData) {
      dispatch(addToCart(product, token));
    } else {
      dispatch(addToCart(product, ''));
    }
  };
  const handleIncreaseQuantity = (productId) => {

    if(authData){
      const cartItem = cartItems.find((item) => item.product_id === productId.product_id);
    if (cartItem && cartItem.quantity < Number(productId.max_order_quantity) && cartItem.quantity <= Number(productId.stock_level)) {
      const updatedCartItem = { ...cartItem, quantity: cartItem.quantity + 1 };
      dispatch(increaseCartItem(updatedCartItem, token));
    }
    else{
      if(cartItem.quantity >= Number(productId.max_order_quantity)){
        toast.error(`A user can order only up to the specified maximum limit of ${productId.max_order_quantity} items for this product.`);
      }
      else{
        toast.error(`Cannot add more! Only ${productId.stock_level} units available.`);
      }
    }
    
    }
    else{
      const cartItem = cartItems.find((item) => item.product_id === productId.product_id);
      if (cartItem && cartItem.quantity < Number(productId.max_order_quantity) && cartItem.quantity <= Number(productId.stock_level)){
        dispatch(increaseCartItem(productId,''));
      }
      else{
        if(cartItem.quantity >= Number(productId.max_order_quantity)){
          toast.error(`A user can order only up to the specified maximum limit of ${productId.max_order_quantity} items for this product.`);
        }
        else{
          toast.error(`Cannot add more! Only ${productId.stock_level} units available.`);
        }
      }
      
    }
    
  };

  const handleDecreaseQuantity = (productId) => {
    if(authData){
     
    const cartItem = cartItems.find((item) => item.product_id === productId.product_id);
    if (cartItem && cartItem.quantity > Number(productId.minimum_order_quantity))  {
      const updatedCartItem = { ...cartItem, quantity: cartItem.quantity - 1 };
      dispatch(decreaseCartItem(updatedCartItem, token));
    }

    else{
      removeFromCart(productId.product_id)
    }
  }
  else{
    const cartItem = cartItems.find((item) => item.product_id === productId.product_id);
    if (cartItem && cartItem.quantity > Number(productId.minimum_order_quantity)){
      dispatch(decreaseCartItem(productId, ''));
    }
    else{
      removeFromCart(productId.product_id)
    }
   
  }
  };

  const removeFromCart = (productId) => {
    if(authData){
      const cartItem = cartItems.find((item) => item.product_id === productId);
      if (cartItem) {
        dispatch(deleteCartItem(cartItem.cart_item_id, token));
      }
    }
    else{
      //console.log('cartitemsss removed',authData);
      dispatch(deleteCartItem(productId, ''))
    }


   
  };



  // Filter products based on the search query
  const filteredProducts = products.filter((product) =>
    product.product_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

 

  return (
    <div className="product-list-section">
      <h5 className="product-list-header">
        {searchQuery ? `Search Results for "${searchQuery}"` : 'Available Products'}
      </h5>
      {loading ? (
        <div className="loading-message">Loading products...</div>
      ) : (
        <div className="product-list-container">
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product) => (
              <CardItem
                key={product.product_id}
                title={product.product_name}
                price={`${product.price}`}
                mrp={`${product.mrp}`}
          
                discount={`${product.discount}`}
                quantity={getProductQuantity(product.product_id)}
                onClick={() => handleProductClick(product.product_id, product.product_name)}
                onIncrease={() => handleIncreaseQuantity(product)}
                onDecrease={() => handleDecreaseQuantity(product)}
                onRemove={() => removeFromCart(product.product_id)}
                onAddToCart={() => handleAddToCart(product)}
                imageUrl={productUrls[product.product_id]}
                hideClose={true}
                hidePause={true}
                min_order={product.minimum_order_quantity}
                showAddButton={true}
                stock_level={product.stock_level}


              />
            ))
          ) : (
            <div className="no-results-message">No products found for "{searchQuery}".</div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductItemCards;
