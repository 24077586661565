import React from 'react';
import './ShopCard.scss';
import Noproduct from '../../../assets/images/Noproduct.webp'; 

const ShopCard = ({ title, image, width, height, onClick }) => {
  return (
    <div
      className="shop-card"
      style={{ width: width || '200px', height: height || '250px', cursor: 'pointer' }} // Default size
      onClick={onClick}
    >
      <div className="shop-card-content">
      <h6 className="shop-card-title">{title}</h6>
      <img
  src={image || Noproduct}
  alt={title}
  className="shop-card-image"
  onError={(e) => { e.target.src = Noproduct; }}
/>

       
      </div>
    </div>
  );
};

export default ShopCard;
