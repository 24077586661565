import React, { useState } from 'react';
import './QuantityInput.scss';
import RemoveItemPopup from '../RemoveItemPopup/RemoveItemPopup';

const QuantityInput = ({ quantity, onRemove, onIncrease, onDecrease,min_order,popupState=true }) => {
  const [showPopup, setShowPopup] = useState(false);
  //console.log(quantity,min_order,'---quantityyyyyy')
  const handleDecrease = () => {
    if (quantity > min_order) {
      onDecrease();
    } else {
      if (popupState){
        setShowPopup(true); // Show popup if trying to decrement to zero

      }
    }
  };

  const handleConfirmRemove = () => {
    onRemove();
    setShowPopup(false);
  };

  const handleCancelRemove = () => {
    setShowPopup(false);
  };

  return (
    <div className="quantity-input-container">
      <div className="input-group custom-quantity-input">
        <button className="btn buttoncustom" type="button" onClick={handleDecrease}>-</button>
        <input 
          type="text" 
          className="form-control input-field" 
          value={quantity} 
          aria-label="Quantity" 
          readOnly
        />
        <button className="btn buttoncustom" type="button" onClick={onIncrease}>+</button>
      </div>
      
      {showPopup && (
        <RemoveItemPopup 
          onConfirm={handleConfirmRemove} 
          onCancel={handleCancelRemove} 
        />
      )}
    </div>
  );
};

export default QuantityInput;
