import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProductCard from '../common/ProductCard/ProductCard';
import './ProductItemCards.scss';
import { useSelector, useDispatch } from 'react-redux';
import { 
  addToCart, 
  decreaseCartItem, 
  increaseCartItem, 
  deleteCartItem, 
  getBulkCartItems 
} from '../../redux/actions/Addtocart';
import { fetchProducts } from '../../redux/actions/productActions';
import { fetchPresignedUrl } from '../../redux/actions/fetchImage';
import { Carousel, Row, Col } from 'react-bootstrap';
import toast from 'react-hot-toast';

const ProductItemCards = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Redux state
  const token = useSelector((state) => state.auth.token) || '';
  const products = useSelector((state) => state.products.basicProducts) || [];
  const cartItems = useSelector((state) => state.cart.cartItems) || [];
  const authData = useSelector((state) => state.auth.user) || '';
  const cart_id = useSelector((state) => state.cart.cart_id) || '';
  const productUrls = useSelector((state) => state.image.urls.products) || {}; // Pre-signed URLs for products
  const loading = useSelector((state) => state.products.loading); // Centralized loading state
  //console.log(products,'-------urlssssssssss')
  const [cardsPerRow, setCardsPerRow] = useState(4);

  // Handle navigation to product details
  const handleProductClick = (productId, productName) => {
   
    navigate(`/singleproducts/${encodeURIComponent(productName)}`, { state: productId });
    setTimeout(() => {
      window.scrollTo(0, 0); // Ensure it scrolls to the top after navigation
    }, 100);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      await dispatch(fetchProducts(token)); 
      await dispatch(getBulkCartItems(cart_id, authData ? token : ''));
    };

    fetchInitialData();
  }, [dispatch, token, cart_id, authData]);

  useEffect(() => {
    if (!loading && products.length > 0) {
      products.forEach((product) => {
        if (product?.product_id && product?.image_url) {
          dispatch(fetchPresignedUrl("products", product.product_id, product.image_url, token));
        }
      });
    }
  }, [products, loading, token]);
  
  useEffect(() => {
    // Function to update the cards per row based on window size
    const handleResize = () => {
      setCardsPerRow(getCardsPerSlide());
    };
  
    // Set the initial value
    setCardsPerRow(getCardsPerSlide());
  
  // Attach the event listener to the window resize event
    window.addEventListener("resize", handleResize);
  
    // Cleanup the event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  // Group products into slides based on screen width
  const groupProducts = (items, itemsPerSlide) => {
    const groupedProducts = [];
    for (let i = 0; i < items.length; i += itemsPerSlide) {
      groupedProducts.push(items.slice(i, i + itemsPerSlide));
    }
    return groupedProducts;
  };

  // Determine number of cards per slide dynamically
  const getCardsPerSlide = () => {
    const width = window.innerWidth;
    if (width >= 1400) return 5;
  
    if (width >= 1200) return 4;
    
    if (width >= 992) return 4;
    if (width >= 768) return 3;
    if (width >= 576) return 2;
    if (width < 576 && width> 344) return 2;
    if(width <344) return 1;
    return 1;
  };
  // Group products with updated URLs
  const groupedProducts = groupProducts(
    products.map((product) => ({
      ...product,
      url: productUrls[product.product_id] || "", // Use pre-signed URL if available
    })),
    getCardsPerSlide()
  );

  // Get the quantity of a product in the cart
  const getProductQuantity = (productId) => {
    //console.log(cartItems,cart_id,'----carttuuuuitems')
    const item = cartItems.find((item) => item.product_id === productId);
    //console.log("Cart Quantity Debug:", { productId, quantity: item ? item.quantity : 0 });
    return item ? item.quantity : 0;
  };
  
  // Add other handlers for cart actions

  const handleAddToCart = (product) => {
 
    //console.log("Adding to cart:", product);
    if (authData) {
      dispatch(addToCart(product, token));
    } else {
      dispatch(addToCart(product, ''));
    }
  };
  const handleIncreaseQuantity = (productId) => {

    if(authData){
      const cartItem = cartItems.find((item) => item.product_id === productId.product_id);
    if (cartItem && cartItem.quantity < Number(productId.max_order_quantity) && cartItem.quantity <= Number(productId.stock_level)) {
      const updatedCartItem = { ...cartItem, quantity: cartItem.quantity + 1 };
      dispatch(increaseCartItem(updatedCartItem, token));
    }
    else{
      if(cartItem.quantity >= Number(productId.max_order_quantity)){
        toast.error(`A user can order only up to the specified maximum limit of ${productId.max_order_quantity} items for this product.`);
      }
      else{
        toast.error(`Cannot add more! Only ${productId.stock_level} units available.`);
      }
    }
    
    }
    else{
      const cartItem = cartItems.find((item) => item.product_id === productId.product_id);
      if (cartItem && cartItem.quantity < Number(productId.max_order_quantity) && cartItem.quantity <= Number(productId.stock_level)){
        dispatch(increaseCartItem(productId,''));
      }
      else{
        if(cartItem.quantity >= Number(productId.max_order_quantity)){
          toast.error(`A user can order only up to the specified maximum limit of ${productId.max_order_quantity} items for this product.`);
        }
        else{
          toast.error(`Cannot add more! Only ${productId.stock_level} units available.`);
        }
      }
      
    }
    
  };

  const handleDecreaseQuantity = (productId) => {
    if(authData){
     
    const cartItem = cartItems.find((item) => item.product_id === productId.product_id);
    if (cartItem && cartItem.quantity > Number(productId.minimum_order_quantity))  {
      const updatedCartItem = { ...cartItem, quantity: cartItem.quantity - 1 };
      dispatch(decreaseCartItem(updatedCartItem, token));
    }

    else{
      removefromCart(productId.product_id)
    }
  }
  else{
    const cartItem = cartItems.find((item) => item.product_id === productId.product_id);
    if (cartItem && cartItem.quantity > Number(productId.minimum_order_quantity)){
      dispatch(decreaseCartItem(productId, ''));
    }
    else{
      removefromCart(productId.product_id)
    }
   
  }
  };

  const removefromCart = (productId) => {
    if(authData){
      const cartItem = cartItems.find((item) => item.product_id === productId);
      if (cartItem) {
        dispatch(deleteCartItem(cartItem.cart_item_id, token));
      }
    }
    else{
      //console.log('cartitemsss removed',authData);
      dispatch(deleteCartItem(productId, ''))
    }


   
  };

  return (
    <div className="popular-main product-section">
      <div className="popular-product product-container">
        <h5 className="popular-product product-section-title">Popular Products</h5>
        {loading ? (
          <Row className="skeleton-loader-row">
            {Array.from({ length: cardsPerRow }).map((_, index) => (
              <Col key={index} className="skeleton-loader-col">
                <div className="skeleton-card"></div>
              </Col>
            ))}
          </Row>
        ) : (
          <div className="popular-product product-carousel">
            <Carousel indicators={true} controls={true} interval={null}>
              {groupedProducts.map((group, index) => (
                <Carousel.Item key={index}>
                  <div className="popular-product product-group">
                    {group.map((product) => (
                      <ProductCard
                      key={`product-${product.product_id}`}
                        discount={`${Number(product.discount)}% off`}
                        
                        productName={product.product_name}
                        mrp={product.mrp}
                        price={product.price}
                        quantity={getProductQuantity(product.product_id)}
                        description={product.description}
                        onAddToCart={() => handleAddToCart(product)} 
                        onIncrease={() => handleIncreaseQuantity(product)}
                        onDecrease={() => handleDecreaseQuantity(product)}
                        onRemove={() => removefromCart(product.product_id)}
                        image_url={productUrls[product.product_id]}
                        stock_level={product.stock_level}
                        min_order={product.minimum_order_quantity}
                        onClick={() => handleProductClick(product.product_id, product.product_name)}
                      />
                    ))}
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductItemCards;

