import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import InputComponent from '../../components/common/InputComponent/InputComponent'; 
import ButtonComponent from '../../components/common/buttons/ButtonComponent'; 
import SearchableDropdown from '../../components/common/SearchableDropdown/SearchableDropdown';
import DropdownButton from '../../components/common/dropdown/DropdownButton';
import { FaTrash, FaPlus } from 'react-icons/fa';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import "./bulk-product.scss"; 
import { updateProductsWorkflow, createProductsWorkflow } from "../../redux/actions/productActions"; 
import toast from "react-hot-toast";
import Datepicker from '../../components/common/datepicker/datepicker';
const BulkProduct = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const normalizeDate = (dateString) => {
    if (!dateString) return '';
    
    // Check if the date is already in DD-MM-YYYY format
    const dateParts = dateString.match(/(\d{2})[-\/](\d{2})[-\/](\d{4})/);
    if (dateParts) {
      return `${dateParts[1]}-${dateParts[2]}-${dateParts[3]}`; // Ensure it remains DD-MM-YYYY
    }
    
    // Parse and format to DD-MM-YYYY
    const date = new Date(dateString);
    if (!isNaN(date)) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    }
    
    return ''; // Return an empty string if the date is invalid
  };
  
  // Selectors from Redux store
  const basicProducts = useSelector((state) => state.products.basicProducts) || [];
  const customProducts = useSelector((state) => state.products.customProducts) || [];
  const categories = useSelector((state) => state.category.categoryList.categories) || [];
  const brands = useSelector((state) => state.brand.brandList.brands) || [];
  const token = useSelector(state => state.adminLogin.token);
  const initialProducts = location.state || [];
  const categoryDropdownOptions = categories.map(category => ({ label: category.name, id: category.category_id }));
   const brandDropdownOptions = brands.map(brand => ({ label: brand.name, id: brand.brand_id }));
   const [isSidebarVisible, setIsSidebarVisible] = useState(false);
   const [entity,setEntity] = useState('')
  const [productData, setProductData] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const prescription_required_fun =(required)=>{
    if (required === "Yes" || required ==="yes" || required ==="YES" ){
      return true;
    }
    else if(required === "no" || required ==="No" || required ==="NO" ){
      return false;
    }
  }
  useEffect(() => {
    
    const processProducts = () => {
      const categoryMap = categories.reduce((map, category) => {
        map[category.category_id] = category.name;
        return map;
      }, {});
      
      const brandMap = brands.reduce((map, brand) => {
        map[brand.brand_id] = brand.name;
        return map;
      }, {});
      if (!initialProducts || initialProducts.length === 0) {
        setProductData([{
          basicProducts: {
            product_name: '', category_id: '', short_code: '', brand_id: '', description: '',category_name: '', brand_name: '',
            price: '', discount: '', mrp: '', unit: '', pcs_per_unit: '', stock_level: '', minimum_order_quantity: '',
            max_order_quantity: '', product_weight: '', dimensions: '', storage_conditions: '', packaging_type: '',
            delivery_timeframe: '', shipping_price: '', returns_policy: ''
          },
          customProducts: {
            prescription_required: false, dosage_information: '', form: '', strength: '', active_ingredients: '',
            side_effects: '', contraindications: '', instructions: '', license_information: '', expiry_date: '',
            batch_number: '', manufacturing_date: '', manufacturer_license_number: '', material_type: '', size: '',
            intended_use: '', weight_capacity: '', adjustability: '', mobility_features: '', care_instructions: '',
            warranty: '', certifications: ''
          }
        }]);
      } else if (typeof initialProducts[0] === 'string') {
        const processedProducts = initialProducts.map(id => {
          const basicProduct = basicProducts.find(product => product.product_id === id) || {};
          const customProduct = customProducts.find(product => product.product_id === id) || {};
          return {
            basicProducts: {
              product_id:basicProduct.product_id||'',
              product_name: basicProduct.product_name || '',
              category_id: basicProduct.category_id || '',
              short_code: basicProduct.short_code || '',
              category_name: categoryMap[basicProduct.category_id] || '', // Use lookup for category name
              brand_name: brandMap[basicProduct.brand_id] || ''  ,
              brand_id: basicProduct.brand_id || '',
              description: basicProduct.description || '',
              price: basicProduct.price || '',
              discount: basicProduct.discount || '',
              mrp: basicProduct.mrp || '',
              unit: basicProduct.unit || '',
              pcs_per_unit: basicProduct.pcs_per_unit || '',
              stock_level: basicProduct.stock_level || '',
              minimum_order_quantity: basicProduct.minimum_order_quantity || '',
              max_order_quantity: basicProduct.max_order_quantity || '',
              product_weight: basicProduct.product_weight || '',
              dimensions: basicProduct.dimensions || '',
              storage_conditions: basicProduct.storage_conditions || '',
              packaging_type: basicProduct.packaging_type || '',
              delivery_timeframe: basicProduct.delivery_timeframe || '',
              shipping_price: basicProduct.shipping_price || '',
              returns_policy: basicProduct.returns_policy || '',
           
              // daily_subscription_price:basicProduct.daily_subscription_price,
              // weekly_subscription_price:basicProduct.weekly_subscription_price,
              // monthly_subscription_price:basicProduct.monthly_subscription_price,
              // yearly_subscription_price:basicProduct.yearly_subscription_price,
            },
            customProducts: {
              product_id:customProduct.product_id||'',
              prescription_required: customProduct.prescription_required || false,
              dosage_information: customProduct.dosage_information || '',
              form: customProduct.form || '',
              strength: customProduct.strength || '',
              active_ingredients: customProduct.active_ingredients || '',
              side_effects: customProduct.side_effects || '',
              contraindications: customProduct.contraindications || '',
              instructions: customProduct.instructions || '',
              license_information: customProduct.license_information || '',
              expiry_date: normalizeDate(customProduct.expiry_date) || '',
              batch_number: customProduct.batch_number || '',
              manufacturing_date: normalizeDate(customProduct.manufacturing_date) || '',
              manufacturer_license_number: customProduct.manufacturer_license_number || '',
              material_type: customProduct.material_type || '',
              size: customProduct.size || '',
              intended_use: customProduct.intended_use || '',
              weight_capacity: customProduct.weight_capacity || '',
              adjustability: customProduct.adjustability || '',
              mobility_features: customProduct.mobility_features || '',
              care_instructions: customProduct.care_instructions || '',
              warranty: customProduct.warranty || '',
              certifications: customProduct.certifications || ''
            }
          };
        });
        setProductData(processedProducts);
        
      } else if (typeof initialProducts[0] === 'object') {
        const processedProducts = initialProducts.map(product => ({

          
          basicProducts: {
            product_name: product.product_name || '',
            category_id: product.category_id || '',
            short_code: product.short_code || '',
            
            brand_id: product.brand_id || '',
            description: product.description || '',
            price: product.price || '',
            discount: product.discount || '',
            mrp: product.mrp || '',
            unit: product.unit || '',
            pcs_per_unit: product.pcs_per_unit || '',
            stock_level: product.stock_level || '',
            minimum_order_quantity: product.minimum_order_quantity || '',
            max_order_quantity: product.max_order_quantity || '',
            product_weight: product.product_weight || '',
            dimensions: product.dimensions || '',
            storage_conditions: product.storage_conditions || '',
            packaging_type: product.packaging_type || '',
            delivery_timeframe: product.delivery_timeframe || '',
            shipping_price: product.shipping_price || '',
            returns_policy: product.returns_policy || '',
            start_date: product.start_date || '',
            expiry_date: product.expiry_date || '',
            category_name: product.category_name || '', // Set name based on ID or use existing
            brand_name: product.brand_name || '', // Set name based on ID or use existing
            // daily_subscription_price:product.daily_subscription_price ||'',
            // weekly_subscription_price:product.weekly_subscription_price ||'',
            // monthly_subscription_price:product.monthly_subscription_price||'',
            // yearly_subscription_price:product.yearly_subscription_price||'',
          },
          customProducts: {
       
            prescription_required:prescription_required_fun(product.prescription_required) || false,
            dosage_information: product.dosage_information || '',
            form: product.form || '',
            strength: product.strength || '',
            active_ingredients: product.active_ingredients || '',
            side_effects: product.side_effects || '',
            contraindications: product.contraindications || '',
            instructions: product.instructions || '',
            license_information: product.license_information || '',
            expiry_date: product.expiry_date || '',
            batch_number: product.batch_number || '',
            manufacturing_date: product.manufacturing_date || '',
            manufacturer_license_number: product.manufacturer_license_number || '',
            material_type: product.material_type || '',
            size: product.size || '',
            intended_use: product.intended_use || '',
            weight_capacity: product.weight_capacity || '',
            adjustability: product.adjustability || '',
            mobility_features: product.mobility_features || '',
            care_instructions: product.care_instructions || '',
            warranty: product.warranty || '',
            certifications: product.certifications || ''
          }
        }));
        setProductData(processedProducts);
      }
    };

    processProducts();
  }, [initialProducts, basicProducts, customProducts]);

  const handleInputChange = (index, section, field, value) => {
    setProductData(prevData => {
      const updatedData = [...prevData];
      updatedData[index][section][field] = value;
      return updatedData;
    });
  };

  const handleCategorySelect = (index, selectedCategoryId) => {
    //console.log(selectedCategoryId,'------category idddd')
    const selectedCategory = categories.find(c => c.category_id === selectedCategoryId);
    if (selectedCategory) {
      setProductData(prevData => {
        const updatedData = [...prevData];
        updatedData[index].basicProducts.category_id = selectedCategory.category_id; // Update ID
        updatedData[index].basicProducts.category_name = selectedCategory.name; // Update name
        return updatedData;
      });
    }
  };
  
  
  const handleBrandSelect = (index, selectedBrandId) => {
    const selectedBrand = brands.find(b => b.brand_id === selectedBrandId);
    if (selectedBrand) {
      setProductData(prevData => {
        const updatedData = [...prevData];
        updatedData[index].basicProducts.brand_id = selectedBrand.brand_id; // Update ID
        updatedData[index].basicProducts.brand_name = selectedBrand.name; // Update name
        return updatedData;
      });
    }
  };
  

  const handleAddRow = () => {
    setProductData(prevData => [
      ...prevData,
      {
        basicProducts: {
          product_name: '', category_id: '', short_code: '', brand_id: '', description: '',
          price: '', discount: '', mrp: '', unit: '', pcs_per_unit: '', stock_level: '', minimum_order_quantity: '',
          max_order_quantity: '', product_weight: '', dimensions: '', storage_conditions: '', packaging_type: '',
          delivery_timeframe: '', shipping_price: '', returns_policy: '', start_date: '', expiry_date: ''
        },
        
        customProducts: {
          prescription_required: false, dosage_information: '', form: '', strength: '', active_ingredients: '',
          side_effects: '', contraindications: '', instructions: '', license_information: '', expiry_date: '',
          batch_number: '', manufacturing_date: '', manufacturer_license_number: '', material_type: '', size: '',
          intended_use: '', weight_capacity: '', adjustability: '', mobility_features: '', care_instructions: '',
          warranty: '', certifications: ''
        }
      }
    ]);
  };


  const handleDelete = (index) => {
    setProductData(prevData => prevData.filter((_, i) => i !== index));
  };

  const handleSaveClick = async () => {
    const basicProductData = productData.map((product) => {
      const category = product.basicProducts.category_id 
        ? product.basicProducts.category_id 
        : categories.find(c => c.name === product.basicProducts.category_name)?.category_id || '';
  
      const brand = product.basicProducts.brand_id 
        ? product.basicProducts.brand_id 
        : brands.find(b => b.name === product.basicProducts.brand_name)?.brand_id || '';
      
      return {
        ...product.basicProducts,
        category_id: category,
        brand_id: brand
      };
    });
    //console.log(basicProductData,'----basiccccccccc')
    // Define customProductData here
    const customProductData = productData.map(product => ({
      ...product.customProducts
    }));
    //console.log(customProductData,'----customProductData')
    setIsSaving(true);
    let responseStatus = false;
  
    try {
      if (typeof initialProducts[0] === 'object') {
        responseStatus = await dispatch(createProductsWorkflow(basicProductData, customProductData, token));
      } else {
        
        responseStatus = await dispatch(updateProductsWorkflow(basicProductData, customProductData, token));
      }
    } catch (error) {
      // console.error("Error occurred while saving products:", error);
    }
  
    setIsSaving(false);
  
    if (responseStatus) {
      navigate('/admin');
    } else {
      toast.error("Failed to save products.");
      // console.error("Failed to save products");
    }
  };
  
  return (
    <div className="bulk-products">
          <div className="breadcrumb-container">
         
         <Link to="/admin/dashboard" className="breadcrumb-text">
         <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
         </Link>
         <span className="breadcrumb-separator">{'>'}</span>
         <Link to="/admin" className="breadcrumb-text">
         <span className="breadcrumb-text">Product List</span>
         </Link>
         <span className="breadcrumb-separator">{'>'}</span>
         <span className="breadcrumb-text">Bulk Product</span>
     </div>
     <div className="bulk-brand-container"></div>
     <div className="bulk-brand">
         <div className="brand-table">
         <div className="header-row">
         <h5 className="text-heading1">Bulk Product</h5>
      <div className="add-new-button">
        {typeof initialProducts[0] === 'object' && (
          <ButtonComponent className="save-button"
            text={<><FaPlus    style={{ marginRight: '8px' }} />Add New</>} 
            onClick={handleAddRow} 
           
          />
        )}
        <ButtonComponent 
          text={isSaving ? "Saving..." : "Save"} 
          onClick={handleSaveClick} 
          className="save-button" 
          disabled={isSaving} 
        />
      </div>
      </div>
      </div>
      <div className="table-container">
        <table className="custom-table">
          <thead>
            <tr>
              {/* Headers for basic and custom product fields */}
              <th>Product Name</th>
              <th>Category</th>
              <th>Short Code</th>
              <th>Brand</th>
              <th>Description</th>
              <th>Price</th>
              <th>Discount</th>
              <th>MRP</th>
              <th>Unit</th>
              <th>Pieces per Unit</th>
              <th>Stock Level</th>
              <th>Min Order Qty</th>
              <th>Max Order Qty</th>
              <th>Product Weight</th>
              <th>Dimensions</th>

              {/* <th>Daily Subscription Price</th>
              <th>Weekly Subscription Price</th>
              <th>Monthly Subscription Price</th>
              <th>Yearly Subscription Price</th> */}

              <th>Storage Conditions</th>
              <th>Packaging Type</th>
              <th>Delivery Timeframe</th>
              <th>Shipping Price</th>
              <th>Manufatcuring Date</th>
              <th>Expiry Date</th>
              <th>Prescription Required</th>
              <th>Dosage Information</th>
              <th>Form</th>
              <th>Strength</th>
              <th>Active Ingredients</th>
              <th>Side Effects</th>
              <th>Contraindications</th>
              <th>Instructions</th>
              <th>License Information</th>
              <th>Batch Number</th>
              <th>Manufacturer License</th>
              <th>Material Type</th>
              <th>Size</th>
              <th>Intended Use</th>
              <th>Weight Capacity</th>
              <th>Adjustability</th>
              <th>Mobility Features</th>
              <th>Care Instructions</th>
              <th>Warranty</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {productData.map((product, index) => (
              <tr key={index}>
                <td><InputComponent value={product.basicProducts.product_name} onChange={(e) => handleInputChange(index, 'basicProducts', 'product_name', e.target.value)} /></td>
                <td>
               <SearchableDropdown
      options={categoryDropdownOptions}
      placeholder="Select Category"
      value={product.basicProducts.category_name || ''} // Display the category name
      onSelect={(selectedOption) => handleCategorySelect(index, selectedOption)}
    />
                  {/* <SearchableDropdown
                    options={categories.map(category => ({ label: category.name, value: category.id }))}
                    placeholder="Select Category"
                    value={product.basicProducts.category_id}
                    onSelect={(selectedOption) => handleInputChange(index, 'basicProducts', 'category_id', selectedOption.value)}
                  /> */}
                </td>
                <td><InputComponent value={product.basicProducts.short_code} onChange={(e) => handleInputChange(index, 'basicProducts', 'short_code', e.target.value)} /></td>
              
                <td>
                <SearchableDropdown
  options={brandDropdownOptions}
  placeholder="Select Brand"
  value={product.basicProducts.brand_name || ''} // Display the brand name
  onSelect={(selectedOption) => handleBrandSelect(index, selectedOption)}
/>
                </td>
                <td><InputComponent value={product.basicProducts.description} onChange={(e) => handleInputChange(index, 'basicProducts', 'description', e.target.value)} /></td>
                <td><InputComponent type="number" value={product.basicProducts.price} onChange={(e) => handleInputChange(index, 'basicProducts', 'price', e.target.value)} /></td>
                <td><InputComponent type="number" value={product.basicProducts.discount} onChange={(e) => handleInputChange(index, 'basicProducts', 'discount', e.target.value)} /></td>
                <td><InputComponent type="number" value={product.basicProducts.mrp} onChange={(e) => handleInputChange(index, 'basicProducts', 'mrp', e.target.value)} /></td>
                <td><InputComponent value={product.basicProducts.unit} onChange={(e) => handleInputChange(index, 'basicProducts', 'unit', e.target.value)} /></td>
                <td><InputComponent type="number" value={product.basicProducts.pcs_per_unit} onChange={(e) => handleInputChange(index, 'basicProducts', 'pcs_per_unit', e.target.value)} /></td>
                <td><InputComponent type="number" value={product.basicProducts.stock_level} onChange={(e) => handleInputChange(index, 'basicProducts', 'stock_level', e.target.value)} /></td>
                <td><InputComponent type="number" value={product.basicProducts.minimum_order_quantity} onChange={(e) => handleInputChange(index, 'basicProducts', 'minimum_order_quantity', e.target.value)} /></td>
                <td><InputComponent type="number" value={product.basicProducts.max_order_quantity} onChange={(e) => handleInputChange(index, 'basicProducts', 'max_order_quantity', e.target.value)} /></td>
                <td><InputComponent value={product.basicProducts.product_weight} onChange={(e) => handleInputChange(index, 'basicProducts', 'product_weight', e.target.value)} /></td>
                <td><InputComponent value={product.basicProducts.dimensions} onChange={(e) => handleInputChange(index, 'basicProducts', 'dimensions', e.target.value)} /></td>

                {/* <td><InputComponent type="number" value={product.basicProducts.daily_subscription_price} onChange={(e) => handleInputChange(index, 'basicProducts', 'daily_subscription_price', e.target.value)} /></td>
                <td><InputComponent type="number" value={product.basicProducts.weekly_subscription_price} onChange={(e) => handleInputChange(index, 'basicProducts', 'weekly_subscription_price', e.target.value)} /></td>
                <td><InputComponent type="number" value={product.basicProducts.monthly_subscription_price} onChange={(e) => handleInputChange(index, 'basicProducts', 'monthly_subscription_price', e.target.value)} /></td>
                <td><InputComponent type="number" value={product.basicProducts.yearly_subscription_price} onChange={(e) => handleInputChange(index, 'basicProducts', 'yearly_subscription_price', e.target.value)} /></td> */}

                <td><InputComponent value={product.basicProducts.storage_conditions} onChange={(e) => handleInputChange(index, 'basicProducts', 'storage_conditions', e.target.value)} /></td>
                <td><InputComponent value={product.basicProducts.packaging_type} onChange={(e) => handleInputChange(index, 'basicProducts', 'packaging_type', e.target.value)} /></td>
                <td><InputComponent value={product.basicProducts.delivery_timeframe} onChange={(e) => handleInputChange(index, 'basicProducts', 'delivery_timeframe', e.target.value)} /></td>
                <td><InputComponent type="number" value={product.basicProducts.shipping_price} onChange={(e) => handleInputChange(index, 'basicProducts', 'shipping_price', e.target.value)} /></td>
              
                <td><Datepicker dateValue={product.customProducts.manufacturing_date} onDateChange={(date) => handleInputChange(index, 'customProducts', 'manufacturing_date', date)} /></td>
                <td><Datepicker dateValue={product.customProducts.expiry_date} onDateChange={(date) => handleInputChange(index, 'customProducts', 'expiry_date', date)} /></td>
                <td>
                    <select
                value={product.customProducts.prescription_required ? 'Yes' : product.customProducts.prescription_required === false ? 'No' : ''}
                onChange={(e) => handleInputChange(index, 'customProducts', 'prescription_required', e.target.value === 'Yes')}
              >
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                </select>
                </td>
                <td><InputComponent value={product.customProducts.dosage_information} onChange={(e) => handleInputChange(index, 'customProducts', 'dosage_information', e.target.value)} /></td>
                <td><InputComponent value={product.customProducts.form} onChange={(e) => handleInputChange(index, 'customProducts', 'form', e.target.value)} /></td>
                <td><InputComponent value={product.customProducts.strength} onChange={(e) => handleInputChange(index, 'customProducts', 'strength', e.target.value)} /></td>
                <td><InputComponent value={product.customProducts.active_ingredients} onChange={(e) => handleInputChange(index, 'customProducts', 'active_ingredients', e.target.value)} /></td>
                <td><InputComponent value={product.customProducts.side_effects} onChange={(e) => handleInputChange(index, 'customProducts', 'side_effects', e.target.value)} /></td>
                <td><InputComponent value={product.customProducts.contraindications} onChange={(e) => handleInputChange(index, 'customProducts', 'contraindications', e.target.value)} /></td>
                <td><InputComponent value={product.customProducts.instructions} onChange={(e) => handleInputChange(index, 'customProducts', 'instructions', e.target.value)} /></td>
                <td><InputComponent value={product.customProducts.license_information} onChange={(e) => handleInputChange(index, 'customProducts', 'license_information', e.target.value)} /></td>
                <td><InputComponent value={product.customProducts.batch_number} onChange={(e) => handleInputChange(index, 'customProducts', 'batch_number', e.target.value)} /></td>
                <td><InputComponent value={product.customProducts.manufacturer_license_number} onChange={(e) => handleInputChange(index, 'customProducts', 'manufacturer_license_number', e.target.value)} /></td>
                <td><InputComponent value={product.customProducts.material_type} onChange={(e) => handleInputChange(index, 'customProducts', 'material_type', e.target.value)} /></td>
                <td><InputComponent value={product.customProducts.size} onChange={(e) => handleInputChange(index, 'customProducts', 'size', e.target.value)} /></td>
                <td><InputComponent value={product.customProducts.intended_use} onChange={(e) => handleInputChange(index, 'customProducts', 'intended_use', e.target.value)} /></td>
                <td><InputComponent value={product.customProducts.weight_capacity} onChange={(e) => handleInputChange(index, 'customProducts', 'weight_capacity', e.target.value)} /></td>
                <td><InputComponent value={product.customProducts.adjustability} onChange={(e) => handleInputChange(index, 'customProducts', 'adjustability', e.target.value)} /></td>
                <td><InputComponent value={product.customProducts.mobility_features} onChange={(e) => handleInputChange(index, 'customProducts', 'mobility_features', e.target.value)} /></td>
                <td><InputComponent value={product.customProducts.care_instructions} onChange={(e) => handleInputChange(index, 'customProducts', 'care_instructions', e.target.value)} /></td>
                <td><InputComponent value={product.customProducts.warranty} onChange={(e) => handleInputChange(index, 'customProducts', 'warranty', e.target.value)} /></td>
                {/* <td><InputComponent value={product.customProducts.certifications} onChange={(e) => handleInputChange(index, 'customProducts', 'certifications', e.target.value)} /></td> */}
                <td className="actions1">
                  <FaTrash className="action-icon1" onClick={() => handleDelete(index)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
  );
};

export default BulkProduct; 