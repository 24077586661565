import React, { useState, useEffect } from 'react';
import InputComponent from '../../components/common/InputComponent/InputComponent';
import DropdownButton from '../../components/common/dropdown/DropdownButton';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import './admin-categories.scss';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { faTimes, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createCategory, fetchCategory, updateCategory } from '../../redux/actions/categoryAction';
import { uploadDocumentAPI, generatePresignedUrlAPI } from '../../api/services/filestorageService';


const AdminCategories = ({ urls }) => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.category);
  const token = useSelector((state) => state.adminLogin.token);
  const [loading, setLoading] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [originalData, setOriginalData] = useState(null);
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(''); // For presigned URL
  const [formData, setFormData] = useState({
    category_id: '',
    name: '',
    description: '',
    parent_category_ID: '',
    image_url: '',
    status: '',
    created_at: '',
    updated_at: '',
    sort_order: '',
  });
  const statusOptions = ['active', 'inactive'];
  const location = useLocation();
  const navigate = useNavigate();
  const category_Id = urls?null:location.state || null;;

  const generatePresignedUrl = async (imageUrl) => {
    try {
      const presignedUrlData = await generatePresignedUrlAPI(imageUrl, token);
      //console.log(presignedUrlData, '---url');
      if (presignedUrlData.presigned_url) {
        setFileUrl(presignedUrlData.presigned_url); // Set presigned URL for display
      }
    } catch (error) {
      // console.error('Error generating presigned URL:', error);
      // toast.error('An error occurred while fetching the image.');
    }
  };

  const isDuplicate = () => {
    return categories.some((cat) => 
      (cat.name === formData.name && cat.category_id !== category_Id) 
    );
  };
  useEffect(() => {
    if (category_Id) {
      const categoryData = categories.categoryList.categories.find(
        (cate) => cate.category_id === category_Id
      );
      if (categoryData) {
        setFormData(categoryData);
        setOriginalData(categoryData);

        if (categoryData.image_url) {
          generatePresignedUrl(categoryData.image_url); // Generate presigned URL if image exists
        }
      }
    }
  }, [category_Id, categories]);

  useEffect(() => {
    const isFormModified = JSON.stringify(formData) !== JSON.stringify(originalData);
    setIsModified(isFormModified);
  }, [formData, originalData]);

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileUrl(URL.createObjectURL(selectedFile)); // Create an object URL for preview
      setIsModified(true); // Enable save button as a file has been added
    }
  };

  const handleFileUpload = async () => {
    if (file) {
      try {
        const uploadedUrl = await uploadDocumentAPI(file,'category/', token);
        if (uploadedUrl && uploadedUrl.file_url) {
          setFileUrl(uploadedUrl.file_url);
          setFormData((prevData) => ({
            ...prevData,
            image_url: uploadedUrl.file_url,
          }));
          return { ...formData, image_url: uploadedUrl.file_url };
        } else {
          toast.error('File upload failed. Please try again.');
        }
      } catch (error) {
        // console.error('Error uploading file:', error);
        // toast.error('An error occurred during file upload.');
      }
    }
    return formData;
  };

  const handleSubmit = async () => {
    let updatedFormData = formData;
    if (file) {
      updatedFormData = await handleFileUpload();
    }
    //console.log(updatedFormData, 'Updated form data before submission');

    if (updatedFormData.name && updatedFormData.status) {
      setLoading(true);
     
      
      const actionResult = category_Id
        ? await dispatch(updateCategory(category_Id, updatedFormData, token))
        : await dispatch(createCategory(updatedFormData, token));

      if (actionResult?.success) {
        toast.success('Category updated/created successfully');
        if(!urls){
          navigate('/admin');  
        }
        else{
          await dispatch(fetchCategory(token));
        }
      } else {
        toast.error('Category not updated/created. Please try again.');
      }
      setLoading(false);
    } else {
      toast.error('Need to fill the mandatory fields');
    }
  };

  const handleFileRemove = () => {
    setFile(null); // Clear selected file
    setFileUrl(''); // Clear file URL
    setFormData((prevData) => ({ ...prevData, image_url: '' })); // Reset image URL in formData
  };

  return (
    <div className="add-category-section">
      {!urls && ( // Conditionally render the breadcrumb container based on the presence of `urls`
        <div className="breadcrumb-container">
          <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
          <span className="breadcrumb-separator">{'>'}</span>
          <Link to="/admin" className="breadcrumb-text">
            Category List
          </Link>
          <span className="breadcrumb-separator">{'>'}</span>
          <span className="breadcrumb-text">Add Category</span>
        </div>
      )}
      <div className="add-category-container"></div>
      <div className="add-category-class">
        <h5 className="text-heading1">Add Category Items</h5>
        <div className="form-row">
          <div className="form-group">
            <label>
              Name <span className="required-symbol">*</span>
            </label>
            <InputComponent
              placeholder=" "
              name="name"
              value={formData.name}
              onChange={(e) => handleInputChange('name', e.target.value)}
            />
          </div>
       
          <div className="form-group">
            <label>Description</label>
            <InputComponent
              placeholder=" "
              name="description"
              value={formData.description}
              onChange={(e) => handleInputChange('description', e.target.value)}
            />
          </div>
        </div>
        <div className="form-row">
        <div className="form-group">
            <label>Short code</label>
            <InputComponent
              placeholder=" "
              name="sort code"
              type="numeric"
              value={formData.sort_order}
              onChange={(e) => handleInputChange('sort_order', e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>
              Status <span className="required-symbol">*</span>
            </label>
            <DropdownButton
                options={statusOptions}
                label={formData.status || 'Select Status'}
                onSelect={(value) =>
                  setFormData((prev) => ({
                    ...prev,
                    status: String(value), // Explicitly ensure it's a string
                  }))
                }
              />

          </div>
        </div>
        <div className="form-row1">
         
          <div className="form-group">
            <label>Image Upload</label>
            {fileUrl ? (
              <div className="image-container">
                <img src={fileUrl} alt="Uploaded" className="uploaded-image" />
                <FontAwesomeIcon className="close-icon" icon={faTimes} onClick={handleFileRemove} />
              </div>
            ) : (
              <input type="file" accept=".jpg,.jpeg,.png" onChange={handleFileChange} />
            )}
          </div>
        </div>
        <div className="action-buttons">
          <ButtonComponent
            text={loading ? 'Saving...' : 'Save'} 
            onClick={handleSubmit}
            className="save-button"
            disabled={!isModified}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminCategories;
