import React, { useState, useEffect } from 'react';
import InputComponent from '../../components/common/InputComponent/InputComponent';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import './personal-information.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser, updateUser } from '../../redux/actions/adminuserActions';
import { uploadDocumentAPI, generatePresignedUrlAPI } from '../../api/services/filestorageService';
import { toast } from 'react-hot-toast';

const PersonalInformation = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.adminLogin.token);
  const user_id = useSelector((state) => state.adminLogin.user);
  const userProfile = useSelector((state) =>
    state.usermanage.users.find((user) => user.user_id === user_id)
  );
  //console.log(userProfile,user_id,'-----------------')
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    mobile_number: '',
    gender: '',
    dob: '',
    proof: '',
    internal_id:'',
  });

  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState('');
  const [fileType, setFileType] = useState('');
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    if (userProfile) {
      setFormData({
        username: userProfile.username || '',
        email: userProfile.email || '',
        mobile_number: userProfile.mobile_number || '',
        gender: userProfile.gender || '',
        dob: userProfile.dob || '',
        proof: userProfile.proof || '',
        internal_id:userProfile.internal_id || '', 
      });
      if (userProfile.proof) {
        generatePresignedUrl(userProfile.proof);
      }
    }
  }, [userProfile]);

  const generatePresignedUrl = async (imageUrl) => {
    try {
      const presignedUrlData = await generatePresignedUrlAPI(imageUrl, token);
      if (presignedUrlData.presigned_url) {
        setFileUrl(presignedUrlData.presigned_url);
        setFileType(imageUrl.split('.').pop().toLowerCase());
      }
    } catch (error) {
      console.error("Error generating presigned URL:", error);
      // toast.error("An error occurred while fetching the file.");
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && !['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'].includes(selectedFile.type)) {
      toast.error('Only JPG, JPEG, PNG, and PDF formats are supported.');
      return;
    }

    setFile(selectedFile);
    setFileType(selectedFile.type);
    setFileUrl(URL.createObjectURL(selectedFile));
    setIsModified(true);
  };

  const handleFileUpload = async () => {
    if (file) {
      try {
        const uploadedUrl = await uploadDocumentAPI(file,'brand/', token);
        if (uploadedUrl && uploadedUrl.file_url) {
          setFileUrl(uploadedUrl.file_url);
          setFormData((prevData) => ({
            ...prevData,
            proof: uploadedUrl.file_url,
          }));
          return uploadedUrl.file_url;
        } else {
          toast.error("File upload failed. Please try again.");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        // toast.error("An error occurred during file upload.");
      }
    }
    return formData.proof;
  };


  const handleFileRemove = () => {
    setFile(null);
    setFileUrl('');
    setFileType('');
    setFormData((prevData) => ({ ...prevData, proof: '' }));
  };

  const handleSubmit = async () => {
    let updatedFormData ={ ...formData };
    if (file) {
      updatedFormData.proof = await handleFileUpload();
    }

    if (!updatedFormData.username || !updatedFormData.email || !updatedFormData.mobile_number) {
      toast.error("Please fill in all required fields.");
      return;
    }
    //console.log(updatedFormData,'----------------userrrrr')
    dispatch(updateUser(user_id, updatedFormData, token));
    toast.success("Profile updated successfully!");
  };

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    setIsModified(true);
  };

  return (
    <div className="profile-page">
      <div className="profile-form">
        <h2>Profile Information</h2>
        
        <div className="form-group">
          <label>Name *</label>
          <InputComponent
            placeholder="Enter Name"
            name="name"
            value={formData.username}
            onChange={(e) => handleInputChange("username", e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Email ID *</label>
          <InputComponent
            placeholder="Enter Email"
            name="email"
            value={formData.email}
            onChange={(e) => handleInputChange("email", e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Internal Id *</label>
          <InputComponent
            placeholder="Enter Internal id"
            name="internal_id"
            value={formData.internal_id}
            onChange={(e) => handleInputChange("internal_id", e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Mobile Number *</label>
          <InputComponent
            placeholder="Enter Mobile Number"
            name="mobile_number"
            value={formData.mobile_number}
            onChange={(e) => handleInputChange("mobile_number", e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Gender</label>
          <select
            name="gender"
            value={formData.gender}
            onChange={(e) => handleInputChange("gender", e.target.value)}
            className="form-select"
          >
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>

        <div className="form-group">
          <label>Date of Birth</label>
          <InputComponent
            type="date"
            placeholder="Enter Date of Birth"
            name="dob"
            value={formData.dob}
            onChange={(e) => handleInputChange("dob", e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Proof (JPG, JPEG, PNG, PDF)</label>
          {fileUrl ? (
            <div>
              {fileType === 'application/pdf' ? (
                <embed src={fileUrl} type="application/pdf" width="200px" height="150px" />
              ) : (
                <img src={fileUrl} alt="Proof" style={{ width: '200px', height: '150px' }} />
              )}
              <button onClick={handleFileRemove}>Remove</button>
            </div>
          ) : (
            <input type="file" accept=".jpg,.jpeg,.png,.pdf" onChange={handleFileChange} />
          )}
        </div>

        <div className="action-buttons">
          <ButtonComponent
            text="Save"
            variant="primary"
            onClick={handleSubmit}
            disabled={!isModified}
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
