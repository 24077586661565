import { generatePresignedUrlAPI } from "../../api/services/filestorageService";

// Action types
export const FETCH_URL_REQUEST = 'FETCH_URL_REQUEST';
export const FETCH_URL_SUCCESS = 'FETCH_URL_SUCCESS';
export const FETCH_URL_FAILURE = 'FETCH_URL_FAILURE';

// Action creator to fetch pre-signed URL
export const fetchPresignedUrl = (entity, id, url,token) => async (dispatch) => {
    dispatch({ type: FETCH_URL_REQUEST });
    //console.log(entity, id, url,token,'-----urlssss')
    try {
        // API call to generate the pre-signed URL for the given entity and id
        const response = await generatePresignedUrlAPI(url,token);
        //console.log(response,'preassinggeddurlssss')
        const presignedUrl = response ?response.presigned_url:"" ; // Extract the URL from the response

        // Dispatch success action with entity, id, and URL
        dispatch({
            type: FETCH_URL_SUCCESS,
            payload: { entity, id, url: presignedUrl },
        });
    } catch (error) {
        // Dispatch failure action with entity, id, and error message
       
        dispatch({
            type: FETCH_URL_FAILURE,
            payload: { entity, id, error: error.message },
        });
    }
};
