import { 
 fetchAllocatedCouponsFromAPI,
 updateAllocatedCouponInAPI,
 deleteAllocatedCouponAPI,
 createNewAllocatedCouponAPI,
bulkCreateAllocatedCouponsAPI,
bulkDeleteAllocatedCouponsAPI,
bulkUpdateAllocatedCouponsAPI 
} from '../../api/services/couponAllocationService';
import { toast } from 'react-hot-toast';

export const FETCH_ALLOCATED_COUPONS_REQUEST = 'FETCH_ALLOCATED_COUPONS_REQUEST';
export const FETCH_ALLOCATED_COUPONS_SUCCESS = 'FETCH_ALLOCATED_COUPONS_SUCCESS';
export const FETCH_ALLOCATED_COUPONS_FAILURE = 'FETCH_ALLOCATED_COUPONS_FAILURE';
export const CLEAR_ALLOCATED_COUPONS = 'CLEAR_ALLOCATED_COUPONS';

export const DELETE_ALLOCATED_COUPON_REQUEST = 'DELETE_ALLOCATED_COUPON_REQUEST';
export const DELETE_ALLOCATED_COUPON_SUCCESS = 'DELETE_ALLOCATED_COUPON_SUCCESS';
export const DELETE_ALLOCATED_COUPON_FAILURE = 'DELETE_ALLOCATED_COUPON_FAILURE';

export const UPDATE_ALLOCATED_COUPON_REQUEST = 'UPDATE_ALLOCATED_COUPON_REQUEST';
export const UPDATE_ALLOCATED_COUPON_SUCCESS = 'UPDATE_ALLOCATED_COUPON_SUCCESS';
export const UPDATE_ALLOCATED_COUPON_FAILURE = 'UPDATE_ALLOCATED_COUPON_FAILURE';

export const CREATE_ALLOCATED_COUPON_REQUEST = 'CREATE_ALLOCATED_COUPON_REQUEST';
export const CREATE_ALLOCATED_COUPON_SUCCESS = 'CREATE_ALLOCATED_COUPON_SUCCESS';
export const CREATE_ALLOCATED_COUPON_FAILURE = 'CREATE_ALLOCATED_COUPON_FAILURE';

export const BULK_DELETE_ALLOCATED_COUPONS_REQUEST = 'BULK_DELETE_ALLOCATED_COUPONS_REQUEST';
export const BULK_DELETE_ALLOCATED_COUPONS_SUCCESS = 'BULK_DELETE_ALLOCATED_COUPONS_SUCCESS';
export const BULK_DELETE_ALLOCATED_COUPONS_FAILURE = 'BULK_DELETE_ALLOCATED_COUPONS_FAILURE';

export const BULK_UPDATE_ALLOCATED_COUPONS_REQUEST = 'BULK_UPDATE_ALLOCATED_COUPONS_REQUEST';
export const BULK_UPDATE_ALLOCATED_COUPONS_SUCCESS = 'BULK_UPDATE_ALLOCATED_COUPONS_SUCCESS';
export const BULK_UPDATE_ALLOCATED_COUPONS_FAILURE = 'BULK_UPDATE_ALLOCATED_COUPONS_FAILURE';

export const BULK_CREATE_ALLOCATED_COUPONS_REQUEST = 'BULK_CREATE_ALLOCATED_COUPONS_REQUEST';
export const BULK_CREATE_ALLOCATED_COUPONS_SUCCESS = 'BULK_CREATE_ALLOCATED_COUPONS_SUCCESS';
export const BULK_CREATE_ALLOCATED_COUPONS_FAILURE = 'BULK_CREATE_ALLOCATED_COUPONS_FAILURE';

// Fetch allocated coupons with a caching mechanism
export const fetchAllocatedCoupons = (token) => async (dispatch, getState) => {
  const { allocatedCoupons = [] } = getState().allocateCoupon.allocatedCoupons || [];
  const timestamp = getState().allocateCoupon.timestamp;
  const FIVE_MINUTES = 5 * 60 * 1000;

  if (allocatedCoupons.length > 0 && timestamp) {

    const now = Date.now();
    if (now - timestamp < FIVE_MINUTES) {
      return; // Data is still valid, no need to fetch
    } else {
      dispatch({ type: CLEAR_ALLOCATED_COUPONS });
    }
  }

  dispatch({ type: FETCH_ALLOCATED_COUPONS_REQUEST });
  try {
    const fetchedAllocatedCoupons = await fetchAllocatedCouponsFromAPI(token); 
    //console.log(fetchedAllocatedCoupons)
    dispatch({
      type: FETCH_ALLOCATED_COUPONS_SUCCESS,
      payload: { allocatedCoupons: fetchedAllocatedCoupons, timestamp: Date.now() },
    });
  } catch (error) {
    dispatch({ type: FETCH_ALLOCATED_COUPONS_FAILURE, payload: error.message });

  }
};

// Action to clear allocated coupons manually
export const clearAllocatedCoupons = () => ({
  type: CLEAR_ALLOCATED_COUPONS,
});

// Action to delete an allocated coupon
export const deleteAllocatedCoupon = (allocationId, token) => async (dispatch) => {
  dispatch({ type: DELETE_ALLOCATED_COUPON_REQUEST });

  try {
    await deleteAllocatedCouponAPI(allocationId, token); 
    dispatch({
      type: DELETE_ALLOCATED_COUPON_SUCCESS,
      payload: allocationId,
    });
    toast.success('Allocated coupon deleted successfully!');
  } catch (error) {
    dispatch({ type: DELETE_ALLOCATED_COUPON_FAILURE, payload: error.message });
    toast.error(`Failed to delete allocated coupon: ${error.message}`);
  }
};

// Action to update an allocated coupon
export const updateAllocatedCoupon = (allocationId, updatedData, token) => async (dispatch) => {
  dispatch({ type: UPDATE_ALLOCATED_COUPON_REQUEST });

  try {
    const updatedAllocatedCoupon = await updateAllocatedCouponInAPI(allocationId, updatedData, token); 
    dispatch({
      type: UPDATE_ALLOCATED_COUPON_SUCCESS,
      payload: updatedData,
    });
    toast.success('Allocated coupon updated successfully!');
    return true ;
  } catch (error) {
    dispatch({ type: UPDATE_ALLOCATED_COUPON_FAILURE, payload: error.message });
    toast.error(`Failed to update allocated coupon: ${error.message}`);
    return false ;
  }
};

// Action to create a new allocated coupon
export const createAllocatedCoupon = (newAllocatedCouponData, token) => async (dispatch) => {
  dispatch({ type: CREATE_ALLOCATED_COUPON_REQUEST });

  try {
    const createdAllocatedCoupon = await createNewAllocatedCouponAPI(newAllocatedCouponData, token);
    dispatch({
      type: CREATE_ALLOCATED_COUPON_SUCCESS,
      payload: newAllocatedCouponData,
    });
    toast.success('Allocated coupon created successfully!');
    return { success: true };
  } catch (error) {
    dispatch({ type: CREATE_ALLOCATED_COUPON_FAILURE, payload: error.message });
    toast.error(`Failed to create allocated coupon: ${error.message}`);
    return { success: false };
  }
};

// Bulk delete allocated coupons
export const bulkDeleteAllocatedCoupons = (allocationIds, token) => async (dispatch) => {
  dispatch({ type: BULK_DELETE_ALLOCATED_COUPONS_REQUEST });

  try {
 await bulkDeleteAllocatedCouponsAPI(allocationIds, token);
    dispatch({
      type: BULK_DELETE_ALLOCATED_COUPONS_SUCCESS,
      payload: allocationIds,
    });
    toast.success('Allocated coupons deleted successfully!');
  } catch (error) {
    dispatch({ type: BULK_DELETE_ALLOCATED_COUPONS_FAILURE, payload: error.message });
    toast.error(`Failed to delete allocated coupons: ${error.message}`);
  }
};

// Bulk update allocated coupons
export const bulkUpdateAllocatedCoupons = (allocatedCouponsData, token) => async (dispatch) => {
  dispatch({ type: BULK_UPDATE_ALLOCATED_COUPONS_REQUEST });

  try {
    const updatedAllocatedCoupons = await bulkUpdateAllocatedCouponsAPI(allocatedCouponsData, token);
    dispatch({
      type: BULK_UPDATE_ALLOCATED_COUPONS_SUCCESS,
      payload: allocatedCouponsData,
    });
    toast.success('Allocated coupons updated successfully!');
    return { success: true };
  } catch (error) {
    dispatch({ type: BULK_UPDATE_ALLOCATED_COUPONS_FAILURE, payload: error.message });
    toast.error(`Failed to update allocated coupons: ${error.message}`);
    return { success: false };
  }
};

// Bulk create allocated coupons
export const bulkCreateAllocatedCoupons = (newAllocatedCouponsData, token) => async (dispatch) => {
  dispatch({ type: BULK_CREATE_ALLOCATED_COUPONS_REQUEST });

  try {
    const createdAllocatedCoupons = await bulkCreateAllocatedCouponsAPI(newAllocatedCouponsData, token);
    dispatch({
      type: BULK_CREATE_ALLOCATED_COUPONS_SUCCESS,
      payload: newAllocatedCouponsData,
    });
    toast.success('Allocated coupons created successfully!');
    return { success: true };
  } catch (error) {
    dispatch({ type: BULK_CREATE_ALLOCATED_COUPONS_FAILURE, payload: error.message });
    toast.error(`Failed to create allocated coupons: ${error.message}`);
    return { success: false };
  }
};
