import React, { useState } from 'react';
import './halfsidebar.scss';
import { FaTimes } from 'react-icons/fa';
import ButtonComponent from '../buttons/ButtonComponent';
import AdminCategories from '../../../pages/AdminCategory/admin-categories';
import Brands from '../../../pages/AdminBrandManagement/brands';
import SupplierManagement from '../../../pages/Supplier/supplier-management';
import DetailsTable from '../../../pages/basic-info-details-page/BasicInfoDetailsPage';
import CustomDetails from '../../../pages/custom-details/CustomDetails';
import OfferPage from '../../../pages/OfferPage/OfferPage';
import CouponPage from '../../../pages/CouponPage/CouponPage';
import AddDeliveryPerson from '../../../pages/add-delivery-person/AddDeliveryPerson';
const Sidebar = ({ type, onClose, urls }) => {
  const [isOpen, setIsOpen] = useState(false);

  // Open the sidebar
  const openSidebar = () => {
    setIsOpen(true);
  };

  // Close the sidebar
  const closeSidebar = (e) => {
    e.stopPropagation(); // Prevent the event from bubbling up
    setIsOpen(false);
  };

  // Alternative close method for header click
  const closeFromHeader = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    setIsOpen(false);
  };

  // Render the content based on the type prop
  const renderContent = () => {
    switch (type) {
      case 'category':
        return <AdminCategories urls={urls} />;
      case 'brand':
        return <Brands urls={urls} />;
      case 'supplier':
        return <SupplierManagement urls={urls} />;
      case 'coupon':
        return <CouponPage urls={urls} />;
      case 'offer':
        return <OfferPage urls={urls} />;
      case 'basic':
        return <DetailsTable urls={urls} />;
      case 'custom':
        return <CustomDetails urls={urls} onClose={onClose} />;
      case 'delivery':
          return <AddDeliveryPerson urls={urls}  />;
      default:
        return <div>Invalid type selected</div>;
    }
  };

  return (
    <div>
      {/* Button to toggle the sidebar */}
    

      {/* Sidebar */}
      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <div className="sidebar-header">
          <h3>{type.charAt(0).toUpperCase() + type.slice(1)}</h3>
          <FaTimes className="close-icon" onClick={onClose} />
        </div>
        <div className="sidebar-content">{renderContent()}</div>
      </div>

      {/* Overlay for closing */}
      {isOpen && <div className="overlay" onClick={closeSidebar}></div>}
    </div>
  );
};

export default Sidebar;
