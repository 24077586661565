import React, { useState, useEffect } from "react";
import ButtonComponent from "../../common/buttons/ButtonComponent";
import InputComponent from "../../common/InputComponent/InputComponent";
import { PhoneAuthProvider, signInWithCredential } from "firebase/auth";
import { auth } from "../../../firebase";
import doctorImage from '../../../assets/images/doctor.png'; 
import pharmaLogo from '../../../assets/images/pharma.png';
import toast from "react-hot-toast";
import "./OtpPage.scss";
import { useDispatch, useSelector } from "react-redux";
import { mobRegisterAction } from "../../../redux/actions/authActions";

const OtpPage = ({
  MobileNumber,
  verificationId,
  onOtpVerified,
  onBackToLogin,
  existingUser,
  onResendOtp
}) => {
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(60); // Timer starts at 60 seconds
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);


  
  
  
  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            return 0; // Ensure timer doesn't drop below 0
          }
          return prevTimer - 1;
        });
      }, 1000);
  
      return () => clearInterval(interval); // Cleanup to avoid memory leaks
    }
  }, [timer]);
  
  const handleOtpVerify = async () => {
    try {
      if (!otp || otp.length !== 6) {
        toast.error("Please enter a valid 6-digit OTP.");
        return;
      }
      setLoading(true);
  
      const credential = PhoneAuthProvider.credential(verificationId, otp);
  
      // Retry logic
      let attempts = 0;
      let userCredential = null;
      while (attempts < 3) {
        try {
          userCredential = await signInWithCredential(auth, credential);
          break; // Break if successful
        } catch (err) {
          attempts++;
          if (attempts === 3) throw err; // Rethrow after max retries
        }
      }
  
      if (userCredential) {
        // console.log("Firebase OTP Verified:", userCredential);
        onOtpVerified();
        await dispatch(mobRegisterAction(MobileNumber, otp));
      }
    } catch (error) {
      // console.error("Error verifying OTP:", error);
      toast.error(error.message || "An error occurred during OTP verification.");
    } finally {
      setLoading(false);
    }
  };
  
  
  const handleResendOtp = async () => {
    if (timer > 0) return; // Prevent resend during cooldown

    try {
      setTimer(60); // Reset timer to 60 seconds
      await onResendOtp(); // Call resend OTP logic from parent
      toast.success("OTP resent successfully!");
    } catch (error) {
      // console.error("Error resending OTP:", error);
      toast.error("Failed to resend OTP. Please try again.");
    }
  };

  return (
    <div className="login-main-container">
      <div className="row login-row">
        <div className="col-md-6 login-left-container">
          <img src={doctorImage} alt="Doctor" className="img-fluid login-doc-image" />
        </div>
        <div className="col-md-12 col-lg-6 col-xl-6 login-right-container">
          <div className="login-text-left">
            <div className="login-pharma-logo-class">
              <img src={pharmaLogo} alt="Pharma" className="img-fluid login-pharma-logo" />
            </div>
            <h3 className="otp-header">Verify Your OTP</h3>
            <p className="otp-header-text">
              Enter the one-time password sent on <span>{MobileNumber}</span>
              <span className="edit-number-link" onClick={onBackToLogin}>
                Edit
              </span>
            </p>

            <InputComponent
              id="otp"
              placeholder="Enter 6-digit OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value.replace(/\D/g, ""))} // Ensure only digits
            />
          {timer > 0 ? (
  <>
    <ButtonComponent
      className="otp-button"
      text={loading ? "Verifying OTP..." : "Verify OTP"}
      onClick={handleOtpVerify}
      disabled={loading} // Disable button during verification
    />
    <div className="timer-text">
      Resend your OTP in {timer}s
    </div>
  </>
) : (
  <>
    <ButtonComponent
      className="otp-button"
      text="Resend OTP"
      onClick={handleResendOtp}
    />
    <div className="timer-text">
      If your OTP expired, please click the button above to resend it.
    </div>
  </>
)}

          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpPage;
