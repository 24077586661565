import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { auth, RecaptchaVerifier, signInWithPhoneNumber } from "../../../firebase";
import ButtonComponent from "../../common/buttons/ButtonComponent";
import InputComponent from "../../common/InputComponent/InputComponent";
import OtpPage from "../OtpPage/OtpPage";
import doctorImage from '../../../assets/images/doctor.png'; 
import pharmaLogo from '../../../assets/images/pharma.png';
import { loginAction } from "../../../redux/actions/authActions";
import "./LoginModal.scss";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

const LoginModal = ({ show, handleClose }) => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [otpRequested, setOtpRequested] = useState(false);
  const [verificationId, setVerificationId] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const [existingUser,setExistingUser] = useState(false);
  const [otpCooldown, setOtpCooldown] = useState(false);
  const handleBackToLogin = () => {
    setOtpRequested(false); // Reset the OTP requested state
    setVerificationId(""); // Clear the verification ID
    setError(""); // Clear any errors
    setMobileNumber(""); // Reset the mobile number field
    setExistingUser(false); // Reset existing user state
    setOtpCooldown(false);
  };
  const cleanupRecaptcha = () => {
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
      window.recaptchaVerifier = null;
      // console.log("reCAPTCHA verifier cleared.");
    }
  };
  

  const [user, setUser] = useState(null);
  const initializeRecaptcha = () => {
    try {
      if (window.recaptchaVerifier) {
        // console.log("ReCAPTCHA verifier already initialized.");
        return;
      }
  
      // console.log("Checking for recaptcha-container in DOM...");
      const recaptchaContainer = document.getElementById("recaptcha-container");
      if (!recaptchaContainer) {
        // console.error("ReCAPTCHA container not found.");
        return;
      }
  
      // console.log("Initializing new reCAPTCHA verifier...");
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            // console.log("ReCAPTCHA solved successfully:", response);
          },
          "expired-callback": () => {
            // console.error("ReCAPTCHA expired.");
            // toast.error("ReCAPTCHA expired. Please resend OTP.");
            cleanupRecaptcha(); // Cleanup expired instance
            initializeRecaptcha(); // Reinitialize
          },
        },
        auth
      );
  
      window.recaptchaVerifier.render().then((widgetId) => {
        // console.log("ReCAPTCHA widget rendered with ID:", widgetId);
      });
    } catch (error) {
      // console.error("Error initializing ReCAPTCHA:", error);
    }
  };
  
  
  

  const handleChange=()=>{
    setOtpRequested(false);
    cleanupRecaptcha();
    handleClose();
  }

  const fireBaseOtp = async () => {
    try {
      initializeRecaptcha(); // Ensure ReCAPTCHA is initialized
      const appVerifier = window.recaptchaVerifier;
      const formattedPhoneNumber = `+91${mobileNumber.trim()}`;
      let attempts = 0;
  
      while (attempts < 3) {
        try {
          // console.log(`Attempt ${attempts + 1}: Sending OTP to ${formattedPhoneNumber}`);
          const confirmationResult = await signInWithPhoneNumber(auth, formattedPhoneNumber, appVerifier);
          // console.log("OTP sent successfully:", confirmationResult);
          setVerificationId(confirmationResult.verificationId);
          setOtpRequested(true);
          toast.success("OTP sent successfully!");
          return; // Exit loop after success
        } catch (error) {
          attempts++;
          // console.error(`Attempt ${attempts} failed:`, error);
  
          if (error.code === "auth/timeout") {
            toast.error("Request timed out. Please check your connection and try again.");
          }
  
          if (attempts === 3) {
            // console.error("Maximum retry attempts reached.");
            toast.error("Failed to send OTP after multiple attempts. Please try again later.");
            throw error; // Rethrow after max retries
          }
  
          // console.log("Retrying after 2 seconds...");
          await new Promise((resolve) => setTimeout(resolve, 2000)); // Delay before retrying
        }
      }
    } catch (error) {
      // console.error("Error sending OTP:", error.message);
      handleOtpError(error);
    }
  };
  
  
  const handleGetOtpClick = async () =>{
    //console.log(auth,'----auuttthhhhh')
    if (!mobileNumber || mobileNumber.length !== 10) {
      setError("Please enter a valid 10-digit mobile number.");
      return;
    }

    setError(""); // Clear previous errors
    setLoading(true);

    try {
      const loginVal = await dispatch(loginAction(mobileNumber));
      //console.log("Login validation:", loginVal);

      if (loginVal === "No User found with this mobile number") {
        //console.log("No existing user. Sending OTP...");
        setExistingUser(false);
        await fireBaseOtp();
      } else if (loginVal) {
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: "otpVerified",
              message:loginVal,
            })
          );
        }

        handleClose();
      } else {
        //console.log("Existing user. Sending OTP...");
        setExistingUser(true);
        
        await fireBaseOtp();
      }
    } catch (error) {
      console.error("Error during login validation:", error.message);
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  }


  const handleOtpError = (error) => {
    const errorCode = error?.code || "UNKNOWN_ERROR";
    const errorMessage = error?.message || "An unknown error occurred.";
  
    switch (errorCode) {
      case "auth/invalid-phone-number":
        setError("Invalid phone number format. Please check and try again.");
        break;
      case "auth/quota-exceeded":
        setError("SMS quota exceeded. Please try again later.");
        break;
      case "auth/too-many-requests":
        setError("Too many requests. Please try again later.");
        break;
      default:
        setError(errorMessage);
    }
  };
  

  return (
    <Modal show={show} onHide={handleClose} centered dialogClassName="modal-lg">
      {/* Recaptcha container for Firebase */}
      {!otpRequested ? (
        <div className="login-main-container">
        <div className="row login-row">
          <div className="col-md-6 login-left-container">
            <img src={doctorImage} alt="Doctor" className="img-fluid login-doc-image" />
          </div>
          <div className="col-md-12 col-lg-6 col-xl-6  login-right-container">
            <div className="login-text-left">
              <div className="login-pharma-logo-class">
                <img src={pharmaLogo} alt="Pharma" className="img-fluid login-pharma-logo" />
              </div>
              <div className="login-text-left1">
                <h6 className="form-label login-header-text">
                  Enter your mobile number to get started
                </h6>

        
          <InputComponent
            id="mobileNumber"
            placeholder="Enter your 10-digit mobile number"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value.replace(/\D/g, ""))} // Ensure only digits
          />
          <div id="recaptcha-container"></div>


          <ButtonComponent
            className="otp-button"
            text={loading ? "Requesting OTP..." : "Get OTP"}
            onClick={handleGetOtpClick}
            disabled={loading} // Disable button while loading
          />

          
        
          {error && <p className="text-danger">{error}</p>}
        </div>
        </div>
        <div className='login-sign-in'>
                    <span className="login-sign-in-text">
                      By signing up, I agree to the
                      <span className="login-text-link"> Terms and Conditions</span>
                    </span>
                  </div>

                </div>
              </div>
            </div>

      ) : (
        <OtpPage
          MobileNumber={mobileNumber}
          verificationId={verificationId}
          onOtpVerified={handleChange}
          existingUser = {existingUser}
          onBackToLogin={handleBackToLogin} 
          onResendOtp={fireBaseOtp} 
        />
      )}
    </Modal>
  );
};

export default LoginModal;
