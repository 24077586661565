import { initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyBjs18ZZtQvo8OygEyg0cjayGlQSxnY0_0",
  authDomain: "pmpharma-ecom.firebaseapp.com",
  projectId: "pmpharma-ecom",
  storageBucket: "pmpharma-ecom.firebasestorage.app",
  messagingSenderId: "857882037641",
  appId: "1:857882037641:web:8ce27442f0bf82a3902558",
  measurementId: "G-D4D7MQE0TJ"
};
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export { RecaptchaVerifier, signInWithPhoneNumber };