import React, { useState, useEffect,useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import CardItem from '../../components/common/card/card-items';
import OffcanvasComponent from '../../components/OffcanvasComponent/OffcanvasComponent';
import BillDetailsCard from '../../components/billdetailscard/billdetailscard';
import SkeletonLoader from '../../components/common/SkeletonLoader/SkeletonLoader';
import MedicineDetailsModal from '../../components/sections/MedicineDetailsModal/MedicineDetailsModal';
import AddressOffcanvas from '../../components/common/address-offcanvas/AddressOffcanvas';
import './cart.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
  addToCart,
  decreaseCartItem,
  increaseCartItem,
  deleteCartItem,
} from '../../redux/actions/Addtocart';
import { fetchBulkAddresses, setSelectedAddress } from '../../redux/actions/AddressAction';
import toast from 'react-hot-toast';
import { setFile, clearFile } from "../../redux/actions/fileAction"
import { useNavigate } from 'react-router-dom';
const Cart = () => {
  const [showAddressDropdown, setShowAddressDropdown] = useState(false);
  const [showAddressOffcanvas, setShowAddressOffcanvas] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [selectedMedicine, setSelectedMedicine] = useState({});
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.basicProducts) || [];
  const cartItems = useSelector((state) => state.cart.cartItems) || [];
  const token = useSelector((state) => state.auth.token) || '';
  const addresses = useSelector((state) => state.address.addresses) || [];
  const selectedAddressId = useSelector((state) => state.address.selectedAddressId) || '';
  const selectedAddress = addresses.find((address) => address.address_id === selectedAddressId);
  const [show, setShow] = useState(false);
  const authData = useSelector((state) => state.auth.user) || '';
  const [discount, setDiscount] = useState(0);
  const [deliveryTimeFrame,setDeliveryTimeFrame]=useState('');
  const [deliveryDate, setDeliveryDate] = useState('');
  const uploadedFiles = useSelector((state) => state.fileUpload.uploadedFile);
  //console.log(uploadedFiles?uploadedFiles.type:'','------------ilessssss')
  const productUrls = useSelector((state)=>state.image.urls.products)
  const [localFile, setLocalFile] = useState(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(fetchBulkAddresses(token));
  }, [dispatch, token]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    dispatch(fetchBulkAddresses(token));

    const calculateDeliveryDate = () => {
      // Extract delivery times from products in the cart
      const deliveryTimes = cartItems
        .map((cartItem) => {
          const product = products.find((p) => p.product_id === cartItem.product_id);
          return product?.delivery_timeframe || 0; // Default to 0 if no delivery time
        })
        .filter((time) => time > 0); // Exclude invalid times
     
      if (deliveryTimes.length > 0) {
        // Calculate the average delivery time
        const totalDeliveryTime = deliveryTimes.reduce((sum, time) => sum + Number(time), 0);
        const averageDeliveryTime = totalDeliveryTime / deliveryTimes.length;

        // Calculate the estimated delivery date
        const today = new Date();
        const estimatedDeliveryDate = new Date(today);
        estimatedDeliveryDate.setDate(today.getDate() + Math.round(averageDeliveryTime));

        // Format and set the delivery date
        setDeliveryDate(
          estimatedDeliveryDate.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          })
        );
      } else {
        setDeliveryDate('Unavailable'); // If no valid times
      }
    };

    calculateDeliveryDate();
  }, [cartItems, products, dispatch, token]);

  const handleProductClick = (productId, productName) => {
   
    navigate(`/singleproducts/${encodeURIComponent(productName)}`, { state: productId });
    setTimeout(() => {
      window.scrollTo(0, 0); // Ensure it scrolls to the top after navigation
    }, 100);
  };

  const filteredProducts = products.filter((product) =>
    cartItems.some((cartItem) => cartItem.product_id === product.product_id)
  );
  const handleAddToCart = (product) => {
    if(authData){
      dispatch(addToCart(product, token));
    }
    else{
      dispatch(addToCart(product, ''));
  }
  };
  const handleIncreaseQuantity = (productId) => {

    if(authData){
      const cartItem = cartItems.find((item) => item.product_id === productId.product_id);
    if (cartItem && cartItem.quantity < Number(productId.max_order_quantity) && cartItem.quantity <= Number(productId.stock_level)) {
      const updatedCartItem = { ...cartItem, quantity: cartItem.quantity + 1 };
      dispatch(increaseCartItem(updatedCartItem, token));
    }
    else{
      if(cartItem.quantity >= Number(productId.max_order_quantity)){
        toast.error(`A user can order only up to the specified maximum limit of ${productId.max_order_quantity} items for this product.`);
      }
      else{
        toast.error(`Cannot add more! Only ${productId.stock_level} units available.`);
      }
    }
    
    }
    else{
      const cartItem = cartItems.find((item) => item.product_id === productId.product_id);
      if (cartItem && cartItem.quantity < Number(productId.max_order_quantity) && cartItem.quantity <= Number(productId.stock_level)){
        dispatch(increaseCartItem(productId,''));
      }
      else{
        if(cartItem.quantity >= Number(productId.max_order_quantity)){
          toast.error(`A user can order only up to the specified maximum limit of ${productId.max_order_quantity} items for this product.`);
        }
        else{
          toast.error(`Cannot add more! Only ${productId.stock_level} units available.`);
        }
      }
      
    }
    
  };

  const handleDecreaseQuantity = (productId) => {
    
    if(authData){
     
    const cartItem = cartItems.find((item) => item.product_id === productId.product_id);
    
    if (cartItem && cartItem.quantity > Number(productId.minimum_order_quantity))  {
      const updatedCartItem = { ...cartItem, quantity: cartItem.quantity - 1 };
      dispatch(decreaseCartItem(updatedCartItem, token));
    }

    else{
      removeFromCart(productId.product_id)
    }
  }
  else{
    const cartItem = cartItems.find((item) => item.product_id === productId.product_id);
    if (cartItem && cartItem.quantity > Number(productId.minimum_order_quantity)){
      dispatch(decreaseCartItem(productId, ''));
    }
    else{
      removeFromCart(productId.product_id)
    }
   
  }
  };

  const removeFromCart = (productId) => {
    //console.log(productId,'-=================in removecartItem')
    if(authData){
      const cartItem = cartItems.find((item) => item.product_id === productId);
      if (cartItem) {
        dispatch(deleteCartItem(cartItem.cart_item_id, token));
      }
    }
    else{
      
      dispatch(deleteCartItem(productId, token))
    }
   
  };


  const handleSelectAddress = (address) => {
    dispatch(setSelectedAddress(address.address_id));
    setShowAddressDropdown(false);
  };

  const handleAddNewAddress = () => {
    setShowAddressOffcanvas(true);
    setShowAddressDropdown(false);
  };

  const getProductQuantity = (productId) => {
    const item = cartItems.find((item) => item.product_id === productId);
    return item ? item.quantity : 0;
  };
  const handleApplyCoupon = (discountValue) => {
    setDiscount(discountValue); // Update the discount value
    //console.log(`Coupon applied with discount: ₹${discountValue}`);
  };

  const handleCardClick = (medicine) => {
    navigate(`/singleproducts/${encodeURIComponent(medicine.product_name)}`, { state: medicine.product_id });
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLocalFile(file); // Store the file in state
    }
  };

  const handleRemoveFile = () => {
    setLocalFile(null); // Clear the file state
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset the input field
    }
  };
  
  const handleAddMoreMedicines = () => {
    navigate('/products'); // Navigate to the products page
  };
  

  return (
    <div className="cart-section">
      <div className="cart-container">
        <div className="left-section">
        <div className="selected-address-card">
      <div className="address-header">
        <FontAwesomeIcon icon={faMapMarkerAlt} />
        <span>Deliver To</span>
        <strong>{selectedAddress?.name || 'User'}</strong>
        <button
          className="change-address-btn"
          onClick={() => setShowAddressOffcanvas(true)}
        >
          Change
        </button>
      </div>
      <div className="address-details">
        {selectedAddress ? (
          <>
            <p>{`${selectedAddress.address}, ${selectedAddress.city}, ${selectedAddress.state}, ${selectedAddress.pincode}`}</p>
            {/* <p>
              Delivery By <strong>{deliveryDate}</strong>
            </p> */}
          </>
        ) : (
          <p>No address selected</p>
        )}
      </div>
    </div>
  
    {/* Offcanvas for Address Selection */}
    <AddressOffcanvas
      show={showAddressOffcanvas}
      handleClose={() => setShowAddressOffcanvas(false)}
      addresses={addresses}
      handleSelectAddress={handleSelectAddress}
    />

          <h5 className="cart cart-items-header">{filteredProducts.length} Items in your cart</h5>
          <div className="cart cart-items-container">
            {filteredProducts.map((item) => (
              <CardItem
                key={item.product_id}
                imageUrl={productUrls[item.product_id]}
                title={item.product_name}
                company={item.company}
                price={item.price}
                mrp={item.mrp}
                discount={`${item.discount}`}
                min_order={item.minimum_order_quantity}
                quantity={getProductQuantity(item.product_id)}
                onRemove={() => removeFromCart(item.product_id)}
                onClick={() => handleProductClick(item.product_id, item.product_name)}

                onIncrease={() => handleIncreaseQuantity(item)}
                onDecrease={() => handleDecreaseQuantity(item)}
                hidePause={true}
                hideClose={true}
              />
            ))}

            {loading ? (
              <SkeletonLoader className="add-more-medicines-container" />
            ) : (
              <div className="cart add-more-medicines-container">
                <button className="cart add-more-medicines-btn" onClick={handleAddMoreMedicines}>
                  <FontAwesomeIcon icon={faCirclePlus} />
                  <p className="cart add-more-medicines-text">Add More Medicines</p>
                </button>
              </div>
            )}
{/* 
{loading ? (
  <SkeletonLoader className="upload-prescription-header" />
) : (
  <>
    <h6 className="cart upload-prescription-header">Upload Prescription</h6>
    {errorMessage && (
      <span className="cart error-message" style={{ color: 'red' }}>
        {errorMessage}
      </span>
    )}
  <div className="cart upload-prescription-container">
  <input
    type="file"
    accept=".pdf, .jpg, .jpeg, .png, .doc, .docx"
    onChange={handleFileUpload}
    ref={fileInputRef}
    className="upload-prescription-input"
    style={{ display: 'none' }}
    id="upload-prescription"
  />
  <label htmlFor="upload-prescription" className="cart upload-prescription-btn">
    <FontAwesomeIcon icon={faCirclePlus} />
  </label>
  <p className="cart add-prescription-text">Add Prescription</p>

  {localFile && (
                <div className="uploaded-files">
                  {localFile.type.startsWith('image/') ? (
                    <img
                      src={URL.createObjectURL(localFile)}
                      alt="Uploaded File Preview"
                      className="file-preview"
                    />
                  ) : (
                    <p>{localFile.name}</p>
                  )}
                  <button className="remove-file-btn" onClick={handleRemoveFile}>
                    ✖
                  </button>
                </div>
              )}
</div>

  </>
)} */}

          </div>
        </div>

        <div className="right-section">
          <BillDetailsCard discount_val={discount} onApplyCouponClick={() => setShow(true)} localFile={localFile} />
        </div>
      </div>

      <OffcanvasComponent show={show} handleClose={() => setShow(false)} onApplyCoupon={handleApplyCoupon} />

      <MedicineDetailsModal
        show={modalShow}
        handleClose={() => setModalShow(false)}
        medicineData={selectedMedicine}
      />
    </div>
  );
};

export default Cart;
