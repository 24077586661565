/* global Razorpay */ // Add this to tell ESLint Razorpay is a global variable
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import './billdetailscard.scss';
import ButtonComponent from '../common/buttons/ButtonComponent';
import BillDetailCard from '../common/card/billdetails-card';
import AddressOffcanvas from '../common/address-offcanvas/AddressOffcanvas';
import SkeletonLoader from '../common/SkeletonLoader/SkeletonLoader';
import SuccessAnimationModal from '../../components/sections/SuccessAnimationModal';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllocatedCoupons } from '../../redux/actions/couponAllocationAction';
import { fetchCoupons } from '../../redux/actions/couponAction';
import { createOrder, createBulkOrderItems,getBulkOrderItems,getBulkOrders } from '../../redux/actions/orderActions';
import { deleteBulkCartItems } from '../../redux/actions/Addtocart';
import { fetchLocations } from '../../redux/actions/locationAction';
import { uploadDocumentAPI } from '../../api/services/filestorageService';
import { updateProductsWorkflow } from '../../redux/actions/productActions';
import { removeCoupon, updateCartTotal } from '../../redux/actions/setCouponAction';
import { faCheckCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import PharmaModal from '../sections/LoginModal/LoginModal';
import { clearFile } from '../../redux/actions/fileAction';
import { useNavigate } from 'react-router-dom';
import { clearCookieCart } from '../../utils/schema/cookieCart';
import { fetchOffers } from '../../redux/actions/discountAction';
import { fetchAllocatedOffers } from '../../redux/actions/discountAllocateAction';
import { createRazorpayOrder,verifyRazorpayPayment } from '../../api/services/razorPayService';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

// Helper function to calculate amounts
const calculateAmounts = (cartItems, products, couponDiscount, shippingFee, bestOffer) => {
  const totalMRP = cartItems.reduce((total, item) => {
    const product = products.find((p) => p.product_id === item.product_id);
    const mrp = product ? parseFloat(product.mrp) : 0;
    return total + (mrp * item.quantity || 0);
  }, 0);

  const totalProductDiscount = cartItems.reduce((total, item) => {
    const product = products.find((p) => p.product_id === item.product_id);
    if (product) {
      const productDiscount = (product.mrp - item.price_per_unit) * item.quantity;
      return total + productDiscount;
    }
    return total;
  }, 0);

  // //console.log(bestOffer,'matches----')
  // Calculate the offer discount if applicable
  const offerDiscount = bestOffer
  ? cartItems.reduce((total, item) => {
      const product = products.find((p) => p.product_id === item.product_id);

      // Check if the offer applies to the product's category or the product itself
      const isCategoryMatch =
        bestOffer.allocatedOffer.entity_type === 'category' &&
        product?.category_id === bestOffer.allocatedOffer.entity_id;

      const isProductMatch =
        bestOffer.allocatedOffer.entity_type === 'product' &&
        bestOffer.allocatedOffer.entity_id === item.product_id;
      //console.log(isCategoryMatch,isProductMatch,'matches----')
      // Add the item's discount to the total if the offer is applicable
      return isCategoryMatch || isProductMatch
        ? total + item.price_per_unit * item.quantity
        : total;
    }, 0) > 0
    ? bestOffer.discount_value
    : 0
  : 0;


  const totalDiscount =
    parseFloat(totalProductDiscount) +
    parseFloat(couponDiscount || 0) +
    parseFloat(offerDiscount || 0);

  const estimatePayable =
    parseFloat(totalMRP) - parseFloat(totalDiscount) + parseFloat(shippingFee || 0);

  return {
    totalMRP: totalMRP,
    totalProductDiscount: totalProductDiscount,
    offerDiscount: offerDiscount,
    totalDiscount: totalDiscount,
    estimatePayable: estimatePayable,
  };
};
const PaymentMethodModal = ({ show, handleClose, onPaymentSelect }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Select Payment Method</Modal.Title>
      </Modal.Header>
      <Modal.Body>
  <div className="d-flex justify-content-around">
    <Button
      variant="outline-primary"
      className="w-50 mx-2"
      onClick={() => onPaymentSelect("cash")}
    >
      Cash
    </Button>
    <Button
      variant="outline-success"
      className="w-50 mx-2"
      onClick={() => onPaymentSelect("online")}
    >
      Online Payment
    </Button>
  </div>
</Modal.Body>

    </Modal>
  );
};
const BillDetailsCard = ({ onApplyCouponClick, localFile}) => {
  const naviagte = useNavigate()
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const [showAddressOffcanvas, setShowAddressOffcanvas] = useState(false);
  const [isProceedToPay, setIsProceedToPay] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [totalMRP, setTotalMRP] = useState(0);
  const [totalProductDiscount, setTotalProductDiscount] = useState(0);
  const [appliedCouponDiscount, setAppliedCouponDiscount] = useState(0);
  const [discountedTotal, setDiscountedTotal] = useState(0);
  const [shippingFee, setShippingFee] = useState(0);
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token) || '';
  const cartItems = useSelector((state) => state.cart.cartItems) || [];
  const products = useSelector((state) => state.products.basicProducts) || [];
  const address_id = useSelector((state) => state.address.selectedAddressId) || '';
  const allocatedCoupons = useSelector((state) => state.coupon.couponList) || [];
  const locations = useSelector((state) => state.location.locations || []);
  const userid = useSelector((state) => state.auth.user);
  const address = useSelector((state) => state.address.addresses);
  const discount_val = useSelector((state)=>state.setcoupon.appliedCouponId);
  const prescriptionUrl = useSelector((state)=>state.fileUpload.uploadedFile);
  const [orderStatus,setOrderStatus] = useState(false);
  const [url,setUrl] =useState("")
  const [appliedOffer, setAppliedOffer] = useState(null);
const [offerDiscount, setOfferDiscount] = useState(0);
const allocatedOffers = useSelector((state) => state.allocateOffer.allocatedOffers || []);
const offerList = useSelector((state) => state.discount.offerList || []);

  useEffect(() => {
    dispatch(fetchAllocatedCoupons(token));
    dispatch(fetchCoupons(token));
    dispatch(fetchAllocatedOffers(token));
    dispatch(fetchOffers(token))
    dispatch(fetchLocations(token));
  }, [token, dispatch]);

  // Calculate amounts whenever cart items, products, coupon discount, or shipping fee change
  useEffect(() => {
    if (cartItems.length > 0 && products.length > 0) {
      // Map cart items to include category_id
      const cartWithCategories = cartItems.map((item) => {
        const product = products.find((p) => p.product_id === item.product_id);
        return {
          ...item,
          category_id: product?.category_id || null, // Attach category_id if available
        };
      });
  
      // Get all applicable offers
      const applicableOffers = getApplicableOffers(cartWithCategories, allocatedOffers, offerList);
  
      // Select the best offer
      const bestOffer = selectBestOffer(applicableOffers, cartWithCategories);
  
      // Calculate amounts including the best offer
      const { totalMRP, totalProductDiscount, offerDiscount, totalDiscount, estimatePayable } =
        calculateAmounts(cartItems, products, appliedCouponDiscount, shippingFee, bestOffer);
  
      // Update local states
      setTotalMRP(totalMRP);
      setTotalProductDiscount(totalProductDiscount);
      setOfferDiscount(offerDiscount);
      setDiscountedTotal(estimatePayable);
  
      // Update the applied offer in state
      setAppliedOffer(bestOffer);
  
      // Dispatch updated total to Redux
      dispatch(updateCartTotal(estimatePayable));
    }
  }, [cartItems, products, appliedCouponDiscount, shippingFee, allocatedOffers, offerList]);
  

  const getApplicableOffers = (cartWithCategories, allocatedOffers, offerList) => {
    const now = new Date();

    return allocatedOffers
      .map((allocatedOffer) => {
        const offer = offerList.find((o) => o.offer_id === allocatedOffer.offer_id);
        if (
          offer &&
          now >= new Date(offer.start_date) &&
          now <= new Date(offer.expiry_date)
        ) {
          const isApplicable = cartWithCategories.some((item) => {
            return (
              (allocatedOffer.entity_type === 'product' &&
                allocatedOffer.entity_id === item.product_id) ||
              (allocatedOffer.entity_type === 'category' &&
                allocatedOffer.entity_id === item.category_id)
            );
          });

          return isApplicable ? { ...offer, allocatedOffer } : null;
        }
        return null;
      })
      .filter(Boolean);
  };
  
  const selectBestOffer = (applicableOffers, cartWithCategories) => {
    return applicableOffers
      .map((offer) => {
        const totalAmount = cartWithCategories.reduce((sum, item) => {
          const isCategoryMatch =
            offer.allocatedOffer.entity_type === 'category' &&
            offer.allocatedOffer.entity_id === item.category_id;
  
          const isProductMatch =
            offer.allocatedOffer.entity_type === 'product' &&
            offer.allocatedOffer.entity_id === item.product_id;
  
          // Sum up the total for applicable items
          return isCategoryMatch || isProductMatch
            ? sum + item.quantity * item.price_per_unit
            : sum;
        }, 0);
  
        // No minimum purchase validation needed, return the offer directly
        return { ...offer, totalAmount };
      })
      .filter(Boolean)
      .sort((a, b) => b.discount_amount - a.discount_amount)[0]; // Sort by discount and pick the best one
  };
  
  useEffect(() => {
    if (address_id && address.length > 0 && locations.length > 0) {
      const selectedAddress = address.find((addr) => addr.address_id === address_id);
  
      if (locations.some((location) => location.location_name.toLowerCase() === "all" && location.serviceable)) {
        // If "all" location exists and is serviceable, set its base charge
        const allLocation = locations.find((location) => location.location_name.toLowerCase() === "all" && location.serviceable);
        setShippingFee(allLocation?.base_charge || 0);
      } else if (selectedAddress && selectedAddress.address) {
        const matchingLocation = locations.find(
          (location) =>
            selectedAddress.address.toLowerCase().includes(location.location_name.toLowerCase()) && location.serviceable
        );
        setShippingFee(matchingLocation ? matchingLocation.base_charge || 0 : 0);
      } else {
        setShippingFee(0);
      }
    } else {
      setShippingFee(0);
    }
  }, [address_id, address, locations]);
  

  // Handle coupon discount calculation
  useEffect(() => {
    //console.log('Discount value:', discount_val);
    if (discount_val) {
      const coupon = allocatedCoupons.find((c) => c.code === discount_val);
      if (coupon) {
        setAppliedCoupon(coupon);
        if (coupon.discount_type === 'fixed_amount') {
          // Apply fixed discount directly
          setAppliedCouponDiscount(parseFloat(coupon.discount_value || 0));
        } else if (coupon.discount_type === 'percentage') {
          // Calculate percentage discount
          const totalDiscount = (parseFloat(discountedTotal) * parseFloat(coupon.discount_value || 0)) / 100;
          //console.log(totalDiscount,'percentage discount----')
          setAppliedCouponDiscount(totalDiscount);
        }
      } else {
        setAppliedCoupon(null);
        setAppliedCouponDiscount(0); // Reset discount if coupon is invalid
      }
    } else {
      setAppliedCoupon(null);
      setAppliedCouponDiscount(0); // Reset discount if no coupon is applied
    }
  }, [discount_val, allocatedCoupons, totalMRP]);
  
  // Handle order creation

  const handleCashPayment = async () => {
    setOrderStatus(true);
  
    try {
   
      if (localFile) {
        //console.log('Uploading file:', localFile);
        const uploadedFile = await uploadDocumentAPI(localFile, 'prescription/', token);
        if (uploadedFile?.file_url) {
          setUrl(uploadedFile.file_url); // Save the uploaded file URL
          //console.log('File uploaded successfully:', uploadedFile.file_url);
        } else {
          throw new Error('File upload failed.');
        }
      }
  
      // Prepare order data
      const orderData = {
        user_id: userid,
        order_date: new Date().toISOString(),
        order_status: "pending",
        payment_status: "pending",
        payment_method: "cash",
        shipping_address_id: address_id,
        billing_address_id: address_id,
        total_amount: discountedTotal,
        tax_amount: 0.0,
        discount_amount: parseFloat(appliedCouponDiscount) + parseFloat(totalProductDiscount),
        shipping_fee: shippingFee,
        coupon_id: appliedCoupon?.coupon_id || null,
        prescription_url: url,
        delivery_id: null,
        transaction_id: null,
        discount_id: appliedOffer?.offer_id || null,
      };
  
      //console.log("Creating Cash Order:", orderData);
  
      const orderResponse = await dispatch(createOrder(orderData, token));
      if (orderResponse && orderResponse.order_id) {
        await handleCartItemsUpdate(orderResponse.order_id);
        await updateProductStockLevels(cartItems);
        setShowSuccessModal(true);
      }
    } catch (error) {
      // console.error("Error during cash order creation:", error);
      setOrderStatus(false);
    }
    finally{
      setOrderStatus(false)
    }
  };
  
  
  
  const updateProductStockLevels = async (cartItems) => {
    const stockLevelDecrements = cartItems
      .map((item) => {
        const product = products.find((p) => p.product_id === item.product_id);
        if (product) {
          return {
            product_id: product.product_id,
            stock_level: +product.stock_level - +item.quantity,
          };
        }
        return null;
      })
      .filter((product) => product !== null);

    if (stockLevelDecrements.length > 0) {
      try {
        await dispatch(updateProductsWorkflow(stockLevelDecrements, '', token));
        //console.log('Stock levels updated successfully.');
      } catch (error) {
        // console.error('Error updating stock levels:', error);
      }
    }
  };

  const handleCartItemsUpdate = async (order_id) => {
    const orderItems = cartItems.map((item) => {
      const product = products.find((p) => p.product_id === item.product_id);

      const price_per_unit = product ? parseFloat(product.mrp) : 0;
      const total_price = item.quantity * (item.total_price / item.quantity || 0);
      const discount_amount = product
        ? (product.mrp - item.total_price / item.quantity) * item.quantity
        : 0;
      const tax_amount = total_price * 0.18;

      return {
        order_id: order_id,
        product_id: item.product_id,
        quantity: item.quantity,
        price_per_unit: price_per_unit,
        total_price: total_price,
        tax_amount: tax_amount,
        discount_amount: discount_amount,
      };
    });

    await dispatch(createBulkOrderItems(orderItems, token));
    setShowSuccessModal(true);
    const cartItemIds = cartItems.map((item) => item.cart_item_id);
    await dispatch(deleteBulkCartItems(cartItemIds, token));

    
    
    const res = await dispatch(getBulkOrders(token));
    
    if (res){
      setShowSuccessModal(false);
      clearStateAfterOrder();
      naviagte('/ordersuccess',{state:order_id})
    }
    

  };
  const clearStateAfterOrder = () => {
    // Clear local states
    setOrderStatus(false);
    setIsProceedToPay(false);
    setAppliedCoupon(null);
    setAppliedCouponDiscount(0);
    setDiscountedTotal(0);
    clearFile();
    clearCookieCart()
    
    
    
  };
  

  const handleAddAddressClick = () => {
    if (!userid) {
      setShowLoginModal(true); // Show Login Modal
    } else if (!address_id) {
      setShowAddressOffcanvas(true);
    } else if (isProceedToPay) {
      setShowPaymentModal(true); // Show payment method modal
    } else {
      setIsProceedToPay(true);
    }
  };
  const handlePaymentSelection = (method) => {
  if (method === "cash") {
    // For cash, directly place the order
    handleCashPayment();
  } else {
    // For online, initiate Razorpay payment
    handleOnlinePayment();
  }
  setShowPaymentModal(false);
};

const handleOnlinePayment = async () => {
  setOrderStatus(true);

  try {
    if (localFile) {
      //console.log('Uploading file:', localFile);
      const uploadedFile = await uploadDocumentAPI(localFile, 'prescription/', token);
      if (uploadedFile?.file_url) {
        setUrl(uploadedFile.file_url); // Save the uploaded file URL
        //console.log('File uploaded successfully:', uploadedFile.file_url);
      } else {
        throw new Error('File upload failed.');
      }
    }

    // Step 1: Create Razorpay Order
    const razorpayOrderResponse = await createRazorpayOrder(token, {
      total_amount: parseInt(discountedTotal), // Amount in paisa
    });

    if (!razorpayOrderResponse?.razorpay_order.id) {
      throw new Error("Failed to create Razorpay order.");
    }

    // Step 2: Launch Razorpay Checkout
    const options = {
      key: "rzp_test_1P0GnYfJCtrjSx", // Replace with your Razorpay Key
      amount: parseInt(discountedTotal),
      currency: "INR",
      name: "Pharma Order",
      description: "Order Payment",
      order_id: razorpayOrderResponse.razorpay_order.id,
      handler: async (response) => {
        //console.log("Payment Success:", response);

        // Step 3: Verify Payment
        const verificationPayload = {
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
        };

        const verifyResponse = await verifyRazorpayPayment(token, verificationPayload);

        if (verifyResponse) {
          //console.log("Payment Verified Successfully");

          // Finalize order
          await finalizeOnlineOrder(response.razorpay_payment_id, prescriptionUrl);
        } else {
          // console.error("Payment verification failed.");
        }
      },
    };

    const rzp = new Razorpay(options);
    rzp.open();
  } catch (error) {
    // console.error("Error during online payment:", error);
    setOrderStatus(false);
  }
  finally{
    setOrderStatus(false)
  }
};

const finalizeOnlineOrder = async (transactionId, prescriptionUrl) => {
  const orderData = {
    user_id: userid,
    order_date: new Date().toISOString(),
    order_status: "pending",
    payment_status: "paid",
    payment_method: "online payment",
    shipping_address_id: address_id,
    billing_address_id: address_id,
    total_amount: discountedTotal,
    tax_amount: 0.0,
    discount_amount: parseFloat(appliedCouponDiscount) + parseFloat(totalProductDiscount),
    shipping_fee: shippingFee,
    coupon_id: appliedCoupon?.coupon_id || null,
    prescription_url: prescriptionUrl,
    transaction_id: transactionId,
    delivery_id: null,
    discount_id: appliedOffer?.offer_id || null,
  };

  //console.log("Finalizing Online Order:", orderData);

  const orderResponse = await dispatch(createOrder(orderData, token));
  if (orderResponse && orderResponse.order_id) {
    await handleCartItemsUpdate(orderResponse.order_id);
    await updateProductStockLevels(cartItems);
    setShowSuccessModal(true);
  }
};

  const handleRemoveCoupon = () => {
    setAppliedCoupon(null);
    setAppliedCouponDiscount(0);
    dispatch(removeCoupon()); // Dispatch removeCoupon action
    if (onApplyCouponClick) { 
      onApplyCouponClick(null); // Notify parent if needed
    }
  };

  return (
    <div className="bill-details-card">
      <PharmaModal show={showLoginModal} handleClose={() => setShowLoginModal(false)} />
       {appliedCoupon ? (
        <div className="applied-coupon-section">
          <div className="applied-coupon-header">
            <FontAwesomeIcon icon={faCheckCircle} className="coupon-check-icon" />
            <span className="applied-coupon-text">
              {appliedCoupon.code} applied
            </span>
            <FontAwesomeIcon
              icon={faTrashAlt}
              className="coupon-remove-icon"
              onClick={handleRemoveCoupon}
            />
          </div>
          <p className="applied-coupon-subtext">
            Yay! ₹{appliedCouponDiscount.toFixed(2)} off on this order
          </p>
        </div>
      )  : (
        <button className="bill-apply-coupon-btn" onClick={onApplyCouponClick}>
          <span>Apply Coupon</span> <FontAwesomeIcon icon={faArrowRight} />
        </button>
      )}

      <div className="bill-details">
        <h6 className="bill-details-header">Bill Details</h6>
        <hr />
        <BillDetailCard label="Total MRP" value={`₹${(totalMRP - totalProductDiscount).toFixed(2)}`} isSmall />

        <BillDetailCard label="Coupon Discount" value={`-₹${appliedCouponDiscount.toFixed(2)}`} isDiscount isSmall />
        <BillDetailCard label="Offer Discount" value={`-₹${offerDiscount}`} isDiscount isSmall />
        <BillDetailCard label="Shipping Fee" value={`₹${shippingFee}`} isSmall />

        {shippingFee === 0 && (
  <span className="text-danger text-center mb-2">
    Service not available for this location
  </span>
)}

      </div>

      <div className="bill-estimated-payable">
        <p className="bill-estimated">Estimate Payable</p>
        <h6 className="bill-amount">₹{discountedTotal.toFixed(2)}</h6>
        {/* <p className="text-muted">Inclusive of all taxes*</p> */}

      </div>

      <ButtonComponent
  text={
    !userid
      ? 'Proceed to Login'
      : !address_id
      ? 'Add Address'
      : isProceedToPay
      ? orderStatus === true
        ? 'Placing Order'
        : 'Proceed to Pay'
      : 'Proceed to Checkout'
  }
  onClick={handleAddAddressClick}
  className="add-address-btn"
  disabled={isProceedToPay && shippingFee === 0} // Disable only if Proceed to Pay and shippingFee is zero
/>

      <AddressOffcanvas
        show={showAddressOffcanvas}
        handleClose={() => setShowAddressOffcanvas(false)}
        onSaveAndContinueClick={() => setShowAddressOffcanvas(false)}
      />

<PaymentMethodModal
  show={showPaymentModal}
  handleClose={() => setShowPaymentModal(false)}
  onPaymentSelect={handlePaymentSelection}
/>

      <SuccessAnimationModal show={showSuccessModal} handleClose={() => setShowSuccessModal(false)} />
    </div>
  );
};

export default BillDetailsCard;
