import { toast } from "react-hot-toast";
import apiClient from "../apiClient"; // Ensure this is configured to make API requests
import { export_endpoints } from "../endPoints"; // Ensure this contains the appropriate endpoints

/**
 * Export Orders API
 * @param {string} token - User's authentication token
 * @param {string} startDate - Optional start date for filtering orders (in YYYY-MM-DD format)
 * @param {string} endDate - Optional end date for filtering orders (in YYYY-MM-DD format)
 */
export const exportOrdersAPI = async (token, startDate, endDate) => {
  try {
    const params = {};


    const response = await apiClient.get(export_endpoints.GET_EXPORT_ENDPOINTS, {
      params:{start_date:startDate,end_date:endDate},
      headers: {
        Authorization: token,
        
      },
      responseType: 'blob',
    });
    //console.log(response.data); // Log the blob content here


    
    const contentType = response.headers["content-type"];
    if (contentType !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      throw new Error("Invalid file type received.");
    }

    const blob = new Blob([response.data], { type: contentType });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;

    const contentDisposition = response.headers["content-disposition"];
    const filename = contentDisposition
      ? contentDisposition.split("filename=")[1].replace(/['"]/g, "")
      : "orders_export.xlsx";

    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();

    toast.success("Orders exported successfully!");
  } catch (error) {
    // console.error("Error exporting orders:", error);
    toast.error(
      error.response?.data?.error || "An error occurred while exporting orders."
    );
    throw error;
  }
};
