import React, { useEffect, useState } from 'react';
import InputComponent from '../../components/common/InputComponent/InputComponent';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import DropdownButton from '../../components/common/dropdown/DropdownButton';
import RadioButtonComponent from '../../components/common/RadioButtonComponent/RadioButtonComponent';
import Textbox from '../../components/common/Textbox/Textbox';
import { updateSupplier, createSupplier,fetchSuppliers } from '../../redux/actions/supplierActions';
import './supplier-management.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Country, State, City } from 'country-state-city';

import toast from 'react-hot-toast';
import SearchableDropdown from '../../components/common/SearchableDropdown/SearchableDropdown';
const SupplierManagement = ({urls}) => {
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    phone: '',
    address: '',
    state: '',
    city: '',
    gst_no: '',
    fssai_lic_no: '',
    type: '',
    both_purchase_sale: false,
    tcs_percent: '',
    country:'',
  });

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [error, setError] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const token = useSelector((state)=>state.adminLogin.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const[isSaving,setIsSaving] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState('');
const [selectedState, setSelectedState] = useState('');
const [selectedCity, setSelectedCity] = useState('');
const [countryOptions, setCountryOptions] = useState([]);
const [stateOptions, setStateOptions] = useState([]);
const [cityOptions, setCityOptions] = useState([]);


  const supplierId =urls?null:location.state || null;
  const supplierList = useSelector((state) => state.supplier.suppliers) || [];
  useEffect(() => {
    const countries = Country.getAllCountries();
    console.log("countriessss",countries)
    setCountryOptions(countries);
  }, []);
  const handleCountryChange = (countryCode) => {
    const country = countryOptions.find((c) => c.isoCode === countryCode);
    setSelectedCountry(countryCode);
    setFormData((prev) => ({
      ...prev,
      country: country?.isoCode || '', // Save country name in formData
    }));
    const states = State.getStatesOfCountry(countryCode);
    setStateOptions(states);
    setSelectedState('');
    setCityOptions([]);
  };
  
  const handleStateChange = (stateCode) => {
    setSelectedState(stateCode);
    setFormData((prev) => ({
      ...prev,
      state: stateOptions.find((state) => state.isoCode === stateCode)?.name || '', // Update state name
    }));
    const cities = City.getCitiesOfState(selectedCountry, stateCode);
    setCityOptions(cities);
    setSelectedCity(''); // Reset city selection
  };
  
  const handleCityChange = (cityName) => {
    setSelectedCity(cityName);
    setFormData((prev) => ({
      ...prev,
      city: cityName, // Update city name
    }));
  };
  
  useEffect(() => {
    if (supplierId && supplierList.length > 0) {
      const existingSupplier = supplierList.find((s) => String(s.supplier_id) === String(supplierId.id));
      if (existingSupplier) {
        setFormData(existingSupplier);
        const country = countryOptions.find((c) => c.name === existingSupplier.country);
        if (country) {
          setSelectedCountry(country.isoCode); // Prepopulate selectedCountry
          const states = State.getStatesOfCountry(country.isoCode);
          setStateOptions(states);
  
          const state = states.find((s) => s.name === existingSupplier.state);
          if (state) {
            setSelectedState(state.isoCode); // Prepopulate selectedState
            const cities = City.getCitiesOfState(country.isoCode, state.isoCode);
            setCityOptions(cities);
          }
        }
        setIsButtonDisabled(true);
      }
    }
  }, [supplierId, supplierList, countryOptions]);
  
  
  // Enable Save button only if changes are made
  const handleInputChange = (fieldName, value) => {
    setFormData((prev) => ({ ...prev, [fieldName]: value }));
    setIsButtonDisabled(false); // Enable button on any change
  };

  // Validation check
  const validateForm = () => {
    const requiredFields = ['name', 'company', 'email', 'phone', 'address', 'state', 'city', 'gst_no', 'fssai_lic_no', 'type', 'tcs_percent'];

    for (let field of requiredFields) {
      if (!formData[field]) {
        setError(`Please fill in the mandatory field: ${field.replace('_', ' ')}`);
        setShowPopup(true);
        return false;
      }
    }

    // Duplicate check
    const duplicate = supplierList.some(
      (supplier) =>
        (supplier.name === formData.name ||
          supplier.email === formData.email ||
          supplier.phone === formData.phone) 
       
    );
    if(!supplierId){
      if (duplicate) {
        setError('A supplier with the same name, email, or phone already exists.');
        setShowPopup(true);
        return false;
      }
    }
    

    setError('');
    return true;
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!validateForm()) return; // Stop if validation fails
    setIsSaving(false);
    //console.log('suppliiierrrrr',supplierId)
    let response = false
    if (supplierId) {
      //console.log('suppliiierrrrr',supplierId,'updateeeeee')
      response =await dispatch(updateSupplier(formData,token));
    } else {
      //console.log('suppliiierrrrr',supplierId,'createeeeee')
      response =await dispatch(createSupplier(formData,token));
    }

    if (response){
      const fetch_response = await dispatch(fetchSuppliers('',token));
      if (fetch_response){
        if(!urls){
          navigate('/admin/supplier');  
        }
        if (supplierId) {
        toast.success("supplier updated successfully");
        } 
        else{
          toast.success("supplier created successfully");
        }   
      }
      else{
        setIsSaving(true);
        if (supplierId) {
          toast.error("supplier not updated");
          } 
          else{
            toast.error("supplier not created");
          }   
      }
      
      }
      setIsSaving(true);
     
    
  };

  // Close the popup message
  const closePopup = () => setShowPopup(false);

  return (
    <div className="supplier-management-section">
       <div className="breadcrumb-container">
    <Link to="/admin/dashboard" className="breadcrumb-text">
    <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
      </Link>
      
      <span className="breadcrumb-separator">{'>'}</span>
      <Link to="/admin" className="breadcrumb-text">
        Product List
      </Link>
      <span className="breadcrumb-separator">{'>'}</span>
      <Link to="/admin/supplier" className="breadcrumb-text">
      Supplier List
      </Link>
      <span className="breadcrumb-separator">{'>'}</span>
      <span className="breadcrumb-text">Add Supplier</span>
     
    </div>
    <div className="supplier-list-container"></div>
      <div className="supplier-management">
      <h5 className="text-heading1">Notifications</h5>
        <hr />

        <div className="form-row">
          <div className="form-group">
            <label>Name*</label>
            <InputComponent
              placeholder=" "
              value={formData.name}
              onChange={(e) => handleInputChange('name', e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Company*</label>
            <InputComponent
              placeholder=" "
              value={formData.company}
              onChange={(e) => handleInputChange('company', e.target.value)}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Email*</label>
            <InputComponent
              placeholder=" "
              value={formData.email}
              onChange={(e) => handleInputChange('email', e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Phone*</label>
            <InputComponent
              placeholder=" "
              value={formData.phone}
              onChange={(e) => handleInputChange('phone', e.target.value)}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Address*</label>
            <Textbox
              value={formData.address}
              onChange={(e) => handleInputChange('address', e.target.value)}
            />
          </div>
        </div>

        <div className="form-row">
  <div className="form-group">
  <div className="form-group">
  <label>Country*</label>
  <SearchableDropdown
    options={countryOptions.map((country) => ({
      label: country.name, // Display country name
      id: country.isoCode, // Use isoCode as the unique value
    }))}
   
    value={
      countryOptions.find((option) => option.isoCode === selectedCountry)?.name || ''
    }
    onSelect={(value) => {
      console.log('Selected Country Value:', value); // Log the selected value
      handleCountryChange(value); // Pass the selected value to handleCountryChange
    }}
  />
</div>


  </div>
  <div className="form-group">
    <label>State*</label>
    <SearchableDropdown
  options={stateOptions.map((state) => ({
    label: state.name, // Display the state name
    id: state.isoCode, // Use isoCode as the value
  }))}
  label={selectedState}
  onSelect={(value) => handleStateChange(value)}
  disabled={!selectedCountry}

    />
  </div>
  <div className="form-group">
    <label>City*</label>
    <SearchableDropdown
    options={cityOptions.map((city) => ({
      label: city.name, // Display city name
      id: city.name, // Use city name as the unique value
    }))}
    
    value={selectedCity}
    onSelect={handleCityChange} // Use the updated handleCityChange function
    disabled={!selectedState} // Disable dropdown if no state is selected
  />
  </div>
</div>


        <div className="form-row">
          <div className="form-group">
            <label>GST No*</label>
            <InputComponent
              placeholder=" "
              value={formData.gst_no}
              onChange={(e) => handleInputChange('gst_no', e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>FSSAI Lic No.*</label>
            <InputComponent
              placeholder=" "
              value={formData.fssai_lic_no}
              onChange={(e) => handleInputChange('fssai_lic_no', e.target.value)}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Type*</label>
            <DropdownButton
  label={formData.type || "Select Type"} // Display the selected type or placeholder
  options={["Both", "Purchase", "Sale"]} // Dropdown options
  onSelect={(selectedOption) =>
    handleInputChange("type", selectedOption) // Directly update type with the selected option
  }
/>


          </div>
          <div className="form-group">
            <label>TCS %*</label>
            <InputComponent
              placeholder=" "
              value={formData.tcs_percent}
              onChange={(e) => handleInputChange('tcs_percent', e.target.value)}
            />
          </div>
        </div>

        <div className="action-buttons">
          <ButtonComponent
            text={isSaving?"Save":"Saving..."}
            onClick={handleSubmit}
            disabled={isButtonDisabled}
            className="save-button"
          />
        </div>

        {showPopup && (
          <div className="popup-overlay" onClick={closePopup}>
            <div className="popup-message">
              <p>{error}</p>
              <button onClick={closePopup}>Close</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SupplierManagement;
