import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SearchableDropdown from "../../components/common/SearchableDropdown/SearchableDropdown";
import ButtonComponent from "../../components/common/buttons/ButtonComponent";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import DropdownButton from "../../components/common/dropdown/DropdownButton";
import { createNewConfig, updateConfigById } from "../../redux/actions/deliveryConfigActions";
import "./delivery-config.scss";
const DeliveryConfig = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
  
  
  
    const [productId, setProductId] = useState("");
    const [appliesToAll, setAppliesToAll] = useState(false);
    const configId = location.state || null; // If editing, get the existing config ID
    const token = useSelector((state) => state.auth.token);
    const productList = useSelector((state) => state.products.basicProducts || []);
    const deliveryConfigs = useSelector((state) => state.deliveryConfig.allConfigs || []);
    const statusOptions = ["Mon", "Tue", "Wed", "Thu","Fri"];
    const [currentStock,setcurrentStock]=useState('');
    const [formData, setFormData] = useState({
    // Auto-generate config_id if not editing
      product_id: "",
      delivery_days: [],
      
    });
    const [errors, setErrors] = useState({});
    const [originalData, setOriginalData] = useState({});
    const [isModified, setIsModified] = useState(false);
  
    const productOptions = productList.map((product) => ({
      label: product.product_name,
      id: product.product_id,
    }));
    
    const validateForm = () => {
      const validationErrors = {};
  
      if (!productId) validationErrors.productId = "Product ID is required.";
      if (!formData.delivery_days.length)
        validationErrors.delivery_days = "At least one delivery day is required.";
  
      return validationErrors;
    };
  
    useEffect(() => {
      if (configId) {
        const configData = deliveryConfigs.find((config) => config.config_id === configId);
        if (configData) {
          setFormData({
          
            product_id: configData.product_id || "",
            delivery_days: configData.delivery_days || [],
           
          });
          setOriginalData(configData);
          //console.log("Loaded Config Data:", configData);
        }
      }
    }, [configId, deliveryConfigs]);
  
    useEffect(() => {
      const currentData = {
        ...formData,
        product_id: productId,
      };
      setIsModified(JSON.stringify(currentData) !== JSON.stringify(originalData));
    }, [formData, productId, originalData]);
  

    const handleDeliveryDaysChange = (selectedDay) => {
      setFormData((prev) => {
        // Ensure delivery_days is always treated as a string
        const deliveryDaysString = typeof prev.delivery_days === "string" ? prev.delivery_days : "";
    
        // Safely split and trim the string to get an array
        const deliveryDaysArray = deliveryDaysString
          .split(",")
          .map((day) => day.trim())
          .filter((day) => day); // Remove any empty entries
    
        // Add or remove the selected day
        const updatedDaysArray = deliveryDaysArray.includes(selectedDay)
          ? deliveryDaysArray.filter((day) => day !== selectedDay) // Remove the day
          : [...deliveryDaysArray, selectedDay]; // Add the day
    
        // Convert back to a string
        const updatedDaysString = updatedDaysArray.join(", ");
    
        //console.log("Updated Delivery Days (String):", updatedDaysString); // Debug log
    
        return {
          ...prev,
          delivery_days: updatedDaysString, // Store as a string
        };
      });
    };
    
    const handleProductSelection = (selectedId) => {
      setProductId(selectedId);
    
      // Find the selected product details
      const selectedProduct = productList.find((product) => product.product_id === selectedId);
    
      // Update currentStock based on the selected product's stock
      if (selectedProduct) {
        setcurrentStock(selectedProduct.stock_level || "0"); // Default to "0" if stock is undefined
      }
    };
   
    const handleSubmit = async (e) => {
        e.preventDefault();
      
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          return;
        }
      
        // Prepare the delivery config data
            const configData = {
              config_id:configId,
              product_id: productId,
              applies_to_all: appliesToAll,
              delivery_days: Array.isArray(formData.delivery_days)
                ? formData.delivery_days.join(", ") // Convert array to string
                : formData.delivery_days, // Ensure it's a string
            };
      
        //console.log("Final Config Data Before API Call:", configData); // Log to check the data
      
        try {
          if (configId) {
            //console.log("Updating Config with ID:", configId);
            await dispatch(updateConfigById(configId, configData, token));
            toast.success("Delivery config updated successfully.");
          } else {
            //console.log("Creating New Config with Data:", configData);
            //console.log("tokennnnnnnnnnnnnn",token);
            await dispatch(createNewConfig(configData, token));
            toast.success("Delivery config created successfully.");
          }
          navigate("/admin/delivery-config-displaypage");
        } catch (error) {
          // console.error("Error during delivery config operation:", error);
          toast.error(error.response?.data?.message || "Failed to save the delivery config.");
        }
      };
      
  return (
    <div className="delivery-config-page-section">
      <div className="breadcrumb-container">
        <Link to="/admin/dashboard" className="breadcrumb-text">
          <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        </Link>
        <span className="breadcrumb-separator">{">"}</span>
        <Link to="/admin/delivery-config-displaypage" className="breadcrumb-text">
          Delivery Configuration List
        </Link>
        <span className="breadcrumb-separator">{">"}</span>
        <span className="breadcrumb-text">Add Delivery Config</span>
      </div>
      <div className="delivery-config-container"></div>
      <div className="config-page">
        <h5 className="text-heading1">Delivery Config</h5>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
           
            <div className="form-group">
              <label>Product</label>
              <SearchableDropdown
                placeholder="Search for a product"
                options={productOptions}
                value={
                  productOptions.find((option) => option.id === formData.product_id)?.label || ""
                }
                
                onSelect={handleProductSelection}
              />
              {errors.productId && <span className="error-text">{errors.productId}</span>}
            </div>
          
            <div className="form-group">
              <label>Delivery Days</label>
              <DropdownButton
                placeholder="Select Delivery Days" // Add this placeholder text
                options={statusOptions} // The available options (["Mon", "Tue", ...])
                label={
                  formData.delivery_days && formData.delivery_days.length > 0
                    ? formData.delivery_days
                    : "Select Delivery Days" // Display the placeholder when empty
                }
                onSelect={(value) => handleDeliveryDaysChange(value)} // Trigger value selection
              />
            </div>
            </div>
              <div className="form-row">
                <div className="form-group">
                  <div className="applies-to-all">
                    <input
                      type="checkbox"
                      id="appliesToAll"
                      checked={appliesToAll}
                      onChange={(e) => setAppliesToAll(e.target.checked)}
                    />
                    <label htmlFor="appliesToAll" className="checkbox-label">
                      Applies to All
                    </label>
                  </div>
                </div>
              </div>
          <div className="form-row save-button-container">
            <ButtonComponent
              text={configId ? "Update Config" : "Create Config"}
              className={`submit-button ${!isModified ? "disabled" : ""}`}
              onClick={handleSubmit}
              disabled={!isModified}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
export default DeliveryConfig;