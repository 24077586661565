import React, { useState, useEffect } from "react";
import {
  FaChartLine,
  FaUtensils,
  FaReceipt,
 
  FaMotorcycle,
 
  FaEllipsisV,
  FaBoxes
} from "react-icons/fa";
import DropdownButton from "../../components/common/dropdown/DropdownButton";
import UserCard from "../../components/common/usercard/usercard";
import BarChart from "../../components/barchart/BarChart";
import LineChart from "../../components/linechart/LineChart";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./UserPage.scss";
import FetchOrderPopup from "../../components/common/fetch-orders-popup/fetch-orders-popup";
import { useSelector, useDispatch } from "react-redux";
import { fetchCategory } from "../../redux/actions/categoryAction";
import { fetchBrand } from "../../redux/actions/brandAction";
import { fetchProducts } from "../../redux/actions/productActions";
import { fetchCoupons } from "../../redux/actions/couponAction";
import { fetchUsers } from "../../redux/actions/adminuserActions";
import { getBulkOrders } from "../../redux/actions/orderActions";
import { fetchSuppliers } from "../../redux/actions/supplierActions";
const UserPage = () => {
  const token = useSelector((state) => state.adminLogin.token);
  const orders = useSelector((state) => state.orders.orders);
  const dispatch = useDispatch();

  const [selectedRange, setSelectedRange] = useState("Today");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [barChartData, setBarChartData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [LineChartData, setLineChartData] = useState([]); 
  const [popupVisible, setPopupVisible] = useState(false);


  // useEffect(() => {
  //   const currentDate = new Date().toISOString().split("T")[0];
  //   const lastPopupDate = localStorage.getItem("lastPopupDate");

  //   if (lastPopupDate !== currentDate) {
  //     setPopupVisible(true);
  //     localStorage.setItem("lastPopupDate", currentDate);
  //   }
  // }, []);

  const closePopup = () => {
    setPopupVisible(false);
  };

  // Fetch data on mount and refresh periodically
  useEffect(() => {
    const fetchData = () => {
      dispatch(fetchCategory(token));
      dispatch(fetchBrand(token));
      dispatch(fetchProducts(token));
      dispatch(fetchCoupons(token));
      dispatch(fetchUsers(token));
      dispatch(getBulkOrders(token));
      dispatch(fetchSuppliers('',token));
    };

    fetchData();
    const interval = setInterval(fetchData, 300000); // Refresh every 5 minutes
    return () => clearInterval(interval);
  }, [dispatch, token]);

  const getCurrentDate = () => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  };

  const getDateNDaysAgo = (n) => {
    const date = new Date();
    date.setDate(date.getDate() - n);
    date.setHours(0, 0, 0, 0);
    return date;
  };
  const generatePaymentData = (orders) => {
    const paymentTypes = [
      { paymentType: "Cash", method: "cash" },
      { paymentType: "Online Payment", method: "online payment" },
     
      
    ];
  
    return paymentTypes.map((type) => ({
      paymentType: type.paymentType,
      value: orders
        .filter((order) => order.payment_method === type.method)
        .reduce((sum, order) => sum + Number(order.total_amount), 0),
    }));
  };
  
  const isDateInRange = (order, field) => {
    const targetDate = new Date(order[field]).setUTCHours(0, 0, 0, 0);
  
    switch (selectedRange) {
      case "Today":
        return targetDate === getCurrentDate().setUTCHours(0, 0, 0, 0);
      case "Yesterday":
        return targetDate === getDateNDaysAgo(1).setUTCHours(0, 0, 0, 0);
      case "Last 7 Days":
        return (
          targetDate >= getDateNDaysAgo(7).setUTCHours(0, 0, 0, 0) &&
          targetDate <= getCurrentDate().setUTCHours(0, 0, 0, 0)
        );
      case "Last 30 Days":
        return (
          targetDate >= getDateNDaysAgo(30).setUTCHours(0, 0, 0, 0) &&
          targetDate <= getCurrentDate().setUTCHours(0, 0, 0, 0)
        );
        case "Custom Range":
          if (!startDate || !endDate) return false;
          const rangeStart = new Date(startDate).setHours(0, 0, 0, 0);
          const rangeEnd = new Date(endDate).setHours(23, 59, 59, 999);
          return targetDate >= rangeStart && targetDate <= rangeEnd;
      default:
        return true;
    }
  };
  

  const filterOrdersByDate = () => {
    return orders.filter((order) => isDateInRange(order, "order_date"));
  };
 
  // Update filtered data and chart data when selection changes
  useEffect(() => {
    if (selectedRange === "Custom Range" && (!startDate || !endDate)) {
      setFilteredOrders([]);
      setBarChartData([]);
      return;
    }

    const filtered = filterOrdersByDate();
    setFilteredOrders(filtered);

    const chartData = generateBarChartData(filtered);
    setBarChartData(chartData);

    const lineData = generatePaymentData(filtered);
    setLineChartData(lineData);
  }, [selectedRange, orders, startDate, endDate]);

  const handleCalendarSelect = (range) => {
    setSelectedRange(range);
    if (range !== "Custom Range") {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const generateBarChartData = (orders) => {
    if (!orders || orders.length === 0) return []; // Handle empty orders gracefully
  
    if (selectedRange === "Today" || selectedRange === "Yesterday") {
      const timeRanges = [
        { label: "12:00 AM-04:00 AM", start: 0, end: 4 },
        { label: "04:00 AM-08:00 AM", start: 4, end: 8 },
        { label: "08:00 AM-12:00 PM", start: 8, end: 12 },
        { label: "12:00 PM-04:00 PM", start: 12, end: 16 },
        { label: "04:00 PM-08:00 PM", start: 16, end: 20 },
        { label: "08:00 PM-12:00 AM", start: 20, end: 24 },
      ];
  
      const data = timeRanges.map((range) => ({
        timeRange: range.label,
        sales: 0,
      }));
  
      orders.forEach((order) => {
        const orderHour = new Date(order.order_date).getHours(); // Extract hour
        timeRanges.forEach((range, index) => {
          if (orderHour >= range.start && orderHour < range.end) {
            data[index].sales += Number(order.total_amount);
          }
        });
      });
  
      return data;
    } 
    
    else if (selectedRange === "Custom Range" && startDate && endDate) {
      // Generate all dates in the range
      const dateRanges = [];
      const start = new Date(startDate);
      const end = new Date(endDate);
    
      for (let date = new Date(start); date <= end; date.setDate(date.getDate() + 1)) {
        dateRanges.push(new Date(date).toISOString().split("T")[0]);
      }
    
      // Initialize data with all dates in the range and set sales to 0
      const data = dateRanges.map((date) => ({
        timeRange: date,
        sales: 0,
      }));
    
      // Aggregate sales data for the matching dates
      orders.forEach((order) => {
        const orderDate = new Date(order.order_date).toISOString().split("T")[0];
        const dateEntry = data.find((entry) => entry.timeRange === orderDate);
        if (dateEntry) {
          dateEntry.sales += Number(order.total_amount);
        }
      });
    
      // Return the array (already in chronological order because of date generation)
      return data;
    }
    
    
    else {
      const dateRanges = [];
      const startDate = selectedRange === "Last 7 Days" ? getDateNDaysAgo(7) : getDateNDaysAgo(30);
      const endDate = getCurrentDate();
  
      for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
        dateRanges.push(date.toISOString().split("T")[0]);
      }
  
      const data = dateRanges.map((date) => ({
        timeRange: date,
        sales: 0,
      }));
  
      orders.forEach((order) => {
        const orderDate = new Date(order.order_date).toISOString().split("T")[0];
        data.forEach((range) => {
          if (range.timeRange === orderDate) {
            range.sales += Number(order.total_amount);
          }
        });
      });
  
      return data;
    }
  };
  
  const lineChartData = {
    labels: filteredOrders.map((order) => new Date(order.order_date).toLocaleDateString()), // x-axis
    sales: filteredOrders.map((order) => order.total_amount), // y-axis
  };

  const processingOrders = filteredOrders.filter(
    (order) => order.order_status === "processing"
  );
  
  const shippedOrders = filteredOrders.filter(
    (order) => order.order_status === "shipped" && isDateInRange(order, "delivery_date")
  );
  
  const deliveredOrders = filteredOrders.filter(
    (order) => order.order_status === "delivered"
  );
  
  const calendarOptions = ["Today", "Yesterday", "Last 7 Days", "Last 30 Days", "Custom Range"];
  const totalAmount = filteredOrders.reduce((acc, order) => acc + Number(order.total_amount), 0);
  const userData = [
    {
      title: "Total Sales",
      value: filteredOrders.reduce((acc, order) => acc + Number(order.total_amount), 0).toFixed(2),
      orders: filteredOrders.length,
      icon: <FaChartLine className="card-icon" />,
    },
    {
      title: "Processing Orders",
      value: processingOrders.reduce((sum, order) => sum + Number(order.total_amount), 0).toFixed(2),
      orders: processingOrders.length,
      icon: <FaBoxes className="card-icon" />,
    },
    {
      title: "Out for Delivery",
      value: shippedOrders.reduce((sum, order) => sum + Number(order.total_amount), 0).toFixed(2),
      orders: shippedOrders.length,
      icon: <FaReceipt className="card-icon" />,
    },
    {
      title: "Delivered",
      value: deliveredOrders.reduce((sum, order) => sum + Number(order.total_amount), 0).toFixed(2),
      orders: deliveredOrders.length,
      icon: <FaMotorcycle className="card-icon" />,
    },
  ];
  
  
  return (
    <div className="user-page">
      {/* {popupVisible && (
        <FetchOrderPopup
          onConfirm={() => {
            // Handle 'Fetch Orders' action
            closePopup();
          }}
          onCancel={closePopup}
        />
      )} */}
      <div className="main-content">
        <div className="left-section">
          <div className="user-page-header">
          
            <div className="controls">
              <DropdownButton
                label={selectedRange}
                options={calendarOptions}
                onSelect={handleCalendarSelect}
                className="dropdown"
              />
              {selectedRange === "Custom Range" && (
                <div style={{ display: "flex", gap: "10px", alignItems: "center", marginLeft: "10px" }}>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    placeholderText="Start Date"
                    dateFormat="yyyy-MM-dd"
                    className="date-input"
                  />
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    placeholderText="End Date"
                    dateFormat="yyyy-MM-dd"
                     className="date-input"
                  />
                </div>
              )}
              
            </div>
          </div>
          <div className="user-card-grid">
           {
              userData.map((data, index) => (
                <UserCard key={index}>
                  <div className="user-card-header">
                    <span className="card-title">{data.title}</span>
                    {data.icon}
                  </div>
                  <div className="user-card-body">
                    <span className="card-value">₹ {data.value}</span>
                  </div>
                  <div className="user-card-footer">
                    <span className="card-orders">
                      {data.orders} Order{data.orders !== 1 ? "s" : ""}
                    </span>
                    <FaEllipsisV
                      className="dots-icon"
                      onClick={() => setSelectedCard(index)}
                    />
                  </div>
                </UserCard>
              ))
            }
          </div>
          <div className="no-sales-recorded">
            <BarChart data={barChartData} />
          </div>
        </div>
        <div className="right-section">
        <div className="sales-details">
            <h6>Payment methods</h6>
            <LineChart data={LineChartData} />
          </div>
        </div>
      </div>
      {modalVisible && (
        <div className="modal-overlay" onClick={() => setModalVisible(false)}>
          <div className="modal-content">
            <h6>{userData[selectedCard]?.title} Stats</h6>
            <ul>
              <li>
                <span>No. of Orders</span>
                <span>{userData[selectedCard]?.orders}</span>
              </li>
              <li>
                <span>Total</span>
                <span>₹ {userData[selectedCard]?.value}</span>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserPage;
