import React, { useState, useEffect } from 'react';
import InputComponent from '../../components/common/InputComponent/InputComponent';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import './admin-user.scss';
import { Link } from 'react-router-dom';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {  updateUser, createUser } from '../../redux/actions/adminuserActions';
import Datepicker from '../../components/common/datepicker/datepicker';
import DropdownButton from '../../components/common/dropdown/DropdownButton';

const AdminUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user_Id = location.state || null;

  const token = useSelector((state) => state.adminLogin.token);
  const userlist = useSelector((state) => state.usermanage.users) || [];

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    mobile_number: '',
    dob: '',
    gender: '',
    role: 'user',
    location: '',
  });

  const [originalData, setOriginalData] = useState(null);
  const [isModified, setIsModified] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSaving,setIsSaving] = useState(true)
  useEffect(() => {
    if (user_Id) {
      const userData = userlist.find(user => user.user_id === user_Id);
      if (userData) {
        //console.log(userData,'----------')
        setFormData(userData);
        setOriginalData(userData);
      }
    }
  }, [user_Id, userlist]);

  useEffect(() => {
    const isFormModified = JSON.stringify(formData) !== JSON.stringify(originalData);
    setIsModified(isFormModified);
  }, [formData, originalData]);

  // Check for duplicate email or mobile number
  const isDuplicate = () => {
    return userlist.some((user) => 
      (user.email === formData.email && user.user_id !== user_Id) ||
      (user.mobile_number === formData.mobile_number && user.user_id !== user_Id)
    );
  };

  // Check if all mandatory fields are filled
  const isFormValid = () => {
    return formData.username && formData.email && formData.mobile_number;
  };

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {
    setIsSaving(false);
    if (isDuplicate()) {
     toast.error("Duplicate email or mobile number found.");
      return;
    }

    if (!isFormValid()) {
      toast.error("Please fill in all required fields.");
      return;
    }

    setErrorMessage('');

    if (user_Id) {
      const response =await dispatch(updateUser(user_Id, formData, token));
      if (response){
        navigate('/admin/user-management');
      }
    } else {
      const response =await dispatch(createUser(formData, token));
      if (response){
        navigate('/admin/user-management');
      }
    }

    
    setIsSaving(true);
  };

  return (
    <div className="admin-add-user-section">
     <div className="breadcrumb-container">
      <Link to="/admin/dashboard" className="breadcrumb-text">
      <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        </Link>
        
        <span className="breadcrumb-separator">{'>'}</span>
        <Link to="/admin/user-management" className="breadcrumb-text">
          User List
        </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <span className="breadcrumb-text">Add Info</span>
      </div>
      <div className="categorylist-container"></div>
      <form className="admin-user-form">
      <h5 className='text-heading1'>User Information</h5>
        <div className="form-row">
          <div className="form-group">
            <label className="required">Username</label>
            <InputComponent
              placeholder="Enter Username"
              value={formData.username}
              onChange={(e) => handleInputChange('username', e.target.value)}
            />
          </div>

          <div className="form-group">
            <label className="required">Email</label>
            <InputComponent
              type="email"
              placeholder="Enter Email"
              value={formData.email}
              onChange={(e) => handleInputChange('email', e.target.value)}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label className="required">Mobile Number </label>
            <InputComponent
              type="tel"
              placeholder="Enter Mobile Number"
              value={formData.mobile_number}
              onChange={(e) => handleInputChange('mobile_number', e.target.value)}
            />
          </div>

          <div className="form-group">
            <label>DOB</label>
            <Datepicker
              className={"date-input"}
              dateValue={formData.dob}
               placeholder="Enter DOB"
              onDateChange={(date) => handleInputChange('dob', date)}

            />
          </div>
        </div>

        <div className="form-row1">
  <div className="form-group">
    <label className="required">Gender</label>
    <DropdownButton
      label={formData.gender || "Select Gender"}
      options={["Male", "Female", "Other"]}
      onSelect={(selectedOption) => handleInputChange('gender', selectedOption)}
    />
  </div>
</div>


        {errorMessage && <div className="error-message">{errorMessage}</div>}

        <div className="action-buttons">
          <ButtonComponent
            text={isSaving?"Save":"Saving..."}
            onClick={handleSubmit}
            disabled={!isModified || !isFormValid()}
            className={'submit-button'}
          />
        </div>
      </form>
    </div>
  );
};

export default AdminUser;
