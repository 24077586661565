import React from "react";
import "./privacy.scss";

const PrivacyPolicy = () => {
  return (
    <div className="policy-container">
      <div className="header">
        <h1>Privacy Policy</h1>
      </div>
      <div className="template-box">
        <div className="content-box">
          <h2>Privacy Policy</h2>
          <p className="effective-date">Last Updated: Feburary 28, 2025</p>
          <p>Your privacy is important to us. This policy outlines how we collect, use, and protect your information.</p>
          <h3>Information We Collect</h3>
          <p>We collect personal details including your name, email, phone number, and prescription details where applicable.</p>
          <p>We do not store or process payment details directly; transactions are managed securely by third-party payment processors.</p>
          <h3>How We Use Your Data</h3>
          <ul>
            <li>To process and deliver pharmaceutical products.</li>
            <li>To communicate order updates and respond to inquiries.</li>
            <li>To comply with legal and regulatory obligations.</li>
          </ul>
          <h3>Data Sharing</h3>
          <p>We do not sell your personal data. Information may be shared with regulatory authorities and logistics partners as required.</p>
          <h3>Security Measures</h3>
          <p>We follow industry best practices to protect your personal information from unauthorized access and misuse.</p>
          <h3>Cookies</h3>
          <p>We use cookies for essential site functions and analytics. You can manage cookie preferences in your browser settings.</p>
          <h3>Account Deletion</h3>
          <p>To request account deletion, please contact us at support@pharma.com. Data will be removed within 7 working days unless required for compliance.</p>
          <p>For any privacy concerns, reach us at:</p>
          <ul className="contact-list">
            <li><strong>Email:</strong> pmpharama.in@gmail.com</li>
            <li><strong>Phone:</strong> 8825745035</li>
          </ul>
        </div>
        <div className="contact-box">
          <h2>Need Help?</h2>
          <p>For any queries, reach out to our customer support team.</p>
          <p className="contact-number">8825745035</p>
          <p className="contact-email">pmpharma.in@gmail.com</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
