import { user_mananagement_endpoint } from '../endPoints';
import { toast } from 'react-hot-toast';
import apiClient from '../apiClient';

export const fetchUserFromAPI = async (token) => {
    //console.log("in fetch user")
    const response = await apiClient.get(user_mananagement_endpoint.GET_BULK_PERSONAL_INFO,
        {
          headers: {
            Authorization:token,

          }
        },    
      ); 
      //console.log("discount response",response)// Assuming categories are a part of the products API
      if (!response.data) {
        throw new Error('Failed to fetch categories');
      }
      return await response.data.users;
};
export const fetchSingleUserFromAPI = async (user_id,token) => {
  
  const response = await apiClient.get(user_mananagement_endpoint.GET_PERSONAL_INFO,
      {
        params:{user_id:user_id},
        headers: {
          Authorization:token,

        }
      },    
    ); 
    //console.log("discount response",response)// Assuming categories are a part of the products API
    if (!response.data) {
      throw new Error('Failed to fetch user');
    }
    return await response.data;
};
// Create a new user
export const createNewUserAPI = async (newUser,token) => {
  //console.log(newUser,'-----------newUser');
    const response = await apiClient.post(
        user_mananagement_endpoint.GET_PERSONAL_INFO, 
        newUser,
        {
          headers: {
           
            Authorization: token,
  
          },
        }
      );
  
      if (!response.data) {
        throw new Error(`Failed to delete category with ID: ${newUser}`);
      }
  
      //console.log('Category deleted successfully', response.data);
      return response.data; // Optionally return the deleted category data
    
};

// Delete a user
export const deleteUserAPI = async (userId, token) => {
  try {
    //console.log('Deleting user with ID:', userId);
    //console.log('API URL:', user_mananagement_endpoint.GET_PERSONAL_INFO);

    const response = await apiClient.delete(user_mananagement_endpoint.GET_PERSONAL_INFO, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      params: {
        user_id: userId, // Ensure this matches the backend's expected parameter
      },
    });

    if (!response || !response.data) {
      throw new Error(`Failed to delete user with ID: ${userId}`);
    }

    //console.log('Delete user response:', response.data);
    return response.data;
  } catch (error) {
    // console.error('Error deleting user:', error.message || error.response?.data);
    throw error;
  }
};


// Update a user by ID
export const updateUserInAPI = async (userId, updatedData,token) => {
  //console.log("-------update",updatedData)
    const response = await apiClient.put(                                             
        user_mananagement_endpoint.GET_PERSONAL_INFO, 
        updatedData,
        {
          headers: {
           
            Authorization:token,
  
          },
        }
      );
  
    
    
      return response.data; // Optionally return the deleted category data
    
};
export const createNewBulkUserAPI = async (newUser,token) => {
  const response = await apiClient.post(
      user_mananagement_endpoint.GET_BULK_PERSONAL_INFO, 
       newUser,                                 
      {
        headers: {
         
          Authorization: token,

        },
      }
    );

    if (!response.data) {
      throw new Error(`Failed to delete category with ID: ${newUser}`);
    }

    //console.log('users created successfully', response.data);
    return response.data; // Optionally return the deleted category data
  
};

// Delete a user
export const deletBulkUserAPI = async (userId,token) => {
  //console.log('delete', userId);
  const response = await apiClient.delete(
      user_mananagement_endpoint.GET_BULK_PERSONAL_INFO, // The endpoint for category deletion
      {
        headers: {
          'Content-Type': 'application/json', // Set the correct Content-Type
          Authorization:  token,

        },
        data:userId
      }
    );

  // Check for a successful response
  if (!response.data) {
      throw new Error(`Failed to delete user with ID: ${userId}`);
  }

  return await response.data; // Optionally return the deleted user data
};

// Update a user by ID
export const updateBulkUserInAPI = async (updatedData,token) => {
  //console.log(updatedData,'---------update')
  const response = await apiClient.put(                                             
      user_mananagement_endpoint.GET_BULK_PERSONAL_INFO, 
      updatedData,
      {
        headers: {
         
          Authorization:token,

        },
      }
    );
    //console.log(response,'response');
    if (!response.data) {
      throw new Error(`Failed to delete category with ID: ${updatedData}`);
    }

  
    return response.data; // Optionally return the deleted category data
  
};
