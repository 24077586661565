import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import InputComponent from "../../components/common/InputComponent/InputComponent";
import DropdownComponent from "../../components/common/dropdown/DropdownButton";
import ButtonComponent from "../../components/common/buttons/ButtonComponent";
import DatePickerComponent from "../../components/common/datepicker/datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport, faFileExport, faStar } from '@fortawesome/free-solid-svg-icons';
import "./new_manual_stock_list.scss";

const NewManualStockList = () => {
    const [selectedDate, setSelectedDate] = useState(new Date()); // State to manage the selected date
    const [tableData, setTableData] = useState([
        { id: 1, favorite: false, category: "Skin care", rawMaterial: "Lotion", existingStock: "10 Kg" },
        { id: 2, favorite: false, category: "Hair care", rawMaterial: "Shampoo", existingStock: "10 Kg" },
    ]);

    const exportOptions = [
        { label: 'Export to PDF' },
        { label: 'Export to CSV' },
    ];

    // Toggle favorite status
    const toggleFavorite = (id) => {
        const updatedData = tableData.map(row => {
            if (row.id === id) {
                return { ...row, favorite: !row.favorite };
            }
            return row;
        });
        setTableData(updatedData);
    };

    const navigate = useNavigate(); // Use navigate hook for navigation

    // Function to handle navigation when the button is clicked
    const handleAddClosingStock = () => {
        navigate('/admin/closingstock'); // Navigate to /admin/closingstock
    };

    return (
        <div className="new-manual-stock-list-container">
            <div className="new-manual-stock-list">
                {/* Header Section */}
                <div className="header-section">
                    <ButtonComponent 
                        text="+ Add Closing Stock" 
                        variant="danger" 
                        className="add-button" 
                        onClick={handleAddClosingStock} // Trigger navigation on click
                    />
                    <DropdownComponent
                        label={<><FontAwesomeIcon icon={faFileImport} className="export-icon" /> Import</>}
                        className="btn-dropdown"
                        options={exportOptions}
                    />
                    <DropdownComponent
                        label={<><FontAwesomeIcon icon={faFileExport} className="export-icon" /> Export</>}
                        className="btn-dropdown"
                        options={exportOptions}
                    />
                </div>

                <hr className="hr-line" />

                {/* Filters Section */}
                <div className="filters-section">
                    <div className="filter-item">
                        <label>Raw Material</label>
                        <InputComponent />
                    </div>
                    <div className="filter-item">
                        <label>Category</label>
                        <DropdownComponent label="All" options={["All", "Vegetable", "Fruit", "Dairy"]} />
                    </div>
                    <div className="filter-item">
                        <label>Date</label>
                        <DatePickerComponent
                            className="date-picker"
                            dateValue={selectedDate}
                            onDateChange={(date) => setSelectedDate(date)} // Update state on date change
                        />
                    </div>
                    <div className="filter-item">
                        <label>Closing stock calculated on</label>
                        <DropdownComponent label="Daily" options={["Daily", "Weekly", "Monthly"]} />
                    </div>
                    <div className="filter-buttons">
                        <ButtonComponent text="Load" variant="danger" className="load-button" />
                        <ButtonComponent text="Show All" variant="secondary" className="show-all-button" />
                    </div>
                </div>

                <hr />

                {/* Table Section */}
                <div className="table-section">
                    <table>
                        <thead>
                            <tr>
                                <th>Mark As Favorite</th>
                                <th>Category</th>
                                <th>Raw Material</th>
                                <th>Existing Stock</th>
                                <th>Current Stock</th>
                                <th>Comments</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((row) => (
                                <tr key={row.id}>
                                    <td>
                                        <FontAwesomeIcon
                                            icon={faStar}
                                            className={`favorite-icon ${row.favorite ? 'favorite' : ''}`}
                                            onClick={() => toggleFavorite(row.id)}
                                        />
                                    </td>
                                    <td>{row.category}</td>
                                    <td>{row.rawMaterial}</td>
                                    <td>{row.existingStock}</td>
                                    <td>
                                        <InputComponent placeholder="Current Stock" suffix="/ Kg" />
                                    </td>
                                    <td>
                                        <InputComponent placeholder="Comments" />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Save Button */}
                <div className="save-button-class">
                    <ButtonComponent text="Save" variant="danger" className="save-button" />
                </div>
            </div>
        </div>
    );
};

export default NewManualStockList;
