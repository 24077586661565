import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import InputComponent from "../../components/common/InputComponent/InputComponent";
import ButtonComponent from "../../components/common/buttons/ButtonComponent";
import SearchableDropdown from "../../components/common/SearchableDropdown/SearchableDropdown";
import DropdownButton from "../../components/common/dropdown/DropdownButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-hot-toast";
import { bulkGetSubscriptions } from "../../redux/actions/subscriptionAction";
import { createNewExpiryAlert, updateExpiryAlertByIdAction } from "../../redux/actions/expiryAlertActions";
import "./expiry-alert.scss";
import { Link} from 'react-router-dom';
import { validate as validateUUID } from "uuid";
const ExpiryAlert = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const subscriptions = useSelector((state) => state.subscription.subscriptionList || []);
  const productList = useSelector((state) => state.products.basicProducts || []);
  const alertId = location.state || null; // If editing, get the existing alert ID
  const token = useSelector((state) => state.auth.token);
    const [loading, setLoading] = useState(false);
   const expiryAlerts = useSelector((state) => state.expiryAlert.allExpiryAlerts || []);
  const [formData, setFormData] = useState({
    alert_id: "",
    product_id: "",
    subscription_id: "",
    alert_count: "",
    alert_interval: "",
    alert_status: "",
  });

  const [errors, setErrors] = useState({});
  const [originalData, setOriginalData] = useState({});
  const [isModified, setIsModified] = useState(false);

  const productOptions = productList.map((product) => ({
    label: product.product_name,
    id: product.product_id,
  }));

  const subscriptionOptions = subscriptions.map((subscription) => ({
    label: subscription.subscription_id,
    id: subscription.subscription_id,
  }));

  const statusOptions = ["delivered", "read"];

  const validateForm = () => {
    const validationErrors = {};
    if (!formData.product_id) validationErrors.product_id = "Product is required.";
    if (!formData.subscription_id) validationErrors.subscription_id = "Subscription ID is required.";
    if (formData.alert_count <= 0) validationErrors.alert_count = "Alert count must be a positive number.";
    if (formData.alert_interval <= 0) validationErrors.alert_interval = "Alert interval must be a positive number.";
    if (!formData.alert_status) validationErrors.alert_status = "Alert status is required.";
    return validationErrors;
  };

  useEffect(() => {
    // Fetch subscriptions on component mount
    dispatch(bulkGetSubscriptions(token)); // Pass token if required by the action
  }, [dispatch, token]);

  useEffect(() => {
    if (alertId) {
      const alertData = expiryAlerts.find((alert) => alert.alert_id === alertId);
      if (alertData) {
        setFormData({
          alert_id: alertData.alert_id || "",
         
          subscription_id: alertData.subscription_id || "",
          alert_count: alertData.alert_count || "",
          alert_interval: alertData.alert_interval || "",
          alert_status: alertData.alert_status || "",
        });
        setOriginalData(alertData);
      }
    }
  }, [alertId,  expiryAlerts]);

  useEffect(() => {
    setIsModified(JSON.stringify(formData) !== JSON.stringify(originalData));
  }, [formData, originalData]);

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: field === "subscription_id" && validateUUID(value) ? value : prev[field],
      [field]: field === "alert_count" || field === "alert_interval" ? parseInt(value, 10) || 0 : value,
    }));
  };
  
  const handleSubmit = async () => {
    if (!validateUUID(formData.subscription_id)) {
      toast.error("Invalid Subscription ID format.");
      return;
    }
  //console.log("alerttttttt",formData)
    try {
      if (alertId) {
        //console.log("updateeeeee",formData)
        await dispatch(updateExpiryAlertByIdAction(alertId, formData, token));
        toast.success("Expiry alert updated successfully.");
      } else {
        await dispatch(createNewExpiryAlert(formData, token));
        toast.success("Expiry alert created successfully.");
      }
      navigate("/admin/expiry-alert-displaypage");
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to save expiry alert.");
    }
  };
  
  return (
    <div className="expiry-alert-page-section">
      <div className="breadcrumb-container">
        <Link to="/admin/dashboard" className="breadcrumb-text">
               <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
               </Link>
        <span className="breadcrumb-separator">{">"}</span>
          <Link to="/admin/expiry-alert-displaypage" className="breadcrumb-text">
              Expirt Alert List
          </Link>
          <span className="breadcrumb-separator">{">"}</span>
        <span className="breadcrumb-text">Expiry Alert Management</span>
      </div>
      <div className="expiry-alert-container"></div>
      <div className="expiry-alert-container-class">
        <h5 className="text-heading1">Manage Expiry Alerts</h5>

        <form onSubmit={handleSubmit}>
          <div className="form-row">
           
            <div className="form-group">
              <label>Subscription ID</label>
              <SearchableDropdown
                placeholder="Select a Subscription"
                options={subscriptionOptions}
                value={subscriptionOptions.find((option) => option.id === formData.subscription_id)?.label || ""}
                onSelect={(value) => handleInputChange("subscription_id", value)}
              />
              {errors.subscription_id && <span className="error-text">{errors.subscription_id}</span>}
            </div>
         
            <div className="form-group">
              <label>Alert Count</label>
              <InputComponent
                type="number"
                placeholder="Enter alert count"
                value={formData.alert_count}
                onChange={(e) => handleInputChange("alert_count", e.target.value)}
              />
              {errors.alert_count && <span className="error-text">{errors.alert_count}</span>}
            </div>
            </div>

              <div className="form-row">
            <div className="form-group">
              <label>Alert Interval</label>
              <InputComponent
                placeholder="Enter alert interval (e.g., days)"
                value={formData.alert_interval}
                type="number"
                onChange={(e) => handleInputChange("alert_interval", e.target.value)}
              />
              {errors.alert_interval && <span className="error-text">{errors.alert_interval}</span>}
            </div>
          
            <div className="form-group">
              <label>Alert Status</label>
              <DropdownButton
                placeholder="Select Status"
                options={statusOptions}
                label={formData.alert_status || "Select Status"}
                onSelect={(value) => handleInputChange("alert_status", value)}
              />
              {errors.alert_status && <span className="error-text">{errors.alert_status}</span>}
            </div>
          </div>

          <div className="form-row save-button-container">
            
            <ButtonComponent
             text={loading ? 'Saving...' : 'Save'}
             
              className='submit-button'
              disabled={!isModified || loading}
              onClick={handleSubmit}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ExpiryAlert;
