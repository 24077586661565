// services/documentService.js

import { document_endpoints } from '../endPoints';
import apiClient from '../apiClient';
import { toast } from 'react-hot-toast';

// Upload Document
export const uploadDocumentAPI = async (file,path, token) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('folder_name',path)

  //console.log("Uploading document:", file.name);

  try {
    const response = await apiClient.post(
      document_endpoints.UPLOAD_DOCUMENT,
      formData,
      {
        headers: {
          Authorization: token,
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    if (!response.data || response.status !== 200) {
      throw new Error('Failed to upload document');
    }

    //console.log('Document uploaded successfully', response.data);
    return response.data;
  } catch (error) {
    // console.error("Error uploading document:", error);
    // toast.error(error.response?.data?.error || "An error occurred while uploading the document.");
    throw error;
  }
};

// Generate Pre-Signed URL
export const generatePresignedUrlAPI = async (fileUrl, token) => {
  //console.log("Generating presigned URL for:", fileUrl);

  try {
    const response = await apiClient.get(
      document_endpoints.GENERATE_PRESIGNED_URL,
      {
        headers: {
          Authorization: token,
        },
        params: {
          file_url: fileUrl,
        },
      }
    );

    if (!response.data || response.status !== 200) {
      throw new Error('Failed to generate presigned URL');
    }

    //console.log('Pre-signed URL generated successfully', response.data);
    return response.data;
  } catch (error) {
    // console.error("Error generating presigned URL:", error);
    // toast.error(error.response?.data?.error || "An error occurred while generating the pre-signed URL.");
    throw error;
  }
};

// Download Document
export const downloadDocumentAPI = async (fileUrl, token) => {
  //console.log("Downloading document from URL:", fileUrl);

  try {
    const response = await apiClient.get(fileUrl, {
      headers: {
        Authorization: token,
      },
      responseType: 'blob', // Indicates we're expecting binary data
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileUrl.split('/').pop()); // Extract file name from URL
    document.body.appendChild(link);
    link.click();
    link.remove();

    //console.log("Document downloaded successfully");
  } catch (error) {
    // console.error("Error downloading document:", error);
    // toast.error("An error occurred while downloading the document.");
    throw error;
  }
};

// Delete Document
export const deleteDocumentAPI = async (fileUrl, token) => {
  //console.log("Deleting document from URL:", fileUrl);

  try {
    const response = await apiClient.delete(
      document_endpoints.DELETE_DOCUMENT,
      {
        headers: {
          Authorization: token,
        },
        params: {
          file_url: fileUrl,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error('Failed to delete document');
    }

    //console.log('Document deleted successfully', response.data);
    return response.data;
  } catch (error) {
    // console.error("Error deleting document:", error);
    // toast.error(error.response?.data?.error || "An error occurred while deleting the document.");
    throw error;
  }
};
