import { fetchLocations, deleteLocation, bulkDeleteLocations } from '../../redux/actions/locationAction';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import CheckboxComponent from '../../components/common/CheckboxComponent/CheckboxComponent';
import AddItemsAdminModal from '../../components/sections/AddItems-adminModal/AddItems-adminModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPencilAlt,faTrash } from '@fortawesome/free-solid-svg-icons';
import AdminCategoryOffcanvas from '../../components/common/AdminCategoryOffcanvas/AdminCategoryOffcanvas';
import './Locationservice.scss';
import {  faHome } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const LocationDisplayPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigationOptions = {
    gridPath: '/admin/add-location',
    sheetPath: '/admin/uploadpage',
    from: 'location',
  };

  const token = useSelector(state => state.adminLogin.token);
  const locations = useSelector(state => state.location.locations) || [];

  useEffect(() => {
    const fetchInitialLocations = () => {
      dispatch(fetchLocations(token));
    };

    fetchInitialLocations();
    const intervalId = setInterval(fetchInitialLocations, 180000);

    return () => clearInterval(intervalId);
  }, [dispatch]);

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  const handleOffcanvasClose = () => setShowOffcanvas(false);

  const filteredLocations = locations.filter(location =>
    location.location_name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleActionSelect = (action) => {
    if (selectedIds.length === 0) {
      alert("Please select at least one location to proceed.");
      return;
    }

    if (action === "delete") {
      if (window.confirm(`Are you sure you want to delete the selected locations?`)) {
        dispatch(bulkDeleteLocations(selectedIds, token));
        setSelectedIds([]);
      }
    } else if (action === "edit") {
      navigate('/admin/bulk-location', { state: selectedIds });
      setSelectedIds([]);
    }
  };

  const handleDelete = async (locationId) => {
    if (window.confirm('Are you sure you want to delete this location?')) {
      try {
        dispatch(deleteLocation(locationId, token));
      } catch (error) {
        // console.error(error);
      }
    }
  };

  const handleCheckboxChange = (locationId) => {
    if (selectedIds.includes(locationId)) {
      setSelectedIds(prev => prev.filter(id => id !== locationId));
    } else {
      setSelectedIds(prev => [...prev, locationId]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedIds([]);
    } else {
      const allIds = locations.map(item => item.location_id);
      setSelectedIds(allIds);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    setSelectAll(selectedIds.length === locations.length);
  }, [selectedIds, locations.length]);

  return (
    <div>
      <div className="admin-location-page-layout">
      <div className="breadcrumb-container">
         
         <Link to="/admin/dashboard" className="breadcrumb-text">
         <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
         </Link>
         <span className="breadcrumb-separator">{'>'}</span>
         <span className="breadcrumb-text">Location Service</span>
       </div>
       <div className="location-container"></div>
        <div className="location-page-container">
          <div className="right-section">
          <h5 className='text-heading1'>Location Service</h5>
            <div className="toolbar">
              
              <div className="search-wrapper">
                <FontAwesomeIcon icon={faSearch} className="icon" />
                <input
                  type="text"
                  className="search-bar"
                  placeholder="Search by location name"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className="item-controls">
                <ButtonComponent text="+ Add Location" variant="dark" className="add-items-button" onClick={handleModalShow} />
                <Dropdown>
                  <Dropdown.Toggle variant="primary" className="dropdown-button" id="actions-dropdown">
                    Actions
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleActionSelect("edit")}>Edit</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleActionSelect("delete")}>Delete</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <div className="table-container">
              {filteredLocations.length === 0 ? (
                <div className="no-locations-message">No locations found.</div>
              ) : (
                <table className="custom-table">
                  <thead>
                    <tr>
                      <th><CheckboxComponent checked={selectAll} onChange={handleSelectAll} /></th>
                      <th>Location ID</th>
                      <th>Location Name</th>
                      <th>Serviceable</th>
                      <th>Base Charge</th>
                      <th>Additional Charge per KM</th>
                      <th>Actions</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredLocations.map((location, index) => (
                      <tr key={`${index}-${location.location_id}`}>
                        <td>
                          <CheckboxComponent
                            checked={selectedIds.includes(location.location_id)}
                            onChange={() => handleCheckboxChange(location.location_id)}
                          />
                        </td>
                        <td>{index + 1}</td>
                        <td>{location.location_name}</td>
                        <td>{location.serviceable ? 'Yes' : 'No'}</td>
                        <td>{location.base_charge}</td>
                        <td>{location.additional_charge_per_km}</td>
                        <td className="actions-column">
                          <button
                            className="icon-button"
                            onClick={() => navigate('/admin/add-location', { state: location.location_id })}
                          >
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </button>
                        </td>
                        <td className="actions-column">
                          <button
                            className="icon-button"
                            onClick={() => handleDelete(location.location_id)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>

            <AddItemsAdminModal show={showModal} handleClose={handleModalClose} navigationOptions={navigationOptions} />
            {/* <div className="showing-records-pagination">
              <div className="showing-records">
                <p>Showing {1} to {filteredLocations.length} of {filteredLocations.length} records</p>
              </div>
              <div className="pagination-section">
                <Pagination
                  currentPage={1}
                  totalPages={1} // Adjust based on your pagination logic
                  onPageChange={() => {}}
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <AdminCategoryOffcanvas show={showOffcanvas} onClose={handleOffcanvasClose} />
    </div>
  );
};

export default LocationDisplayPage;
