import React, { useState ,useEffect,useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import SearchBar from './SearchBar/SearchBar';
import IconsGroup from './IconsGroup/IconsGroup';
import pharmaLogo from '../../../assets/images/pharma.png';
import PersonalDetailOffcanvas from '../../common/PersonalDetailOffcanvas/PersonalDetailOffcanvas'; 
import BottomNavigation from '../BottomNavigation/BottomNavigation'; // Import BottomNavigation component
import './PrimaryNavbar.scss';
import { logoutAction } from '../../../redux/actions/adminRegisterActions';
import { useDispatch,useSelector } from 'react-redux';
// Bootstrap imports
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { fetchSingleUser, updateUser } from "../../../redux/actions/adminuserActions";
import { deleteAddress, fetchBulkAddresses } from "../../../redux/actions/AddressAction";

const PrimaryNavbar = () => {
  const navigate = useNavigate(); // Hook to handle navigation
  const location = useLocation(); // Hook to get the current location
  const dispatch = useDispatch();
  const [showAccountSection, setShowAccountSection] = useState(false); // State to control the visibility of the account section
  const [showOffcanvas, setShowOffcanvas] = useState(false); // State to control the visibility of the offcanvas
  const isLoggedIn = useSelector((state) => state.auth.user);
  const [showProfileMenu, setShowProfileMenu] = useState(false); // State to toggle profile menu visibility

  const handleNavigation = (path) => {
    navigate(path);
    setShowAccountSection(false);
  
    // Close the offcanvas manually
  
  };
  
   const [form, setForm] = useState({
      username: "",
      mobile_number: "",
      addresses: [],
    });
    const handleProfileIconClick = () => {
      setShowProfileMenu((prev) => !prev); // Toggle the profile menu on icon click
    };
    
   
    
    const handleProfileNavigation = () => {
      setShowProfileMenu(false); // Close the menu
      navigate("/admin/profile"); // Navigate to profile page
    };
    
   const token = useSelector((state) => state.auth.token);
    const userId = useSelector((state) => state.auth.user);
    const users = useSelector((state) => state.usermanage.users) || [];


    useEffect(() => {
        if (userId) {
          dispatch(fetchSingleUser(userId, token));
          dispatch(fetchBulkAddresses(userId, token));
        }
      }, [dispatch, token, userId]);
      useEffect(() => {
        // console.log('Users Effect Triggered:', { users });
      
        if (users.length > 0) {
          const reduxUser = users[0];
          setForm((prevForm) => {
            // Only update if there's a change in the values
            if (
              prevForm.username !== reduxUser.username ||
              prevForm.mobile_number !== reduxUser.mobile_number
            ) {
              // console.log('Updating Form State:', { reduxUser, prevForm });
              return {
                username: reduxUser.username || "",
                mobile_number: reduxUser.mobile_number || "",
              };
            }
            return prevForm;
          });
        }
      }, [users]); // Only runs when `users` changes
      
      
  const handleAccountClick = () => {
    setShowAccountSection(true); // Show the account section when the "Account" button is clicked
  };

  const handleEditClick = (e) => {
    e.preventDefault(); // Prevent default action
    setShowOffcanvas(true); // Show the offcanvas when "Edit" is clicked
  };

  const handleOffcanvasClose = () => {
    setShowOffcanvas(false); // Close the offcanvas
  };

  const handleLogout =()=>{
    dispatch(logoutAction());
    navigate('/admin/login');
  }
  const hiddenLogoutRoutes = ['/admin/login', '/admin/register'];
  const hiddenRoutes = [
    '/admin',
    '/admin/add-category',
    '/admin/add-brand',
    '/basic-info-details',
    '/admin/add-coupon',
    '/admin/offer',
    '/admin/offer-allocation',
    '/admin/add-offer-allocation',
    '/admin/coupon/allocate',
    '/admin/coupon/add-allocate',
    '/admin/request_for_purchase_list',
    '/admin/add-request-for-purchase',
    '/admin/purchase-list',
    '/admin/add-purchase',
    '/admin/supplier',
    '/admin/add-supplier',
    '/admin/bulk-products',
    '/admin/bulk-category',
    '/admin/bulk-brand',
    '/admin/bulk-coupon',
    '/admin/bulk-offer',
    '/admin/bulk-allocate-offer',
    '/admin/dashboard',
    '/admin/uploadpage',
    '/admin/business_information',
    '/admin/personal_information',
    '/admin/banner',
    '/admin/banners',
    '/admin/login',
    '/admin/order-status-management',
    '/admin/order-management',
    '/admin/bulk-location',
    '/admin/add-location',
    '/admin/location',
    '/admin/profile',
    '/admin/user-management',
    '/admin/add-user',
    '/admin/products',
    '/admin/bulk-supplier',
    '/delivery/add-delivery-person',
    '/admin/add-banner',
    '/basic-info-prodcuts',
    '/custom-details',
    '/admin/bulk-user',
    '/admin/bulk-delivery',
    '/admin/delivery-config',
    '/admin/delivery-config-displaypage',
    '/admin/expiry-alert',
    '/admin/expiry-alert-displaypage',
    '/admin/subscription-displaypage',
    '/admin/add-subscription',
    '/admin/subscription-management',
    '/admin/add-notification-creation',
    '/admin/notification-creation-displaypage',
    '/admin/order-export-table',
    '/admin/inventory-displaypage',
   '/admin/inventory-threshold',
   '/admin/bulk-allocate-coupon'
  ];
  return (
    <nav className="navbar primaryNavbar">
      <div className="container-fluid primaryNavbar-container">
       
        <div className="primaryNavbar-class">
     
      
          <div
            className="primaryNavbarLogo"
            onClick={() => handleNavigation('/')}
          >
            <img
              src={pharmaLogo}
              alt="Pharma"
              className="img-fluid primaryNavbar-pharma-logo"
            />
          </div>
        </div>
 {/* Conditionally render SearchBar and IconsGroup */}
 {!hiddenRoutes.includes(location.pathname) ? (
  <>
    <SearchBar />
    <IconsGroup />
  </>
) : (
  // Render only the Logout text and Profile icon for specified routes
  <div className="iconsGroup">
  <FontAwesomeIcon
    icon={faUser}
    className="iconsGroupProfileIcon"
    size={24}
    onClick={handleProfileIconClick} // Toggle the dropdown on click
  />
  {showProfileMenu && (
    <div className="profile-menu">
      <div
        className="profile-menu-item"
        onClick={handleProfileNavigation} // Navigate to Profile page
      >
        Profile
      </div>
      <div
        className="profile-menu-item"
        onClick={handleLogout} // Handle Logout functionality
      >
        Logout
      </div>
    </div>
  )}
</div>



)}

      </div>
      {/* Main Offcanvas */}
      
      <div
        className="offcanvas offcanvas-bottom"
        tabIndex="-1"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasNavbarLabel">Menu</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          {!showAccountSection ? (
            <div>
              <div className="user-details-section">
                <div className="user-text">
                  <div className="user-name">{form.username || "User Name"}</div>
                  <div className="user-number">{form.mobile_number || "Phone Number"}</div>
                  <a href="/" className="user-details">
                    Add more user details &gt;
                  </a>
                </div>
                <a
                  href="/"
                  className="user-edit"
                  onClick={handleEditClick}
                >
                  Edit
                </a>
              </div>
              <div className="bottom-section">
                <div
                  className="bottom-item"
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavigation('/orders');
                  }}
                >
                  <div className="item-text">My Orders</div>
                  <i className="arrow-icon fas fa-chevron-right"></i>
                </div>
                <div
                  className="bottom-item"
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavigation('/myprofile');
                  }}
                >
                  <div className="item-text">Profile</div>
                  <i className="arrow-icon fas fa-chevron-right"></i>
                </div>
             
              </div>
            </div>
          ) : (
            <div>
              <div className="user-details-section">
                <div className="user-text">
                  <div className="user-name">{form.username || "User Name"}</div>
                  <div className="user-number">{form.mobile_number || "Phone Number"}</div>
                  <a href="/" className="user-details">
                    Add more user details &gt;
                  </a>
                </div>
                <a
                  href="/"
                  className="user-edit"
                  onClick={handleEditClick}
                >
                  Edit
                </a>
              </div>
              <div className="bottom-section">
                <div
                  className="bottom-item"
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavigation('/orders');
                  }}
                >
                  <i className="fas fa-box icon-left"></i>
                  <div className="item-text">My Orders</div>
                  <i className="arrow-icon fas fa-chevron-right"></i>
                </div>
                <div
                  className="bottom-item"
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavigation('/myprofile');
                  }}
                >
                  <i className="fas fa-map-marker-alt icon-left"></i>
                  <div className="item-text">Manage Addresses</div>
                  <i className="arrow-icon fas fa-chevron-right"></i>
                </div>
                <div
                  className="bottom-item"
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavigation('/refer');
                  }}
                >
                  <i className="fas fa-rupee-sign icon-left"></i>
                  <div className="item-text">Refer & earn</div>
                  <i className="arrow-icon fas fa-chevron-right"></i>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <PersonalDetailOffcanvas show={showOffcanvas} onClose={handleOffcanvasClose} />
      {!hiddenRoutes.includes(location.pathname) && (
      <BottomNavigation
        handleNavigation={handleNavigation}
        handleAccountClick={handleAccountClick}
        showAccountSection={showAccountSection}
      />
      )}
    </nav>
  );
};

export default PrimaryNavbar;
