import React, { useState, useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import { faTrash, faPencilAlt, faHome } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import DropdownButton from '../../components/common/dropdown/DropdownButton';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import Datepicker from '../../components/common/datepicker/datepicker';
import CheckboxComponent from '../../components/common/CheckboxComponent/CheckboxComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBulkStock,
  getBulkStockItems,
  deleteBulkStockItems,
  deleteStock
} from '../../redux/actions/stockpurchaseActions';
import './PurchaseList.scss';

const StockManagement = () => {
  const token = useSelector((state) => state.adminLogin.token) || [];
  const dispatch = useDispatch();

  // Fetch stock and stock items on component mount
  useEffect(() => {
    dispatch(getBulkStock(token));
    dispatch(getBulkStockItems(token));
  }, [dispatch]);

  const stocks = useSelector((state) => state.stock.stocks || []);
  const stockItems = useSelector((state) => state.stock.stockItems || []);
  const suppliers = useSelector((state) => state.supplier.suppliers || []);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState('All');
  const [selectAll, setSelectAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [filteredStocks, setFilteredStocks] = useState(stocks);

  const navigate = useNavigate();
  const paymentOptions = ['All', 'paid', 'unpaid'];

  // Handle Payment Filter
  const handlePaymentSelect = (option) => setSelectedPayment(option);

  // Handle Checkbox Selection for Individual Rows
  const handleCheckboxChange = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds((prev) => prev.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds((prev) => [...prev, id]);
    }
  };
  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedIds([]);
    } else {
      setSelectedIds(filteredStocks.map((item) => item.stock_id));
    }
    setSelectAll(!selectAll);
  };
    useEffect(() => {
          if (selectedIds.length === filteredStocks.length) {
            setSelectAll(true);
          } else {
            setSelectAll(false);
          }
        }, [selectedIds,filteredStocks.length]);


  const handleDeleteClick = async (id) => {
    const associatedPurchaseItemIds = stockItems
          .filter(item => item.stock_id === id)
          .map(item => item.stock_item_id);
    dispatch(deleteStock(id, token))

    if (associatedPurchaseItemIds.length > 0) {
      await dispatch(deleteBulkStockItems(associatedPurchaseItemIds, token));
    }
  };
  
  // Navigate to Edit Page for Individual Stock
  const handleEditClick = (id) => {
    navigate(`/admin/add-purchase`, { state: id });
  };

  // Bulk Delete Functionality
  const handleBulkDeleteClick = async () => {
    if (selectedIds.length === 0) {
      alert('Please select at least one stock entry to delete.');
      return;
    }

    if (!window.confirm('Are you sure you want to delete the selected stock entries?')) {
      return;
    }

    try {
      for (const stockId of selectedIds) {
        // Delete associated stock items
        const associatedItemIds = stockItems
          .filter((item) => item.stock_id === stockId)
          .map((item) => item.stock_item_id);

        if (associatedItemIds.length > 0) {
          await dispatch(deleteBulkStockItems(associatedItemIds, token));
        }

        // Delete the stock
        await dispatch(deleteStock(stockId, token));
      }

      // Refresh data
      dispatch(getBulkStock(token));
      dispatch(getBulkStockItems(token));
     
      setSelectedIds([]); // Clear selection
    } catch (error) {
      console.error('Error during bulk deletion:', error);
      alert('An error occurred while deleting the selected stock entries. Please try again.');
    }
  };

  // Filter Stocks by Date and Payment
  const handleSearch = () => {
    const filtered = stocks.filter((stock) => {
      const matchesStartDate = !startDate || new Date(stock.invoice_date) >= new Date(startDate);
      const matchesEndDate = !endDate || new Date(stock.invoice_date) <= new Date(endDate);
      const matchesPayment = selectedPayment === 'All' || stock.payment_status === selectedPayment;
      return matchesStartDate && matchesEndDate && matchesPayment;
    });
    setFilteredStocks(filtered);
  };

  // Update Filtered Stocks when `stocks` changes
  useEffect(() => {
    setFilteredStocks(stocks);
  }, [stocks]);

  return (
    <div className="stock-management-page">
      <div className="breadcrumb-container">
        <Link to="/admin/dashboard" className="breadcrumb-text">
          <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <Link to="/admin" className="breadcrumb-text">
          Stock Purchase List
        </Link>
      </div>
      <div className="purchase-list-container"></div>
      <div className="purchase-management-container">
        <div className="stock-management-container">
          <div className="header-row">
            <h5 className="text-heading1">Stock Purchase List</h5>
            <div className="top-right-buttons">
              <ButtonComponent
                text={<><FaPlus className="add-icon" /> Add New Stock</>}
                className="add-purchase-button1"
                onClick={() => navigate('/admin/add-purchase')}
              />
              
            </div>
          </div>
        </div>

        <hr />

        <div className="filter-section">
          <div className="filter-item">
            <label>Start Date</label>
            <Datepicker
              className="date-input"
              dateValue={startDate}
              onDateChange={setStartDate}
              placeholder="Select Start Date"
            />
          </div>
          <div className="filter-item">
            <label>End Date</label>
            <Datepicker
              className="date-input"
              dateValue={endDate}
              onDateChange={setEndDate}
              placeholder="Select End Date"
            />
          </div>

          <div className="filter-item">
            <label>Payment</label>
            <DropdownButton
              options={paymentOptions}
              label={selectedPayment}
              onSelect={handlePaymentSelect}
            />
          </div>

          <div className="action-buttons">
            <ButtonComponent text="Search" className="search-button" onClick={handleSearch} />
            <ButtonComponent text="Show All" className="show-all-button" onClick={() => setFilteredStocks(stocks)} />
            <ButtonComponent
                text={<><FontAwesomeIcon icon={faTrash} /> Bulk Delete</>}
                className="bulk-delete-button"
                onClick={handleBulkDeleteClick}
                disabled={selectedIds.length === 0}
              />
          </div>
        </div>

        <hr className="hr-line" />

        <div className="table-container">
          <table className="custom-table">
            <thead>
              <tr>
                <th><CheckboxComponent checked={selectAll} onChange={handleSelectAllChange} /></th>
                <th>PO No.</th>
                <th>Supplier</th>
                <th>Invoice Date</th>
                <th>Payment Status</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {filteredStocks.map((item) => (
                <tr key={item.stock_id}>
                  <td>
                    <CheckboxComponent
                      checked={selectedIds.includes(item.stock_id)}
                      onChange={() => handleCheckboxChange(item.stock_id)}
                    />
                  </td>
                  <td>{item.po_number}</td>
                  <td>{suppliers.find((s) => s.supplier_id === item.supplier_id)?.name || ''}</td>
                  <td>{item.invoice_date}</td>
                  <td>{item.payment_status}</td>
                  <td>
                    <button className="icon-button" onClick={() => handleEditClick(item.stock_id)}>
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </button>
                  </td>
                  <td>
                    <button className="icon-button" onClick={() => handleDeleteClick(item.stock_id)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default StockManagement;
