import React from 'react';
import './ProductCard.scss';
import aloe from '../../../assets/images/Noproduct.webp';
import QuantityInput from '../QuantityInput/QuantityInput';

const ProductCard = ({ 
  onClick, 
  productName, 
  mrp, 
  discount, 
  price, 
  quantity, 
  onAddToCart, 
  onIncrease, 
  onDecrease, 
  onRemove, 
  image_url, 
  min_order, 
  stock_level,
  description
}) => {
  const isOutOfStock = stock_level < min_order;

  return (
    <div className={`products product-card ${isOutOfStock ? 'out-of-stock-card' : ''}`} >
    
      {discount && <div className="products discount-badge">{discount}</div>}

     
      <div className={`product-image-container ${isOutOfStock ? 'out-of-stock' : ''}`}>
      <img
  src={image_url ? image_url : aloe}
  alt={productName}
  className="product-image"
  onClick={onClick}
  onError={(e) => { e.target.src = aloe; }}
/>

      </div>

      {/* Product Details */}
      <h6 className="products product-name" title={productName}>{productName}</h6>

      {/* <p className="products product-description">{description}</p> */}
      <hr />

      {/* Price and Quantity Controls */}
      <div className="products product-info">
        <div className="products product-price-info">
          <span className="products product-mrp">
            MRP: <span>{mrp}</span>
          </span>
          <span className="products product-price">₹{price}</span>
        </div>

        {isOutOfStock ? (
          <button className="products out-of-stock-button" disabled>
            Out of Stock
          </button>
        ) : quantity > 0 ? (
        

        <QuantityInput
            onRemove={onRemove} 
            quantity={quantity}
            onIncrease={onIncrease} 
            onDecrease={onDecrease} 
            
            min_order={min_order}
          />
    
         
        ) : (
          <button onClick={onAddToCart} className="products add-button">
            Add
          </button>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
