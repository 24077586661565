import React, { useState, useEffect } from 'react';
import InputComponent from '../../components/common/InputComponent/InputComponent';
import DropdownButton from '../../components/common/dropdown/DropdownButton';
import Textbox from '../../components/common/Textbox/Textbox';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight,faHome,faTimes } from '@fortawesome/free-solid-svg-icons';
import './BasicInfoDetailsPage.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SearchableDropdown from '../../components/common/SearchableDropdown/SearchableDropdown';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/common/HalfSideBar/halfsidebar';
import { uploadDocumentAPI, generatePresignedUrlAPI } from '../../api/services/filestorageService';
import { toast } from 'react-hot-toast';
import { fetchProducts, updateSingleProductWorkflow, createSingleProductWorkflow } from '../../redux/actions/productActions';

const DetailsTable = ({urls,onClose}) => {
  const data = useLocation().state || null;
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    brand_id: null,
    category_id: null,
    delivery_timeframe: null,
    description: "",
    dimensions: "",
    discount: null,
    max_order_quantity: null,
    minimum_order_quantity: null,
    mrp: null,
    packaging_type: "",
    pcs_per_unit: null,
    price: null,
    product_name: "",
    product_weight: null,
   
    shipping_price: null,
    short_code: "",
    stock_level: null,
    storage_conditions: "",
    unit: "",
    image_url: "",
    // daily_subscription_price:null,
    // weekly_subscription_price:null,
    // monthly_subscription_price:null,
    // yearly_subscription_price:null,
  });
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState('');
  const token = useSelector(state => state.adminLogin.token);
  const navigate = useNavigate();
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);
  const[entity,setEntity]=useState('')
  const [Saving, setSaving] = useState(false);

  const[next,setNext] = useState(false);
  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const productData = useSelector((state) => state.products.basicProducts);
  const categoryOptions = useSelector((state) => state.category.categoryList.categories) || [];
  const brandOptions = useSelector((state) => state.brand.brandList.brands);

  const categoryDropdownOptions = categoryOptions.map(category => ({ label: category.name, id: category.category_id }));
  const brandDropdownOptions = brandOptions.map(brand => ({ label: brand.name, id: brand.brand_id }));

  useEffect(() => {
    if (data) {
      const product_data = productData.find(d => d.product_id === data.product_id);
      if (product_data) {
        setFormData(product_data);
      }
    }
  }, [data, productData]);

  const handleCategorySelect = (selectedCategoryId) => {
    const available = categoryOptions.find(c => c.category_id === selectedCategoryId);
    if (available) {
      handleInputChange('category_id', selectedCategoryId);
    } else {
      setEntity('category');
      setIsSidebarVisible(true);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileUrl(URL.createObjectURL(selectedFile)); // Temporary display URL
    }
  };

   const handleSaveClick = async () => {
    setSaving(true);
    try{
      const customformData={prescription_required:true}
        const response =await dispatch(createSingleProductWorkflow(formData,customformData, token));
        if (response){
          toast.success("product created successfully");
         
        }
        else{
          toast.error("product not created");
        }

    }
    catch{
      toast.error("product not created");
    }
    finally{
      setSaving(false)
    }
    };
  

  const handleFileUpload = async () => {
    if (file) {
      try {
        const uploadedUrl = await uploadDocumentAPI(file, 'product/', token);
        if (uploadedUrl && uploadedUrl.file_url) {
          setFileUrl(uploadedUrl.file_url); // Set the final URL from server
          setFormData(prevData => ({ ...prevData, image_url: uploadedUrl.file_url }));
          return uploadedUrl.file_url;
        } else {
          toast.error("File upload failed. Please try again.");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        // toast.error("An error occurred during file upload.");
      }
    }
    return formData.image_url;
  };

  const generatePresignedUrl = async (imageUrl) => {
    //console.log('pre-assinged url',imageUrl)
    try {
      const presignedUrlData = await generatePresignedUrlAPI(imageUrl, token);
      if (presignedUrlData.presigned_url) {
        setFileUrl(presignedUrlData.presigned_url);
      }
    } catch (error) {
      // console.error("Error generating presigned URL:", error);
      // toast.error("An error occurred while fetching the image.");
    }
  };

  useEffect(() => {
    if (formData && formData.image_url) {
      generatePresignedUrl(formData.image_url);
    }
  }, [formData]);


  const handleFileRemove = () => {
    setFile(null);
    setFileUrl('');
    setFormData(prevData => ({ ...prevData, image_url: '' }));
  };

  const handleBrandSelect = (selectedBrandId) => {
    const available = brandOptions.find(b => b.brand_id === selectedBrandId);
    if (available) {
      handleInputChange('brand_id', selectedBrandId);
    } else {
      setEntity('brand');
      setIsSidebarVisible(true);
    }
  };

  const handleCloseSidebar = () => {
    setIsSidebarVisible(false);
  };


  const validateRequiredFields = () => {
    const requiredFields = [
      'brand_id', 'category_id', 'delivery_timeframe',
      'max_order_quantity', 'minimum_order_quantity', 'mrp', 'price', 
     
      'packaging_type', 'pcs_per_unit', 'product_name', 'product_weight',
      'shipping_price', 'short_code', 'stock_level', 'storage_conditions',
      'unit', 'discount', 'dimensions',
    ];
    const allFieldsFilled = requiredFields.every(field => formData[field] !== '');
    setIsNextButtonEnabled(allFieldsFilled);
  };
  
  useEffect(() => {
    validateRequiredFields();
  }, [formData]);

  const handleNextClick = async () => {
    setNext(true);
    let updatedFormData = { ...formData };
    if (file) {
      updatedFormData.image_url = await handleFileUpload();
    }
    //console.log(formData,'-----')
    if (urls) {
      // Open sidebar instead of navigating
      handleSaveClick(updatedFormData)
    } else {
      // Perform full navigation
      setNext(false);
      navigate('/custom-details', { state: updatedFormData });

    }
   
  };

  const renderRequiredLabel = (label) => (
    <span>
      {label} <span style={{ color: 'red' }}>*</span>
    </span>
  );

  return (
    <div className='details-table'>
      {!urls && (
         <div className="breadcrumb-container">
         <Link to="/admin/dashboard" className="breadcrumb-text">
         <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
           </Link>
           
           <span className="breadcrumb-separator">{'>'}</span>
           <Link to="/admin" className="breadcrumb-text">
             Product List
           </Link>
           <span className="breadcrumb-separator">{'>'}</span>
           <span className="breadcrumb-text">Add Basic Info</span>
         </div>
      )}
     
      <div className="basic-details-container"></div>
      <div className="details-table-container">
        <div className='text-heading1'>Basic Info Details</div>
        <div className='details-form'>
          <div className="form-row">
            <div className="form-group">
              <label>{renderRequiredLabel("Product Name")}</label>
              <InputComponent
                placeholder="Enter Product Name"
                value={formData.product_name || ''}
                onChange={(e) => handleInputChange('product_name', e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>{renderRequiredLabel("Category")}</label>
              <SearchableDropdown
                options={categoryDropdownOptions}
                placeholder="Select Category"
                value={categoryOptions.find(option => option.category_id === formData.category_id)?.name || ''}
                onSelect={handleCategorySelect}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>{renderRequiredLabel("Short Code")}</label>
              <InputComponent
                placeholder="Enter Short Code"
                value={formData.short_code || ''}
                onChange={(e) => handleInputChange('short_code', e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>{renderRequiredLabel("Brand Name")}</label>
              <SearchableDropdown
                options={brandDropdownOptions}
                placeholder="Select Brand"
                value={brandOptions.find(option => option.brand_id === formData.brand_id)?.name || ''}
                onSelect={handleBrandSelect}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>Description</label>
              <Textbox
                value={formData.description}
                onChange={(e) => handleInputChange('description', e.target.value)}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group number-input-group">
              <label>{renderRequiredLabel("Price")}</label>
              <InputComponent
                type="numeric"
                value={formData.price}
                onChange={(e) => handleInputChange('price', e.target.value)}
              />
            </div>
            <div className="form-group number-input-group">
              <label>{renderRequiredLabel("MRP")}</label>
              <InputComponent
                type="numeric"
                value={formData.mrp}
                onChange={(e) => handleInputChange('mrp', e.target.value)}
              />
            </div>
          </div>
          <div className="form-row">
          <div className="form-group number-input-group">
            <label>{renderRequiredLabel("Discount")}</label>
            <InputComponent
              type="numeric"
              value={formData.discount}
              onChange={(e) => handleInputChange('discount', e.target.value)}
            />
  </div>
  <div className="form-group number-input-group">
    <label>{renderRequiredLabel("Dimensions")}</label>
    <InputComponent
      placeholder="Enter Dimensions"
      value={formData.dimensions}
      onChange={(e) => handleInputChange('dimensions', e.target.value)}
    />
  </div>
          </div>

       
          <div className="form-row">
            <div className="form-group number-input-group">
              <label>{renderRequiredLabel("Unit")}</label>
              <InputComponent
                type="numeric"
                value={formData.unit}
                onChange={(e) => handleInputChange('unit', e.target.value)}
              />
            </div>
            <div className="form-group number-input-group">
              <label>{renderRequiredLabel("Pcs per Unit")}</label>
              <InputComponent
                type="numeric"
                value={formData.pcs_per_unit}
                onChange={(e) => handleInputChange('pcs_per_unit', e.target.value)}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group number-input-group">
              <label>{renderRequiredLabel("Stock Level")}</label>
              <InputComponent
                type="numeric"
                value={formData.stock_level}
                onChange={(e) => handleInputChange('stock_level', e.target.value)}
              />
            </div>
            <div className="form-group number-input-group">
              <label>{renderRequiredLabel("Minimum Order Quantity")}</label>
              <InputComponent
                type="numeric"
                value={formData.minimum_order_quantity}
                onChange={(e) => handleInputChange('minimum_order_quantity', e.target.value)}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group number-input-group">
              <label>{renderRequiredLabel("Max Order Quantity")}</label>
              <InputComponent
                type="numeric"
                value={formData.max_order_quantity}
                onChange={(e) => handleInputChange('max_order_quantity', e.target.value)}
              />
            </div>
            <div className="form-group number-input-group">
              <label>{renderRequiredLabel("Product Weight")}</label>
              <InputComponent
                type="numeric"
                value={formData.product_weight}
                onChange={(e) => handleInputChange('product_weight', e.target.value)}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>{renderRequiredLabel("Storage Conditions")}</label>
              <Textbox
                value={formData.storage_conditions}
                onChange={(e) => handleInputChange('storage_conditions', e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>{renderRequiredLabel("Packaging Type")}</label>
              <InputComponent
                placeholder="Enter Packaging Type"
                value={formData.packaging_type}
                onChange={(e) => handleInputChange('packaging_type', e.target.value)}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group number-input-group">
              <label>{renderRequiredLabel("Delivery Time Frame")}</label>
              <InputComponent
                type="numeric"
                value={formData.delivery_timeframe}
                onChange={(e) => handleInputChange('delivery_timeframe', e.target.value)}
              />
            </div>
            <div className="form-group number-input-group">
              <label>{renderRequiredLabel("Shipping Price")}</label>
              <InputComponent
                type="numeric"
                value={formData.shipping_price}
                onChange={(e) => handleInputChange('shipping_price', e.target.value)}
              />
            </div>
          </div>

          <div className="form-row">
          <div className="form-group">
            <label>Product Image</label>
            {fileUrl ? (
              <div className="image-container">
                <img src={fileUrl} alt="Uploaded" className="uploaded-image" />
                <FontAwesomeIcon className="close-icon" icon={faTimes} onClick={handleFileRemove} />
              
              </div>
            ) : (
              <input type="file" accept=".jpg,.jpeg,.png" onChange={handleFileChange} />
            )}
          </div>
        </div>

        <div className="button-row">
  <ButtonComponent
    text={
      urls ? (
        Saving ? (
          <>
            {/* Add a spinner if required */}
            Saving...
          </>
        ) : (
          'Save'
        )
      ) : next ? (
        <>
          {/* Add a spinner if required */}
          Loading...
        </>
      ) : (
        <>
          Next
          <FontAwesomeIcon icon={faArrowRight} className="right-arrow-icon" />
        </>
      )
    }
    className="next-button"
    onClick={urls ? handleSaveClick : handleNextClick} // Dynamic handler based on `urls`
    disabled={!isNextButtonEnabled && !urls} // Ensure proper disabling logic
  />
</div>

                                                      
          {isSidebarVisible && <Sidebar type={entity} urls={'/basic-info-details'} onClose={handleCloseSidebar} />}
        </div>
      </div>
    </div>
  );
};

export default DetailsTable;