import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginAction, resetPasswordAction } from '../../redux/actions/adminLoginActions';
import { Link, useNavigate } from 'react-router-dom';
import './adminlogin.scss';
import doctorImage from '../../assets/images/doctor.png';
import pharmaLogo from '../../assets/images/pharma.png';
import InputComponent from '../../components/common/InputComponent/InputComponent';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';

const AdminLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error, token, resetMessage, isLoggedIn } = useSelector((state) => state.adminLogin);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email && password) {
      dispatch(loginAction(email, password)).then(() => {
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 3000); // Auto-hide success message after 3 seconds
      });
    } else {
      alert('Please enter both email and password');
    }
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    if (!newPassword || !confirmPassword) {
      alert('Please fill both password fields.');
    } else if (newPassword !== confirmPassword) {
      alert('Passwords do not match.');
    } else {
      dispatch(resetPasswordAction(email));
      alert('Password reset successful! Please log in.');
      setIsForgotPassword(false);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/admin/dashboard');
    }
  }, [isLoggedIn, navigate]);

  return (
    <div className="admin-login-page-container">
      <div className="admin-login-logo">
        <img src={pharmaLogo} alt="Pharma" className="img-fluid login-pharma-logo" />
      </div>

      {!isForgotPassword ? (
        <div className="admin-login-box">
          <div className="admin-login-left">
            <img src={doctorImage} alt="Doctor" className="img-fluid login-doc-image" />
          </div>
          <div className="admin-login-right">
            <h2 className="sign-in-header">Sign In</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Email</label>
                <InputComponent
                  type="email"
                  className="form-control login-input"
                  placeholder="Enter Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>Password</label>
                <InputComponent
                  type="password"
                  className="form-control login-input"
                  placeholder="Enter Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <ButtonComponent
                text={loading ? 'Signing In...' : 'Sign In'}
                className="login-btn"
                type="submit"
                disabled={loading}
              />
              {error && <div className="text-danger mt-3">{error}</div>}
              {showSuccessMessage && <div className="text-success mt-3">Login successful!</div>}
            </form>
           
          </div>
        </div>
      ) : (
        <div className="admin-login-box">
          <div className="admin-login-left">
            <img src={doctorImage} alt="Doctor" className="img-fluid login-doc-image" />
          </div>
          <div className="admin-login-right">
            <h2 className="sign-in-header">Reset Password</h2>
            <form onSubmit={handleResetPassword}>
              <div className="form-group">
                <label>New Password</label>
                <InputComponent
                  type="password"
                  className="form-control login-input"
                  placeholder="Enter New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>Confirm Password</label>
                <InputComponent
                  type="password"
                  className="form-control login-input"
                  placeholder="Confirm New Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </div>
              <ButtonComponent
                text="Reset Password"
                className="login-btn"
                type="submit"
              />
              {resetMessage && <div className="text-success mt-3">{resetMessage}</div>}
              {error && <div className="text-danger mt-3">{error}</div>}
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminLogin;
