import React, { useState, useEffect } from 'react';
import InputComponent from '../../components/common/InputComponent/InputComponent';
import DropdownButton from '../../components/common/dropdown/DropdownButton';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createNewBanner,updateExistingBanner } from '../../redux/actions/bannerAction';
import { uploadDocumentAPI, generatePresignedUrlAPI } from '../../api/services/filestorageService';
import './Addbanner.scss';
import {  faHome ,faTimes} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const Banners = ({ urls }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(state => state.adminLogin.token);
  const bannerList = useSelector(state => state.banner.banners);
  const location = useLocation();
  const banner_Id = location.state || null;

  const [formData, setFormData] = useState({
  
    banner_count:'',
    image:'',
    url:'',
    status:''
  });
  const [isModified, setIsModified] = useState(false);
  const [originalData, setOriginalData] = useState(null);
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const statusOptions = ['active', 'inactive'];

  // Generate a presigned URL for the existing image
  const generatePresignedUrl = async (imageUrl) => {
    try {
      const presignedUrlData = await generatePresignedUrlAPI(imageUrl, token);
      if (presignedUrlData.presigned_url) {
        setFileUrl(presignedUrlData.presigned_url); // Set presigned URL for display
      }
    } catch (error) {
      // console.error("Error generating presigned URL:", error);
      toast.error("An error occurred while fetching the image.");
    }
  };

  useEffect(() => {
    if (banner_Id) {
      const bannerData = bannerList.find(data => data.banner_id === banner_Id);
      if (bannerData) {
        setFormData(bannerData);
        setOriginalData(bannerData);
        if (bannerData.url) {
          generatePresignedUrl(bannerData.url); // Generate presigned URL for the existing image
        }
      }
    }
  }, [banner_Id, bannerList]);

  useEffect(() => {
    setIsModified(JSON.stringify(formData) !== JSON.stringify(originalData));
  }, [formData, originalData]);

  const handleInputChange = (field, value) => {
    setFormData(prevData => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileUrl(URL.createObjectURL(selectedFile));
      setIsModified(true);
    }
  };

  const handleFileUpload = async () => {
    if (file) {
      try {
        const uploadedUrl = await uploadDocumentAPI(file, 'banner/', token);
        if (uploadedUrl && uploadedUrl.file_url) {
          setFileUrl(uploadedUrl.file_url);
          setFormData(prevData => ({
            ...prevData,
            url: uploadedUrl.file_url,
            image:uploadedUrl.image_id,
          }));
          return uploadedUrl;
        } else {
          toast.error("File upload failed. Please try again.");
        }
      } catch (error) {
        // console.error("Error uploading file:", error);
        toast.error("An error occurred during file upload.");
      }
    }
    return formData.url;
  };

  const handleSubmit = async () => {
    setLoading(true);
    let updatedFormData = { ...formData };
    if (file) {
        const data = await handleFileUpload()
      updatedFormData.url = data.file_url;
      updatedFormData.image = data.image_id;
    }
    //console.log(updatedFormData,'----------------')
    if (updatedFormData.url && updatedFormData.status) {
        
      const actionResult = banner_Id
        ? await dispatch(updateExistingBanner(updatedFormData, token))
        : await dispatch(createNewBanner(updatedFormData, token));

      if (actionResult?.success) {
        toast.success("Banner updated/created successfully");
        navigate(urls || '/admin/banners');
      } else {
        toast.error("Banner not updated/created. Please try again.");
      }
      setLoading(false);
    } else {
      toast.error("Please fill in the mandatory fields");
    }
  };

  const handleFileRemove = () => {
    setFile(null);
    setFileUrl('');
    setFormData(prevData => ({ ...prevData, url: '' }));
  };

  return (
    <div className="add-banner-main-page">
    

    <div className="breadcrumb-container">
     
        <Link to="/admin/dashboard" className="breadcrumb-text">
        <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <Link to="/admin/banners" className="breadcrumb-text">
        <span className="breadcrumb-text">Banner List</span>
        </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <span className="breadcrumb-text">Add Banner</span>
   </div>
   <div className="add-banner-container"></div>
    <div className="banner-section">
    <h5 className="text-heading1"> Add Banner</h5>
        <div className="form-row">
          <div className="form-group">
            <label>Position <span className="required-symbol">*</span></label>
            <InputComponent
              placeholder="Enter position of image"
              name="banner_count"
              type="number"
              value={formData.banner_count}
              onChange={e => handleInputChange("banner_count", e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Status <span className="required-symbol">*</span></label>
            <DropdownButton
              options={statusOptions}
              label={formData.status || 'Select Status'}
              onSelect={value => handleInputChange("status", value)}
            />
          </div>
        </div>
        <div className="form-row1">
          <div className="form-group">
            <label>Banner Image</label>
            {fileUrl ? (
              <div className="image-container">
                <img src={fileUrl} alt="Uploaded"  className="uploaded-image"  />
                <FontAwesomeIcon className="close-icon" icon={faTimes} onClick={handleFileRemove} />
              </div>
            ) : (
              <input type="file" accept=".jpg,.jpeg,.png" onChange={handleFileChange} />
            )}
          </div>
        </div>
        <div className="action-buttons">
          <ButtonComponent
            text={loading ? "Saving..." : "Save"}
            onClick={handleSubmit}
            className="save-button"
            disabled={!isModified}
          />
        </div>
      </div>
   
      </div>
     
  );
};

export default Banners;
