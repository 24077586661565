import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getOrder, getBulkOrderItems, updateOrder } from '../../redux/actions/orderActions';
import SearchableDropdown from '../../components/common/SearchableDropdown/SearchableDropdown';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import './orderEdit.scss';
import {  faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import InputComponent from '../../components/common/InputComponent/InputComponent';
import Datepicker from '../../components/common/datepicker/datepicker';
import DropdownButton from '../../components/common/dropdown/DropdownButton';
import Sidebar from '../../components/common/HalfSideBar/halfsidebar';

const AddOrder = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const orderId = location.state || null;
    const users = useSelector((state) => state.usermanage.users);
    const token = useSelector((state) => state.adminLogin.token) || [];
    const order = useSelector((state) => state.orders.orders || []);
    const [filteredOrders, setFilteredOrders] = useState({});
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    
    const allOrderItems = useSelector((state) => state.orders.orderItems || []);
    const Persons = useSelector((state) => state.usermanage.users || []);
    const deliveryPersons = Persons.filter((user) => user.role === "delivery");
    const products = useSelector((state) => state.products.basicProducts);

    const [filteredOrderItems, setFilteredOrderItems] = useState([]);
    const [status, setStatus] = useState('');
    const [paymentstatus, setPaymentStatus] = useState('');
    const [deliveryId, setDeliveryId] = useState('');
    const [deliveryDate,setDeliveryDate]=useState('');

    const statusOptions = ['pending', 'processing', 'shipped', 'delivered', 'cancelled','customer not available'];
    const paymentstatusOptions = ['paid', 'pending','failed'];


    const handlePaymentStatus =(status)=>{
        //console.log("statussss",status)
        setPaymentStatus(status);
    }
    const handleOrder =(status)=>{
        //console.log("ORDERRRstatussss",status)
        setStatus(status);
    }
    const getUsername = (user_id) => {
        //console.log(user_id,'---userriddd')
        const user = users.find((user) => user.user_id === user_id);
        return user ? user.username : '';
    };

    const getProductname = (product_id) => {
        const product = products.find((product) => product.product_id === product_id);
        return product ? product.product_name : '';
    };

    useEffect(() => {
        if (orderId) {
            const currentOrder = order.find((order) => order.order_id === orderId);
            //console.log(currentOrder,'-ccureent');
            setFilteredOrders(currentOrder || {});
            setStatus(currentOrder?.order_status || '');
            setPaymentStatus(currentOrder?.payment_status || '');
            setDeliveryId(currentOrder?.delivery_id || '');
            setFilteredOrderItems(allOrderItems.filter((item) => item.order_id === orderId));
        }
    }, [orderId, order, allOrderItems]);
  
    const handleSave = async () => {
        const updatedFields = {
            order_id: orderId,
            order_status: status,
            delivery_id: deliveryId,
            payment_status: paymentstatus,
        };

        try {
            await dispatch(updateOrder(updatedFields, token));
            toast.success('Order updated successfully.');
            navigate('/admin/order-management');
        } catch (error) {
            toast.error('Failed to update order.');
        }
    };
    const handleCloseSidebar = () => {
        setIsSidebarVisible(false);
      };

      const handleAddDelivery = (selectedId) => {
        const available = deliveryPersons.find((c) => c.delivery_id === selectedId);
        if (available) {
          setDeliveryId(selectedId);
        } else {
       
          setIsSidebarVisible(true);
        }
      };
    if (!order || !filteredOrderItems.length) return null;

    return (
        <div className="add-order">
            <div className="breadcrumb-container">
                    
                    <Link to="/admin/dashboard" className="breadcrumb-text">
                    <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
                    </Link>
                    <span className="breadcrumb-separator">{'>'}</span>
                    <Link to="/admin/order-management" className="breadcrumb-text">
                    <span className="breadcrumb-text">Order List</span>
                    </Link>
                    <span className="breadcrumb-separator">{'>'}</span>
                    <span className="breadcrumb-text">Add Order List</span>
                </div>
                <div className="add-order-list-container"></div>
            <div className="add-order-page">
                <div className="add-order-header-container">
                    <h6 className="add-order-header">Order Details</h6>
                    <hr />
                    <div className="add-order-form">
                        <div className="form-row">
                            <div className="form-group order-number-group">
                                <label>Order No.</label>
                                <InputComponent
                                    type="text"
                                    className="form-control order-input"
                                    value={filteredOrders.order_id || ''}
                                    readOnly
                                 />
                                   </div>
                       
                            <div className="form-group">
                                <label>User ID</label>
                                <InputComponent type="text" className="form-control" value={getUsername(filteredOrders.user_id)} readOnly />
                            </div>
                            </div>
                            <div className="form-row">
                            <div className="form-group">
                                <label>Order Date</label>
                                <InputComponent type="text" className="form-control" value={filteredOrders.order_date || ''} readOnly />
                            </div>
                            <div className="form-group">
                                <label>Order Status</label>
                                <DropdownButton
                                    label={status}
                                    options={statusOptions}
                                    placeholder="Select Status"
                                    value={status}
                                    onSelect={(selectedOption) => handleOrder(selectedOption)}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>Payment Status</label>
                                <DropdownButton
                                label={paymentstatus}
                                options={paymentstatusOptions}
                                placeholder="Select Payment Status"
                                value={paymentstatus}
                                onSelect={(selectedOption) => setPaymentStatus(selectedOption)}
                            />
                            </div>
                            <div className="form-group">
                                <label>Payment Method</label>
                                <InputComponent type="text" className="form-control" value={filteredOrders.payment_method || ''} />
                            </div>
                            </div>
                            <div className="form-row">

                            <div className="form-group">
                                <label>Shipping Address Id </label>
                                <InputComponent type="text" className="form-control" value={filteredOrders.shipping_address_id || ''} readOnly />
                            </div>
                        <div className="form-group">
                                <label>Billing Address Id</label>
                                <InputComponent type="text" className="form-control" value={filteredOrders.billing_address_id || ''} />
                            </div>
                            </div>
                            <div className="form-row">

                            <div className="form-group">
                                <label>Total Amount </label>
                                <InputComponent type="text" className="form-control" value={filteredOrders.total_amount || ''}  />
                            </div>
                            <div className="form-group">
                                <label>Tax Amount</label>
                                <InputComponent type="text" className="form-control" value={filteredOrders.tax_amount || ''} />
                            </div>
                            </div>
                            <div className="form-row">

                            <div className="form-group">
                                <label>Discount Amount </label>
                                <InputComponent type="text" className="form-control" value={filteredOrders.discount_amount || ''}  />
                            </div>
                            <div className="form-group">
                                <label>Shipping Fee</label>
                                <InputComponent type="text" className="form-control" value={filteredOrders.shipping_fee || ''} />
                            </div>
                            </div>
                            <div className="form-row">

                            <div className="form-group">
                                <label>Coupon Id </label>
                                <InputComponent type="text" className="form-control" value={filteredOrders.coupon_id || ''}  />
                            </div>
                            <div className="form-group">
                                <label>Transaction Id</label>
                                <InputComponent type="text" className="form-control" value={filteredOrders.transaction_id || ''} />
                            </div>
                            </div>
                            <div className="form-row">

                            <div className="form-group">
                                <label>Delivery Date </label>
                                <Datepicker type="text" className="form-control"   selected={filteredOrders.delivery_date || deliveryDate}
                                onChange={(date) => setDeliveryDate(date)}  />
                            </div>
                            <div className="form-group">
                                <label>Prescription Url</label>
                                <InputComponent type="text" className="form-control" value={filteredOrders.prescription_url || ''} />
                            </div>
                            </div>
                            <div className="form-row">

                            <div className="form-group">
                                <label>Delivery Person</label>
                                <SearchableDropdown
                                    options={deliveryPersons.map(person => ({ label: person.username, id: person.user_id }))}
                                    placeholder="Select Delivery Person"
                                    value={deliveryPersons.find(person => person.user_id === deliveryId)?.username || ""}
                                    onSelect={(selectedOption) => handleAddDelivery(selectedOption)}
                                />
                            </div>
                            <div className="form-group">
                                <label>Consignment Number</label>
                                <InputComponent type="text" className="form-control" value={filteredOrders.consignment_number || ''} />
                            </div>
                            </div>
                            <div className="form-row">

                            <div className="form-group">
                                <label>Courier Name </label>
                                <InputComponent type="text" className="form-control" value={filteredOrders.courier_name || ''}  />
                            </div>
                            <div className="form-group">
                                <label>Order Type</label>
                                <InputComponent type="text" className="form-control" value={filteredOrders.order_type || ''} />
                            </div>
                            </div>
                            <div className="form-row">
                         
                            </div>
                        </div>
                    
                </div>

                <div className="item-details">
                    <div className="header">
                        <h6 className="add-order-header1">Order Item Details</h6>
                    </div>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Quantity</th>
                                <th>Price Per Unit</th>
                                <th>Tax Amount</th>
                                <th>Discount Amount</th>
                                <th>Total Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredOrderItems.map((item, index) => (
                                <tr key={index}>
                                    <td>{getProductname(item.product_id)}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.price_per_unit}</td>
                                    <td>{item.tax_amount}</td>
                                    <td>{item.discount_amount}</td>
                                    <td>{item.total_price}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
               
                <div className="footer-buttons">
                    <ButtonComponent text="Save changes" onClick={handleSave} className="btn-save" />
                </div>
            </div>
        </div>
        {isSidebarVisible && <Sidebar type={"delivery"} urls={'yes'} onClose={handleCloseSidebar} />}
         </div>

    );
};

export default AddOrder;
