import { inventory_threshold_endpoints } from '../endPoints';
import apiClient from '../apiClient';

// Fetch inventory thresholds
export const fetchInventoryThreshold = async (token) => {
  try {
    const response = await apiClient.get(inventory_threshold_endpoints.GET_INVENTORY_THRESHOLD, {
      headers: { Authorization: token }, // Updated header
    });
    return response.data;
  } catch (error) {
    // console.error("Error in fetchInventoryThreshold:", error.response?.data || error.message);
    throw error;
  }
};

// Create inventory threshold
export const createInventoryThreshold = async (thresholdData, token) => {
  try {
    const response = await apiClient.post(
      inventory_threshold_endpoints.CREATE_INVENTORY_THRESHOLD,
      thresholdData,
      { headers: { Authorization: token } } // Updated header
    );
    return response.data;
  } catch (error) {
    // console.error("Error in createInventoryThreshold:", error.response?.data || error.message);
    throw error;
  }
};

// Update inventory threshold
// Update inventory threshold
export const updateInventoryThreshold = async (thresholdId, thresholdData, token) => {
  try {
    if (!thresholdId || !thresholdData || !token) {
      throw new Error("Missing parameters: Threshold ID, data, or token is required.");
    }

    //console.log("PUT URL:", `${inventory_threshold_endpoints.UPDATE_INVENTORY_THRESHOLD}/${thresholdId}`);
    //console.log("Authorization Token:", token);
    //console.log("Threshold Data Sent:", thresholdData);

    const response = await apiClient.put(
      inventory_threshold_endpoints.UPDATE_INVENTORY_THRESHOLD,
      thresholdData,
      {
        headers: { Authorization: token },
      }
    );

    //console.log("API Response:", response.data);
    return response.data;
  } catch (error) {
    // console.error("Error in updateInventoryThreshold:");
    // console.error("Message:", error.message);
    // console.error("Response Data:", error.response?.data || "No response data");
    // console.error("Status:", error.response?.status || "No status");
    // console.error("Headers:", error.response?.headers || "No headers");
    throw error;
  }
};



// Delete inventory threshold
export const deleteInventoryThreshold = async (thresholdId, token) => {
  try {
    const response = await apiClient.delete(
      inventory_threshold_endpoints.DELETE_INVENTORY_THRESHOLD,
      {  params:{threshold_id:thresholdId},
         headers: { Authorization: token } 
        } // Updated header
    );
    return response.data;
  } catch (error) {
    // console.error("Error in deleteInventoryThreshold:", error.response?.data || error.message);
    throw error;
  }
};

// Bulk Create Inventory Thresholds
export const bulkCreateInventoryThresholds = async (bulkData, token) => {
  try {
    const response = await apiClient.post(
      inventory_threshold_endpoints.CREATE_BULK_INVENTORY_THRESHOLD,
      bulkData,
      { headers: { Authorization: token } } // Updated header
    );
    return response.data;
  } catch (error) {
    // console.error("Error in bulkCreateInventoryThresholds:", error.response?.data || error.message);
    throw error;
  }
};

// Bulk Fetch Inventory Thresholds
export const bulkFetchInventoryThresholds = async (token) => {
  try {
    const response = await apiClient.get(
      inventory_threshold_endpoints.GET_BULK_INVENTORY_THRESHOLD,
      { headers: { Authorization: token } } // Updated header
    );
    return response.data.inventories;
  } catch (error) {
    // console.error("Error in bulkFetchInventoryThresholds:", error.response?.data || error.message);
    throw error;
  }
};




export const bulkUpdateInventoryThresholds = async (thresholdData, token) => {
  try {
    //console.log("Bulk Inventory Data Update:", thresholdData);

    const response = await apiClient.put(
      inventory_threshold_endpoints.UPDATE_BULK_INVENTORY_THRESHOLD,
      thresholdData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    //console.log(response,'inverntory responseeee')
    return response.data;
  } catch (error) {
    // console.error("Error updating bulk inventory thresholds:", error.response?.data || error.message);
    throw error;
  }
};

  
export const bulkDeleteInventoryThresholds = async (ids, token) => {
  try {
    const response = await apiClient.delete(
      inventory_threshold_endpoints.DELETE_BULK_INVENTORY_THRESHOLD,
      {
        data:  ids ,
        headers: { Authorization: token },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
