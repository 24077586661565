import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./MyProfile.scss";
import AddressOffcanvas from "../../components/common/address-offcanvas/AddressOffcanvas";
import PersonalDetailOffcanvas from "../../components/common/PersonalDetailOffcanvas/PersonalDetailOffcanvas";
import { fetchSingleUser, updateUser } from "../../redux/actions/adminuserActions";
import { deleteAddress, fetchBulkAddresses,updateAddress } from "../../redux/actions/AddressAction";

const MyProfile = () => {
  const [showAddressOffcanvas, setShowAddressOffcanvas] = useState(false);
  const [editingAddressIndex, setEditingAddressIndex] = useState(null);
  const [showPersonalDetailOffcanvas, setShowPersonalDetailOffcanvas] = useState(false);

  const [form, setForm] = useState({
    username: "",
    mobile_number: "",
    addresses: [],
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.auth.token);
  const userId = useSelector((state) => state.auth.user);
  const users = useSelector((state) => state.usermanage.users) || [];
  const addresses = useSelector((state) => state.address.addresses) || [];

   // Ensure users[0] is accessed safely

  useEffect(() => {
    if (userId) {
      dispatch(fetchSingleUser(userId, token));
      dispatch(fetchBulkAddresses(userId, token));
    }
  }, [dispatch, token, userId]);

  useEffect(() => {
    const reduxUser = users[0] || {};
    setForm({
      username: reduxUser.username || "",
      mobile_number: reduxUser.mobile_number|| "",
      gender:reduxUser.gender|| "",
      dob:reduxUser.dob || "",
      addresses: addresses || [],
    });

    //console.log(addresses,'----update address after')
  }, [addresses,users]);

  const handleEditPersonalDetails = () => {
    setShowPersonalDetailOffcanvas(true);
  };

  const handleUpdateUser = (updatedUser) => {
    const updatedForm = { ...form, ...updatedUser };
    setForm(updatedForm);
    dispatch(updateUser(userId, updatedForm, token));
    setShowPersonalDetailOffcanvas(false);
  };

  const handleSaveAddress = (newAddress) => {
    const updatedAddresses =
      editingAddressIndex !== null
        ? form.addresses.map((address, index) =>
            index === editingAddressIndex
              ? { ...newAddress, address_id: form.addresses[index].address_id } // Include address_id
              : address
          )
        : [...form.addresses, newAddress]; // Adding a new address without address_id
  
    setForm({ ...form, addresses: updatedAddresses });
    setShowAddressOffcanvas(false);
  
    if (editingAddressIndex !== null) {
      // Dispatch the update address action with address_id if editing
      const updatedAddressWithId = {
        ...newAddress,
        address_id: form.addresses[editingAddressIndex].address_id,
      };
      dispatch(updateAddress(updatedAddressWithId, token));
    }
  };
  

  const handleDeleteAddress = (addressId) => {
    const updatedAddresses = form.addresses.filter((address) => address.address_id !== addressId);
    setForm({ ...form, addresses: updatedAddresses });
    dispatch(deleteAddress(addressId, token));
  };

  const handleAddAddressClick = () => {
    setEditingAddressIndex(null);
    setShowAddressOffcanvas(true);
  };

  const handleCloseAddressOffcanvas = () => {
    setShowAddressOffcanvas(false);
  };

  const handleEditAddress = (index) => {
    setEditingAddressIndex(index);
    setShowAddressOffcanvas(true);
  };

  const handleClosePersonalDetailOffcanvas = () => {
    setShowPersonalDetailOffcanvas(false);
  };

  const handleNavigateHome = () => {
    navigate("/");
  };

  return (
    <div className="my-profile">
      <div className="breadcrumb">
        <span onClick={handleNavigateHome} className="breadcrumb-link home-link">
          Home
        </span>
        / <span>Manage Addresses</span>
      </div>

      <div className="user-info">
        <div className="user-details">
          <h3 className="user-name">{form.username || "User Name"}</h3>
          <div className="user-number">{form.mobile_number || "Phone Number"}</div>
          <a
            href=" "
            onClick={(e) => {
              e.preventDefault();
              handleEditPersonalDetails();
            }}
            className="edit-link"
          >
            Edit
          </a>
        </div>
      </div>

      <div className="profile-nav">
        <div className="active">Manage Addresses</div>
      </div>

      <button className="add-address-btn" onClick={handleAddAddressClick}>
        + Add New Address
      </button>

      <div className="address-details" style={{ maxHeight: "400px", overflowY: "auto" }}>
        {form.addresses.length > 0 ? (
          form.addresses.map((address, index) => (
            <div key={index} className="address-card">
              <h4>{address.address_type || "Office"}</h4>
              <div className="address-row">
                <p className="address-paragraph">
                  {`${address.address || ""}, ${address.city || ""}, ${
                    address.state || ""
                  }, ${address.pincode || ""}`}
                </p>
                <div className="address-actions">
                  <a
                    href=" "
                    onClick={(e) => {
                      e.preventDefault();
                      handleEditAddress(index);
                    }}
                    className="address-link"
                  >
                    Edit
                  </a>
                  <a
                    href=" "
                    onClick={(e) => {
                      e.preventDefault();
                      handleDeleteAddress(address.address_id);
                    }}
                    className="address-link"
                    style={{ marginLeft: "10px" }}
                  >
                    Delete
                  </a>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No addresses available. Add a new address.</p>
        )}
      </div>

      <AddressOffcanvas
  show={showAddressOffcanvas}
  handleClose={handleCloseAddressOffcanvas}
  onSaveAndContinueClick={handleSaveAddress}
  initialValues={
    editingAddressIndex !== null && form.addresses[editingAddressIndex]
      ? {
          ...form.addresses[editingAddressIndex],
          address_id: form.addresses[editingAddressIndex]?.address_id, // Safely access address_id
        }
      : {}
  }
  userId={userId} // Ensure userId is also passed
  
/>


      <PersonalDetailOffcanvas
        show={showPersonalDetailOffcanvas}
        onClose={handleClosePersonalDetailOffcanvas}
        user={form}
        onSave={handleUpdateUser}
      />
    </div>
  );
};

export default MyProfile;
