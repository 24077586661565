import * as stockService from '../../api/services/stockpurchaseService';

// Action Types
export const CREATE_STOCK = 'CREATE_STOCK';
export const GET_STOCK = 'GET_STOCK';
export const UPDATE_STOCK = 'UPDATE_STOCK';
export const DELETE_STOCK = 'DELETE_STOCK';

export const GET_BULK_STOCK = 'GET_BULK_STOCK';
export const CREATE_BULK_STOCK = 'CREATE_BULK_STOCK';
export const UPDATE_BULK_STOCK = 'UPDATE_BULK_STOCK';
export const DELETE_BULK_STOCK = 'DELETE_BULK_STOCK';

export const CREATE_STOCK_ITEM = 'CREATE_STOCK_ITEM';
export const GET_STOCK_ITEM = 'GET_STOCK_ITEM';
export const UPDATE_STOCK_ITEM = 'UPDATE_STOCK_ITEM';
export const DELETE_STOCK_ITEM = 'DELETE_STOCK_ITEM';

export const GET_BULK_STOCK_ITEMS = 'GET_BULK_STOCK_ITEMS';
export const CREATE_BULK_STOCK_ITEMS = 'CREATE_BULK_STOCK_ITEMS';
export const UPDATE_BULK_STOCK_ITEMS = 'UPDATE_BULK_STOCK_ITEMS';
export const DELETE_BULK_STOCK_ITEMS = 'DELETE_BULK_STOCK_ITEMS';

// Single Stock Actions
export const createStock = (data, token) => async (dispatch) => {
    try {
        const response = await stockService.createStock(data, token);
        dispatch({ type: CREATE_STOCK, payload: data });
        return response;
    } catch (error) {
        console.error('Error creating stock:', error);
        throw error;
    }
};

export const getStock = (stockId, token) => async (dispatch) => {
    try {
        const response = await stockService.getStock(stockId, token);
        dispatch({ type: GET_STOCK, payload: response });
    } catch (error) {
        console.error('Error fetching stock:', error);
        throw error;
    }
};

export const updateStock = (data, token) => async (dispatch) => {
    try {
        const response = await stockService.updateStock(data, token);
        dispatch({ type: UPDATE_STOCK, payload: data });
    } catch (error) {
        console.error('Error updating stock:', error);
        throw error;
    }
};

export const deleteStock = (stockId, token) => async (dispatch) => {
    try {
        const response = await stockService.deleteStock(stockId, token);
        dispatch({ type: DELETE_STOCK, payload: { stockId } });
    } catch (error) {
        console.error('Error deleting stock:', error);
        throw error;
    }
};

// Bulk Stock Actions
export const getBulkStock = (token) => async (dispatch) => {
    try {
        const response = await stockService.getBulkStock(token);
        dispatch({ type: GET_BULK_STOCK, payload: response });
    } catch (error) {
        console.error('Error fetching bulk stock:', error);
        throw error;
    }
};

export const createBulkStock = (data, token) => async (dispatch) => {
    try {
        const response = await stockService.createBulkStock(data, token);
        dispatch({ type: CREATE_BULK_STOCK, payload: response });
    } catch (error) {
        console.error('Error creating bulk stock:', error);
        throw error;
    }
};

export const updateBulkStock = (data, token) => async (dispatch) => {
    try {
        const response = await stockService.updateBulkStock(data, token);
        dispatch({ type: UPDATE_BULK_STOCK, payload: data });
    } catch (error) {
        console.error('Error updating bulk stock:', error);
        throw error;
    }
};

export const deleteBulkStock = (stockIds, token) => async (dispatch) => {
    try {
        const response = await stockService.deleteBulkStock(stockIds, token);
        dispatch({ type: DELETE_BULK_STOCK, payload: { deletedStockIds: stockIds } });
    } catch (error) {
        console.error('Error deleting bulk stock:', error);
        throw error;
    }
};

// Single Stock Item Actions
export const createStockItem = (data, token) => async (dispatch) => {
    try {
        const response = await stockService.createStockItem(data, token);
        dispatch({ type: CREATE_STOCK_ITEM, payload: response });
    } catch (error) {
        console.error('Error creating stock item:', error);
        throw error;
    }
};

export const getStockItem = (stockItemId, token) => async (dispatch) => {
    try {
        const response = await stockService.getStockItem(stockItemId, token);
        dispatch({ type: GET_STOCK_ITEM, payload: response });
    } catch (error) {
        console.error('Error fetching stock item:', error);
        throw error;
    }
};

export const updateStockItem = (data, token) => async (dispatch) => {
    try {
        const response = await stockService.updateStockItem(data, token);
        dispatch({ type: UPDATE_STOCK_ITEM, payload: data });
    } catch (error) {
        console.error('Error updating stock item:', error);
        throw error;
    }
};

export const deleteStockItem = (stockItemId, token) => async (dispatch) => {
    try {
        const response = await stockService.deleteStockItem(stockItemId, token);
        dispatch({ type: DELETE_STOCK_ITEM, payload: stockItemId  });
    } catch (error) {
        console.error('Error deleting stock item:', error);
        throw error;
    }
};

// Bulk Stock Item Actions
export const getBulkStockItems = (token) => async (dispatch) => {
    try {
        const response = await stockService.getBulkStockItems(token);
        dispatch({ type: GET_BULK_STOCK_ITEMS, payload: response });
    } catch (error) {
        console.error('Error fetching bulk stock items:', error);
        throw error;
    }
};

export const createBulkStockItems = (data, token) => async (dispatch) => {
    try {
        //console.log(data,'stock items')
        const response = await stockService.createBulkStockItems(data, token);
        dispatch({ type: CREATE_BULK_STOCK_ITEMS, payload: data });
    } catch (error) {
        console.error('Error creating bulk stock items:', error);
        throw error;
    }
};

export const updateBulkStockItems = (data, token) => async (dispatch) => {
    try {
        const response = await stockService.updateBulkStockItems(data, token);
        dispatch({ type: UPDATE_BULK_STOCK_ITEMS, payload: data });
    } catch (error) {
        console.error('Error updating bulk stock items:', error);
        throw error;
    }
};

export const deleteBulkStockItems = (stockItemIds, token) => async (dispatch) => {
    try {
        const response = await stockService.deleteBulkStockItems(stockItemIds, token);
        dispatch({ type: DELETE_BULK_STOCK_ITEMS, payload: { deletedStockItemIds: stockItemIds } });
    } catch (error) {
        console.error('Error deleting bulk stock items:', error);
        throw error;
    }
};
