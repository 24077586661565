import React, { useState } from "react";
import Datepicker from "../datepicker/datepicker";
import TextBox from "../../common/Textbox/Textbox";
import ButtonComponent from "../buttons/ButtonComponent";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SubscriptionPauseOffcanvas.scss";
import { modifySubscription } from "../../../redux/actions/subscriptionAction";
import { useDispatch, useSelector } from "react-redux";

const SubscriptionPauseOffcanvas = ({
  isOpen,
  onClose,
  actionType,
  subscriptionDetails = {},
  onConfirm,
}) => {
  const [reason, setReason] = useState("");
  const [startDate, setStartDate] = useState(
    subscriptionDetails.start_date ? new Date(subscriptionDetails.start_date) : null
  );
  const [resumeDate, setResumeDate] = useState(null);

  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  const isValidDate = (date) => date instanceof Date && !isNaN(date.getTime());

  const handleConfirm = async () => {
    const updatedDetails = {
      subscription_id: subscriptionDetails.subscription_id, // Include the ID
      status: "", // Status will be set dynamically
    };

    // Dynamically update fields based on actionType
    if (actionType === "Cancel") {
      updatedDetails.status = "cancel_requst";
      updatedDetails.cancel_reason = reason; // Send cancel_reason field
    } else if (actionType === "Pause") {
      updatedDetails.status = "paused";
      updatedDetails.pause_reason = reason; // Send pause_reason field
      updatedDetails.pause_date = isValidDate(startDate) ? startDate.toISOString() : "N/A";
    } else if (actionType === "Resume") {
      updatedDetails.status = "active";
      updatedDetails.resume_date = isValidDate(resumeDate) ? resumeDate.toISOString() : "N/A";
    }

    await dispatch(modifySubscription(subscriptionDetails.subscription_id, updatedDetails, token));
    onConfirm(updatedDetails);
    
    onClose();
    
  };

  return (
    <>
      <div
        className={`offcanvas-backdrop fade ${isOpen ? "show" : ""}`}
        onClick={onClose}
      ></div>
      <div
        className={`offcanvas offcanvas-end ${isOpen ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title">
            {actionType === "Pause"
              ? "Pause Subscription"
              : actionType === "Resume"
              ? "Resume Subscription"
              : "Cancel Subscription"}
          </h5>
          <button
            type="button"
            className="btn-close7"
            aria-label="Close"
            onClick={onClose}
          ></button>
        </div>
        <div className="offcanvas-body">
          {/* Pause Section */}
          {actionType === "Pause" && (
            <div className="datepickers6">
              <div className="datepicker-container6">
                <label>Start Date</label>
                <Datepicker
                  className="date-input6"
                  dateValue={isValidDate(startDate) ? startDate : null}
                  onDateChange={(date) => setStartDate(date || null)}
                  placeholder="Select Start Date"
                />
              </div>
            </div>
          )}

          {/* Resume Section */}
          {actionType === "Resume" && (
            <div className="datepickers6">
              <div className="datepicker-container6">
            <label>Resume Date</label>
              <Datepicker
                className="date-input7"
                dateValue={isValidDate(resumeDate) ? resumeDate : null}
                onDateChange={(date) => setResumeDate(date || null)}
                placeholder="Select Resume Date"
              />
            </div>
            </div>
            
          )}

          {/* Reason Section (for Pause or Cancel only) */}
          {actionType !== "Resume" && (
            <div className="subscription-text-box">
              <label>Reason:</label>
              <TextBox
                className="subscription-reason-textbox"
                placeholder={`Reason for ${actionType.toLowerCase()}ing subscription...`}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </div>
          )}

          {/* Action Buttons */}
          <div className="subscription-button-container2">
            <ButtonComponent
              className={`subscription-save-button ${
                actionType === "Cancel"
                  ? "cancel-button"
                  : actionType === "Pause"
                  ? "pause-button"
                  : "resume-button"
              }`}
              text={
                actionType === "Pause"
                  ? "Save Pause"
                  : actionType === "Cancel"
                  ? "Cancel Subscription"
                  : "Resume Subscription"
              }
              onClick={handleConfirm}
            >
              {actionType === "Pause"
                ? "Save Pause"
                : actionType === "Cancel"
                ? "Cancel Subscription"
                : "Resume Subscription"}
            </ButtonComponent>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionPauseOffcanvas;
