// actions/productActions.js
import {
  createSingleBasicProduct,
  fetchBulkBasicProducts,
  createBulkBasicProducts,
  updateSingleBasicProduct,
  updateBulkBasicProducts,
  deleteSingleBasicProduct,
  deleteBulkBasicProducts,
  createSingleCustomProduct,
  createBulkCustomProducts,
  fetchBulkCustomProducts,
  updateSingleCustomProduct,
  updateBulkCustomProducts,
  deleteSingleCustomProduct,
  deleteBulkCustomProducts,
} from '../../api/services/productService';

// Action Types for Single Product Operations
export const FETCH_SINGLE_PRODUCT_SUCCESS = 'FETCH_SINGLE_PRODUCT_SUCCESS';
export const CREATE_SINGLE_PRODUCT_SUCCESS = 'CREATE_SINGLE_PRODUCT_SUCCESS';
export const UPDATE_SINGLE_PRODUCT_SUCCESS = 'UPDATE_SINGLE_PRODUCT_SUCCESS';
export const DELETE_SINGLE_PRODUCT_SUCCESS = 'DELETE_SINGLE_PRODUCT_SUCCESS';

// Action Types for Bulk Product Operations
export const FETCH_BULK_PRODUCTS_SUCCESS = 'FETCH_BULK_PRODUCTS_SUCCESS';
export const CREATE_BULK_PRODUCTS_SUCCESS = 'CREATE_BULK_PRODUCTS_SUCCESS';
export const UPDATE_BULK_PRODUCTS_SUCCESS = 'UPDATE_BULK_PRODUCTS_SUCCESS';
export const DELETE_BULK_PRODUCTS_SUCCESS = 'DELETE_BULK_PRODUCTS_SUCCESS';

// Common Error Action Type
export const ERROR = 'ERROR';

// Fetch All Products (Basic and Custom)
export const fetchProducts = (token) => async (dispatch) => {
  try {
    const basicProducts = await fetchBulkBasicProducts(token);
    const customProducts = await fetchBulkCustomProducts(token);
    dispatch({
      type: FETCH_BULK_PRODUCTS_SUCCESS,
      payload: { basicProducts, customProducts },
    });
  } catch (error) {
    console.error('Error fetching products:', error);
    dispatch({ type: ERROR, payload: error.message });
  }
};

// Single Product Create Workflow for Basic and Custom Products
export const createSingleProductWorkflow = (basicProductData, customProductData, token) => async (dispatch) => {
  // try {
    // Step 1: Create a single Basic Product and get its ID
    const createdBasicProduct = await createSingleBasicProduct(basicProductData, token);
    const newProductId = createdBasicProduct.product_id;

    // Step 2: Link the new Basic Product ID to the Custom Product data
    const customDataWithId = { ...customProductData, product_id: newProductId };

    // Step 3: Create a single Custom Product with the linked ID
    const createdCustomProduct = await createSingleCustomProduct(customDataWithId, token);
    return true
    // dispatch({
    //   type: CREATE_SINGLE_PRODUCT_SUCCESS,
    //   payload: { basicProduct: createdBasicProduct, customProduct: createdCustomProduct },
    // });
    // //console.log('Single Basic and Custom product created successfully');
  // } catch (error) {
  //   console.error('Error in createSingleProductWorkflow:', error);
  //   dispatch({ type: ERROR, payload: error.message });
  // }
};

// Bulk Create Workflow for Basic and Custom Products
export const createProductsWorkflow = (basicProductsData, customProductsData, token) => async (dispatch) => {
  try {
    const createdBasicProducts = await Promise.all(
      basicProductsData.map((product) => createSingleBasicProduct(product, token))
    );
    const newProductIds = createdBasicProducts.map((product) => product.product_id);

    const allBasicProducts = await fetchBulkBasicProducts(token);

    const orderedNewProducts = newProductIds.map((id) =>
      allBasicProducts.find((product) => product.product_id === id)
    );

    const customDataWithIds = customProductsData.map((customProduct, index) => ({
      ...customProduct,
      product_id: orderedNewProducts[index]?.product_id,
    }));

    await createBulkCustomProducts(customDataWithIds, token);

    dispatch({ type: CREATE_BULK_PRODUCTS_SUCCESS, payload: { basicProducts: createdBasicProducts } });
    return true

  } catch (error) {
    console.error('Error in createProductsWorkflow:', error);
    dispatch({ type: ERROR, payload: error.message });
    return false;
  }
};

// Single Product Update Workflow
export const updateSingleProductWorkflow = (basicProductData, customProductData, token) => async (dispatch) => {
  try {
    // Update single Basic and Custom Product
    const updatedBasicProduct = await updateSingleBasicProduct(basicProductData, token);
    const customDataWithId = { ...customProductData, product_id: basicProductData.product_id };
    const updatedCustomProduct = await updateSingleCustomProduct(customDataWithId, token);

    dispatch({
      type: UPDATE_SINGLE_PRODUCT_SUCCESS,
      payload: { basicProduct: updatedBasicProduct, customProduct: updatedCustomProduct },
    });
    //console.log('Single Basic and Custom product updated successfully');
    return true;

  } catch (error) {
    console.error('Error updating product:', error);
    dispatch({ type: ERROR, payload: error.message });
    return false;
  }
};

// Bulk Update Workflow
export const updateProductsWorkflow = (basicProductsData, customProductsData, token) => async (dispatch) => {
  try {
    await updateBulkBasicProducts(basicProductsData, token);
    if(customProductsData){
      await updateBulkCustomProducts(customProductsData, token);
    }
    

    // dispatch({ type: UPDATE_BULK_PRODUCTS_SUCCESS });
    //console.log('Basic and Custom products updated successfully');
    return true;
  } catch (error) {
    console.error('Error updating products:', error);
    dispatch({ type: ERROR, payload: error.message });
    return false;
  }
};

// Single Product Delete Workflow
export const deleteSingleProductWorkflow = (productId, token) => async (dispatch) => {
  // try {
    await deleteSingleBasicProduct(productId, token);
    await deleteSingleCustomProduct(productId, token);

    dispatch({ type: DELETE_SINGLE_PRODUCT_SUCCESS, payload: productId });
    //console.log('Single Basic and Custom product deleted successfully');
  // } catch (error) {
  //   console.error('Error deleting product:', error);
  //   dispatch({ type: ERROR, payload: error.message });
  // }
};

// Bulk Delete Workflow
export const deleteProductsWorkflow = (productIds, token) => async (dispatch) => {
  // try {
    await deleteBulkBasicProducts(productIds, token);
    await deleteBulkCustomProducts(productIds, token);

    dispatch({ type: DELETE_BULK_PRODUCTS_SUCCESS, payload: productIds });
  //   //console.log('Basic and Custom products deleted successfully');
  // } catch (error) {
  //   console.error('Error deleting products:', error);
  //   dispatch({ type: ERROR, payload: error.message });
  // }
};
