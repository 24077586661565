import React from "react";
import "./refund.scss";

const RefundPolicy = () => {
  return (
    <div className="policy-container">
      <div className="header">
        <h1>Refund Policy</h1>
      </div>
      <div className="template-box">
        <div className="content-box">
          <h2>Refund Policy</h2>
          <p className="effective-date">Last Updated: Feburary 28, 2025</p>
          <p>At Pmpharma, we strive to ensure customer satisfaction. Please review our refund guidelines below:</p>
          <h3>Refund Eligibility</h3>
          <ul>
            <li><strong>Prescription Medications:</strong> Refunds are not provided unless the product is damaged, defective, or incorrect as per the prescription.</li>
         
          </ul>
          <h3>Refund Processing</h3>
          <p>Refunds will be credited back to the original payment method or provided as store credit based on customer preference.</p>
          <p>Refunds may take 3-5 business days to process after approval.</p>
          <h3>Exclusions</h3>
          <p>Refunds will not be provided for:</p>
          <ul>
            <li>Opened or used prescription medications.</li>
            <li>Products returned beyond the eligible refund period.</li>
            <li>Products damaged due to mishandling post-delivery.</li>
          </ul>
          <h3>Contact Us</h3>
          <p>For refund assistance, please contact our customer support:</p>
          <ul className="contact-list">
            <li><strong>Email:</strong> pmpharama.in@gmail.com</li>
            <li><strong>Phone:</strong> 8825745035</li>
          </ul>
        </div>
        <div className="contact-box">
          <h2>Need Help?</h2>
          <p>For any queries, reach out to our customer support team.</p>
          <p className="contact-number">8825745035</p>
          <p className="contact-email">pmpharama.in@gmail.com</p>
        </div>
      </div>
    </div>
  );
};

export default RefundPolicy;
