import React, { useState } from 'react';
import { Offcanvas, Button } from 'react-bootstrap';
import './BrandsOffcanvas.scss';

const BrandsOffcanvas = ({
  show,
  handleClose,
  brands = [],
  categories = [],
  selectedBrands = null,
  selectedCategories = [],
  onBrandChange,
  onCategoryChange,
  onClearBrand,
  onClearCategory,
}) => {
  const [activeTab, setActiveTab] = useState('Brand'); // Default to Brand tab

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="bottom"
      className="brands-offcanvas"
      backdrop={true}
      scroll={true}
    >
      <Offcanvas.Header>
        <Offcanvas.Title>Filters</Offcanvas.Title>
        <Button variant="primary" className="close-button" onClick={handleClose}>
            X
          </Button>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="offcanvas-content">
          <div className="tabs">
            <button
              className={activeTab === 'Brand' ? 'tab active' : 'tab'}
              onClick={() => setActiveTab('Brand')}
            >
              Brands
            </button>
            <button
              className={activeTab === 'Category' ? 'tab active' : 'tab'}
              onClick={() => setActiveTab('Category')}
            >
              Categories
            </button>
          </div>
          <div className="tab-content">
            {activeTab === 'Brand' && (
              <div className="filter-options">
                {brands.map((brand) => (
                  <div className="filter-option" key={brand.brand_id}>
                    <label>
                      <input
                        type="radio"
                        name="brand"
                        checked={selectedBrands === brand.brand_id}
                        onChange={() => onBrandChange(brand.brand_id)}
                      />
                      <span>{brand.name}</span>
                    </label>
                  </div>
                ))}
              </div>
            )}
            {activeTab === 'Category' && (
              <div className="filter-options">
                {categories.map((category) => (
                  <div className="filter-option" key={category.category_id}>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedCategories.includes(category.category_id)}
                        onChange={() => onCategoryChange(category.category_id)}
                      />
                      <span>{category.name}</span>
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="offcanvas-footer">
          <Button
            variant="link"
            className="clear-button"
            onClick={() => {
              onClearBrand();
              onClearCategory();
            }}
          >
            Clear
          </Button>
         
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default BrandsOffcanvas;
