import React, { useState, useEffect } from 'react';
import './card-items.scss';
import ButtonComponent from '../buttons/ButtonComponent';
import aloe from '../../../assets/images/aloe.avif';
import gel from '../../../assets/images/gel.avif';
import Noproduct from '../../../assets/images/Noproduct.webp';
import QuantityInput from '../QuantityInput/QuantityInput';

const CardItem = ({
  imageUrl,
  title,
  company,
  size,
  price,
  mrp,
  discount,
  onRemove,
  onClick,
  onIncrease,
  onDecrease,
  onAddToCart,
  quantity,
  min_order,
  manage,
  stock_level,
  onPause, // Callback for pause action
  onClose, // Callback for close action
  hidePause = false, // Prop to hide the Pause button, default to false
  hideClose = false,
  showAddButton = false, // Prop to conditionally show the Add button
  popupState = true
  
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const imageMap = {
    aloe,
    gel,
  };
  const isOutOfStock = stock_level < min_order;
  return (
    <div   className={`card-main-container ${isOutOfStock ? 'out-of-stock-card disabled-card' : ''}`} onClick={onClick}>
      {loading ? (
        <div className="card-skeleton-loader">
        {/* Skeleton Loaders */}
        <div className="skeleton-loader large"></div>
       
      </div>
      ) : (
        <>
        <div className={`card-img-class ${isOutOfStock ? 'out-of-stock-overlay' : ''}`}>
            <img
              src={imageUrl || Noproduct || imageMap[title.toLowerCase()] || ''}
              alt={title}
              className="img-fluid card-image"
            />
          </div>
          <div className="card-details">
            <h6 className="card-title">{title}</h6>
            <p className="card-company">{company}</p>
            <p className="card-size">{size}</p>
            <h5 className="card-price">
              ₹{price}{' '}
              <span className="text-muted">
                <s>MRP ₹{mrp}</s>
              </span>{' '}
              <span className="discount-text">{discount}% OFF</span>
            </h5>

            {/* Pause and Close Icons */}
            <div className="card-icons">
                {!hidePause && (
                  <button
                    className="pause-button"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent event bubbling
                      if (onPause) {
                        onPause(); // Call the pause action if provided
                      }
                    }}
                    disabled={isOutOfStock} 
                  >
                    Pause
                  </button>
                )}
                {!hideClose && (
                  <button
                    className="close-button5"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent event bubbling
                      if (onClose) {
                        onClose(); // Call the close action if provided
                      }
                    }}
                  >
                    Cancel
                  </button>
                )}
              </div>

          </div>
          <div className="card-right">
            {manage ? (
              <div className="card-quantity-display">
                <span>Quantity: {quantity}</span>
              </div>
            ) : (
              <>
               {isOutOfStock ? (
  <button className="out-of-stock-button" disabled>
    Out of Stock
  </button>
) : quantity > 0 ? (
  <div className="card quantity-input-wrapper" onClick={(e) => e.stopPropagation()}>
    <QuantityInput
      onRemove={onRemove}
      onIncrease={onIncrease}
      onDecrease={onDecrease}
      quantity={quantity}
      min_order={min_order}
      popupState={popupState}
    />
  </div>
) : (
  showAddButton && (
    <button
      className="add-to-cart-btn"
      onClick={(e) => {
        e.stopPropagation();
        onAddToCart();
      }}
    >
      Add
    </button>
  )
)}

              </>
            )}
            <div
              className="card-icon"
              onClick={(e) => {
                e.stopPropagation();
                onRemove();
              }}
            >
              {/* Icon for Remove (can be added later) */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CardItem;