/* global Razorpay */
import React, { useState, useEffect } from "react";
import BillDetailCard from "../common/card/billdetails-card";
import ButtonComponent from "../common/buttons/ButtonComponent";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllocatedCoupons } from "../../redux/actions/couponAllocationAction";
import { removeCoupon } from "../../redux/actions/setCouponAction";
import PharmaModal from '../sections/LoginModal/LoginModal';
import LoginModal from "../sections/LoginModal/LoginModal";
import AddressOffcanvas from "../common/address-offcanvas/AddressOffcanvas";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { createRazorpayOrder,verifyRazorpayPayment } from '../../api/services/razorPayService';
import { useNavigate } from "react-router-dom";
import { clearSubscriptionCookie } from "../../utils/schema/cookieSubscription";
// Helper function to calculate subscription cost
import { createSubscription } from "../../redux/actions/subscriptionAction";
const calculateSubscriptionCost = (data) => {
  const {
    frequency,
    price,
    quantity,
    timings = [],
    selectedDays = [], // Days like 0 (Sunday), 3 (Wednesday), etc.
    selectedMonthDate,
    selectedAnuallyDate,
    startDate,
    endDate,
  } = data;
 
  const start = new Date(startDate);
  const end = new Date(endDate);
  let totalCost = 0;

  switch (frequency) {
    case "daily": {
      const totalDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24)) + 1;
      totalCost = totalDays * (timings.length || 1) * price * quantity;
      break;
    }
    case "weekly": {
      let dayOccurrences = 0;
    
      // Map day names to numeric values
      const dayNameToNumber = {
        Sunday: 0,
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
      };
    
      // Convert selected day names to numeric values
      const numericSelectedDays = selectedDays.map(day => dayNameToNumber[day]);

      for (let current = new Date(start); current <= end; current.setDate(current.getDate() + 1)) {
        // Check if the current day (numeric) is in numericSelectedDays
        if (numericSelectedDays.includes(current.getDay())) {
          dayOccurrences += 1;
        }
      }
    
      totalCost = dayOccurrences * price * quantity * timings.length;
      break;
    }
    case "monthly": {
      const startDate = new Date(start);
      const endDate = new Date(end);
      let current = new Date(startDate); // Start calculation from the start date
    
      while (current <= endDate) {
        current.setHours(0, 0, 0, 0); // Reset time to avoid time-related issues
    
        const daysInMonth = new Date(
          current.getFullYear(),
          current.getMonth() + 1,
          0
        ).getDate(); // Get the total number of days in the current month
    
        // Iterate over all selected dates
        selectedMonthDate.forEach((selectedDay) => {
          // Check if the selected day exists in the current month
          if (selectedDay > 0 && selectedDay <= daysInMonth) {
            const occurrenceDate = new Date(
              current.getFullYear(),
              current.getMonth(),
              selectedDay
            );
    
            // Check if the occurrence date is within the start and end dates
            if (occurrenceDate >= startDate && occurrenceDate <= endDate) {
              totalCost += price * quantity; // Add the cost for each valid occurrence
            }
          }
        });
    
        current.setMonth(current.getMonth() + 1); // Move to the next month
        current.setDate(1); // Reset to the 1st day of the next month
      }
    
      break;
    }
    

    
    case "annually": {
      const selectedDay = new Date(selectedAnuallyDate).getDate(); // Extract day
      const selectedMonth = new Date(selectedAnuallyDate).getMonth(); // Extract month (zero-based)
    
      let current = new Date(start.getFullYear(), selectedMonth, selectedDay);
    
      while (current <= end) {
        current.setHours(0, 0, 0, 0); // Reset time
        const daysInMonth = new Date(
          current.getFullYear(),
          selectedMonth + 1,
          0
        ).getDate(); // Total days in the selected month
    
        // Check if the selected day exists in the current month
        if (selectedDay > 0 && selectedDay <= daysInMonth) {
          totalCost += price * quantity; // Add the cost for the selected day
        }
    
        current.setFullYear(current.getFullYear() + 1); // Move to the next year
      }
    
      break;
    }
    
    
    default:
      totalCost = 0;
  }

  return totalCost;
};
const PaymentMethodModal = ({ show, handleClose, onPaymentSelect }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Select Payment Method</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-around">
          <Button variant="outline-primary" onClick={() => onPaymentSelect("cash")}>
            Cash
          </Button>
          <Button variant="outline-success" onClick={() => onPaymentSelect("online")}>
            Online Payment
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
const BillDetailsCard = ({ subscriptionData }) => {
  const [subscriptionCost, setSubscriptionCost] = useState(0);
  const [isProceedToPay, setIsProceedToPay] = useState(false);
  const [showAddressOffcanvas, setShowAddressOffcanvas] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const navigate = useNavigate()
  const userid = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const address_id = useSelector((state) => state.address.selectedAddressId) || "";

  const dispatch = useDispatch();

  useEffect(() => {
    const baseCost = calculateSubscriptionCost(subscriptionData);
    setSubscriptionCost(baseCost);
  }, [subscriptionData]);

  const handleAddAddressClick = () => {
    if (!userid) {
      setShowLoginModal(true); // Show Login Modal
    } else if (!address_id) {
      setShowAddressOffcanvas(true); // Show Address Selection
    } else if (isProceedToPay) {
      setShowPaymentModal(true); // Show Payment Method Modal
    } else {
      setIsProceedToPay(true); // Proceed to checkout
    }
  };

  const handleOnlinePayment = async () => {
    //console.log("Online Payment Triggered");
    try {
      
      const razorpayOrderResponse = await createRazorpayOrder(token, {
        total_amount: parseInt(subscriptionCost), // Convert to paisa
      });

      if (!razorpayOrderResponse || !razorpayOrderResponse.razorpay_order.id) {
        throw new Error("Failed to create Razorpay order.");
      }

      const options = {
        key: "rzp_test_1P0GnYfJCtrjSx", // Replace with your Razorpay key
        amount: parseInt(subscriptionCost),
        currency: "INR",
        name: "Pharma Subscription",
        description: "Subscription Payment",
        order_id: razorpayOrderResponse.razorpay_order.id,
        handler: async (response) => {
          //console.log("Payment Success:", response);

          const verificationPayload = {
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
          };

          const verifyResponse = await verifyRazorpayPayment(token, verificationPayload);

          if (verifyResponse) {
            placeSubscription("online payment",'paid', response.razorpay_payment_id); // Proceed with online payment
          } else {
            // console.error("Payment verification failed.");
          }
        },
      };

      const rzp = new Razorpay(options);
      // placeSubscription("online payment",response.razorpay_payment_id)
      rzp.open();
    } catch (error) {
      // console.error("Online payment error:", error);
    }
  };
  const formatDate = (date) => {
    if (!date) return null; // Handle undefined or null dates
    const d = new Date(date);
    if (isNaN(d)) throw new Error(`Invalid date format: ${date}`);
    return d.toISOString().split("T")[0]; // Convert to YYYY-MM-DD
  };
  const placeSubscription = (selectedPaymentMethod,paidtype, transactionId = null) => {
    //console.log("Placing Subscription with:", selectedPaymentMethod, transactionId);

    const formattedData = {
      user_id: userid,
      shipping_address_id: address_id,
      billing_address_id: address_id,
      frequency: subscriptionData.frequency,
      total_amount: subscriptionCost,
      product_id:subscriptionData.productId,
      quantity: subscriptionData.quantity,
      daily_time: subscriptionData.timings,
      weekly_date: subscriptionData.selectedDays,
      monthly_date: subscriptionData.selectedMonthDate,
      yearly_date: subscriptionData.selectedAnuallyDate,
      start_date: formatDate(subscriptionData.startDate), // Format start_date
      end_date: formatDate(subscriptionData.endDate),
      payment_status: paidtype,
      payment_method: selectedPaymentMethod,
      transaction_id: transactionId, // Include transaction ID for online payment
      status:"active",
    };
    //console.log("Subscription Data:", formattedData);

    const response_data = dispatch(createSubscription(formattedData, token));
    if (response_data){
      clearSubscriptionCookie()
      navigate('/subscription-details')
    }
    //console.log("Subscription Data:", formattedData);
    setShowPaymentModal(false); 
  };

  return (
    <div className="bill-details-card">
      <PharmaModal show={showLoginModal} handleClose={() => setShowLoginModal(false)} />

      <AddressOffcanvas
        show={showAddressOffcanvas}
        handleClose={() => setShowAddressOffcanvas(false)}
        onSaveAndContinueClick={() => setShowAddressOffcanvas(false)}
      />

      <PaymentMethodModal
        show={showPaymentModal}
        handleClose={() => setShowPaymentModal(false)}
        onPaymentSelect={(method) => {
          //console.log(`Payment Method Selected: ${method}`);
          if (method === "cash") {
            placeSubscription("cash","unpaid");
          } else {
            handleOnlinePayment();
          }
        }}
      />

      <h5>Subscription Bill Details</h5>
      <hr />
      <BillDetailCard label="Frequency" value={subscriptionData.frequency} isSmall />
      <BillDetailCard label="Subscription Price" value={`₹${subscriptionData.price}`} isSmall />
      <BillDetailCard label="Quantity" value={subscriptionData.quantity} isSmall />
      <BillDetailCard label="Base Subscription Cost" value={`₹${subscriptionCost}`} isSmall />

      <div className="bill-estimated-payable">
        <p className="bill-estimated">Estimate Payable</p>
        <h6 className="bill-amount">₹{subscriptionCost}</h6>
        <p className="text-muted">Inclusive of all taxes*</p>
      </div>

      <ButtonComponent
        text={
          !userid
            ? "Proceed to Login"
            : !address_id
            ? "Add Address"
            : isProceedToPay
            ? "Proceed to Pay"
            : "Proceed to Checkout"
        }
        onClick={handleAddAddressClick}
        className="add-address-btn"
      />
    </div>
  );
};

export default BillDetailsCard;