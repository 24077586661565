import { fetchCategory, deleteCategory, bulkDeleteCategories } from '../../redux/actions/categoryAction';
import React, { useState, useRef ,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { faHome, faSearch,  faPencilAlt,faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ButtonComponent from '../../components/common/buttons/ButtonComponent';

import CheckboxComponent from '../../components/common/CheckboxComponent/CheckboxComponent';
import AddItemsAdminModal from '../../components/sections/AddItems-adminModal/AddItems-adminModal';
import AdminCategoryOffcanvas from '../../components/common/AdminCategoryOffcanvas/AdminCategoryOffcanvas';
import './categorylistpage.scss';
import { useDispatch, useSelector } from 'react-redux';


const CategoryDisplayPage = () => {
  const dispatch = useDispatch(); // Access the dispatch function
  const navigate = useNavigate();
    
    const navigationOptions = {
        gridPath: '/admin/add-category', // Modify as needed
        sheetPath: '/admin/uploadpage',   // Modify as needed
        from:'category',
      };

    const token = useSelector(state=>state.adminLogin.token);
    const categories = useSelector(state => state.category.categoryList.categories)|| [];
    useEffect(() => {
      const fetchInitialCategories = () => {
          dispatch(fetchCategory(token));
      };
  
      fetchInitialCategories(); // Fetch on mount
  
      const intervalId = setInterval(fetchInitialCategories, 180000); // 180,000 ms = 3 minutes
  
      return () => clearInterval(intervalId); // Cleanup on unmount
  }, [dispatch]);
  
  useEffect(() => {
      setTableData(categories.map(category => ({
          ...category,
          checked: false, // Initialize checked property
      })));
  }, [categories]); // Update tableData when categories change
  


   
    const handleDelete = async (categoryId) => {
        if (window.confirm('Are you sure you want to delete this category?')) {
          try {
            dispatch(deleteCategory(categoryId,token)); 
           
           
          } catch (error) {
          
          }
        }
      };

  const [tableData, setTableData] = useState([
    { name: 'TOM YUM CHICK', shortCode: 'TOM', checked: false, onlineDisplayName: '', price: 0, description: '', id: '', product_id: '', product_name: '', category: '', discount: 0, mrp: 0, unit: '', pcs_per_unit: '', stock_level: '', minimum_order_quantity: '', max_order_quantity: '', product_weight: '', dimensions: '', storage_conditions: '', packaging_type: '', delivery_timeframe: '', shipping_price: '', returns_policy: '', created_at: '', updated_at: ''

     },
   
  ]);
  
  const [selectAll, setSelectAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const handleOffcanvasOpen = () => setShowOffcanvas(true);
  const handleOffcanvasClose = () => setShowOffcanvas(false);
  const [searchTerm, setSearchTerm] = useState(''); // Search term state
  const [showSearchFilters, setShowSearchFilters] = useState(false);
  const toggleSearchFilters = () => setShowSearchFilters(!showSearchFilters);
  const [selectedIds, setSelectedIds] = useState([]);



  const handleActionSelect = (action) => {
    if (selectedIds.length === 0) {
        alert("Please select at least one category to proceed.");
        return;
    }

    if (action === "edit") {
        //console.log("Editing categories:", selectedIds);
        navigate('/admin/bulk-category', { state: selectedIds } )
        setSelectedIds([]);
        // Implement edit functionality here
    } else if (action === "delete") {
        if (window.confirm(`Are you sure you want to delete the selected categories?`)) {
            //console.log("Deleting categories:", selectedIds);
            dispatch(bulkDeleteCategories(selectedIds,token));
            setSelectedIds([]);
        }
    }
};


  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (category.description && category.description.toLowerCase().includes(searchTerm.toLowerCase()))
  );
  // Function to handle the 'select all' checkbox
  

  const handleCheckboxChange = (categoryId) => {
    // Update selectedIds based on the checkbox state
    if (selectedIds.includes(categoryId)) {
        // If already checked, remove from selectedIds
        setSelectedIds(prev => prev.filter(id => id !== categoryId));
    } else {
        // If not checked, add to selectedIds
        setSelectedIds(prev => [...prev, categoryId]);
    }
};


  

    const handleEditClick = (id) => {
      navigate('/admin/add-category',{state:id});

    };

  const fileInputRef = useRef(null);

  const handleSelectAll = () => {
    if (selectAll) {
        // If currently all checked, uncheck all
        setSelectedIds([]);
    } else {
        // If currently not all checked, check all
        const allIds = categories.map(item => item.category_id);
        setSelectedIds(allIds);
    }
    setSelectAll(!selectAll); // Toggle selectAll state
};

useEffect(() => {
  if (selectedIds.length === tableData.length) {
      setSelectAll(true);
  } else {
      setSelectAll(false);
  }
}, [selectedIds, tableData.length]);

  return (
    <div>
    
      <div className="admin-category-page-layout">
      <div className="breadcrumb-container">
      <Link to="/admin/dashboard" className="breadcrumb-text">
      <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        </Link>
        
        <span className="breadcrumb-separator">{'>'}</span>
        <span className="breadcrumb-text">
          Category List
        </span>
       
      </div>
      <div className="categorylist-container"></div>
      <div className="admin-category-page-container">
        <div className="right-section">
        <h5 className='text-heading1'>Category List</h5>
          <div className="toolbar">
         
            <div className="search-wrapper" onClick={toggleSearchFilters}>
              <FontAwesomeIcon icon={faSearch} className="icon" />
              <input type="text" className="search-bar" placeholder="Search"
               onChange={(e) => setSearchTerm(e.target.value)} />
            </div>
            <div className="item-controls">
            <ButtonComponent text="+ Add Categories" variant="dark" className="add-items-button" onClick={handleModalShow} />
            
            <div className="actions-toolbar">
        <Dropdown>
          <Dropdown.Toggle variant="primary" id="actions-dropdown" className='dropdown-toggle3'>
            Actions
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleActionSelect("edit")}>Edit</Dropdown.Item>
            <Dropdown.Item onClick={() => handleActionSelect("delete")}>Delete</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
          </div>
          </div>

        
    <div className="table-container">
    { filteredCategories.length === 0 ? (
    <tr>
       <div className="no-categories-message">
        No categories found.
    </div>
    </tr>
  ) : (
    <table className="custom-table">
      <thead>
        <tr>
            <th>
            <CheckboxComponent
            checked={selectAll}
            onChange={handleSelectAll}

            />
          </th>
          <th>ID</th>
          <th>Category Name</th>
          <th>Description</th>
          {/* <th>Parent Category Id</th> */}
       
          <th>Status</th>
        
          <th>Sort Order</th>
          <th>Actions</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
      {
      filteredCategories.map((item, index) => (
      <tr key={`${index}-${item.category_id}`}>
      <td>
        <CheckboxComponent
          checked={selectedIds.includes(item.category_id)}
          onChange={() => handleCheckboxChange(item.category_id)}
        />
      </td>
      <td  className="table-input">
        
       
          {index+1}

        
      </td>
      <td  className="table-input">
       
          {item.name}
        
      </td>
      <td  className="table-input">
        
        
          {item.description}
       
      </td>
    
      <td  className="table-input">
      
          {item.status}
   
      </td>
    
      <td  className="table-input">
       
          {item.sort_order}
    
      </td>
      <td className="actions-column">
        <button
          className="icon-button"
          onClick={() => handleEditClick(item.category_id)}
        >
          <FontAwesomeIcon icon={faPencilAlt} />
        </button>
      </td>
      <td className="actions-column">
        <button 
          className="icon-button" 
          onClick={() => handleDelete(item.category_id)}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </td>
      </tr>
      ))
      }
      </tbody>

          </table>
           )}
        </div>
          <AddItemsAdminModal show={showModal} handleClose={handleModalClose} navigationOptions={navigationOptions}/>

         
        </div>
      </div>
      </div>

      <AdminCategoryOffcanvas show={showOffcanvas} onClose={handleOffcanvasClose} />
    </div>
  );
};

export default CategoryDisplayPage;
