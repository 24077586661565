import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import CategoriesOffcanvas from "../../components/CategoriesOffcanvas/CategoriesOffcanvas";
import ProductCard from "../../components/common/ProductCard/ProductCard";
import { useSelector, useDispatch } from "react-redux";
import { generatePresignedUrlAPI } from "../../api/services/filestorageService";
import {
  addToCart,
  decreaseCartItem,
  increaseCartItem,
  deleteCartItem,
} from "../../redux/actions/Addtocart";
import { Link, useLocation } from "react-router-dom";
import "./Categories.scss";
import toast from "react-hot-toast";
import { useNavigate } from 'react-router-dom';

const Categories = () => {

  useEffect(() => {
    const scrollBehavior = document.documentElement.style.scrollBehavior;
    document.documentElement.style.scrollBehavior = 'auto'; // Disable smooth scrolling
    window.scrollTo(0, 0);
    document.documentElement.style.scrollBehavior = scrollBehavior; // Restore smooth scrolling
  }, []);
  

  const dispatch = useDispatch();
   const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector((state) => state.auth.token) || "";

  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [updatedProducts, setUpdatedProducts] = useState([]); // Products with updated URLs
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryName, setCategoryName] = useState("");
  const authData = useSelector((state) => state.auth.user) || '';

  const categories = useSelector(
    (state) => state.category.categoryList.categories
  ) || [];
  const brands = useSelector((state) => state.brand.brandList.brands) || [];
  const products = useSelector((state) => state.products.basicProducts) || [];
  const productUrls = useSelector((state) => state.image.urls.products) || {};
  //console.log(productUrls,'-----product urlsss')
  const cartItems = useSelector((state) => state.cart.cartItems) || [];
  const locationCategoryId = location.state || null;

 

  const handleClearCategory = () => {
    setSelectedCategory(null);
    filterProducts(null, selectedBrands);
  };

  const handleClearBrands = () => {
    setSelectedBrands([]);
    filterProducts(selectedCategory, []);
  };
  // Generate presigned URLs for all products
  const generatePresignedUrls = async () => {
    try {
      const updatedList = await Promise.all(
        products.map(async (product) => {
          if (product.image_url) {
            const presignedUrlData = await generatePresignedUrlAPI(
              product.image_url,
              token
            );
            //console.log(presignedUrlData,'----pre signed url')
            return {
              ...product,
              url: presignedUrlData?.presigned_url || product.url,
            };
          }
          return { ...product, url: "" };
        })
      );
      setUpdatedProducts(updatedList);
    } catch (error) {
      // console.error("Error generating presigned URLs:", error);
    }
  };

  // Filter products based on category and brand selections
  const filterProducts = (categoryId, brandIds) => {
    let filtered = products.map((product) => ({
      ...product,
      url: productUrls[product.product_id] || product.image_url, // Use URL from Redux or fallback to original
    }));

    if (categoryId) {
      filtered = filtered.filter(
        (product) => product.category_id === categoryId
      );
    }

    if (brandIds.length > 0) {
      filtered = filtered.filter((product) => brandIds.includes(product.brand_id));
    }

    setFilteredProducts(filtered);
  };


  // Handle category change
  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
    filterProducts(categoryId, selectedBrands);

    const category = categories.find((cat) => cat.category_id === categoryId);
    setCategoryName(category ? category.name : "");
  };

  // Handle brand change
  const handleBrandChange = (brandId) => {
    const updatedBrands = selectedBrands.includes(brandId)
      ? selectedBrands.filter((id) => id !== brandId)
      : [...selectedBrands, brandId];

    setSelectedBrands(updatedBrands);
    filterProducts(selectedCategory, updatedBrands);
  };
  const handleProductClick = (productId, productName) => {
   
    navigate(`/singleproducts/${encodeURIComponent(productName)}`, { state: productId });
    setTimeout(() => {
      window.scrollTo(0, 0); // Ensure it scrolls to the top after navigation
    }, 100);
  };

  // Initialize and refresh product URLs
  useEffect(() => {
    generatePresignedUrls();
  }, [products, token]);

  // Apply initial filters based on location state
  useEffect(() => {
    if (locationCategoryId) {
      setSelectedCategory(locationCategoryId);
      const category = categories.find(
        (cat) => cat.category_id === locationCategoryId
      );
      setCategoryName(category ? category.name : "");
    }
  }, [locationCategoryId, categories]);

  // Automatically filter products when URLs are updated
  useEffect(() => {
    filterProducts(selectedCategory, selectedBrands);
  }, [updatedProducts]);

  const handleAddToCart = (product) => {
    //console.log("Adding to cart:", product);
    if (authData) {
      dispatch(addToCart(product, token));
    } else {
      dispatch(addToCart(product, ''));
    }
  };
  const handleIncreaseQuantity = (productId) => {

    if(authData){
      const cartItem = cartItems.find((item) => item.product_id === productId.product_id);
    if (cartItem && cartItem.quantity < Number(productId.max_order_quantity) && cartItem.quantity <= Number(productId.stock_level)) {
      const updatedCartItem = { ...cartItem, quantity: cartItem.quantity + 1 };
      dispatch(increaseCartItem(updatedCartItem, token));
    }
    else{
      if(cartItem.quantity >= Number(productId.max_order_quantity)){
        toast.error(`A user can order only up to the specified maximum limit of ${productId.max_order_quantity} items for this product.`);
      }
      else{
        toast.error(`Cannot add more! Only ${productId.stock_level} units available.`);
      }
    }
    
    }
    else{
      const cartItem = cartItems.find((item) => item.product_id === productId.product_id);
      if (cartItem && cartItem.quantity < Number(productId.max_order_quantity) && cartItem.quantity <= Number(productId.stock_level)){
        dispatch(increaseCartItem(productId,''));
      }
      else{
        if(cartItem.quantity >= Number(productId.max_order_quantity)){
          toast.error(`A user can order only up to the specified maximum limit of ${productId.max_order_quantity} items for this product.`);
        }
        else{
          toast.error(`Cannot add more! Only ${productId.stock_level} units available.`);
        }
      }
      
    }
    
  };

  const handleDecreaseQuantity = (productId) => {
    if(authData){
     
    const cartItem = cartItems.find((item) => item.product_id === productId.product_id);
    //console.log('order quantityt',cartItem.quantity,productId.minimum_order_quantity,productId)
    if (cartItem && cartItem.quantity > Number(productId.minimum_order_quantity))  {
      const updatedCartItem = { ...cartItem, quantity: cartItem.quantity - 1 };
      dispatch(decreaseCartItem(updatedCartItem, token));
    }

    else{
      removefromCart(productId.product_id)
    }
  }
  else{
    const cartItem = cartItems.find((item) => item.product_id === productId.product_id);
    if (cartItem && cartItem.quantity > Number(productId.minimum_order_quantity)){
      dispatch(decreaseCartItem(productId, ''));
    }
    else{
      removefromCart(productId.product_id)
    }
   
  }
  };

  const removefromCart = (productId) => {
    if(authData){
      const cartItem = cartItems.find((item) => item.product_id === productId);
      if (cartItem) {
        dispatch(deleteCartItem(cartItem.cart_item_id, token));
      }
    }
    else{
      //console.log('cartitemsss removed',authData);
      dispatch(deleteCartItem(productId, ''))
    }


   
  };

  const getProductQuantity = (productId) => {
    const item = cartItems.find((item) => item.product_id === productId);
    return item ? item.quantity : 0;
  };
  //console.log(filteredProducts,'---filtered productsss')

  return (
    <div className="categories-page">
      <div className="breadcrumb-container">
        <Link to="/" className="breadcrumb-text">
          Home
        </Link>
        <span className="breadcrumb-separator"> / </span>
        <span className="breadcrumb-text">Categories</span>
      </div>
      <div className="main-content">
        <div className="left-section">
          <h4>Filters</h4>
          <div className="filters-section">
            <div className="filter-container">
              <h6 className="filter-title">
                Category
                {selectedCategory && (
                  <span
                    className="clear-link"
                    onClick={() => handleCategoryChange(null)}
                  >
                    Clear
                  </span>
                )}
              </h6>
              <hr />
              <div className="filter-options">
                {categories.map((category) => (
                  <div className="filter-option" key={category.category_id}>
                    <label>
                      <input
                        type="radio"
                        name="category"
                        checked={selectedCategory === category.category_id}
                        onChange={() => handleCategoryChange(category.category_id)}
                      />
                      <span>{category.name}</span>
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div className="filter-container">
              <h6 className="filter-title">
                Brands
                {selectedBrands.length > 0 && (
                  <span className="clear-link" onClick={() => setSelectedBrands([])}>
                    Clear
                  </span>
                )}
              </h6>
              <hr />
              <div className="filter-options">
                {brands.map((brand) => (
                  <div className="filter-option" key={brand.brand_id}>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedBrands.includes(brand.brand_id)}
                        onChange={() => handleBrandChange(brand.brand_id)}
                      />
                      <span>{brand.name}</span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="right-section">
          <div className="header">
            <h4 className="skin-care-title">{categoryName}</h4>
            <div className="filter-button" onClick={() => setShowOffcanvas(true)}>
              <FontAwesomeIcon icon={faFilter} />
              <span>Filter</span>
            </div>
          </div>
          <hr />
          <div className="products-grid">
            {filteredProducts.map((product) => (
              <ProductCard
                key={product.product_id}
                discount={`${Number(product.discount)}% off`}
                productName={product.product_name}
                price={product.price}
                mrp={product.mrp}
                quantity={getProductQuantity(product.product_id)}
                image_url={product.url}
                onAddToCart={() => handleAddToCart(product)}
                onIncrease={() => handleIncreaseQuantity(product)}
                onDecrease={() => handleDecreaseQuantity(product)}
                onRemove={() => removefromCart(product.product_id)}
                description={product.description}
                min_order={product.minimum_order_quantity}
                onClick={() => handleProductClick(product.product_id, product.product_name)}
                stock_level={product.stock_level}
              />
            ))}
          </div>
        </div>
      </div>
      <CategoriesOffcanvas
        show={showOffcanvas}
        handleClose={() => setShowOffcanvas(false)}
        categories={categories}
        brands={brands}
        selectedCategory={selectedCategory}
        selectedBrands={selectedBrands}
        onCategoryChange={handleCategoryChange}
        onBrandChange={handleBrandChange}
        onClearCategory={handleClearCategory}
        onClearBrands={handleClearBrands}
      />
    </div>


  );
};

export default Categories;
