import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import InputComponent from '../../components/common/InputComponent/InputComponent'; 
import ButtonComponent from '../../components/common/buttons/ButtonComponent'; 
import { FaTrash, FaPlus } from 'react-icons/fa';
import "./bulk-delivery.scss";
import { updateBulkUsers, createBulkUsers } from "../../redux/actions/adminuserActions";
import toast from "react-hot-toast";
import {  faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const BulkDelivery = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const users = useSelector(state => state.usermanage.users || []);
  const token = useSelector(state => state.adminLogin.token);
  const initialUsers = location.state || [];
  const [userData, setUserData] = useState([]);
  const [duplicateErrors, setDuplicateErrors] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  // Initialize userData on component load
  useEffect(() => {
    const processUsers = () => {
      if (!initialUsers || initialUsers.length === 0) {
        setUserData([{ username: '', email: '', mobile_number: '', internal_id: '',role: 'delivery' }]);
      } else {
        const processedUsers = Array.isArray(initialUsers) && typeof initialUsers[0] === 'object'
          ? initialUsers.map(user => ({
              ...user,
              username: user.username || '',
              email: user.email || '',
              // password:user.password || '',
              mobile_number: user.mobile_number || '',
              internal_id: user.internal_id || '',
              password:user.password||'',
              role: user.role || 'delivery'
            }))
          : initialUsers.map(id => {
              const user = users.find(user => user.user_id === id);
              return user
                ? { ...user, username: user.username || '', email: user.email || '', mobile_number:user.mobile_number ||'', internal_id: user.internal_id||'', role: 'delivery' }
                : { user_id: id, username: '', email: '', password:user.password || '', mobile_number: '', internal_id: '', role: 'delivery' };
            });

        setUserData(processedUsers);
      }
    };

    processUsers();
  }, [initialUsers, users]);

  // Handle input changes for each field
  const handleInputChange = (index, field, value) => {
    setUserData(prevUserData => {
      const updatedUsers = [...prevUserData];
      updatedUsers[index][field] = value;
      return updatedUsers;
    });

    if (field === 'user_id') {
      const updatedErrors = [...duplicateErrors];
      updatedErrors[index] = false; // Reset duplicate errors for user_id
      setDuplicateErrors(updatedErrors);
    }
  };

  // Add a new row for user input
  const handleAddRow = () => {
    setUserData(prevUsers => [
      ...prevUsers,
      { username: '', email: '', password: '', mobile_number: '', internal_id: '', role: 'user' }
    ]);
    setDuplicateErrors(prevErrors => [...prevErrors, false]);
  };

  // Handle deletion of a row
  const handleDelete = (index) => {
    const newUsers = [...userData];
    newUsers.splice(index, 1);
    setUserData(newUsers);
    const newErrors = [...duplicateErrors];
    newErrors.splice(index, 1);
    setDuplicateErrors(newErrors);
  };

  // Check for duplicate mobile numbers
  const checkForDuplicateMobileNumbers = () => {
    const existingNumbers = users.map(user => user.mobile_number.trim().toLowerCase());
    const updatedErrors = userData.map(user => {
      return user.mobile_number && existingNumbers.includes(user.mobile_number.trim().toLowerCase());
    });
    setDuplicateErrors(updatedErrors);
    return updatedErrors.some(error => error);
  };

  // Handle save button click
  const handleSaveClick = async () => {
    setIsSaving(true);
    //console.log("---save")
    // Conditionally format the user data based on initialUsers
    const updatedUserData = userData.map(user => {
      if (typeof initialUsers[0] === 'object') {
        // Exclude user_id if initialUsers[0] is an object
        return {
          username: String(user.username || '').trim(),
          email: String(user.email || '').trim(),
          password:String(user.password|| '').trim(),
          mobile_number: String(user.mobile_number || '').trim(),
          internal_id: String(user.internal_id || '').trim(),

          role: user.role || 'delivery'
        };
      }
      // Include user_id if initialUsers[0] is not an object
      return {
        user_id: String(user.user_id || '').trim(),
        username: String(user.username || '').trim(),
        email: String(user.email || '').trim(),
      
        mobile_number: String(user.mobile_number || '').trim(),
        internal_id: String(user.internal_id || '').trim(),
        role: user.role || 'delivery'
      };
    });

    // Check for empty fields (username, email, password, mobile_number, internal_id)
    const hasEmptyFields = updatedUserData.some(user => {
      if (typeof initialUsers[0] !== 'object') {
        return !user.user_id || !user.username || !user.email ||!user.mobile_number || !user.internal_id ;  // All fields are mandatory
      }
      // console.log('userrr',user)
      return !user.username || !user.email|| !user.mobile_number || !user.internal_id || !user.password;  // Only these fields are mandatory
    });

    if (hasEmptyFields) {
      toast.error("Please fill in all mandatory fields (Username, Email,Mobile Number, Internal ID).");
      setIsSaving(false);
      return;
    }

    // Check for duplicate mobile numbers
    // if (checkForDuplicateMobileNumbers()) {
    //   return;
    // }

    
    let responseStatus = false;

    try {
      if (typeof initialUsers[0] === 'object') {
        // Create new users if initialUsers[0] is an object
        responseStatus = await dispatch(createBulkUsers(updatedUserData, token));
      } else {
        // Update users if initialUsers[0] is not an object
        responseStatus = await dispatch(updateBulkUsers(updatedUserData, token));
      }
    } catch (error) {
      // console.error("Error occurred while saving users:", error);
    }

    setIsSaving(false);

    if (responseStatus) {
      navigate('/admin/user-management');
     
    } else {
      toast.error("Failed to save users.");
      // console.error("Failed to save users");
    }
  };

  return (
    <div className="bulk-delivery-main-page">
    

        <div className="breadcrumb-container">
         
            <Link to="/admin/dashboard" className="breadcrumb-text">
            <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
            </Link>
            <span className="breadcrumb-separator">{'>'}</span>
            <Link to="/admin/user-management" className="breadcrumb-text">
            <span className="breadcrumb-text">Delivery List</span>
            </Link>
            <span className="breadcrumb-separator">{'>'}</span>
            <span className="breadcrumb-text">Bulk delivery</span>
       </div>
       <div className="bulk-delivery-container"></div>
       <div className="bulk-delivery">
    <div className="delivery-table">
    <div className="header-row">
    <h5 className="text-heading1">Bulk Delivery</h5>
      <div className="add-new-button">
        {typeof initialUsers[0] === 'object' && (
          <ButtonComponent 
            text={<><FaPlus style={{ marginRight: '8px' }} />Add New</>} 
            onClick={handleAddRow} 
            className="add-button" 
          />
        )}
        <ButtonComponent 
          text={isSaving ? "Saving..." : "Save"} 
          onClick={()=>handleSaveClick()} 
          className="save-button" 
          // disabled={isSaving} 
        />
      </div>
      </div>
      </div>
      <div className="table-container">
        <table className="custom-table">
          <thead>
            <tr>
              <th>Username</th>
              <th>Email</th>
             
              <th>Mobile Number</th>
              <th>Internal ID</th>
              {typeof initialUsers[0] === 'object' && (
                <th>Password</th>
              )}
              
            
              <th className="small-column">Remove</th>
            </tr>
          </thead>
          <tbody>
            {userData.map((user, index) => (
              <tr key={index}>
                <td>
                  <InputComponent
                    className="table-input"
                    value={user.username} 
                    onChange={(e) => handleInputChange(index, 'username', e.target.value)} 
                  />
                </td>
                <td>
                  <InputComponent
                    className="table-input"
                    value={user.email} 
                    onChange={(e) => handleInputChange(index, 'email', e.target.value)} 
                  />
                </td>
               
                <td>
                  <InputComponent
                    className="table-input"
                    value={user.mobile_number} 
                    onChange={(e) => handleInputChange(index, 'mobile_number', e.target.value)} 
                  />
                </td>
                <td>
                  <InputComponent
                    className="table-input"
                    value={user.internal_id} 
                    onChange={(e) => handleInputChange(index, 'internal_id', e.target.value)} 
                  />
                </td>
                {typeof initialUsers[0] === 'object' && (
        <td>
          <InputComponent
            className="table-input"
            value={user.password || ''} 
            onChange={(e) => handleInputChange(index, 'password', e.target.value)} 
          />
        </td>
      )}
             
                <td className="actions1">
                  <FaTrash className="action-icon1" onClick={() => handleDelete(index)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
  );
};

export default BulkDelivery;
